import React from "react";
import { useAppContext } from "../../context/app-context";
import { useMerchantContext } from "../../context/merchant-context";

interface EditOfferBtnPropsType {
  offerInfo?: any;
}

function EditOfferBtn({ offerInfo }: EditOfferBtnPropsType) {
  console.log("EDIT OFFER BTN", offerInfo);

  const merchantContext = useMerchantContext();
  const { setCustomPageIndex, updateSelectedTableOffer } = merchantContext;
  const context = useAppContext();
  const { setIsAddingOffer } = merchantContext;
  return (
    <div>
      <button
        style={{
          background: "transparent",
          outline: "none",
          border: "none",
          cursor: "pointer",
          textDecoration: "underline",
          color: "rgb(64, 81, 167)",
        }}
        onClick={() => {
          // alert("HELLO BRO!");
          updateSelectedTableOffer(offerInfo);
          setIsAddingOffer(false);
          setCustomPageIndex(6);
        }}
      >
        {offerInfo?.original?.offer_text}
      </button>
    </div>
  );
}

export default EditOfferBtn;
