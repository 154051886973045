import { useState } from "react";
import "./style.css";
import { Modal, Button } from "react-bootstrap";
import OtpInput from "react18-input-otp";

interface Props {
  setOpenModal: any;
  openModal: any;
  cb?: Function;
}

const PopOtpModel = ({
  setOpenModal,
  openModal,
  cb = (otp: string) => {},
}: Props) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(true);

  const handleChange = (enteredOtp: any) => {
    console.log(enteredOtp);
    setOtp(enteredOtp);
  };

  return (
    <Modal show={openModal} centered>
      {/* <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header> */}
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column", // added this line
        }}
      >
        <div>
          <p
            style={{
              padding: "10px",
              paddingBottom: "0px",
              marginTop: "20px",
              marginBottom: "0px",
            }}
          >
            Please Enter a OTP
          </p>
        </div>
        {error === false && (
          <p style={{ color: "red", marginTop: "10px" }}>
            Please enter a correct OTP.
          </p>
        )}
        <div style={{ marginTop: "20px" }}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>-</span>}
            inputStyle={{
              width: "100%",
              margin: "10px 0",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "16px",
            }}
            focusStyle={{
              outline: "none",
              boxShadow: "0 0 5px #3f51b5",
              border: "1px solid #3f51b5",
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="secondary"
          style={{
            background: "red",
            borderColor: "red",
          }}
          onClick={() => {
            setOpenModal();
            setError(true);
            setOtp("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const result = await cb(otp);
            console.log(error);
            if (result == false) setError(false);
          }}
          style={{
            background: "rgb(64, 81, 167)",
            borderColor: "rgb(64, 81, 167)",
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopOtpModel;
