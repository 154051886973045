import { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import { FaGreaterThan } from "react-icons/fa";
import LoadingButton from "../../components/LoadingButton";
import DateUtils from "../../utils/date-utils";
import { useAuth } from "../../hooks/useAuth";
import { useUserContext } from "../../context/user-context";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useAppContext } from "../../context/app-context";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import Urls from "../../utils/urls";

const MerchantSignAgreement = () => {
  const navigate = useNavigate();
  const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));
  const { merchant } = JSON.parse(localStorage.getItem("user"));

  const context = useAppContext();
  const {
    updateFormData,
    setOffers,
    setStores,
    setSigningState,
    setselectedAgreement,
  } = context;
  const [loadingAgreement, setLoadingAgreement] = useState(false);
  const [loadingAgreementForSigning, setLoadingAgreementForSigning] =
    useState(false);
  const checkAuth = useAuth("/login");
  const user = useUserContext();
  console.log("USER INFO", user);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState({
    merchant: "",
  });
  const [errorSearchTerm, setErrorSearchTerm] = useState("");
  const [tableValues, setTableValues] = useState([]);
  const [showNoResultsError, setShowNoResultsError] = useState(false);
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  const postsPerPage = 6;

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (typeOfUser.CanSignAgreement) {
      (async () => {
        var data = encodeURIComponent(
          JSON.stringify({
            objectId: {
              $in: merchant,
            },
          })
        );
        console.log("Merchant", merchant);
        var config = {
          method: "get",
          url: `${Urls.parseUrl}/classes/merchant?where=${data}&order=-createdAt`,
          headers: {
            "X-Parse-Master-Key": Urls.MASTERKEY,
            "X-Parse-Application-Id": Urls.APPLICATIONID,
          },
        };
        try {
          const response = await axios(config);
          console.log("response.data.results", response.data.results);
          // if (response.data.results.length > 0) setCurrentPage(currentPage + 1);
          if (response?.data?.results?.length === 0) {
            setShowNoResultsError(true);
            setErrorSearchTerm(value.merchant);
          } else {
            setShowNoResultsError(false);
          }
          setTableValues(response.data.results);
        } catch (e) {}
      })();
    }
  }, []);

  const DirectToSignPage = async (
    id: string,
    isViewing: boolean,
    isSigning: boolean
  ) => {
    setSelectedAgreementId(id);
    console.log("ID", id);
    var data = {
      where: {
        "merchant.id": {
          $eq: id,
        },
      },
    };

    var config = {
      method: "get",
      url: `${Urls.parseUrl}/classes/agreement`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
      },
      params: data,
    };
    if (isSigning == true) {
      setLoadingAgreementForSigning(true);
    } else {
      setLoadingAgreement(true);
    }
    try {
      const response = await axios(config);
      // setLoadingAgreementForSigning(false);
      console.log(response);
      const agreementList: any = response.data.results;
      console.log(agreementList[0]);
      setselectedAgreement(agreementList[0]);
      let merchantResp = await ServerUploadsUtils.fetchObjectIds("merchant", [
        agreementList[0]?.merchant?.id,
      ]);
      let brandResp = await ServerUploadsUtils.fetchObjectIds("brand", [
        agreementList[0]?.brand?.id,
      ]);
      let storesResp = await ServerUploadsUtils.fetchStoresAndOffers(
        "stores",
        agreementList[0]?.stores
      );
      let offerWhereClause = {
        where: { objectId: { $in: agreementList[0]?.offers } },
      };
      let offerConfig = await AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + "/classes/offers?include=stores&order=batchOrder",
        null,
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        offerWhereClause
      );
      let offersResp = await axios(offerConfig);
      // let offersResp = await ServerUploadsUtils.fetchObjectIds(
      //   "offers",
      //   agreementList[0]?.offers
      // );

      console.log("merchantResp", merchantResp);
      console.log("brandResp", brandResp);
      console.log("storesResp", storesResp);
      console.log("offersRep", offersResp);
      const formData = {
        ...merchantResp?.data?.results[0],
        ...brandResp?.data?.results[0],
      };
      console.log("ABCDRF", formData);
      const values = {
        salesRep: formData?.salesRep,
        agreementType: formData?.agreementType,
        registration_no: formData?.registrationNo,
        signingStatus: formData?.signingStatus,
        mouType: formData?.mouType,
        mouValidity: formData?.mouValidity,
        brandType: formData?.brandType,
        country: formData?.country,
        poBox: formData?.poBox,
        brandName: formData?.brandName,
        spocName: formData?.spocName,
        spocNo: formData?.spocNo,
        spocEmail: formData?.spocEmail,
        extraField: formData.extraField,
        companyType: formData?.companyType,
        emirates: formData?.emirates,
        sales: formData.sales,
        signatureDisplayName: formData?.signatureDisplayName,
        templateType: formData.templateType,
        authorisedSignatoryName: formData?.authorisedSignatoryName,
        authorisedSignatoryNo: formData?.authorisedSignatoryNo,
        merchantName: formData?.merchantName,
        corporateAddress: formData?.corporateAddress,
        authorisedSignatoryMail: formData?.authorisedSignatoryMail,
        authorisedSignatoryMail_confirm: formData?.authorisedSignatoryMail,
        registeredAddress: formData?.registeredAddress,
        categoryOfMerchant: formData?.categoryOfMerchant,
        registeredAddressPincode: formData?.registeredAddressPincode,
        corporateAddressCountry: formData?.corporateAddressCountry,
        registeredAddressCountry: formData?.registeredAddressCountry,
        corporateAddressPincode: formData.corporateAddressPincode,
        store_name: formData?.storeName,
        store_address: formData?.storeAddress,
        gst_no: formData?.gstNo,
        registeredAddressState: formData?.registeredAddressState,
        registeredAddressCity: formData?.registeredAddressCity,
        corporateAddressCity: formData?.corporateAddressCity,
        corporateAddressState: formData?.corporateAddressState,
        contact_name: formData?.contactName,
        contact_no: formData?.contactNo,
        shift_number: formData?.shiftNumber,
        store_email: formData?.storeEmail,
        upload_carges_slip: formData?.uploadCargesSlip,
        designation: formData?.designation,
        logo: formData?.logo,
        merchantId: merchantResp?.data?.results[0].objectId,
        brandId: brandResp?.data?.results[0].objectId,
        upload_signed_agreement: agreementList[0].upload_signed_agreement,
      };
      console.log("JHTK", values);
      updateFormData(values);
      setStores(storesResp);
      setOffers(offersResp?.data?.results);

      setSigningState(true);
      navigate("/edit-merchant", {
        state: {
          isSigning: isSigning,
          isViewing: isViewing,
        },
      });
    } catch (e) {
      if (isSigning == true) {
        setLoadingAgreementForSigning(false);
      } else {
        setLoadingAgreement(false);
      }
    }
  };

  const indexOfLastPage = currentPage * postsPerPage;
  let indexOfFirstPage;
  if (indexOfLastPage != 0) indexOfFirstPage = indexOfLastPage - postsPerPage;
  const currentPosts = tableValues.slice(indexOfFirstPage, indexOfLastPage);
  const PageCount = Math.ceil(tableValues.length / postsPerPage);
  return (
    <div
      className="dash"
      style={{
        flexGrow: 1,
      }}
    >
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      {showNoResultsError ? (
        <div style={{ textAlign: "center" }}>
          {!typeOfUser.CanSignAgreement && <span>No result found for</span>}
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            {errorSearchTerm}
          </span>
        </div>
      ) : (
        tableValues?.length > 0 && (
          <div
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              marginLeft: "13px",
              marginRight: "13px",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                background: "white",
                margin: "10px",
                marginBottom: 0,
              }}
            >
              <div style={{ overflowX: "scroll", marginTop: "100px" }}>
                <Table>
                  {tableValues.length !== 0 ? (
                    <thead>
                      <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                        <th>S.No</th>
                        <th>Merchant </th>
                        <th style={{ minWidth: "100px" }}>Sales Rep </th>
                        <th>CreatedAt </th>
                        <th>UpdatedAt </th>
                        {typeOfUser.CanSignAgreement && <th>Sign</th>}
                        {typeOfUser.CanSignAgreement && <th>View</th>}
                      </tr>
                    </thead>
                  ) : null}
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {currentPosts.map((data: any, index) => (
                      <tr key={index}>
                        <td
                          style={
                            {
                              // textAlign: "center",
                            }
                          }
                        >
                          {index + 1 + (currentPage - 1) * 6}
                        </td>
                        <td>{data.merchantName}</td>
                        <td>{data.salesRep}</td>
                        <td style={{ minWidth: "100px" }}>
                          {DateUtils.formatDate(data.createdAt)}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          {" "}
                          {DateUtils.formatDate(data.updatedAt)}
                        </td>

                        {typeOfUser.CanSignAgreement && (
                          <td>
                            <LoadingButton
                              style={{
                                boxShadow: "none",
                                padding: "0.25rem 0.5rem",
                                fontSize: "0.7109375rem",
                                borderRadius: "0.2rem",
                                color: "#fff",
                                backgroundColor: "#405189",
                                borderColor: "#405189",
                                width: "100px",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={async (e) => {
                                e.preventDefault();
                                await DirectToSignPage(
                                  data.objectId,
                                  false,
                                  true
                                );
                              }}
                              isLoading={
                                loadingAgreementForSigning &&
                                data.objectId === selectedAgreementId
                              }
                              disabled={data.isSigning == "true"}
                              text={
                                data.isSigning == "true"
                                  ? "Signed"
                                  : "Click to Sign"
                              }
                            />
                          </td>
                        )}
                        {typeOfUser.CanSignAgreement && (
                          <td>
                            <LoadingButton
                              style={{
                                boxShadow: "none",
                                padding: "0.25rem 0.5rem",
                                fontSize: "0.7109375rem",
                                borderRadius: "0.2rem",
                                color: "#fff",
                                backgroundColor: "#405189",
                                borderColor: "#405189",
                                width: "100px",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={async (e) => {
                                e.preventDefault();
                                await DirectToSignPage(
                                  data.objectId,
                                  true,
                                  false
                                );
                              }}
                              isLoading={
                                loadingAgreement &&
                                data.objectId === selectedAgreementId
                              }
                              disabled={data.isSigning != "true"}
                              text={"View"}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              {currentPage >= 1 && currentPosts?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    style={{
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage > 1 ? "#405189" : "#878a99",
                    }}
                    disabled={currentPage > 1 ? false : true}
                  >
                    Previous
                  </button>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      // color: "black",
                      // fontWeight: "bold",
                      backgroundColor: "#405189",
                      borderColor: "#405189",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                    }}
                  >
                    {currentPage}
                  </div>
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color:
                        currentPage > 0 && currentPage < PageCount
                          ? "#405189"
                          : "#878a99",
                    }}
                    disabled={
                      currentPage > 0 && currentPage < PageCount ? false : true
                    }
                  >
                    Next
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default MerchantSignAgreement;
