import React, { FC, ClassAttributes, InputHTMLAttributes } from "react";
import { Field, ErrorMessage, FieldHookConfig } from "formik";

interface FormikSelectPropsType {
  name: string;
  label: string;
  options: string[];
}

function FormikSelect({
  name,
  label,
  options = [],
  ...props
}: FormikSelectPropsType &
  InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement> &
  FieldHookConfig<string>) {
  console.log("VALUES", options);
  console.log(
    "VALUES 2",
    options.map((option) => option)
  );
  return (
    <div>
      <p
        style={{
          color: "#212124",
          fontWeight: "bold",
          fontSize: "13px",
          padding: 0,
          margin: 0,
          paddingBottom: "8px",
        }}
      >
        {label}
      </p>
      <Field
        className="form-control"
        as="select"
        name={name}
        {...props}
        style={{
          fontSize: "13px",
        }}
      >
        <option value="">Please Select</option>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Field>
      <div
        style={{
          color: "red",
          fontSize: "13px",
        }}
      >
        <small>
          <ErrorMessage name={name} />
        </small>
      </div>
    </div>
  );
}

export default FormikSelect;
