import React from "react";

interface BrandNamePropsType {
  formData?: any;
}

function BrandName({ formData }: BrandNamePropsType) {
  return <div>{formData?.brandName}</div>;
}

export default BrandName;
