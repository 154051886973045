import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import FormInput from "../../../Input";
import Text from "../../../Text";

interface Props {
  formData: any;
  setFormData: any;
  program: any;
  setProgram: any;
  setTermOfUse: any;
  temOfUse: any;
  setRedemption: any;
  redemption: any;
  offerData: any;
  linkStore: any;
  setLinkStore: any;
}

const UpdateOffer = ({
  formData,
  setFormData,
  setProgram,
  setTermOfUse,
  setRedemption,
  offerData,
  linkStore,
  setLinkStore,
}: Props) => {
  const programName = [
    "Axis Bank",
    "DBS",
    "Bank of Baroda",
    "ICICI Bank",
    "HSBC Bank",
    "Cred",
    "IDFC",
    "Citi Bank Gourmet Priviliges",
  ];
  const termOfuseName = ["dhufbdjdf", "duikfkfdud", "idfjjfjhdibdiid"];
  const redemptionName = [
    "dhufjjfjbdjdf",
    "duikfkfdfkkfud",
    "idfjjkfffjhdibdiid",
  ];
  return (
    <>
      <div className="form-box">
        <Text color="#494f57" fontWeight="bold" fontSize="20px">
          Add Offer
        </Text>
      </div>
      <div className="text-1">
        <Text color="#212124" fontWeight="bold" fontSize="15px">
          Offer Text
        </Text>
        <FormInput
          type="text"
          placeholder="Offer Text"
          value={offerData.offerText}
          onChange={(e: any) =>
            setFormData({
              ...formData,
              offer_text: e.target.value,
            })
          }
        />
      </div>
      <div className="text-1">
        <Text color="#212124" fontWeight="bold" fontSize="15px">
          Program
        </Text>
        <Multiselect
          isObject={false}
          onRemove={(event) => {
            setProgram([...event]);
          }}
          onSelect={(event, selectedItem) => {
            setProgram([...event]);
          }}
          options={offerData.program}
          showCheckbox
        />
      </div>
      <div className="text-1">
        <Text color="#212124" fontWeight="bold" fontSize="15px">
          Link Stores
        </Text>
        <Multiselect
          isObject={true}
          onRemove={(event) => {
            setLinkStore([...event]);
          }}
          onSelect={(event, selectedItem) => {
            setLinkStore([...event]);
          }}
          options={offerData.linkStore}
          displayValue="store_id"
          showCheckbox
        />
      </div>
      <div className="text-1">
        <Text color="#212124" fontWeight="bold" fontSize="15px">
          Term of Use
        </Text>
        <Multiselect
          isObject={false}
          onRemove={(event) => {
            setTermOfUse([...event]);
          }}
          onSelect={(event, selectedItem) => {
            setTermOfUse([...event]);
          }}
          options={offerData.termOfuseName}
          showCheckbox
        />
      </div>
      <div className="text-1" style={{ marginBottom: "30px" }}>
        <Text color="#212124" fontWeight="bold" fontSize="15px">
          Redemption Process
        </Text>
        <Multiselect
          isObject={false}
          onRemove={(event) => {
            setRedemption([...event]);
          }}
          onSelect={(event, selectedItem) => {
            setRedemption([...event]);
          }}
          options={offerData.redemptionName}
          showCheckbox
        />
      </div>
    </>
  );
};

export default UpdateOffer;
