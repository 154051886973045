import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ImArrowLeft2 } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../../NavBar";
import UpdateOffer from "../update-offer-1";
import axios from "axios";
import Urls from "../../../../utils/urls";

const ParentFormUpdateOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { offerData } = location.state;
  //   console.log(offerData);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    _id: offerData.objectId,
    offerText: offerData.offerText,
    program: offerData.program,
    linkStore: offerData.linkStore,
    termOfUse: offerData.termOfUse,
    redemption: offerData.redemption,
  });
  //   console.log(formData);
  const [termOfUse, setTermOfUse] = useState([]);
  const [redemption, setRedemption] = useState([]);
  const [program, setProgram] = useState([]);
  const [linkStore, setLinkStore] = useState([]);
  useEffect(() => {
    setFormData({
      ...formData,
      termOfUse,
    });
  }, [termOfUse, setTermOfUse]);
  useEffect(() => {
    setFormData({
      ...formData,
      redemption,
    });
  }, [redemption, setRedemption]);
  useEffect(() => {
    setFormData({
      ...formData,
      program,
    });
  }, [program, setProgram]);

  useEffect(() => {
    setFormData({
      ...formData,
      linkStore,
    });
  }, [linkStore, setLinkStore]);

  const PageDisplay = () => {
    if (page === 0)
      return (
        <UpdateOffer
          offerData={offerData}
          formData={formData}
          setFormData={setFormData}
          temOfUse={termOfUse}
          setTermOfUse={setTermOfUse}
          program={program}
          setProgram={setProgram}
          redemption={redemption}
          setRedemption={setRedemption}
          linkStore={linkStore}
          setLinkStore={setLinkStore}
        />
      );
  };
  const updateOffer = async () => {
    var data = {
      kxbjkd: "xbjbds",
    };
    var config = {
      method: "put",
      url: `${Urls.parseUrl}/classes/offers/${formData._id}`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const updateOffered = await axios(config);
    navigate("/");
  };
  return (
    <>
      <NavBar />
      <div className="">
        <div className="sub-text"></div>
      </div>
      <Form>
        {page !== 0 ? (
          <Button
            variant="light"
            onClick={(e: any) => {
              e.preventDefault();
              setPage((currPage) => currPage - 1);
            }}
            style={{ fontSize: "10px" }}
          >
            <ImArrowLeft2 /> Go Back
          </Button>
        ) : null}
        {PageDisplay()}
        <div className="btn1">
          <div>
            <div>
              <Button onClick={updateOffer}>Update Offer</Button>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        ></div>
      </Form>
      <p
        className="copytext"
        style={{
          color: "black",
          paddingTop: "20px",
          fontSize: "10px",
          textAlign: "center",
          display: "grid",
          justifyContent: "center",
        }}
      >
        Copyright © 2022 Powered by Thriwe Glocal Pvt. Ltd.
      </p>
    </>
  );
};

export default ParentFormUpdateOffer;
