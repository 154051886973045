import React from "react";
import { Table } from "react-bootstrap";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useAppContext } from "../../context/app-context";
import { useNavigate } from "react-router-dom";
import { useMerchantContext } from "../../context/merchant-context";
import DateUtils from "../../utils/date-utils";
import LoadingButton from "../LoadingButton";

interface Props {
  Brand: any;
  Stores: any;
  Offers: any;
  Files: any;
  SalesRep: any;
  createdAt: any;
  index: any;
  onClick: Function;
  agreementId: any;
  loading: boolean;
  updatedAt: any;
}

const Box = ({
  Brand,
  Stores,
  Offers,
  Files,
  SalesRep,
  createdAt,
  index,
  onClick,
  agreementId,
  loading,
  updatedAt,
}: Props) => {
  const context = useAppContext();
  const { updateFormData, setIsEditing, setselectedAgreement } = context;
  const { formData } = context.state;
  const navigate = useNavigate();
  const merchantContext = useMerchantContext();
  // const { setIsAddingStore, setIsAddingOffer } = merchantContext;

  console.log("SELECT FORM DATA", formData);

  return (
    <div>
      {" "}
      <Table bordered>
        <tbody key={index}>
          <tr style={{ fontSize: "13px" }}>
            <td>Brand Name</td>
            <td>{Brand.name}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td>No of Stores</td>
            <td>{Stores.length}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td>No of Offers</td>
            <td>{Offers.length}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td>Sales Rep.</td>
            <td>{SalesRep}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td>CreatedAt</td>
            <td>{DateUtils.formatDate(createdAt)}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td>UpdatedAt</td>
            <td>{DateUtils.formatDate(updatedAt)}</td>
          </tr>
          <tr style={{ fontSize: "13px" }}>
            <td colSpan={2} style={{ textAlign: "end" }}>
              <LoadingButton
                style={{
                  outline: 0,
                  background: "white",
                  border: "1px solid rgba(0,0,0,0.1)",
                  marginTop: "5px",
                  marginLeft: "20px",
                  minWidth: "150px",
                  color: "black",
                }}
                onClick={() => {
                  onClick();
                  // const formData = {
                  //   salesRep: "hello",
                  //   merchantName: "world",
                  // };
                  // updateFormData(formData);
                  // navigate("/add-merchant");
                  setIsEditing(true);
                  setselectedAgreement(agreementId);
                  // setIsAddingOffer(false);
                  // setIsAddingStore(false);
                }}
                text="Edit Agreement"
                isLoading={loading}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Box;
