import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import FormInput from "../../components/Input";
import Text from "../../components/Text";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import { FaGreaterThan } from "react-icons/fa";
import LoadingButton from "../../components/LoadingButton";
import DateUtils from "../../utils/date-utils";
import { useAuth } from "../../hooks/useAuth";
import { useUserContext } from "../../context/user-context";
import Urls from "../../utils/urls";
import ToastUtils from "../../utils/toast/toast-utils";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import PopupLogs from "../../components/PopuLogs";
import ServerUploadsUtils from "../../utils/serverUploads";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [loadingAgreement, setLoadingAgreement] = useState(false);
  const [count, setCount] = useState(0);
  const checkAuth = useAuth("/login");
  const user = useUserContext();
  console.log("USER INFO", user);
  console.log("USER INFO", isLoggedIn);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [allLogs, setAllLogs] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const [value, setValue] = useState({
    merchant: "",
  });
  const [errorSearchTerm, setErrorSearchTerm] = useState("");
  const [tableValues, setTableValues] = useState([]);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showNoResultsError, setShowNoResultsError] = useState(false);
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  useEffect(() => {
    console.log(isLoggedIn, "isLoggedIn");
    if (isLoggedIn == "false") {
      navigate("/login");
    }
  }, [navigate, isLoggedIn]);
  useEffect(() => {
    checkAuth();
  }, []);

  const onClick = () => {
    navigate("/add-merchant");
  };

  const getMerchants = async (limit: number, skip: number) => {
    try {
      var data = {
        where: {
          merchantPointer: {
            $inQuery: {
              where: {
                merchantName: {
                  $regex: value.merchant ? value.merchant.trim() : "",
                  $options: "i",
                },
              },
              className: "merchant",
            },
          },
        },
        order: "-createdAt",
        keys: "brandPointer.registeredAddressCity,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,storePointer.objectId,offersPointer.objectId",
        limit: limit,
        skip: skip,
        count: 1,
      };
      let getConfigCount = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Urls.parseUrl}/classes/agreement`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        params: data,
      };
      const getMerchant = await axios(getConfigCount);
      return getMerchant;
    } catch (error) {
      return error.message;
    }
  };

  const getTableValues = async () => {
    setLoading(true);
    const getMerchant = await getMerchants(15, 0);
    try {
      if (getMerchant.data.count === 0 && !getMerchant.data) {
        setShowNoResultsError(true);
        setErrorSearchTerm(value.merchant);
        setLoading(false);
        return;
      }
      if (getMerchant.data.count % 15) {
        setCount(Math.trunc(getMerchant.data.count / 15) + 1);
      } else {
        setCount(Math.trunc(getMerchant.data.count / 15));
      }
      setShowNoResultsError(false);
      setTableValues(getMerchant.data.results);
      setCurrentPage(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    return;
  };

  console.log("Count:", count);

  useEffect(() => {
    if (typeOfUser?.CanSignAgreement) {
      navigate("/sign-agreement");
    } else if (typeOfUser?.SalesHandler) {
      navigate("/sales");
    } else if (typeOfUser?.audit || typeOfUser?.checkAudit) {
      navigate("/audit");
    }
  }, []);

  const onClickTable = () => {
    getTableValues();
  };
  const DirectToAgreement = async (id: string) => {
    // console.log(id);
    setSelectedAgreementId(id);
    var data = {
      where: {
        "merchant.id": {
          $eq: id,
        },
      },
    };

    var config = {
      method: "get",
      url: `${Urls.parseUrl}/classes/agreement`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
      },
      params: data,
    };
    setLoadingAgreement(true);
    try {
      const response = await axios(config);
      setLoadingAgreement(false);
      console.log(response);
      const agreementList: any = response.data.results;
      navigate("/agreement-list", {
        state: {
          agreementList,
        },
      });
    } catch (e) {
      setLoadingAgreement(false);
    }
  };

  const getViewLogs = async (
    merchantId: string,
    brandId: string,
    storesId: Array<string>,
    offersId: Array<string>
  ) => {
    console.log("merchantId", merchantId);
    console.log("brandId", brandId);
    console.log("storesId", storesId);
    console.log("offersId", offersId);
    try {
      // merchant logs
      const merchantLogQuery = {
        where: {
          collectionId: merchantId,
        },
      };
      const merchantLogConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        merchantLogQuery
      );

      // brand logs
      const brandLogQuery = {
        where: {
          collectionId: brandId,
        },
      };
      const brandLogConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        brandLogQuery
      );

      // store logs
      const storesIdsObject = [];
      storesId.forEach((id: string) => {
        storesIdsObject.push({ collectionId: id });
      });
      const storesLogQuery = {
        where: {
          $or: storesIdsObject,
        },
      };
      const storesLogsConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        storesLogQuery
      );

      // offers logs
      const offersIdsObject = [];
      offersId.forEach((id: string) => {
        offersIdsObject.push({ collectionId: id });
      });
      const offersLogQuery = {
        where: {
          $or: offersIdsObject,
        },
      };
      const offersLogsConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        offersLogQuery
      );

      const merchantLogsResult = await axios(merchantLogConfig);
      const merchantLogsList = merchantLogsResult.data.results;

      const brandLogsResult = await axios(brandLogConfig);
      const brandLogsList = brandLogsResult.data.results;

      const storeLogsResult = await axios(storesLogsConfig);
      const storeLogsList = storeLogsResult.data.results;

      const offerLogsResult = await axios(offersLogsConfig);
      const offersLogsList = offerLogsResult.data.results;
      setAllLogs({
        merchantLogsList,
        brandLogsList,
        storeLogsList,
        offersLogsList,
      });
      setLoadingLogs(false);
      if (
        merchantLogsList.length === 0 &&
        brandLogsList.length === 0 &&
        storeLogsList.length === 0 &&
        offersLogsList.length === 0
      ) {
        return "No Logs Found";
      }
    } catch (error) {
      setLoadingLogs(false);
    }
  };

  return (
    <div
      className="dash"
      style={{
        flexGrow: 1,
      }}
    >
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      {!typeOfUser?.CanSignAgreement && (
        <div className="dashboard">
          <div className="sub-text">
            <Text color="#494f57" fontWeight="500" fontSize="22.2875px">
              Submission
            </Text>
          </div>
          {showPopup && (
            <PopupLogs data={allLogs} onClose={() => setShowPopup(false)} />
          )}
          <Form className="first-comp">
            <FormInput
              type="text"
              placeholder="Please enter a merchant name"
              value={value.merchant}
              onChange={(e: any) => {
                setValue({ ...value, merchant: e.target.value });
                setShowEmptyError(false);
              }}
            />
            {showEmptyError && (
              <div
                style={{
                  color: "red",
                }}
              >
                <small>Please enter merchant name</small>
              </div>
            )}
            <div
              className="btn-group-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <LoadingButton
                isLoading={loading}
                text={"Search"}
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #405089",
                  background: "rgb(64,81,167)",
                  outlineColor: "navajowhite",
                  fontSize: "13px",
                  // width: "70px",
                  height: "35px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onClickTable();
                }}
                type="submit"
              />
            </div>
          </Form>
          <div className="sub-text1">
            <Text color="rgb(73,80,87)" fontWeight="500" fontSize="16.25px">
              Or
            </Text>
          </div>
          <div
            className="btn-group-2"
            style={{
              color: "white",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            <button
              type="button"
              style={{
                color: "white",
                borderRadius: "5px",
                border: "1px solid #0ab39c",
                background: "#0ab39c",
                outlineColor: "navajowhite",
                height: "40px",
                padding: "6px",
                marginBottom: "50px",
                fontSize: "13px",
                fontWeight: "400",
              }}
              onClick={onClick}
            >
              Start New Submission
            </button>
          </div>
        </div>
      )}
      {showNoResultsError ? (
        <div style={{ textAlign: "center" }}>
          {typeOfUser?.CanSignAgreement && <span>No result found for:</span>}
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            {errorSearchTerm}
          </span>
        </div>
      ) : (
        tableValues?.length > 0 && (
          <div
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              marginLeft: "13px",
              marginRight: "13px",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                background: "white",
                margin: "10px",
                marginBottom: 0,
              }}
            >
              <div style={{ overflowX: "scroll" }}>
                <Table>
                  {tableValues.length !== 0 ? (
                    <thead>
                      <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                        <th>S.No</th>
                        <th>Merchant </th>
                        <th> MOU Type</th>
                        <th style={{ minWidth: "100px" }}>Sales Rep </th>
                        <th>Created At </th>
                        <th>Updated At </th>
                        <th>Action</th>
                        {typeOfUser?.CanSignAgreement && (
                          <th>Sign Agreement</th>
                        )}
                        <th>Status</th>
                        <th>View Logs</th>
                      </tr>
                    </thead>
                  ) : null}
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {tableValues.map((data: any, index) => (
                      <tr key={index}>
                        <td
                          style={
                            {
                              // textAlign: "center",
                            }
                          }
                        >
                          {index + 1 + currentPage * 15}.
                        </td>
                        <td>{data.merchantPointer.merchantName}</td>
                        <td>{data.merchantPointer.mouType}</td>
                        <td>{data.merchantPointer.salesRep}</td>
                        <td style={{ minWidth: "100px" }}>
                          {DateUtils.formatDate(data.createdAt)}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          {" "}
                          {DateUtils.formatDate(data.updatedAt)}
                        </td>
                        <td>
                          <LoadingButton
                            style={{
                              boxShadow: "none",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.7109375rem",
                              borderRadius: "0.2rem",
                              color: "#fff",
                              backgroundColor: "#405189",
                              borderColor: "#405189",
                              width: "100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={async (e) => {
                              e.preventDefault();
                              await DirectToAgreement(
                                data.merchantPointer.objectId
                              );
                            }}
                            isLoading={
                              loadingAgreement &&
                              data.merchantPointer.objectId ===
                                selectedAgreementId
                            }
                            text="View Details"
                          />
                        </td>
                        <td>
                          {data?.merchantPointer.salesConfirm === "approved" ? (
                            <span style={{ color: "green" }}>Approved</span>
                          ) : data?.merchantPointer.salesConfirm ===
                            "rejected" ? (
                            <span style={{ color: "red" }}>Rejected</span>
                          ) : data?.merchantPointer.signingStatus === "true" ? (
                            <span style={{ color: "blue" }}>Signed</span>
                          ) : data?.merchantPointer.signingStatus ===
                            "false" ? (
                            <span style={{ color: "purple" }}>
                              Submitted for Signatures
                            </span>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          <LoadingButton
                            style={{
                              boxShadow: "none",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.7109375rem",
                              borderRadius: "0.2rem",
                              color: "#fff",
                              backgroundColor: "#405189",
                              borderColor: "#405189",
                              width: "100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={async (e) => {
                              setLoadingLogs(true);
                              setSelectedAgreementId(
                                data.merchantPointer.objectId
                              );
                              const merchantPointer = data?.merchantPointer;
                              const brandPointer = data?.brandPointer;
                              const storePointer = data?.storePointer;
                              const offersPointer = data?.offersPointer;
                              const storesId = [];
                              storePointer.forEach((store: any) => {
                                storesId.push(store.objectId);
                              });
                              const offersId = [];
                              offersPointer.forEach((offer: any) => {
                                offersId.push(offer.objectId);
                              });
                              const isLogsExist: any =
                                await ServerUploadsUtils.getViewLogs(
                                  merchantPointer.objectId,
                                  brandPointer.objectId,
                                  storesId,
                                  offersId,
                                  data?.objectId
                                );
                              if (isLogsExist === "No Logs Found") {
                                ToastUtils.showInfoToast("No Logs Found");
                                setLoadingLogs(false);
                                navigate("/");
                                return;
                              } else if (isLogsExist === "Error Occurred") {
                                ToastUtils.showInfoToast("Error Occurred");
                                setLoadingLogs(false);
                                navigate("/");
                                return;
                              }
                              const {
                                merchantLogsList,
                                brandLogsList,
                                storeLogsList,
                                offersLogsList,
                                agreementLogsList,
                              } = isLogsExist;
                              setAllLogs({
                                merchantLogsList,
                                brandLogsList,
                                storeLogsList,
                                offersLogsList,
                                agreementLogsList,
                              });
                              setLoadingLogs(false);
                              setShowPopup(true);
                            }}
                            isLoading={
                              loadingLogs &&
                              data.merchantPointer.objectId ===
                                selectedAgreementId
                            }
                            text="View Logs"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              {currentPage >= 0 && tableValues?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={async () => {
                      const getMerchant = await getMerchants(
                        15,
                        (currentPage - 1) * 15
                      );
                      setCurrentPage(currentPage - 1);
                      setTableValues(getMerchant?.data?.results);
                    }}
                    style={{
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage > 0 ? "blue" : "#878a99",
                    }}
                    disabled={currentPage > 0 ? false : true}
                  >
                    Previous
                  </button>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      // color: "black",
                      // fontWeight: "bold",
                      backgroundColor: "#405189",
                      borderColor: "#405189",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                    }}
                  >
                    {currentPage + 1}
                  </div>
                  <button
                    className="page-item pagination-prev disabled"
                    // onClick={() =>
                    //   console.log((currentPage - 1) * 15)}
                    onClick={async () => {
                      const getMerchant = await getMerchants(
                        15,
                        (currentPage + 1) * 15
                      );
                      setCurrentPage(currentPage + 1);
                      setTableValues(getMerchant?.data?.results);
                    }}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage >= count - 1 ? "#878a99" : "blue",
                    }}
                    disabled={currentPage >= count - 1 ? true : false}
                  >
                    Next
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Dashboard;
