import { useNavigate } from "react-router-dom";

export const useAuth = (navigateTo = "/") => {

  const navigate = useNavigate();

   const checkAuth = () =>{
    if(localStorage.getItem("isLoggedIn") !== "true") {
        // alert("PLEASE LOGIN FIRST");
        navigate(navigateTo);
        return;
      } 
      return;
   }

   return checkAuth
}