import React from "react";
import { useMerchantContext } from "../../context/merchant-context";

interface EditOfferBtnPropsType {
  storeInfo?: any;
}

function EditStoreBtn({ storeInfo }: EditOfferBtnPropsType) {
  console.log("EDIT Store BTN", storeInfo);

  const merchantContext = useMerchantContext();
  const { setCustomPageIndex, updateSelectedTableStore, setIsAddingStore } =
    merchantContext;
  return (
    <div>
      <button
        style={{
          background: "transparent",
          outline: "none",
          border: "none",
          cursor: "pointer",
          textDecoration: "underline",
          color: "rgb(64, 81, 167)",
        }}
        onClick={() => {
          // alert("HELLO BRO!");
          updateSelectedTableStore(storeInfo);
          setIsAddingStore(false);
          setCustomPageIndex(3);
        }}
      >
        {storeInfo?.original?.store_name}
      </button>
    </div>
  );
}

export default EditStoreBtn;
