import { useEffect, useRef, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Card, Button, ButtonGroup, Table } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import Urls from "../../utils/urls";
import axios from "axios";
import "./style.css";
import { Form, Formik } from "formik";
import { validationSchema } from "./validationSchema";
import Text from "../../components/Text";
import FormikInput from "../../components/FormikInput";
import LoadingButton from "../../components/LoadingButton";
import ToastUtils from "../../utils/toast/toast-utils";
import * as XLSX from "xlsx";

const SummayDetails = () => {
  const mainSectionFocus = useRef("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSummary, setSelectedSummary] = useState("Total Summary");
  const [summaryForUser, setSummaryForUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [boxData, setBoxData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [initialValues, setInitialValues] = useState({
    startDate: "",
    endDate: "",
  });

  const getFirstDateOfCurrentMonth = () => {
    const today = new Date();
    const firstDateOfCurrentMonth = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), 1)
    );
    firstDateOfCurrentMonth.setUTCHours(0, 0, 0, 0); // Set time to 00:00:00.000 UTC
    return firstDateOfCurrentMonth;
  };

  const getDataCountWithDateAndFields = async (
    filter: string = "",
    fields: {},
    className: string,
    limit = 0,
    keys = "",
    startDate: Date = new Date(),
    endDate: Date = new Date()
  ) => {
    const today = new Date();
    const yesterday = new Date(today);

    const defaultStartDate = new Date().toISOString();
    const defaultEndDate = new Date().toISOString();

    const isStartDateDefault = startDate.toISOString() === defaultStartDate;
    const isEndDateDefault = endDate.toISOString() === defaultEndDate;

    // start time of yesterday date
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0); // Set time to the beginning of yesterday

    //end time of yesterday date
    const endOfYesterday = new Date(today);
    endOfYesterday.setDate(today.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59, 999); // Set time to the end of yesterday

    // start date of current month
    const firstDateOfCurrentMonth = getFirstDateOfCurrentMonth();

    // Set the time zone offset for India (Indian Standard Time)
    const ISTOffset = 5.5 * 60 * 60 * 1000; // India is UTC+5.5

    // Calculate the current time in India
    const indiaTime = new Date(today.getTime() + ISTOffset);

    // Define the type for the `getDataCountWithDateAndFields` object
    type GetDataCountQuery = {
      where: {
        createdAt?: {
          $gte: {
            __type: string;
            iso: string;
          };
          $lt: {
            __type: string;
            iso: string;
          };
        };
      };
    };

    let getDataCountWithDateAndFields: GetDataCountQuery = {
      where: {},
    };

    if (filter !== "till date") {
      getDataCountWithDateAndFields.where.createdAt = {
        $gte: {
          __type: "Date",
          iso:
            filter === "Last Day"
              ? yesterday.toISOString()
              : filter === "Month till date"
              ? firstDateOfCurrentMonth.toISOString()
              : startDate.toISOString(),
        },
        $lt: {
          __type: "Date",
          iso:
            filter === "Last Day"
              ? endOfYesterday.toISOString()
              : filter === "Month till date"
              ? indiaTime.toISOString()
              : endDate.toISOString(),
        },
      };
    }

    for (const field in fields) {
      getDataCountWithDateAndFields.where[field] = fields[field];
    }
    console.log("getDataCountWithDateAndFields", getDataCountWithDateAndFields);
    const withAllConditions = {
      count: 1,
      limit: limit,
      where: JSON.stringify(getDataCountWithDateAndFields.where),
    };
    console.log("WithAllConditions", withAllConditions);
    if (keys) {
      withAllConditions["keys"] = keys;
    }
    const countCondig = AxiosUtils.axiosConfigConstructor(
      "get",
      Urls.parseUrl + `/classes/${className}`,
      null,
      {
        "Content-Type": "application/json",
      },
      withAllConditions
    );

    const count = await axios(countCondig);
    console.log("Count", count);
    return keys ? count.data.results : count.data.count;
  };

  const excelDownlaod = (summaryForUser: any) => {
    try {
      setLoadingDownload(true);
      if (summaryForUser && summaryForUser?.length > 0) {
        const rows = [];
        const header = [
          "S.No.",
          "Email",
          "Total Merchant Submitted",
          "Total Stores Submitted",
          "Total Signed",
          "Pending",
        ];
        rows.push(header);
        summaryForUser?.map((data: any, index: any) => {
          const row = [
            index + 1,
            data.user,
            data.totalAgreementCountForUser,
            data.totalStoreCountForUser,
            data.totalMerchantSignedCountForUser,
            data.totalMerchantPendingCountForUser,
          ];
          rows.push(row);
        });
        console.log("rows", rows);
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        // Write the workbook to an Excel file
        const excelBuffer = XLSX.write(workbook, {
          type: "array",
          bookType: "xlsx",
        });
        const file = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = "sales-summary.xlsx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoadingDownload(false);
      }
    } catch (error) {
      setLoadingDownload(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function fetchApis() {
      await handleFilterClick("Last Day");
    }
    fetchApis();
  }, [selectedSummary]);

  const handleFilterClick = async (filter: string) => {
    setSelectedFilter(filter);
    setLoading(true);
    if (selectedSummary === "Total Summary") {
      const totalMerchantSubmitCount = await getDataCountWithDateAndFields(
        filter,
        {},
        "agreement"
      );

      const totalStoresSubmitCount = await getDataCountWithDateAndFields(
        filter,
        {},
        "stores"
      );

      const totalMerchantPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          filter,
          { signingStatus: "false" },
          "merchant"
        );

      const totalSignedForMerchantCount =
        totalMerchantSubmitCount - totalMerchantPendingForSignatureCount;

      const totalVoucherSubmitCount = await getDataCountWithDateAndFields(
        filter,
        { mouType: "Voucher" },
        "merchant"
      );

      const merchantPointer = {
        $inQuery: {
          where: {
            mouType: "Voucher",
          },
          className: "merchant",
        },
      };

      const totalAgreementCountForVoucher = await getDataCountWithDateAndFields(
        filter,
        { merchantPointer },
        "agreement"
      );

      const totalStoreDataForVoucher = await getDataCountWithDateAndFields(
        filter,
        { merchantPointer },
        "agreement",
        totalAgreementCountForVoucher,
        "stores"
      );
      let totalStoreCountForVoucher = 0;
      totalStoreDataForVoucher.forEach((element) => {
        totalStoreCountForVoucher += element.stores.length;
      });
      console.log("totalStoreCountForVoucher", totalStoreCountForVoucher);
      const totalVoucherPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          filter,
          { mouType: "Voucher", signingStatus: "false" },
          "merchant"
        );

      const totalSignedForVoucher =
        totalVoucherSubmitCount - totalVoucherPendingForSignatureCount;

      const totalOfferSubmitCount = await getDataCountWithDateAndFields(
        filter,
        { mouType: "Offer" },
        "merchant"
      );
      let totalStoreCountForOffer =
        totalStoresSubmitCount - totalStoreCountForVoucher;
      console.log("totalStoreCountForOffer", totalStoreCountForOffer);

      const totalOfferPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          filter,
          { mouType: "Offer", signingStatus: "false" },
          "merchant"
        );

      const totalSignedForOffer =
        totalOfferSubmitCount - totalOfferPendingForSignatureCount;
      setBoxData([
        { id: 1 },
        {
          id: 2,
          name: "Total Merchant Submitted",
          value: totalMerchantSubmitCount,
        },
        {
          id: 3,
          name: "Total Stores Submitted",
          value: totalStoresSubmitCount,
        },
        {
          id: 4,
          name: "Pending for Signatures",
          value: totalMerchantPendingForSignatureCount,
        },
        {
          id: 5,
          name: "Total Signed",
          value: String(totalSignedForMerchantCount),
        },
        { id: 6 },
        {
          id: 7,
          name: "Total Vouchers Submitted",
          value: totalVoucherSubmitCount,
        },
        {
          id: 8,
          name: "Total Stores Submitted For Vouchers",
          value: String(totalStoreCountForVoucher),
        },
        {
          id: 9,
          name: "Vouchers Pending for Signatures",
          value: totalVoucherPendingForSignatureCount,
        },
        {
          id: 10,
          name: "Vouchers Digitally Signed",
          value: String(totalSignedForVoucher),
        },
        { id: 11 },
        {
          id: 12,
          name: "Total Offers Submitted",
          value: totalOfferSubmitCount,
        },
        {
          id: 13,
          name: "Total Stores Submitted For Offers",
          value: String(totalStoreCountForOffer),
        },
        {
          id: 14,
          name: "Offers Pending For Signatures",
          value: String(totalOfferPendingForSignatureCount),
        },
        {
          id: 15,
          name: "Offers Digitally Signed",
          value: String(totalSignedForOffer),
        },
      ]);
    } else if (selectedSummary === "Sales") {
      const userWhereQuery = {
        where: {
          "permission.salesRepresentative": true,
        },
      };
      const userConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + "/users",
        null,
        {
          "Content-Type": "application/json",
        },
        {
          count: 1,
          where: JSON.stringify(userWhereQuery.where),
        }
      );
      const userSales = await axios(userConfig);
      console.log("User Data", userSales.data.results);
      const userData = userSales.data.results;
      const summaryArrayForUser = [];
      for (let i = 0; i < userData.length; i++) {
        const merchantPointer = {
          $inQuery: {
            where: {
              sales: {
                $regex: `^${userData[i]?.username}$`,
                $options: "i",
              },
            },
            className: "merchant",
          },
        };
        const totalAgreementCountForUser = await getDataCountWithDateAndFields(
          filter,
          { merchantPointer },
          "agreement"
        );
        console.log("totalAgreementCountForUser", totalAgreementCountForUser);
        const totalStoreDataForUser = await getDataCountWithDateAndFields(
          filter,
          { merchantPointer },
          "agreement",
          totalAgreementCountForUser,
          "stores"
        );
        let totalStoreCountForUser = 0;
        totalStoreDataForUser.forEach((element) => {
          totalStoreCountForUser += element.stores.length;
        });
        console.log("totalStoreCountForUser", totalStoreCountForUser);
        console.log("totalStoreCountForUser", totalStoreCountForUser);
        const totalMerchantPendingCountForUser =
          await getDataCountWithDateAndFields(
            filter,
            {
              signingStatus: "false",
              sales: {
                $regex: `^${userData[i]?.username}$`,
                $options: "i",
              },
            },
            "merchant"
          );
        const totalMerchantSignedCountForUser =
          totalAgreementCountForUser - totalMerchantPendingCountForUser;
        console.log(
          "totalMerchantPendingCountForUser",
          totalMerchantPendingCountForUser
        );
        summaryArrayForUser.push({
          user: userData[i]?.username,
          totalAgreementCountForUser,
          totalStoreCountForUser,
          totalMerchantPendingCountForUser,
          totalMerchantSignedCountForUser,
        });
      }
      summaryArrayForUser.sort(
        (a, b) => b.totalAgreementCountForUser - a.totalAgreementCountForUser
      );
      setSummaryForUser(summaryArrayForUser);
    }

    setLoading(false);
  };

  const handleClickButton = async (startDate: Date, endDate: Date) => {
    setLoadingButton(true);
    if (selectedSummary === "Total Summary") {
      const totalMerchantSubmitCount = await getDataCountWithDateAndFields(
        "",
        {},
        "agreement",
        0,
        "",
        startDate,
        endDate
      );
      console.log("totalMerchantSubmitCount", totalMerchantSubmitCount);
      const totalStoresSubmitCount = await getDataCountWithDateAndFields(
        "",
        {},
        "stores",
        0,
        "",
        startDate,
        endDate
      );
      console.log("totalStoresSubmitCount", totalStoresSubmitCount);
      const totalMerchantPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          "",
          { signingStatus: "false" },
          "merchant",
          0,
          "",
          startDate,
          endDate
        );
      console.log(
        "totalMerchantPendingForSignatureCount",
        totalMerchantPendingForSignatureCount
      );

      const totalSignedForMerchantCount =
        totalMerchantSubmitCount - totalMerchantPendingForSignatureCount;
      console.log("totalSignedForMerchantCount", totalSignedForMerchantCount);

      const totalVoucherSubmitCount = await getDataCountWithDateAndFields(
        "",
        { mouType: "Voucher" },
        "merchant",
        0,
        "",
        startDate,
        endDate
      );

      console.log();

      const merchantPointer = {
        $inQuery: {
          where: {
            mouType: "Voucher",
          },
          className: "merchant",
        },
      };

      const totalAgreementCountForVoucher = await getDataCountWithDateAndFields(
        "",
        { merchantPointer },
        "agreement",
        0,
        "",
        startDate,
        endDate
      );

      const totalStoreDataForVoucher = await getDataCountWithDateAndFields(
        "",
        { merchantPointer },
        "agreement",
        totalAgreementCountForVoucher,
        "stores",
        startDate,
        endDate
      );
      let totalStoreCountForVoucher = 0;
      totalStoreDataForVoucher.forEach((element) => {
        totalStoreCountForVoucher += element.stores.length;
      });
      console.log("totalStoreCountForVoucher", totalStoreCountForVoucher);
      const totalVoucherPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          "",
          { mouType: "Voucher", signingStatus: "false" },
          "merchant",
          0,
          "",
          startDate,
          endDate
        );

      const totalSignedForVoucher =
        totalVoucherSubmitCount - totalVoucherPendingForSignatureCount;

      const totalOfferSubmitCount = await getDataCountWithDateAndFields(
        "",
        { mouType: "Offer" },
        "merchant",
        0,
        "",
        startDate,
        endDate
      );
      let totalStoreCountForOffer =
        totalStoresSubmitCount - totalStoreCountForVoucher;
      console.log("totalStoreCountForOffer", totalStoreCountForOffer);

      const totalOfferPendingForSignatureCount =
        await getDataCountWithDateAndFields(
          "",
          { mouType: "Offer", signingStatus: "false" },
          "merchant",
          0,
          "",
          startDate,
          endDate
        );

      const totalSignedForOffer =
        totalOfferSubmitCount - totalOfferPendingForSignatureCount;
      setBoxData([
        { id: 1 },
        {
          id: 2,
          name: "Total Merchant Submitted",
          value: totalMerchantSubmitCount,
        },
        {
          id: 3,
          name: "Total Stores Submitted",
          value: totalStoresSubmitCount,
        },
        {
          id: 4,
          name: "Pending for Signatures",
          value: totalMerchantPendingForSignatureCount,
        },
        {
          id: 5,
          name: "Total Signed",
          value: String(totalSignedForMerchantCount),
        },
        { id: 6 },
        {
          id: 7,
          name: "Total Vouchers Submitted",
          value: totalVoucherSubmitCount,
        },
        {
          id: 8,
          name: "Total Stores Submitted For Vouchers",
          value: String(totalStoreCountForVoucher),
        },
        {
          id: 9,
          name: "Vouchers Pending for Signatures",
          value: totalVoucherPendingForSignatureCount,
        },
        {
          id: 10,
          name: "Vouchers Digitally Signed",
          value: String(totalSignedForVoucher),
        },
        { id: 11 },
        {
          id: 12,
          name: "Total Offers Submitted",
          value: totalOfferSubmitCount,
        },
        {
          id: 13,
          name: "Total Stores Submitted For Offers",
          value: String(totalStoreCountForOffer),
        },
        {
          id: 14,
          name: "Offers Pending For Signatures",
          value: String(totalOfferPendingForSignatureCount),
        },
        {
          id: 15,
          name: "Offers Digitally Signed",
          value: String(totalSignedForOffer),
        },
      ]);
    } else if (selectedSummary === "Sales") {
      const userWhereQuery = {
        where: {
          "permission.salesRepresentative": true,
        },
      };
      const userConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + "/users",
        null,
        {
          "Content-Type": "application/json",
        },
        {
          count: 1,
          where: JSON.stringify(userWhereQuery.where),
        }
      );
      const userSales = await axios(userConfig);
      console.log("User Data", userSales.data.results);
      const userData = userSales.data.results;
      const summaryArrayForUser = [];
      for (let i = 0; i < userData.length; i++) {
        const merchantPointer = {
          $inQuery: {
            where: {
              sales: {
                $regex: `^${userData[i]?.username}$`,
                $options: "i",
              },
            },
            className: "merchant",
          },
        };
        const totalAgreementCountForUser = await getDataCountWithDateAndFields(
          "",
          { merchantPointer },
          "agreement",
          0,
          "",
          startDate,
          endDate
        );
        console.log("totalAgreementCountForUser", totalAgreementCountForUser);
        const totalStoreDataForUser = await getDataCountWithDateAndFields(
          "",
          { merchantPointer },
          "agreement",
          totalAgreementCountForUser,
          "stores",
          startDate,
          endDate
        );
        let totalStoreCountForUser = 0;
        totalStoreDataForUser.forEach((element) => {
          totalStoreCountForUser += element.stores.length;
        });
        console.log("totalStoreCountForUser", totalStoreCountForUser);
        console.log("totalStoreCountForUser", totalStoreCountForUser);
        const totalMerchantPendingCountForUser =
          await getDataCountWithDateAndFields(
            "",
            {
              signingStatus: "false",
              sales: {
                $regex: `^${userData[i]?.username}$`,
                $options: "i",
              },
            },
            "merchant",
            0,
            "",
            startDate,
            endDate
          );
        const totalMerchantSignedCountForUser =
          totalAgreementCountForUser - totalMerchantPendingCountForUser;
        console.log(
          "totalMerchantPendingCountForUser",
          totalMerchantPendingCountForUser
        );
        summaryArrayForUser.push({
          user: userData[i]?.username,
          totalAgreementCountForUser,
          totalStoreCountForUser,
          totalMerchantPendingCountForUser,
          totalMerchantSignedCountForUser,
        });
      }
      summaryArrayForUser.sort(
        (a, b) => b.totalAgreementCountForUser - a.totalAgreementCountForUser
      );
      setSummaryForUser(summaryArrayForUser);
    }
    setLoadingButton(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dash" style={{ flexGrow: 1 }}>
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          Summary
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            href="/"
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <small style={{ color: "#878a99", fontSize: "13px" }}> Summary</small>
        </div>
      </div>
      {/* Filter Box */}
      <div className="parentCard">
        <div className="cardStyle">
          <Card
            style={{
              backgroundColor: "white",
              width: "300px", // Adjust the width to your desired size
              borderRadius: "5px",
              display: "flex",
            }}
          >
            <Card.Body>
              <Card.Title>Summary</Card.Title>
              <ButtonGroup aria-label="Filter buttons">
                <Button
                  style={{
                    backgroundColor:
                      selectedSummary === "Total Summary"
                        ? "#007BFF"
                        : "#FFFFFF",
                    color:
                      selectedSummary === "Total Summary"
                        ? "#FFFFFF"
                        : "#000000",
                    marginRight: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => {
                    console.log("Total Summay");
                    setSelectedSummary("Total Summary");
                  }}
                >
                  Total Summary
                </Button>
                <Button
                  style={{
                    backgroundColor:
                      selectedSummary === "Sales" ? "#007BFF" : "#FFFFFF",
                    color: selectedSummary === "Sales" ? "#FFFFFF" : "#000000",
                    marginRight: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => setSelectedSummary("Sales")}
                >
                  Sales
                </Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </div>
        <div className="cardStyle">
          <Card>
            <Card.Body>
              <Card.Title>Filter by:</Card.Title>
              <ButtonGroup aria-label="Filter buttons">
                <Button
                  style={{
                    backgroundColor:
                      selectedFilter === "Custom" ? "#007BFF" : "#FFFFFF",
                    color: selectedFilter === "Custom" ? "#FFFFFF" : "#000000",
                    marginRight: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => {
                    setSelectedFilter("Custom");
                    setSummaryForUser([]);
                    setBoxData([]);
                  }}
                >
                  Custom
                </Button>
                <Button
                  style={{
                    backgroundColor:
                      selectedFilter === "Last Day" ? "#007BFF" : "#FFFFFF",
                    color:
                      selectedFilter === "Last Day" ? "#FFFFFF" : "#000000",
                    marginRight: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => handleFilterClick("Last Day")}
                >
                  Last Day
                </Button>
                <Button
                  style={{
                    backgroundColor:
                      selectedFilter === "Month till date"
                        ? "#007BFF"
                        : "#FFFFFF",
                    color:
                      selectedFilter === "Month till date"
                        ? "#FFFFFF"
                        : "#000000",
                    marginRight: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => handleFilterClick("Month till date")}
                >
                  Month till date
                </Button>
                <Button
                  style={{
                    backgroundColor:
                      selectedFilter === "till date" ? "#007BFF" : "#FFFFFF",
                    color:
                      selectedFilter === "till date" ? "#FFFFFF" : "#000000",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                  onClick={() => handleFilterClick("till date")}
                >
                  till date
                </Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* Boxes */}
      {selectedFilter === "Custom" && (
        <div
          style={{
            margin: "5px",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values: any) => {
              const startDate = new Date(values.startDate);
              const endDate = new Date(values.endDate);
              const today = new Date();
              const differenceInTime = endDate.getTime() - startDate.getTime();
              const differenceInDays = differenceInTime / (1000 * 3600 * 24);

              if (differenceInDays > 7) {
                ToastUtils.showErrorToast(
                  "The maximum allowed difference between start and end dates is 7 days"
                );
                setLoading(false);
                return;
              } else if (differenceInDays < 0) {
                ToastUtils.showErrorToast(
                  "End date cannot be earlier than start date"
                );
                setLoading(false);
                return;
              } else if (startDate > today) {
                ToastUtils.showErrorToast(
                  "you can not select a start date as future date"
                );
                setLoading(false);
                return;
              } else if (endDate > today) {
                ToastUtils.showErrorToast(
                  "you can not select a end date as future date"
                );
                setLoading(false);
                return;
              }
              await handleClickButton(startDate, endDate);
              console.log("Type Of", typeof startDate, startDate);
            }}
          >
            {({ handleSubmit }) => (
              <div
                style={{
                  background: "#ffffff",
                  marginBottom: "10px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <div className="form-box" style={{ textAlign: "center" }}>
                  <Text color="#494f57" fontWeight="bold" fontSize="20px">
                    View Datewise Summary
                  </Text>
                </div>
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <FormikInput
                      label="Start Date"
                      type="Date"
                      name="startDate"
                    />
                    <FormikInput label="End Date" type="Date" name="endDate" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <LoadingButton
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          minWidth: "70px",
                          height: "35px",
                        }}
                        text={"View Summary"}
                        type="button"
                        onClick={handleSubmit}
                        isLoading={loadingButton}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      )}
      {selectedSummary === "Total Summary" && boxData.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: "10px",
            marginBottom: "50px",
            backgroundColor: "white",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {boxData.map((box, index) => {
            if (index % 5 !== 0) {
              return (
                <>
                  <div key={box.id} className="boxStyle">
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "0",
                        color: "#333",
                      }}
                    >
                      {box.name}
                    </h6>
                    <p
                      style={{
                        fontSize: "32px",
                        fontWeight: "700",
                        color: "#007bff",
                        margin: "10px 0",
                      }}
                    >
                      {box.value}
                    </p>
                  </div>
                </>
              );
            } else {
              return (
                <div key={box.id} style={{ display: "contents" }}>
                  <h6
                    className="headingStat"
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      margin: "0",
                      color: "#333",
                      width: "100vw",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {box.id === 1
                      ? "Total Merchant: "
                      : box.id === 6
                      ? "Vouchers: "
                      : "Offers: "}
                  </h6>
                </div>
              );
            }
          })}
        </div>
      )}
      {selectedSummary === "Sales" && summaryForUser.length > 0 && (
        <div
          style={{
            backgroundColor: "white",
            marginBottom: "20px",
            marginLeft: "13px",
            marginRight: "13px",
          }}
        >
          <div
            style={{
              marginTop: "10px",
              background: "white",
              margin: "10px",
              marginBottom: 0,
            }}
          >
            <div style={{ overflowX: "scroll" }}>
              <Table>
                <thead>
                  <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                    <th>S.No</th>
                    <th>Email </th>
                    <th>Total Merchant Submitted</th>
                    <th>Total Stores Submitted </th>
                    <th>Total Signed </th>
                    <th>Pending </th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                  {summaryForUser.map((data: any, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.user}</td>
                      <td>{data.totalAgreementCountForUser}</td>
                      <td>{data.totalStoreCountForUser}</td>
                      <td>{data.totalMerchantSignedCountForUser}</td>
                      <td style={{ minWidth: "100px" }}>
                        {data.totalMerchantPendingCountForUser}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
      {selectedSummary === "Sales" &&
        selectedFilter === "Custom" &&
        summaryForUser.length > 0 && (
          <div
            style={{
              alignItems: "center",
              backgroundColor: "white",
              margin: "10px",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <LoadingButton
              style={{
                color: "white",
                borderRadius: "5px",
                border: "1px solid #405089",
                background: "rgb(64,81,167)",
                outlineColor: "navajowhite",
                fontSize: "13px",
                minWidth: "70px",
                height: "35px",
              }}
              text={"Downlaod Data"}
              type="button"
              onClick={() => {
                excelDownlaod(summaryForUser);
              }}
              // onClick={() => alert("hello")}
              isLoading={loadingDownload}
            />
          </div>
        )}
    </div>
  );
};

export default SummayDetails;
