import React, { useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { BsFillCloudArrowDownFill } from "react-icons/bs";
import Text from "../../components/Text";
import { useMerchantContext } from "../../context/merchant-context";
import { PDFViewer } from "react-view-pdf";
import axios from "axios";
import { saveAs } from "file-saver";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useAppContext } from "../../context/app-context";
import { useUserContext } from "../../context/user-context";
import LoadingButton from "../../components/LoadingButton";
import PopOtpModel from "../../components/ModelOtp";
import Urls from "../../utils/urls";

const PdfViewer = () => {
  const { setFormData } = useMerchantContext();
  const merchantContext = useMerchantContext();
  const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));
  const [Loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState("");
  const context = useAppContext();
  const { clearOffers, clearStores, setIsEditing } = context;
  const {
    stores,
    offers,
    isEditing,
    selectedAgreement,
    formData: updateFormData,
  } = context.state;
  const { addPageIndex, setCustomPageIndex } = merchantContext;
  const [otp, setOtp] = useState("");

  const handleOtpSubmit = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  const [showModal, setShowModal] = useState(false);
  const { formData, selectedOffers, selectedStores } = merchantContext.state;
  console.log(formData);
  const userContext = useUserContext();
  console.log("Chal Ja Bhai", formData);
  console.log("Chal Ja Bhai", formData.upload_signed_agreement);

  const downloadPDF = (pdfUrl) => {
    fetch(pdfUrl)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        // set the blog type to final pdf
        const file = new Blob([resp], { type: "application/pdf" });
        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "FileName" + new Date() + ".pdf";
        link.click();
      });
  };

  const postRequest = async () => {
    // alert(JSON.stringify(values));
    // setLoading(true);
    const isSigning = "false";

    console.log(isEditing);
    try {
      if (isEditing) {
        await ServerUploadsUtils.updateDataToDB(
          { ...formData },
          selectedStores,
          selectedOffers,
          selectedAgreement?.objectId,
          formData?.sales,
          formData.signingStatus == "true" ? "true" : "false",
          formData.signingStatus == "true" ? true : false
        );
      } else {
        await ServerUploadsUtils.saveDataToDB(
          { ...formData },
          selectedStores,
          selectedOffers,
          userContext?.state?.user?.username,
          isSigning
        );
        console.log("FormDtaa hai Bhai", { ...formData });
      }
      // setLoading(false);
      // addPageIndex();
      console.log("formData", formData.isSigning);
      setCustomPageIndex(13);
      setFormData({});
      clearStores();
      clearOffers();
      setIsEditing(false);
    } catch (e) {
      // setLoading(false);
    }
  };
  console.log(formData.isSigning);
  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            New Submission
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            marginBottom: "20px",
            paddingTop: "35px",
            paddingBottom: "35px",
          }}
        >
          <div className="form-box" style={{ textAlign: "center" }}>
            <Text color="#494f57" fontWeight="bold" fontSize="20px">
              View Pdf
            </Text>
            <Text color="#494f57" fontWeight="bold" fontSize="20px">
              Date:&nbsp;
              {new Date().getDate() +
                "/" +
                new Date().getMonth() +
                "/" +
                new Date().getFullYear()}
              &nbsp;&nbsp; Time : &nbsp;{new Date().getHours() + ":"}
              &nbsp;{new Date().getMinutes() + ":"}&nbsp;
              {new Date().getSeconds()}
            </Text>
          </div>

          <div>
            {/* <iframe
              src={`../../../backend/src/${formData.email}.pdf`}
              className="pdf-read"
              frameBorder="0"
              title="hello"
            ></iframe> */}
            <PDFViewer
              url={formData.upload_signed_agreement}
              // url="https://design.thriwe.com/tgpl-crm-prime/assets/images/Citi%20Logo%20Partner_Playbook_04-08-2020.pdf"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <button
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "1px solid rgb(243,246,249)",
                  background: "rgb(243,246,249)",
                  outlineColor: "rgb(243,246,249)",
                  fontSize: "13px",
                  // width: "1px",
                  height: "35px",
                  marginLeft: "10px",
                }}
                onClick={() => downloadPDF(formData.upload_signed_agreement)}
              >
                <BsFillCloudArrowDownFill onClick={() => {}} /> Download Pdf
              </button>
            </div>
            {typeOfUser?.CanSignAgreement == true ? (
              formData.signingStatus != "true" ? (
                <button
                  style={{
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid #405089",
                    background: "rgb(64,81,167)",
                    outlineColor: "navajowhite",
                    fontSize: "13px",
                    // width: "1px",
                    height: "35px",
                    marginLeft: "10px",
                  }}
                  onClick={async () => {
                    setShowModal(true);
                    var data = {
                      phone: formData?.authorisedSignatoryNo,
                      mail: formData?.authorisedSignatoryMail,
                      countryCode: formData.country === "UAE" ? "+971" : "+91",
                    };
                    let config = {
                      method: "post",
                      url: Urls.lambdaUrl,
                      headers: {
                        "Content-Type": "application/json",
                      },
                      data: data,
                    };
                    const result: any = await axios.request(config);
                    console.log("result", result);
                    console.log(result?.data);
                    setObjectId(result?.data);
                  }}
                >
                  {"Sign Pdf"}
                </button>
              ) : null
            ) : (
              <LoadingButton
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #405089",
                  background: "rgb(64,81,167)",
                  outlineColor: "navajowhite",
                  fontSize: "13px",
                  // width: "1px",
                  height: "35px",
                  marginLeft: "10px",
                }}
                text={"Submit"}
                type="submit"
                onClick={async () => {
                  setLoading(true);
                  await postRequest();
                }}
                isLoading={Loading}
              >
                Submit
              </LoadingButton>
            )}
          </div>
        </div>
        <PopOtpModel
          setOpenModal={() => setShowModal(false)}
          openModal={showModal}
          cb={async (otp: string) => {
            let config = {
              method: "get",
              maxBodyLength: Infinity,
              url: `${Urls.parseUrl}/classes/Otp/${objectId}`,
              headers: {
                "X-Parse-Master-Key": Urls.MASTERKEY,
                "X-Parse-Application-Id": Urls.APPLICATIONID,
              },
            };
            const data = await axios.request(config);
            console.log(data);
            const getOtp = data.data.code;
            if (otp == getOtp) {
              setFormData({ ...formData, otp: otp });
              addPageIndex();
              return true;
            } else {
              console.log(otp, getOtp);
              return false;
            }
          }}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
