import React from "react";
import { FaGreaterThan } from "react-icons/fa";
import GoBackBtn from "../../components/GoBackBtn";
import Text from "../../components/Text";
import { useMerchantContext } from "../../context/merchant-context";

const ViewAgreement = () => {
  const merchantContext = useMerchantContext();
  const { addPageIndex } = merchantContext;
  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            New Submission
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            marginBottom: "20px",
            paddingTop: "35px",
            paddingBottom: "35px",
          }}
        >
          <div className="form-box" style={{ textAlign: "center" }}>
            <Text color="#494f57" fontWeight="bold" fontSize="20px">
              Sign Your Digital Agreement
            </Text>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <button
              style={{
                color: "white",
                borderRadius: "5px",
                border: "1px solid #405089",
                background: "rgb(64,81,167)",
                outlineColor: "navajowhite",
                fontSize: "13px",
                // width: "1px",
                height: "35px",
                marginLeft: "10px",
              }}
              onClick={() => addPageIndex()}
            >
              View Agreement
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAgreement;
