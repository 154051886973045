import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikInput from "../../components/FormikInput";
import Text from "../../components/Text";
import { validationSchema } from "./validationSchema";
import { useUserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
import "./style.css";
import tgpl from "../../assets/images/thriwe-logo.png";
import ServerUploadsUtils from "../../utils/serverUploads";
import LoadingButton from "../../components/LoadingButton";
import ToastUtils from "../../utils/toast/toast-utils";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const Login = () => {
  const userContext = useUserContext();
  const [Loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State variable to toggle password visibility
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") == "true") {
      navigate("/");
      return;
    }
  }, []);

  console.log("user Information", userContext);
  return (
    <div
      className="auth-page-wrapper auth-bg-cover"
      style={{
        // background: "red",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="auth-page-content overflow-hidden ">
        <div className="" style={{ width: "70%", margin: "auto" }}>
          <div className="card overflow-hidden">
            <div className="row g-0">
              <div className="col-lg-6">
                <div className="p-lg-5 p-4 auth-one-bg h-100"></div>
              </div>
              {/* end col */}
              <div className="col-lg-6">
                <div className="p-lg-5 p-4">
                  <div>
                    <div className="mb-4 text-center mr-auto">
                      <a href="#!" className="d-block">
                        <img
                          src={tgpl}
                          alt=""
                          style={{ height: "40px", width: "100px" }}
                        />
                      </a>
                    </div>
                    <h5 className="text-primary text-center">Welcome</h5>
                    <p className="text-muted text-center">
                      Sign in to continue to Thriwe Consulting Pvt. Ltd..
                    </p>
                  </div>
                  <div className="mt-4">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={validationSchema}
                      validateOnMount={true}
                      enableReinitialize={true}
                      onSubmit={async (values) => {
                        setLoading(true);
                        // alert(JSON.stringify(values));
                        try {
                          const result = await ServerUploadsUtils.login({
                            username: values.email.toLowerCase(),
                            password: values.password,
                          });
                          userContext.updateUser({
                            ...result,
                          });
                          userContext.updateUserPermission({
                            ...result.permission,
                          });

                          localStorage.setItem(
                            "user",
                            JSON.stringify({ ...result })
                          );

                          localStorage.setItem(
                            "userPermissions",
                            JSON.stringify({ ...result.permission })
                          );

                          console.log("MY LOGIN API RESULT", result);
                          if (result.sessionToken) {
                            localStorage.setItem("isLoggedIn", "true");
                          } else {
                            localStorage.setItem("isLoggedIn", "false");
                          }
                          setLoading(false);
                          navigate("/");
                          return;
                        } catch (error) {
                          console.log("MY ERROR", error);
                          // alert(error.response?.data?.error);
                          ToastUtils.showErrorToast(
                            error?.response?.data?.error
                          );
                          setLoading(false);
                          return;
                        }
                      }}
                    >
                      {({ isSubmitting, values, handleSubmit }) => (
                        <div
                          style={{
                            // background: "#ffffff",
                            marginBottom: "20px",
                            paddingTop: "35px",
                            paddingBottom: "35px",
                          }}
                        >
                          <div
                            className="form-box"
                            style={{ textAlign: "center" }}
                          >
                            <Text
                              color="#494f57"
                              fontWeight="bold"
                              fontSize="20px"
                            >
                              Login
                            </Text>
                          </div>
                          <Form>
                            <div
                              style={{
                                marginLeft: "10%",
                                marginRight: "10%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                              }}
                            >
                              <div className="mb-3">
                                <FormikInput
                                  label="Email"
                                  type="email"
                                  name="email"
                                />
                              </div>
                              <div
                                className="mb-3"
                                style={{ position: "relative" }}
                              >
                                <FormikInput
                                  label="Password"
                                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                                  name="password"
                                />
                                <span
                                  onClick={() => setShowPassword(!showPassword)} // Toggle the showPassword state
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "31px",
                                    right: "10px",
                                  }}
                                >
                                  {showPassword ? (
                                    <AiFillEye />
                                  ) : (
                                    <AiFillEyeInvisible />
                                  )}
                                </span>
                              </div>
                              {/* <div>
                    {!uploadingFile && fileUploadSuccess ? "success" : ""}
                  </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {/* <LoadingButton
                                      style={{
                                        color: "white",
                                        borderRadius: "5px",
                                        border: "1px solid #405089",
                                        background: "rgb(64,81,167)",
                                        outlineColor: "navajowhite",
                                        fontSize: "13px",
                                        width: "70px",
                                        height: "35px",
                                      }}
                                    >
                                      Login
                                    </LoadingButton> */}
                                <LoadingButton
                                  style={{
                                    color: "white",
                                    borderRadius: "5px",
                                    border: "1px solid #405089",
                                    background: "rgb(64,81,167)",
                                    outlineColor: "navajowhite",
                                    fontSize: "13px",
                                    minWidth: "70px",
                                    height: "35px",
                                  }}
                                  text={"Login"}
                                  type="button"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  // disabled={isSubmitting || uploadingFile ? true : false}
                                  isLoading={Loading}
                                />
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end card */}

          {/* end col */}

          {/* end row */}
        </div>
        {/* end container */}
      </div>
    </div>
  );
};

export default Login;
