import React from "react";
import { ImArrowLeft2 } from "react-icons/im";

function GoBackBtn({ onClick = () => {} }) {
  return (
    <button
      onClick={(e: any) => {
        e.preventDefault();
        onClick();
      }}
      style={{
        color: "white",
        borderRadius: "5px",
        border: "1px solid #405089",
        background: "rgb(64,81,167)",
        outlineColor: "navajowhite",
        fontSize: "12px",
        width: "70px",
        height: "35px",
        fontWeight: "normal",
      }}
    >
      Go Back
    </button>
  );
}

export default GoBackBtn;
