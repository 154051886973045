import { Form, Formik } from "formik";
import React, { useState } from "react";
import Text from "../../components/Text";
import { FaGreaterThan } from "react-icons/fa";
import LoadingButton from "../../components/LoadingButton";
import FormikInput from "../../components/FormikInput";
import ServerUploadsUtils from "../../utils/serverUploads";
import { validationSchema } from "./validationSchema";
import ToastUtils from "../../utils/toast/toast-utils";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

const AuditUpload = () => {
  const [initialValues, setInitialValues] = useState({
    auditUpload: "",
  });
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [uploadingFileForAuditUpload, setUploadingFileForAuditUpload] =
    useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("Data Present", data);

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer: any = e.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet);
        console.log("excel data", excelData);
        // Process the data as needed, e.g., call your separateDataBySNO function
        const sno1Data = separateDataBySNO(excelData);
        console.log("sno1Data", sno1Data);
        setData(sno1Data);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadData = async (url: string) => {
    const auditObject = data.map((item) => {
      const items = item["Materials"]?.split(",");
      console.log("Items", items);
      const program = [];
      item["Program"]?.map((prog: string) => {
        program.push({ id: uuidv4(), name: prog });
      });
      return {
        store_name: item["Outlet Name"],
        store_address: item["Outlet Address"],
        program: program,
        offer_text: item["Offer Details"],
        offerExclusion: item["Offer Exclusion"],
        offer_validity_endDate: item["End Date"],
        outletId: String(item["Outlet_ID"]),
        authorisedSignatoryName: item["Signatory Name"],
        designation: item["Designation"],
        registeredAddressCity: item["Outlet City"],
        email: item["Agent Email"],
        brandName: item["Brand Name"],
        items: items,
        type: item["Type"],
        url: url,
        signatoryContactNo: item["Signatory Contact No"],
      };
    });
    try {
      const auditResponse = ServerUploadsUtils.createBatch(
        "post",
        "audit",
        auditObject
      );
      console.log("Audit Response", auditResponse);
      return "Data Upload Successfully";
    } catch (error) {
      setLoading(false);
    }
  };

  function separateDataBySNO(excelData: any) {
    let separateData = [];
    excelData.map((data: any) => {
      if (data["S.No"]) {
        console.log("End Date Check", Number(data["End Date"]));
        const excelDateValue = Number(data["End Date"]);
        const jsDate = new Date((excelDateValue - 25569) * 86400 * 1000);

        // Adjust for time zone offset (add minutes based on your time zone)
        jsDate.setMinutes(jsDate.getMinutes() + jsDate.getTimezoneOffset());

        // Format the date as needed (for example, as 'DD/MM/YYYY')
        const formattedDate = `${jsDate.getDate()}/${
          jsDate.getMonth() + 1
        }/${jsDate.getFullYear()}`;
        console.log(formattedDate);
        separateData.push({
          "S.No": data["S.No"],
          Program: [data["Program"]],
          "Offer Details": [data["Offer Details"]],
          "Offer Exclusion": data["Offer Exclusion"],
          Outlet_ID: data["Outlet_ID"],
          "End Date": [formattedDate],
          "Signatory Name": data["Signatory Name"],
          Designation: data["Designation"],
          "Outlet Name": data["Outlet Name"],
          "Outlet Address": data["Outlet Address"],
          "Outlet City": data["Outlet City"],
          "Agent Email": data["Agent Email"],
          "Brand Name": data["Brand Name"],
          "Signatory Contact No": data["Signatory Contact No"],
          Materials: data["Materials"],
          Type: data["Type"],
        });
      } else {
        for (const key in data) {
          console.log("dataa", key, data, data[key], separateData);
          if (key?.trim() === "Program") {
            console.log("Inside", data[key]);
            separateData[separateData.length - 1][`${key}`]?.push(
              data[`${key}`]
            );
          } else if (key?.trim() === "End Date") {
            const excelDateValue = Number(data[key]);
            const jsDate = new Date((excelDateValue - 25569) * 86400 * 1000);

            // Adjust for time zone offset (add minutes based on your time zone)
            jsDate.setMinutes(jsDate.getMinutes() + jsDate.getTimezoneOffset());

            // Format the date as needed (for example, as 'DD/MM/YYYY')
            const formattedDate = `${jsDate.getDate()}/${
              jsDate.getMonth() + 1
            }/${jsDate.getFullYear()}`;

            separateData[separateData.length - 1][`${key}`]?.push(
              formattedDate
            );
          } else if (key?.trim() === "Offer Details") {
            console.log("Inside", data[key], separateData);
            separateData[separateData.length - 1][`${key}`]?.push(
              data[`${key}`]
            );
          }
        }
      }
    });
    console.log("separateData", separateData);
    return separateData;
  }

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}></h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values: any, { resetForm }) => {
            console.log("Values", values);
            setLoading(true);
            if (!data) {
              ToastUtils.showErrorToast("Please upload a file");
            }
            setLoading(false);
            const uploadResposne = await uploadData(values.auditUpload);
            if (uploadResposne === "Data Upload Successfully")
              ToastUtils.showSuccessToast("Data Upload Successfully");
            setLoading(false);
            setData([]);
            resetForm();
          }}
        >
          {({ handleSubmit, values }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  Download Data
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormikInput
                    label="Audit Upload"
                    type="file"
                    name="auditUpload"
                    useDefaultInput={true}
                    filename={values.auditUpload}
                    onChange={async (e) => {
                      setUploadingFileForAuditUpload(true);
                      setUploadSuccess(true);
                      let contentType = "";
                      const file = e.target.files[0];
                      const fileName = e.target.files[0].name.replace(
                        /[^a-zA-Z]/g,
                        ""
                      );
                      if (
                        file.type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      ) {
                        contentType =
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel file
                      } else {
                        setUploadingFileForAuditUpload(false);
                        ToastUtils.showErrorToast(
                          "Invalid file type. Please upload an Excel file"
                        );
                        return;
                      }
                      try {
                        values.auditUpload =
                          await ServerUploadsUtils.uploadImageToServer(
                            e.target.files[0],
                            e.target.files[0].name
                          );
                        console.log(typeof e.target.files[0]);
                        const url = values.auditUpload;
                        // console.log(url);
                        const str1 = url.substring(0, 4);
                        const str2 = url.substring(4);
                        values.auditUpload = str1 + "s" + str2;
                        handleFileUpload(e);
                        setUploadingFileForAuditUpload(false);
                        setUploadSuccess(false);
                      } catch (e) {
                        setUploadingFileForAuditUpload(false);
                      }
                    }}
                    disabled={uploadingFileForAuditUpload}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <LoadingButton
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background: "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        minWidth: "70px",
                        height: "35px",
                      }}
                      text={"Submit"}
                      type="Submit"
                      onClick={handleSubmit}
                      disabled={fileUploadSuccess}
                      isLoading={loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AuditUpload;
