import React from "react";

interface EditOfferBtnPropsType {
  storeInfo?: any;
}
function Locality({ storeInfo }: EditOfferBtnPropsType) {
  return <div> {storeInfo?.original?.locality}</div>;
}

export default Locality;
