import * as Yup from "yup";
import RegexUtils from "../../../utils/regexUtils";

export const validationSchema = (selectedCountry) =>
  Yup.object({
    brandType: Yup.string().required("Brand Type is required"),
    brandName: Yup.string().required("Brand name is required"),
    registeredAddress: Yup.string().required("Registered address is required"),
    registeredAddressCity: Yup.string().required("City is required"),
    registeredAddressState: Yup.string().required("State is required"),
    registeredAddressCountry: Yup.string().required("Country is required"),
    corporateAddressCountry: Yup.string().required("Country is required"),
    registeredAddressPincode: Yup.number().when(
      "registeredAddressCountry",
      (country, schema) => {
        if (selectedCountry === "UAE" || selectedCountry === "Saudi") {
          return schema.notRequired();
        } else {
          return schema.required("Pincode is required");
        }
      }
    ),
    // registeredAddressPincode: Yup.string().matches(RegexUtils.pincode, "Please enter a valid pincode").required("Registered address pincode is required"),
    corporateAddress: Yup.string().required("Corporate Address is required"),
    corporateAddressCity: Yup.string().required("City is required"),
    corporateAddressState: Yup.string().required("State is required"),
    corporateAddressPincode: Yup.number().when(
      "registeredAddressCountry",
      (country, schema) => {
        if (selectedCountry === "UAE" || selectedCountry === "Saudi") {
          return schema.notRequired();
        } else {
          return schema.required("Pincode is required");
        }
      }
    ),
    // orporate_address_pincode: Yup.string().matches(RegexUtils.pincode, "Please enter a valid pincode").required("Corporate address pincode is required"),
    spocName: Yup.string().required("Spoc name is required"),
    spocNo: Yup.number()
      .typeError("Spoc number should be number")
      .required("Spoc number is required"),
    spocEmail: Yup.string()
      .email("Invalid email")
      .required("Authorized Spoc Mail is required"),
  });
