import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Form, Table } from "react-bootstrap";
import DateUtils from "../../utils/date-utils";
import LoadingButton from "../../components/LoadingButton";
import Urls from "../../utils/urls";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Formik } from "formik";
import ToastUtils from "../../utils/toast/toast-utils";
import Text from "../../components/Text";
import FormikInput from "../../components/FormikInput";
import FormInput from "../../components/Input";
import AuditDeleteConfirmaiton from "../../components/AuditDeleteModal";

const AuditList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [tableValues, setTableValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [value, setValue] = useState({
    outletName: "",
  });
  const [isAuditDeleteModalOpen, setIsAuditDeleteModalOpen] = useState(false);
  const [auditId, setAuditId] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const navigate = useNavigate();

  const getAudits = async (limit: number, skip: number) => {
    try {
      setLoading(true);
      let queryParams: Object;
      queryParams = {
        where: {
          status: {
            $ne: "Close", // Add this to filter status not equal to "Close"
          },
          store_name: {
            $regex: value?.outletName ? value?.outletName?.trim() : "",
            $options: "i",
          },
        },
        limit,
        skip,
        count: 1,
        order: "-createdAt",
      };

      let getConfigCount = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Urls.parseUrl}/classes/audit`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        params: queryParams,
      };
      const getAudit = await axios(getConfigCount);
      setLoading(false);
      if (getAudit.data?.results?.length === 0) {
        ToastUtils.showInfoToast("No Outlets found");
      }
      return getAudit;
    } catch (error) {
      setLoading(false);
      return error.message;
    }
  };

  const getTableValues = async () => {
    setLoading(true);
    const getAudit = await getAudits(15, 0);
    try {
      if (getAudit.data.count === 0 && !getAudit.data) {
        setLoading(false);
        return;
      }
      if (getAudit.data.count % 15) {
        setCount(Math.trunc(getAudit.data.count / 15) + 1);
      } else {
        setCount(Math.trunc(getAudit.data.count / 15));
      }
      console.log("Address", getAudit.data.results);
      setTableValues(getAudit.data.results);
      setCurrentPage(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    return;
  };

  useEffect(() => {
    const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    if (!(userPermissions?.SalesHandler || userPermissions?.checkAudit)) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getTableValues();
  }, [isRefresh]);

  const submitHandler = () => {
    if (value?.outletName === "") {
      setShowEmptyError(true);
      return;
    }
    getTableValues();
  };

  return (
    <div
      className="dash"
      style={{
        flexGrow: 1,
      }}
    >
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      {isAuditDeleteModalOpen && (
        <AuditDeleteConfirmaiton
          onCloseHandler={() => setIsAuditDeleteModalOpen(false)}
          auditId={auditId}
          setIsRefresh={setIsRefresh}
          isRefresh={isRefresh}
        />
      )}
      {loading && <LoadingSpinner />}
      <div style={{ marginTop: "100px" }}>
        <Form className="first-comp">
          <FormInput
            type="text"
            placeholder="Please enter a outlet name"
            value={value?.outletName}
            onChange={(e: any) => {
              setValue({ ...value, outletName: e.target.value });
              if (e.target.value) {
                setShowEmptyError(false);
              } else {
                setShowEmptyError(true);
              }
            }}
          />
          <div
            className="btn-group-1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            <LoadingButton
              isLoading={loading}
              text={"Search"}
              style={{
                color: "white",
                borderRadius: "5px",
                border: "1px solid #405089",
                background: "rgb(64,81,167)",
                outlineColor: "navajowhite",
                fontSize: "13px",
                // width: "70px",
                height: "35px",
              }}
              onClick={submitHandler}
              type="submit"
            />
          </div>
        </Form>
      </div>

      <div>
        {tableValues?.length > 0 && (
          <div
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              marginLeft: "13px",
              marginRight: "13px",
              marginTop: "100px",
              padding: "5px",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                background: "white",
                margin: "10px",
                marginBottom: 0,
              }}
            >
              <div style={{ overflowX: "scroll", marginTop: "50px" }}>
                <Table>
                  {tableValues.length !== 0 ? (
                    <thead>
                      <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                        <th>S.No</th>
                        <th style={{ minWidth: "100px" }}>Audit Type </th>
                        <th style={{ minWidth: "100px" }}>Outlet </th>
                        <th>Outlet Address </th>
                        <th>City </th>
                        <th>Status </th>
                        <th>Action </th>
                      </tr>
                    </thead>
                  ) : null}
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {tableValues.map((data: any, index) => (
                      <tr key={index}>
                        <td
                          style={
                            {
                              // textAlign: "center",
                            }
                          }
                        >
                          {index + 1 * currentPage * 15 + 1}
                        </td>
                        <td>{data.type}</td>
                        <td>{data.store_name}</td>
                        <td style={{ minWidth: "100px" }}>
                          {data.store_address}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          {" "}
                          {data.registeredAddressCity}
                        </td>
                        <td>
                          {data.status ? (
                            data?.status === "Close" ? (
                              <span style={{ color: "green" }}> Close</span>
                            ) : (
                              <span style={{ color: "green" }}> Hold</span>
                            )
                          ) : (
                            <span style={{ color: "red" }}> Open</span>
                          )}
                        </td>

                        <td>
                          <LoadingButton
                            style={{
                              boxShadow: "none",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.7109375rem",
                              borderRadius: "0.2rem",
                              color: "#fff",
                              backgroundColor: "#d9534f", // A red color to indicate delete action
                              borderColor: "#d43f3a", // A slightly darker red for the border
                              width: "100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setIsAuditDeleteModalOpen(true);
                              setAuditId(data?.objectId);
                            }}
                            text={"Delete"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              {currentPage >= 0 && tableValues?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={async () => {
                      const getAudit = await getAudits(
                        15,
                        (currentPage - 1) * 15
                      );
                      setCurrentPage(currentPage - 1);
                      setTableValues(getAudit?.data?.results);
                    }}
                    style={{
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage > 0 ? "blue" : "#878a99",
                    }}
                    disabled={currentPage > 0 ? false : true}
                  >
                    Previous
                  </button>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      // color: "black",
                      // fontWeight: "bold",
                      backgroundColor: "#405189",
                      borderColor: "#405189",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                    }}
                  >
                    {currentPage + 1}
                  </div>
                  <button
                    className="page-item pagination-prev disabled"
                    // onClick={() =>
                    //   console.log((currentPage - 1) * 15)}
                    onClick={async () => {
                      const getAudit = await getAudits(
                        15,
                        (currentPage + 1) * 15
                      );
                      setCurrentPage(currentPage + 1);
                      setTableValues(getAudit?.data?.results);
                    }}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage >= count - 1 ? "#878a99" : "blue",
                    }}
                    disabled={currentPage >= count - 1 ? true : false}
                  >
                    Next
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditList;
