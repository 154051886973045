import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Table } from "react-bootstrap";
import DateUtils from "../../utils/date-utils";
import LoadingButton from "../../components/LoadingButton";
import Urls from "../../utils/urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Formik } from "formik";
import ToastUtils from "../../utils/toast/toast-utils";
import Text from "../../components/Text";
import FormikInput from "../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import * as XLSX from "xlsx";
import FormInput from "../../components/Input";
import { Form } from "react-bootstrap";

const Audit = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [tableValues, setTableValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [checkAudit, setCheckAudit] = useState(false);
  const [customReport, setCustomReport] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    startDate: "",
    endDate: "",
    dataType: "",
  });
  const [value, setValue] = useState({
    outletName: "",
  });

  const getAudits = async (limit: number, skip: number) => {
    try {
      const { username } = JSON.parse(localStorage.getItem("user"));
      const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));

      let queryParams: Object;
      if (typeOfUser?.audit) {
        if (value?.outletName) {
          queryParams = {
            where: {
              email: username,
              store_name: {
                $regex: value.outletName ? value.outletName.trim() : "",
                $options: "i",
              },
            },
            limit,
            skip,
            count: 1,
            order: "status,-submissionDate",
          };
        } else {
          queryParams = {
            where: {
              email: username,
            },
            limit,
            skip,
            count: 1,
            order: "status,-submissionDate",
          };
        }
      } else {
        setCheckAudit(true);
        queryParams = {
          where: {
            status: "Close",
            store_name: {
              $regex: value?.outletName ? value?.outletName?.trim() : "",
              $options: "i",
            },
          },
          limit,
          skip,
          count: 1,
          order: "-submissionDate",
        };
      }
      let getConfigCount = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Urls.parseUrl}/classes/audit`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        params: queryParams,
      };
      const getAudit = await axios(getConfigCount);
      return getAudit;
    } catch (error) {
      return error.message;
    }
  };

  const getTableValues = async () => {
    setLoading(true);
    const getAudit = await getAudits(15, 0);
    try {
      if (getAudit.data.count === 0 && !getAudit.data) {
        setLoading(false);
        return;
      }
      if (getAudit.data.count % 15) {
        setCount(Math.trunc(getAudit.data.count / 15) + 1);
      } else {
        setCount(Math.trunc(getAudit.data.count / 15));
      }
      console.log("Address", getAudit.data.results);
      setTableValues(getAudit.data.results);
      setCurrentPage(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    return;
  };

  function formatDateToDDMMYYYY(dateString: string) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    getTableValues();
  }, []);

  const getData = async (startDate: string, endDate: string) => {
    var agreement = {
      method: "get",
      url: `${Urls.parseUrl}/classes/audit?where={"submissionDate":{"$gte":"${startDate}","$lte":"${endDate}"},"status":"Close"}&count=1&order=-submissionDate`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
        "Content-Type": "application/json",
      },
    };
    const data: any = await axios(agreement);
    let arr = [];
    for (let i = 0; i < data.data.count; i += 50) {
      var agreementConfig = {
        method: "get",
        url: `${Urls.parseUrl}/classes/audit?where={"submissionDate":{"$gte":"${startDate}","$lte":"${endDate}"},"status":"Close"}&limit=50&skip=${i}&count=1&order=-submissionDate`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        // params: query,
      };
      const agreementResults: any = await axios(agreementConfig);
      console.log("Agreement Results", agreementResults.data.results);
      arr = arr.concat(agreementResults.data.results);
    }
    console.log("data", arr);
    if (arr.length > 0) fullDataDownload(arr);
    else ToastUtils.showErrorToast("No Data Found");
  };

  const fullDataDownload = (auditData: any) => {
    try {
      const rows = [];
      // Add header row including program headings
      const header = [
        "Date",
        "Brand Name",
        "Outlet Name",
        "Address",
        "Personal Met",
        "Email",
        "Designation",
        "Contact No",
        "Second Person Incharge Name",
        "Second Person Incharge Contact No",
        "Add External Store Photos",
        "Add Internal Store Photos",
        "Signatory Contact No",
        "Latitude",
        "Longitude",
        "Program", // Add program headings
        "Offer Details",
        "Offer Exclusion",
        "End Date",
        "Coll. Visi. (Y/N)",
        "Hon. (Y/N)	",
        "Signatory Name",
        "Designation",
        "Remarks",
        "Items",
        "Created BY",
        "Date & Time",
        "Outlet ID",
      ];
      rows.push(header);
      auditData?.map((data: any) => {
        const externalPhotos =
          data?.externalStorePhotos?.length > 0
            ? data?.externalStorePhotos?.map((item) => item.data)
            : "";
        const internalPhotos =
          data?.internalStorePhotos?.length > 0
            ? data?.internalStorePhotos?.map((item) => item.data)
            : "";
        const dateTime = new Date(data?.updatedAt);
        const options = {
          timeZone: "Asia/Kolkata",
          year: "numeric" as const,
          month: "2-digit" as const,
          day: "2-digit" as const,
          hour: "2-digit" as const,
          minute: "2-digit" as const,
          second: "2-digit" as const,
          hour12: true,
        };

        const formattedDateTime = dateTime
          ?.toLocaleString("en-IN", options)
          ?.replace(/\//g, "-") // Replace slashes with hyphens
          ?.replace(",", ""); // Remove comma and add space

        const originalDate = data?.date;

        // Split the original date string into parts
        const parts = originalDate?.split("-");

        // Rearrange the parts to get dd-mm-yyyy format

        const formattedDate =
          parts?.length >= 3 ? parts[2] + "-" + parts[1] + "-" + parts[0] : "";
        if (data?.program?.length > 0 && data?.type != "Collateral") {
          data?.program?.map((program: any, index: number) => {
            if (index == 0) {
              const row = [
                formattedDate,
                data?.brandName,
                data?.outletName,
                data?.address,
                data?.personalMet,
                data?.auditEmail,
                data?.designation,
                data?.contactNo,
                data?.secondPersonInChargeName,
                data?.secondPersonInChargeNo,
                externalPhotos?.[index],
                internalPhotos?.[index],
                data?.signatoryContactNo,
                data?.latitude ? data?.latitude : "",
                data?.longitude ? data?.longitude : "",
                program.name,
                data?.offer_text,
                data?.offerExclusion,
                data?.offer_validity_endDate,
                program?.coll === "Yes" ? "Yes" : "No",
                program?.hon ? program?.hon : "",
                data?.authorisedSignatoryName,
                data?.designation,
                program?.remark ? program?.remark : "",
                data?.items?.length > 0
                  ? `${Object.keys(data?.items?.[index])[0]}: ${
                      Object.values(data?.items?.[index])[0]
                    }`
                  : "",
                data?.createdBy,
                formattedDateTime,
                data?.outletId,
              ];
              rows.push(row);
            } else {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                externalPhotos?.[index],
                internalPhotos?.[index],
                "",
                "",
                "",
                program.name,
                data?.offer_text,
                data?.offerExclusion,
                data?.offer_validity_endDate,
                program?.coll === "Yes" ? "Yes" : "No",
                program?.hon ? program?.hon : "",
                data?.authorisedSignatoryName,
                data?.designation,
                program?.remark ? program?.remark : "",
                data?.items?.length > 0 && data?.items?.[index]
                  ? `${Object.keys(data?.items?.[index])[0]}: ${
                      Object.values(data?.items?.[index])[0]
                    }`
                  : "",
                ,
                formattedDateTime,
                data?.outletId,
              ];
              rows.push(row);
            }
          });
        } else {
          data.items.map((item, index) => {
            if (index == 0) {
              const row = [
                formattedDate,
                data?.brandName,
                data?.outletName,
                data?.address,
                data?.personalMet,
                data?.auditEmail,
                data?.designation,
                data?.contactNo,
                data?.secondPersonInChargeName,
                data?.secondPersonInChargeNo,
                externalPhotos?.[index],
                internalPhotos?.[index],
                data?.signatoryContactNo,
                data?.latitude ? data?.latitude : "",
                data?.longitude ? data?.longitude : "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                item
                  ? `${Object.keys(item)[0]}: ${Object.values(item)[0]}`
                  : "",
                data?.createdBy,
                formattedDateTime,
                data?.outletId,
              ];
              rows.push(row);
            } else {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                externalPhotos?.[index],
                internalPhotos?.[index],
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                item
                  ? `${Object.keys(item)[0]}: ${Object.values(item)[0]}`
                  : "",
                formattedDateTime,
                data?.outletId,
              ];
              rows.push(row);
            }
          });
        }

        if (
          data?.type === "Both" &&
          data?.program?.length < data?.items?.length
        ) {
          for (let i = data?.program?.length; i < data?.items?.length; i++) {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              externalPhotos?.[i],
              internalPhotos?.[i],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              data?.items?.length > 0
                ? `${Object.keys(data?.items?.[i])[0]}: ${
                    Object.values(data?.items?.[i])[0]
                  }`
                : "",
              data?.createdBy,
              formattedDateTime,
              data?.outletId,
            ];
            rows.push(row);
          }
        }

        if (
          data?.program?.length > 0 &&
          data?.type != "Collateral" &&
          data?.type != "Both"
        ) {
          if (
            data?.program?.length < externalPhotos.length ||
            data?.program?.length < internalPhotos.length
          ) {
            for (
              let i = data?.program?.length;
              i < externalPhotos.length;
              i++
            ) {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                externalPhotos?.[i],
                internalPhotos?.[i],
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ];
              rows.push(row);
            }
          }
          if (
            data?.program?.length < externalPhotos?.length &&
            data?.program?.length < internalPhotos?.length &&
            externalPhotos.length < internalPhotos.length
          ) {
            for (
              let i = externalPhotos?.length;
              i < internalPhotos.length;
              i++
            ) {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                internalPhotos?.[i],
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ];
              rows.push(row);
            }
          }
        } else if (data?.type != "Both") {
          if (
            data?.items?.length < externalPhotos.length ||
            data?.items?.length < internalPhotos.length
          ) {
            for (let i = data?.items?.length; i < externalPhotos.length; i++) {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                externalPhotos?.[i],
                internalPhotos?.[i],
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ];
              rows.push(row);
            }
          }
          if (
            data?.items?.length < externalPhotos?.length &&
            data?.items?.length < internalPhotos?.length &&
            externalPhotos.length < internalPhotos.length
          ) {
            for (
              let i = externalPhotos?.length;
              i < internalPhotos.length;
              i++
            ) {
              const row = [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                internalPhotos?.[i],
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ];
              rows.push(row);
            }
          }
        }
      });

      console.log("Rows", rows);

      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");

      // Write the workbook to an Excel file
      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const file = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = `auditData.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = () => {
    if (value?.outletName === "") {
      setShowEmptyError(true);
      return;
    }
    getTableValues();
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div
      className="dash"
      style={{
        flexGrow: 1,
      }}
    >
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Form className="first-comp">
          <FormInput
            type="text"
            placeholder="Please enter a outlet name"
            value={value?.outletName}
            onChange={(e: any) => {
              setValue({ ...value, outletName: e.target.value });
              if (e.target.value) {
                setShowEmptyError(false);
              } else {
                setShowEmptyError(true);
              }
            }}
          />
          <div
            className="btn-group-1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            <LoadingButton
              isLoading={loading}
              text={"Search"}
              style={{
                color: "white",
                borderRadius: "5px",
                border: "1px solid #405089",
                background: "rgb(64,81,167)",
                outlineColor: "navajowhite",
                fontSize: "13px",
                // width: "70px",
                height: "35px",
              }}
              onClick={submitHandler}
              type="submit"
            />
          </div>
        </Form>
      </div>
      <div>
        {tableValues?.length > 0 && (
          <div
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              marginLeft: "13px",
              marginRight: "13px",
              marginTop: "100px",
              padding: "5px",
            }}
          >
            {checkAudit && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 20px 0 20px",
                }}
              >
                <button
                  className="page-item pagination-prev disabled"
                  style={{
                    boxShadow: "none",
                    padding: "0.25rem 0.5rem",
                    fontSize: "0.7109375rem",
                    borderRadius: "0.2rem",
                    color: "#fff",
                    backgroundColor: "#405189",
                    borderColor: "#405189",
                    width: "150px",
                    alignItems: "center",
                    height: "40px",
                  }}
                  onClick={() => {
                    setCustomReport(!customReport);
                  }}
                >
                  {customReport ? "Audit List" : "Custom Audit Report"}
                </button>
              </div>
            )}
            {!customReport && (
              <>
                <div
                  style={{
                    marginTop: "10px",
                    background: "white",
                    margin: "10px",
                    marginBottom: 0,
                  }}
                >
                  <div style={{ overflowX: "scroll", marginTop: "50px" }}>
                    <Table>
                      {tableValues.length !== 0 ? (
                        <thead>
                          <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                            <th>S.No</th>
                            <th style={{ minWidth: "100px" }}>Audit Type </th>
                            <th style={{ minWidth: "100px" }}>Outlet </th>
                            <th>Outlet Address </th>
                            <th>City </th>
                            <th>Status </th>
                            {checkAudit && <th>Submission Date </th>}
                            <th>Action </th>
                          </tr>
                        </thead>
                      ) : null}
                      <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                        {tableValues.map((data: any, index) => (
                          <tr key={index}>
                            <td
                              style={
                                {
                                  // textAlign: "center",
                                }
                              }
                            >
                              {index + 1 * currentPage * 15 + 1}
                            </td>
                            <td>{data.type}</td>
                            <td>{data.store_name}</td>
                            <td style={{ minWidth: "100px" }}>
                              {data.store_address}
                            </td>
                            <td style={{ minWidth: "100px" }}>
                              {" "}
                              {data.registeredAddressCity}
                            </td>
                            <td>
                              {data.status ? (
                                data?.status === "Close" ? (
                                  <span style={{ color: "green" }}> Close</span>
                                ) : (
                                  <span style={{ color: "green" }}> Hold</span>
                                )
                              ) : (
                                <span style={{ color: "red" }}> Open</span>
                              )}
                            </td>
                            {checkAudit && (
                              <td>
                                {formatDateToDDMMYYYY(data?.submissionDate)}
                              </td>
                            )}
                            <td>
                              <LoadingButton
                                style={{
                                  boxShadow: "none",
                                  padding: "0.25rem 0.5rem",
                                  fontSize: "0.7109375rem",
                                  borderRadius: "0.2rem",
                                  color: "#fff",
                                  backgroundColor: "#405189",
                                  borderColor: "#405189",
                                  width: "100px",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  navigate("/audit-form", {
                                    state: {
                                      auditData: data,
                                    },
                                  });
                                }}
                                disabled={data.isSigning == "true"}
                                text={
                                  data?.status === "Close" && !data?.editCount
                                    ? "Edit"
                                    : "View Details"
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  {currentPage >= 0 && tableValues?.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <button
                        className="page-item pagination-prev disabled"
                        onClick={async () => {
                          const getAudit = await getAudits(
                            15,
                            (currentPage - 1) * 15
                          );
                          setCurrentPage(currentPage - 1);
                          setTableValues(getAudit?.data?.results);
                        }}
                        style={{
                          marginRight: "5px",
                          outline: 0,
                          background: "white",
                          border: "1px solid #e9ebec",
                          padding: "0.372rem 0.75rem",
                          fontSize: "13px",
                          borderRadius: "0.25rem",
                          color: currentPage > 0 ? "blue" : "#878a99",
                        }}
                        disabled={currentPage > 0 ? false : true}
                      >
                        Previous
                      </button>
                      <div
                        style={{
                          textAlign: "center",
                          margin: "3px",
                          // color: "black",
                          // fontWeight: "bold",
                          backgroundColor: "#405189",
                          borderColor: "#405189",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0.372rem 0.75rem",
                          fontSize: "13px",
                          borderRadius: "0.25rem",
                        }}
                      >
                        {currentPage + 1}
                      </div>
                      <button
                        className="page-item pagination-prev disabled"
                        // onClick={() =>
                        //   console.log((currentPage - 1) * 15)}
                        onClick={async () => {
                          const getAudit = await getAudits(
                            15,
                            (currentPage + 1) * 15
                          );
                          setCurrentPage(currentPage + 1);
                          setTableValues(getAudit?.data?.results);
                        }}
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          outline: 0,
                          background: "white",
                          border: "1px solid #e9ebec",
                          padding: "0.372rem 0.75rem",
                          fontSize: "13px",
                          borderRadius: "0.25rem",
                          color: currentPage >= count - 1 ? "#878a99" : "blue",
                        }}
                        disabled={currentPage >= count - 1 ? true : false}
                      >
                        Next
                      </button>
                    </div>
                  ) : null}
                </div>
              </>
            )}
            {customReport && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={async (values: any, { resetForm }) => {
                  setLoading(true);
                  const startDate = new Date(values.startDate);
                  const endDate = new Date(values.endDate);
                  const today = new Date();

                  const differenceInTime =
                    endDate.getTime() - startDate.getTime();
                  const differenceInDays =
                    differenceInTime / (1000 * 3600 * 24);

                  if (differenceInDays > 30) {
                    ToastUtils.showErrorToast(
                      "The maximum allowed difference between start and end dates is 30 days"
                    );
                    setLoading(false);
                    return;
                  } else if (differenceInDays < 0) {
                    ToastUtils.showErrorToast(
                      "End date cannot be earlier than start date"
                    );
                    setLoading(false);
                    return;
                  } else if (startDate > today) {
                    ToastUtils.showErrorToast(
                      "you can not select a start date as future date"
                    );
                    setLoading(false);
                    return;
                  } else if (endDate > today) {
                    ToastUtils.showErrorToast(
                      "you can not select a end date as future date"
                    );
                    setLoading(false);
                    return;
                  }
                  await getData(startDate.toISOString(), endDate.toISOString());
                  setLoading(false);
                  resetForm();
                }}
              >
                {({ handleSubmit }) => (
                  <div
                    style={{
                      background: "#ffffff",

                      paddingBottom: "35px",
                    }}
                  >
                    <div className="form-box" style={{ textAlign: "center" }}>
                      <Text color="#494f57" fontWeight="bold" fontSize="20px">
                        Download Audit Report
                      </Text>
                    </div>
                    <Form>
                      <div
                        style={{
                          marginLeft: "10%",
                          marginRight: "10%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <FormikInput
                          label="Start Date"
                          type="datetime-local"
                          name="startDate"
                        />
                        <FormikInput
                          label="End Date"
                          type="datetime-local"
                          name="endDate"
                        />
                        {/* <FormikInput
                    label="Agreement Type"
                    type="text"
                    name="agreementType"
                    value="Fresh"
                    disabled
                  /> */}
                        {/* <div>
                    {!uploadingFile && fileUploadSuccess ? "success" : ""}
                  </div> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <LoadingButton
                            style={{
                              color: "white",
                              borderRadius: "5px",
                              border: "1px solid #405089",
                              background: "rgb(64,81,167)",
                              outlineColor: "navajowhite",
                              fontSize: "13px",
                              minWidth: "70px",
                              height: "35px",
                            }}
                            text={"Downlaod Report"}
                            type="button"
                            onClick={handleSubmit}
                            // onClick={() => alert("hello")}
                            isLoading={loading}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Audit;
