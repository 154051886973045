import React from "react";
import { FaGreaterThan } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app-context";

export default function SubmitForm() {
  const navigate = useNavigate();
  const context = useAppContext();
  const { isEditing } = context.state;

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}></h3>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          background: "white",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <p
          style={{
            color: "#495057",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          A Form has been submited
        </p>
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <button
            style={{
              color: "white",
              borderRadius: "5px",
              border: "1px solid #405089",
              background: "rgb(64,81,167)",
              outlineColor: "navajowhite",
              fontSize: "13px",
              height: "35px",
            }}
            onClick={(event) => {
              event.preventDefault();
              window.location.reload();
            }}
          >
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
}
