import { FieldArray, Form, Formik } from "formik";
import { off } from "process";
import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";
import FormikInput from "../../../components/FormikInput";
import GoBackBtn from "../../../components/GoBackBtn";
import LoadingButton from "../../../components/LoadingButton";
import Text from "../../../components/Text";
import { useAppContext } from "../../../context/app-context";
import { useMerchantContext } from "../../../context/merchant-context";
import { useUserContext } from "../../../context/user-context";
import ServerUploadsUtils from "../../../utils/serverUploads";
import { validationSchema } from "./validationSchema";
import Multiselect from "../../../components/Multiselect";
import axios from "axios";
import ToastUtils from "../../../utils/toast/toast-utils";
import FormikSelect from "../../../components/FormikSelect";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";

const initialValuesObj = {
  upload_signed_agreement: "",
  reason: [],
  agreement_resign_needed: "",
};

export default function AddAgreement() {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [hold, setHold] = useState(false);
  const [reject, setReject] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [remarks, setRemarks] = useState<string>("");
  const [startSigning, setStartSigning] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesObj);

  const merchantContext = useMerchantContext();
  const context = useAppContext();
  const { clearOffers, clearStores, setIsEditing } = context;
  const {
    stores,
    offers,
    isEditing,
    selectedAgreement,
    formData: updateFormData,
  } = context.state;
  const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));
  const { formData, selectedOffers, selectedStores } = merchantContext.state;
  const { addPageIndex, subtractPageIndex, setCustomPageIndex } =
    merchantContext;
  const { setFormData } = merchantContext;
  const { updateFormData: setMasterFormData } = context;
  const userContext: any = useUserContext();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  useEffect(() => {
    if (isEmpty(updateFormData)) {
      // alert("empty");
      setInitialValues(initialValuesObj);
    } else {
      // alert("not empty" + JSON.stringify(updateFormData));
      setInitialValues({
        upload_signed_agreement: updateFormData?.upload_signed_agreement,
        reason: updateFormData?.reason,
        agreement_resign_needed: updateFormData?.resignNeeded,
      });
      setRemarks(updateFormData.agreementRemarks);
    }
  }, [updateFormData]);

  const handleApprove = async () => {
    setApprove(true);
    const { name, objectId } = JSON.parse(localStorage.getItem("user"));
    try {
      const mailData = {
        approvedMail: formData.sales,
        agreement: formData.upload_signed_agreement,
        merchantName: formData.merchantName,
        brandName: formData.brandName,
        dateOnboarded: formData.signedDate
          ? formData.signedDate
          : formData.updatedAt.substring(0, 10),
        verifyingExecutive: name,
        remarks: remarks,
      };
      const mailConfig = {
        method: "post",
        url: Urls.lambdaUrl,
        headers: {
          "Content-Type": "application/json",
          Origin: "*",
        },
        data: mailData,
      };
      await axios(mailConfig);

      const updateMerchant = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/merchant/${formData?.merchantId}`,
        { salesConfirm: "approved", verifierName: name },
        {
          "Content-Type": "application/json",
        }
      );
      await axios(updateMerchant);
      await ServerUploadsUtils.unlockVerifier(
        formData.agreementId,
        objectId,
        formData.merchantId
      );
      setApprove(false);
      setCustomPageIndex(13);
      setFormData({});
      clearStores();
      clearOffers();
      setIsEditing(false);
    } catch (error) {
      setApprove(false);
    }
  };

  const handleReject = async (reason: Array<string>, resign: string) => {
    setReject(true);
    const { name, objectId } = JSON.parse(localStorage.getItem("user"));
    try {
      const mailData = {
        rejectedMail: formData.sales,
        agreement: formData.upload_signed_agreement,
        merchantName: formData.merchantName,
        brandName: formData.brandName,
        dateOnboarded: formData.signedDate ? formData.signedDate : "",
        verifyingExecutive: name,
        remarks: remarks,
        reason: reason.join(","),
      };
      const mailConfig = {
        method: "post",
        url: Urls.lambdaUrl,
        headers: {
          "Content-Type": "application/json",
          Origin: "*",
        },
        data: mailData,
      };
      await axios(mailConfig);
      const updateMerchant = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/merchant/${formData?.merchantId}`,
        {
          salesConfirm: "rejected",
          agreementRemarks: remarks,
          reason,
          resignNeeded: resign,
          verifierName: name,
        },
        {
          "Content-Type": "application/json",
        }
      );
      await axios(updateMerchant);
      await ServerUploadsUtils.unlockVerifier(
        formData.agreementId,
        objectId,
        formData.merchantId
      );
      await ServerUploadsUtils.addLogs(
        "agreement",
        formData?.agreementId,
        null,
        "",
        "",
        remarks,
        reason.join(",")
      );
      setReject(false);
      setCustomPageIndex(13);
      setFormData({});
      clearStores();
      clearOffers();
      setIsEditing(false);
    } catch (error) {
      setReject(false);
    }
  };

  const handleHold = async () => {
    setHold(true);
    const { name, objectId } = JSON.parse(localStorage.getItem("user"));
    try {
      const updateMerchant = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/merchant/${formData?.merchantId}`,
        { salesConfirm: "hold", verifierName: name },
        {
          "Content-Type": "application/json",
        }
      );
      await axios(updateMerchant);
      await ServerUploadsUtils.unlockVerifier(
        formData.agreementId,
        objectId,
        formData.merchantId
      );
      setApprove(false);
      setCustomPageIndex(13);
      setFormData({});
      clearStores();
      clearOffers();
      setIsEditing(false);
    } catch (error) {
      setApprove(false);
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {" "}
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            marginRight: "5%",
            marginLeft: "5%",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={startSigning ? null : validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={
              startSigning
                ? () => {
                    setLoading(true);
                    if (isEditing) {
                      if (
                        formData?.authorisedSignatoryMail !=
                          formData?.authorisedSignatoryMail_confirm &&
                        formData.signingStatus == "true"
                      ) {
                        alert(
                          "You Can't Change Authorized Signatory Mail if the agreement is signed"
                        );
                        setLoading(false);
                        return;
                      }
                    }
                    addPageIndex();
                  }
                : async (values) => {
                    setLoading(true);
                    try {
                      if (isEditing) {
                        if (
                          formData?.authorisedSignatoryMail !=
                            formData?.authorisedSignatoryMail_confirm &&
                          formData.signingStatus == "true"
                        ) {
                          setLoading(false);
                          ToastUtils.showErrorToast(
                            "You Can't Change Authorized Signatory Mail if the agreement is signed"
                          );
                          return;
                        }
                        if (
                          formData?.authorisedSignatoryMail !=
                          formData?.authorisedSignatoryMail_confirm
                        ) {
                          setLoading(false);
                          ToastUtils.showErrorToast(
                            "if authorised signatory mail is changed you have to start digitally signin again"
                          );
                          return;
                        } else {
                          await ServerUploadsUtils.updateDataToDB(
                            { ...formData, ...values },
                            selectedStores,
                            selectedOffers,
                            selectedAgreement?.objectId,
                            formData?.sales,
                            formData.signingStatus == "true" ? "true" : "false",
                            formData.signingStatus == "true" ? true : false
                          );
                        }
                      } else {
                        await ServerUploadsUtils.saveDataToDB(
                          { ...formData, ...values },
                          selectedStores,
                          selectedOffers,
                          userContext?.state?.user?.username,
                          "true",
                          true
                        );
                      }
                      setLoading(false);
                      // addPageIndex();
                      setCustomPageIndex(13);
                      setFormData({});
                      clearStores();
                      clearOffers();
                      setIsEditing(false);
                    } catch (e) {
                      setLoading(false);
                    }
                  }
            }
          >
            {({ isSubmitting, values, handleSubmit }) => (
              <div
                style={{
                  background: "#ffffff",
                  // marginBottom: "20px",
                  paddingTop: "15px",
                }}
              >
                <div className="form-box" style={{ textAlign: "center" }}>
                  <Text color="#494f57" fontWeight="bold" fontSize="20px">
                    {isEditing ? "Edit Agreement" : "Add Agreement"}
                  </Text>
                  <Text color="#494f57" fontWeight="bold" fontSize="20px">
                    Date:&nbsp;
                    {new Date().getDate() +
                      "/" +
                      new Date().getMonth() +
                      "/" +
                      new Date().getFullYear()}
                    &nbsp;&nbsp; Time : &nbsp;{new Date().getHours() + ":"}
                    &nbsp;{new Date().getMinutes() + ":"}&nbsp;
                    {new Date().getSeconds()}
                  </Text>
                </div>
                <Form>
                  <div
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <FormikInput
                      label="Upload Signed Agreement"
                      type="file"
                      name="upload_signed_agreement"
                      filename={values.upload_signed_agreement}
                      value=""
                      onChange={async (e) => {
                        setUploadingFile(true);
                        setUploadSuccess(false);
                        try {
                          let contentType = "";
                          const file = e.target.files[0];
                          const fileName = e.target.files[0].name.replace(
                            /[^a-zA-Z]/g,
                            ""
                          );
                          if (file.type.startsWith("image/")) {
                            contentType = "image/jpeg";
                          } else if (file.type === "application/pdf") {
                            contentType = "application/pdf";
                          }
                          console.log("contentType", contentType);
                          values.upload_signed_agreement =
                            await ServerUploadsUtils.uploadImageToServer(
                              file,
                              fileName,
                              contentType
                            );
                          const url = values.upload_signed_agreement;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.upload_signed_agreement = str1 + "s" + str2;
                          setUploadingFile(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFile(false);
                        }
                      }}
                      disabled={startSigning || uploadingFile}
                    />
                    {typeOfUser?.SalesHandler == true && (
                      <>
                        <FieldArray
                          name="reason"
                          render={(arrayHelpers) => (
                            <Multiselect
                              label="Reason"
                              name="reason"
                              options={[
                                "Checked and Approved",
                                "Offer is incorrect",
                                "Commission is incorrect",
                                "Incorrect Program selection",
                                "Validity missing",
                                "TID MID missing",
                                "Multiple issues",
                                "Refer remarks",
                                "Others",
                              ]}
                              selectedValues={values.reason}
                              onSelect={(e, listItem) => {
                                arrayHelpers.push(listItem);
                              }}
                              onRemove={(e, listItem) => {
                                const elementToRemoveIndex =
                                  values.reason.indexOf(listItem);
                                arrayHelpers.remove(elementToRemoveIndex);
                              }}
                            />
                          )}
                        />
                        <p
                          style={{
                            marginBottom: "1px",
                            paddingBottom: "5px",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Remarks
                        </p>
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows={10}
                          cols={50}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            setRemarks(e.target.value);
                          }}
                          value={remarks}
                        ></textarea>
                        <FormikSelect
                          label="Agreement Resign Needed"
                          name="agreement_resign_needed"
                          options={["Yes", "No"]}
                        />
                      </>
                    )}
                    {typeOfUser?.SalesHandler != true && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        OR
                      </div>
                    )}
                    {typeOfUser?.SalesHandler != true && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <label
                          htmlFor="startSign"
                          style={{
                            order: 1,
                          }}
                        >
                          Start Digital Signing
                        </label>
                        <input
                          type="checkbox"
                          checked={startSigning}
                          onChange={() => setStartSigning(!startSigning)}
                          id="startSign"
                        />
                      </div>
                    )}
                    {typeOfUser?.SalesHandler != true && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          gap: "9px",
                        }}
                      >
                        <GoBackBtn
                          onClick={() => {
                            setMasterFormData(formData);
                            subtractPageIndex();
                          }}
                        />
                        <LoadingButton
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #405089",
                            background:
                              isSubmitting || uploadingFile
                                ? "rgb(64,81,167)"
                                : "rgb(64,81,167)",
                            outlineColor: "navajowhite",
                            fontSize: "13px",
                            minWidth: "70px",
                            height: "35px",
                          }}
                          text={startSigning ? "Next" : "Submit"}
                          type="button"
                          onClick={handleSubmit}
                          disabled={
                            isSubmitting || uploadingFile ? true : false
                          }
                          isLoading={loading}
                        />
                      </div>
                    )}

                    {typeOfUser?.SalesHandler == true && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          gap: "9px",
                        }}
                      >
                        <GoBackBtn
                          onClick={() => {
                            setMasterFormData(formData);
                            subtractPageIndex();
                          }}
                        />
                        {formData.verificationStatus !== "approved" &&
                          formData.verificationStatus !== "rejected" && (
                            <>
                              {" "}
                              <LoadingButton
                                style={{
                                  color: "white",
                                  borderRadius: "5px",
                                  border: "1px solid #405089",
                                  background:
                                    isSubmitting || uploadingFile
                                      ? "rgb(64,81,167)"
                                      : "rgb(64,81,167)",
                                  outlineColor: "navajowhite",
                                  fontSize: "13px",
                                  minWidth: "70px",
                                  height: "35px",
                                }}
                                text={"Reject"}
                                type="button"
                                onClick={async () => {
                                  await handleReject(
                                    values.reason,
                                    values.agreement_resign_needed
                                  );
                                }}
                                isLoading={reject}
                              />
                              <LoadingButton
                                style={{
                                  color: "white",
                                  borderRadius: "5px",
                                  border: "1px solid #405089",
                                  background:
                                    isSubmitting || uploadingFile
                                      ? "rgb(64,81,167)"
                                      : "rgb(64,81,167)",
                                  outlineColor: "navajowhite",
                                  fontSize: "13px",
                                  minWidth: "70px",
                                  height: "35px",
                                }}
                                text={"Approve"}
                                type="button"
                                disabled={
                                  values.agreement_resign_needed === "Yes"
                                }
                                onClick={async () => {
                                  await handleApprove();
                                }}
                                isLoading={approve}
                              />
                              <LoadingButton
                                style={{
                                  color: "white",
                                  borderRadius: "5px",
                                  border: "1px solid #405089",
                                  background:
                                    isSubmitting || uploadingFile
                                      ? "rgb(64,81,167)"
                                      : "rgb(64,81,167)",
                                  outlineColor: "navajowhite",
                                  fontSize: "13px",
                                  minWidth: "70px",
                                  height: "35px",
                                }}
                                text={"Hold"}
                                type="button"
                                disabled={
                                  values.agreement_resign_needed === "Yes"
                                }
                                onClick={async () => {
                                  await handleHold();
                                }}
                                isLoading={hold}
                              />
                            </>
                          )}
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
