import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import FormInput from "../../components/Input";
import Text from "../../components/Text";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import { FaGreaterThan } from "react-icons/fa";
import Loading from "../../components/shared/Loading";
import LoadingButton from "../../components/LoadingButton";
import DateUtils from "../../utils/date-utils";
import { useAuth } from "../../hooks/useAuth";
import { useUserContext } from "../../context/user-context";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useAppContext } from "../../context/app-context";
import Urls from "../../utils/urls";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import ToastUtils from "../../utils/toast/toast-utils";
import PopupLogs from "../../components/PopuLogs";

const SalesRepresent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [hold, setHold] = useState(false);
  const [count, setCount] = useState(0);
  const [reject, setReject] = useState(false);
  const typeOfUser = JSON.parse(localStorage.getItem("userPermissions"));
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const { objectId } = JSON.parse(localStorage.getItem("user"));
  const [showPopup, setShowPopup] = useState(false);
  const [loadingAgreement, setLoadingAgreement] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState({
    merchant: "",
  });
  const [errorSearchTerm, setErrorSearchTerm] = useState("");
  const [tableValues, setTableValues] = useState([]);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showNoResultsError, setShowNoResultsError] = useState(false);
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [allLogs, setAllLogs] = useState({});

  const context = useAppContext();
  const {
    updateFormData,
    setOffers,
    setStores,
    setSigningState,
    setselectedAgreement,
    setIsEditing,
  } = context;
  const checkAuth = useAuth("/login");

  useEffect(() => {
    console.log(isLoggedIn, "isLoggedIn");
    if (isLoggedIn == "false") {
      navigate("/login");
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (typeOfUser?.salesRepresentative) {
      navigate("/");
    } else if (typeOfUser?.CanSignAgreement) {
      navigate("/sign-agreement");
    }
  }, []);

  const getTableValues = async (merchantType: string) => {
    if (merchantType === "SignedMerchant") {
      setLoading(true);
    } else if (merchantType === "ApprovedMerchant") {
      setApprove(true);
    } else if (merchantType === "HoldMerchant") {
      setHold(true);
    } else {
      setReject(true);
    }
    const getMerchant = await getMerchants(merchantType, 15, 0);
    try {
      if (getMerchant.data.count === 0 && !getMerchant.data) {
        setShowNoResultsError(true);
        setErrorSearchTerm(value.merchant);
        setLoading(false);
        return;
      }
      if (getMerchant.data.count % 15) {
        setCount(Math.trunc(getMerchant.data.count / 15) + 1);
      } else {
        setCount(Math.trunc(getMerchant.data.count / 15));
      }
      setShowNoResultsError(false);
      setTableValues(getMerchant.data.results);
      setCurrentPage(0);
      if (merchantType === "SignedMerchant") {
        setLoading(false);
      } else if (merchantType === "ApprovedMerchant") {
        setApprove(false);
      } else if (merchantType === "HoldMerchant") {
        setHold(false);
      } else {
        setReject(false);
      }
    } catch (e) {
      if (merchantType === "SignedMerchant") {
        setLoading(false);
      } else if (buttonType === "ApprovedMerchant") {
        setApprove(false);
      } else {
        setReject(false);
      }
    }
    return;
  };

  const onClickTable = (merchantType) => {
    getTableValues(merchantType);
  };

  const getMerchants = async (
    merchantType: string,
    limit: number,
    skip: number
  ) => {
    try {
      let queryParams: Object;
      if (merchantType === "SignedMerchant") {
        queryParams = {
          where: {
            merchantPointer: {
              $inQuery: {
                where: {
                  signingStatus: "true",
                  merchantName: {
                    $regex: value.merchant ? value.merchant.trim() : "",
                    $options: "i",
                  },
                  salesConfirm: "",
                },
                className: "merchant",
              },
            },
          },
          order: "-createdAt",
          keys: "brandPointer.registeredAddressCity,brandPointer.categoryOfMerchant,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,storePointer.objectId,offersPointer.objectId",
          limit: limit,
          skip: skip,
          count: 1,
        };
      } else if (merchantType === "ApprovedMerchant") {
        queryParams = {
          where: {
            merchantPointer: {
              $inQuery: {
                where: {
                  merchantName: {
                    $regex: value.merchant.trim(),
                    $options: "i", // Case-insensitive matching
                  },
                  salesConfirm: "approved",
                },
                className: "merchant",
              },
            },
          },
          order: "-createdAt",
          keys: "brandPointer.registeredAddressCity,brandPointer.categoryOfMerchant,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,merchantPointer.verifierName,storePointer.objectId,offersPointer.objectId",
          limit: limit,
          skip: skip,
          count: 1,
          include: "merchantPointer.verifier",
        };
      } else if (merchantType === "HoldMerchant") {
        queryParams = {
          where: {
            merchantPointer: {
              $inQuery: {
                where: {
                  merchantName: {
                    $regex: value.merchant.trim(),
                    $options: "i", // Case-insensitive matching
                  },
                  salesConfirm: "hold",
                },
                className: "merchant",
              },
            },
          },
          order: "-createdAt",
          keys: "brandPointer.registeredAddressCity,brandPointer.categoryOfMerchant,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,merchantPointer.verifierName,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,storePointer.objectId,offersPointer.objectId",
          limit: limit,
          skip: skip,
          count: 1,
        };
      } else {
        queryParams = {
          where: {
            merchantPointer: {
              $inQuery: {
                where: {
                  merchantName: {
                    $regex: value.merchant.trim(),
                    $options: "i", // Case-insensitive matching
                  },
                  salesConfirm: "rejected",
                },
                className: "merchant",
              },
            },
          },
          order: "-createdAt",
          keys: "brandPointer.registeredAddressCity,brandPointer.categoryOfMerchant,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,merchantPointer.verifierName,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,storePointer.objectId,offersPointer.objectId",
          limit: limit,
          skip: skip,
          count: 1,
        };
      }
      console.log("QueryParams", queryParams);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Urls.parseUrl}/classes/agreement`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        params: queryParams, // Pass the params as an object
      };
      const getMerchant = await axios.request(config);
      return getMerchant;
    } catch (error) {
      console.log(error);
    }
  };

  const DirectToSignPage = async (id: string) => {
    console.log("ID", id);
    var agreementQuery = {
      where: {
        "merchant.id": {
          $eq: id,
        },
      },
      include: "merchantPointer",
    };
    const config = AxiosUtils.axiosConfigConstructor(
      "get",
      Urls.parseUrl + `/classes/agreement`,
      null,
      {
        "Content-Type": "application/json",
      },
      agreementQuery
    );
    try {
      const response = await axios(config);
      // setLoadingAgreementForSigning(false);
      console.log(response);
      const agreementList: any = response.data.results;
      console.log(agreementList[0]);
      setselectedAgreement(agreementList[0]);
      let merchantResp = await ServerUploadsUtils.fetchObjectIds("merchant", [
        agreementList[0]?.merchant?.id,
      ]);
      let brandResp = await ServerUploadsUtils.fetchObjectIds("brand", [
        agreementList[0]?.brand?.id,
      ]);
      let storesResp = await ServerUploadsUtils.fetchStoresAndOffers(
        "stores",
        agreementList[0]?.stores
      );
      let offersResp = await ServerUploadsUtils.fetchStoresAndOffers(
        "offers",
        agreementList[0]?.offers
      );

      console.log("merchantResp", merchantResp);
      console.log("brandResp", brandResp);
      console.log("storesResp", storesResp);
      console.log("offersRep", offersResp);
      const formData = {
        ...merchantResp?.data?.results[0],
        ...brandResp?.data?.results[0],
      };
      console.log("ABCDRF", formData);
      const values = {
        salesRep: formData?.salesRep,
        agreementType: formData?.agreementType,
        registration_no: formData?.registrationNo,
        signingStatus: formData?.signingStatus,
        mouType: formData?.mouType,
        mouValidity: formData?.mouValidity,
        brandType: formData?.brandType,
        country: formData?.country,
        poBox: formData?.poBox,
        brandName: formData?.brandName,
        companyType: formData?.companyType,
        emirates: formData?.emirates,
        spocName: formData?.spocName,
        spocNo: formData?.spocNo,
        spocEmail: formData?.spocEmail,
        extraField: formData.extraField,
        sales: formData.sales,
        signatureDisplayName: formData?.signatureDisplayName,
        templateType: formData.templateType,
        authorisedSignatoryName: formData?.authorisedSignatoryName,
        authorisedSignatoryNo: formData?.authorisedSignatoryNo,
        merchantName: formData?.merchantName,
        corporateAddress: formData?.corporateAddress,
        authorisedSignatoryMail: formData?.authorisedSignatoryMail,
        authorisedSignatoryMail_confirm: formData?.authorisedSignatoryMail,
        registeredAddress: formData?.registeredAddress,
        categoryOfMerchant: formData?.categoryOfMerchant,
        registeredAddressPincode: formData?.registeredAddressPincode,
        corporateAddressCountry: formData?.corporateAddressCountry,
        registeredAddressCountry: formData?.registeredAddressCountry,
        corporateAddressPincode: formData.corporateAddressPincode,
        store_name: formData?.storeName,
        store_address: formData?.storeAddress,
        gst_no: formData?.gstNo,
        registeredAddressState: formData?.registeredAddressState,
        registeredAddressCity: formData?.registeredAddressCity,
        corporateAddressCity: formData?.corporateAddressCity,
        corporateAddressState: formData?.corporateAddressState,
        contact_name: formData?.contactName,
        contact_no: formData?.contactNo,
        shift_number: formData?.shiftNumber,
        store_email: formData?.storeEmail,
        upload_carges_slip: formData?.uploadCargesSlip,
        designation: formData?.designation,
        logo: formData?.logo,
        merchantId: merchantResp?.data?.results[0].objectId,
        brandId: brandResp?.data?.results[0].objectId,
        upload_signed_agreement: agreementList[0].upload_signed_agreement,
        updatedAt: agreementList[0].updatedAt,
        signedDate: formData?.signedDate,
        agreementId: agreementList[0].objectId,
        verificationStatus: agreementList[0]?.merchantPointer?.salesConfirm,
        agreementRemarks: formData.agreementRemarks,
        reason: formData.reason,
        resignNeeded: formData.resignNeeded,
      };
      console.log("JHTK", values);
      updateFormData(values);
      setStores(storesResp);
      setOffers(offersResp);
      setLoadingAgreement(false);
      setSigningState(true);
      navigate("/edit-merchant");
    } catch (e) {
      setLoadingAgreement(false);
    }
  };

  return (
    <div
      className="dash"
      style={{
        flexGrow: 1,
      }}
    >
      <div
        style={{
          // paddingTop: "90px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      {!typeOfUser?.CanSignAgreement && (
        <div className="dashboard">
          <div className="sub-text">
            <Text color="#494f57" fontWeight="500" fontSize="22.2875px">
              Submission
            </Text>
          </div>
          {showPopup && (
            <PopupLogs data={allLogs} onClose={() => setShowPopup(false)} />
          )}
          <Form className="first-comp">
            <FormInput
              type="text"
              placeholder="Please enter a merchant name"
              value={value.merchant}
              onChange={(e: any) => {
                setValue({ ...value, merchant: e.target.value });
                setShowEmptyError(false);
              }}
            />
            {showEmptyError && (
              <div
                style={{
                  color: "red",
                }}
              >
                <small>Please enter merchant name</small>
              </div>
            )}
            <div
              className="btn-group-1"
              style={{
                justifyContent: "center",
                alignItems: "center",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <LoadingButton
                isLoading={loading}
                text={"Search"}
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Button Clicked");
                  setButtonType("SignedMerchant");
                  onClickTable("SignedMerchant");
                }}
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #405089",
                  background: "rgb(64,81,167)",
                  outlineColor: "navajowhite",
                  fontSize: "13px",
                  // width: "70px",
                  height: "35px",
                }}
                type="submit"
              />
              <Text color="rgb(73,80,87)" fontWeight="500" fontSize="16.25px">
                Or
              </Text>
              <LoadingButton
                isLoading={hold}
                text={"Hold Merchant"}
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Button Clicked");
                  setButtonType("HoldMerchant");
                  onClickTable("HoldMerchant");
                }}
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #0ab39c",
                  background: "#0ab39c",
                  outlineColor: "navajowhite",
                  height: "40px",
                  padding: "6px",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
                type="submit"
              />
              <div className="sub-text1">
                <Text color="rgb(73,80,87)" fontWeight="500" fontSize="16.25px">
                  Or
                </Text>
                <LoadingButton
                  isLoading={reject}
                  text={"Rejected Merchant"}
                  className="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Button Clicked");
                    setButtonType("RejectMerchant");
                    onClickTable("RejectMerchant");
                  }}
                  style={{
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid #0ab39c",
                    background: "#0ab39c",
                    outlineColor: "navajowhite",
                    height: "40px",
                    padding: "6px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                  type="submit"
                />
                <Text color="rgb(73,80,87)" fontWeight="500" fontSize="16.25px">
                  Or
                </Text>
                <LoadingButton
                  isLoading={approve}
                  text={"Approved Merchant"}
                  className="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Button Clicked");
                    setButtonType("ApprovedMerchant");
                    onClickTable("ApprovedMerchant");
                  }}
                  style={{
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid #0ab39c",
                    background: "#0ab39c",
                    outlineColor: "navajowhite",
                    height: "40px",
                    padding: "6px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </div>
      )}
      {showNoResultsError ? (
        <div style={{ textAlign: "center" }}>
          <span>No result found for:</span>
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            {errorSearchTerm}
          </span>
        </div>
      ) : (
        tableValues?.length > 0 && (
          <div
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              marginLeft: "13px",
              marginRight: "13px",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                background: "white",
                margin: "10px",
                marginBottom: 0,
              }}
            >
              <div style={{ overflowX: "scroll" }}>
                <Table>
                  {tableValues.length !== 0 ? (
                    <thead>
                      <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                        <th>S.No</th>
                        <th>Merchant </th>
                        <th>City </th>
                        <th style={{ minWidth: "120px" }}> MOU Type</th>
                        <th style={{ minWidth: "120px" }}>Standalone/Chain</th>
                        <th style={{ minWidth: "100px" }}>Sales Rep </th>
                        <th>Created At </th>
                        <th>Updated At </th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>View Logs</th>
                        <th>Verifier Name</th>
                      </tr>
                    </thead>
                  ) : null}
                  <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
                    {tableValues.map((data: any, index) => (
                      <tr key={index}>
                        <td
                          style={
                            {
                              // textAlign: "center",
                            }
                          }
                        >
                          {index + 1 + currentPage * 15}.
                        </td>
                        <td>{data.merchantPointer.merchantName}</td>
                        <td>{data.brandPointer.registeredAddressCity}</td>
                        <td>{data.merchantPointer.mouType}</td>
                        <td>{data?.brandPointer?.categoryOfMerchant}</td>
                        <td>{data.merchantPointer.salesRep}</td>
                        <td style={{ minWidth: "100px" }}>
                          {DateUtils.formatDate(data.createdAt)}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          {" "}
                          {DateUtils.formatDate(data.updatedAt)}
                        </td>
                        <td>
                          <LoadingButton
                            style={{
                              boxShadow: "none",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.7109375rem",
                              borderRadius: "0.2rem",
                              color: "#fff",
                              backgroundColor: "#405189",
                              borderColor: "#405189",
                              width: "100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            disabled={
                              data?.verifiersPointer?.userId &&
                              data?.verifiersPointer?.userId !== objectId
                            }
                            onClick={async (e) => {
                              e.preventDefault();
                              setIsEditing(true);
                              setSelectedAgreementId(
                                data.merchantPointer.objectId
                              );
                              setLoadingAgreement(true);
                              const queryParams = {
                                where: {
                                  objectId: data.objectId,
                                },
                                order: "-createdAt",
                                keys: "brandPointer.registeredAddressCity,merchantPointer.merchantName,merchantPointer.mouType,merchantPointer.salesRep,merchantPointer.signingStatus,verifiersPointer.userId,verifiersPointer.merchant,merchantPointer.salesConfirm,storePointer.objectId,offersPointer.objectId",
                              };
                              let config = {
                                method: "get",
                                maxBodyLength: Infinity,
                                url: `${Urls.parseUrl}/classes/agreement`,
                                headers: {
                                  "X-Parse-Master-Key": Urls.MASTERKEY,
                                  "X-Parse-Application-Id": Urls.APPLICATIONID,
                                  "Content-Type": "application/json",
                                },
                                params: queryParams, // Pass the params as an object
                              };
                              const getMerchant = await axios.request(config);

                              const agreement = getMerchant.data.results[0];

                              if (
                                typeOfUser.SalesHandler &&
                                agreement?.merchantPointer?.salesConfirm !==
                                  "approved"
                              ) {
                                if (
                                  agreement?.verifiersPointer &&
                                  agreement?.verifiersPointer?.userId !==
                                    objectId
                                ) {
                                  ToastUtils.showErrorToast(
                                    "This merchant is already asign you can't verify this merchant"
                                  );
                                  setLoadingAgreement(false);
                                  return;
                                } else if (!agreement?.verifiersPointer) {
                                  const getVerifierQuery = {
                                    where: {
                                      userId: objectId,
                                    },
                                  };
                                  const verifierConfig =
                                    AxiosUtils.axiosConfigConstructor(
                                      "get",
                                      Urls.parseUrl + `/classes/verifier`,
                                      null,
                                      {
                                        "Content-Type": "application/json",
                                      },
                                      getVerifierQuery
                                    );
                                  const isVerifierExist = await axios(
                                    verifierConfig
                                  );
                                  if (isVerifierExist.data.results.length > 0) {
                                    const updateVirifierQuery = {
                                      merchant: {
                                        __op: "AddUnique",
                                        objects: [
                                          agreement.merchantPointer.objectId,
                                        ],
                                      },
                                    };
                                    const updateVerifierConfig =
                                      AxiosUtils.axiosConfigConstructor(
                                        "put",
                                        Urls.parseUrl +
                                          `/classes/verifier/${isVerifierExist.data.results[0].objectId}`,
                                        updateVirifierQuery,
                                        {
                                          "Content-Type": "application/json",
                                        },
                                        null
                                      );
                                    await axios(updateVerifierConfig);
                                    const updateAgreementQuery = {
                                      verifiersPointer: {
                                        __type: "Pointer",
                                        className: "verifier",
                                        objectId:
                                          isVerifierExist.data.results[0]
                                            .objectId,
                                      },
                                    };
                                    const updateAgreementConfig =
                                      AxiosUtils.axiosConfigConstructor(
                                        "put",
                                        Urls.parseUrl +
                                          `/classes/agreement/${agreement.objectId}`,
                                        updateAgreementQuery,
                                        {
                                          "Content-Type": "application/json",
                                        },
                                        null
                                      );
                                    await axios(updateAgreementConfig);
                                  } else {
                                    const createVirifierQuery = {
                                      merchant: [
                                        agreement.merchantPointer.objectId,
                                      ],
                                      userId: objectId,
                                    };
                                    const createVerifierConfig =
                                      AxiosUtils.axiosConfigConstructor(
                                        "post",
                                        Urls.parseUrl + `/classes/verifier`,
                                        createVirifierQuery,
                                        {
                                          "Content-Type": "application/json",
                                        },
                                        null
                                      );
                                    const verifier = await axios(
                                      createVerifierConfig
                                    );
                                    const updateAgreementQuery = {
                                      verifiersPointer: {
                                        __type: "Pointer",
                                        className: "verifier",
                                        objectId: verifier.data.objectId,
                                      },
                                    };
                                    const updateAgreementConfig =
                                      AxiosUtils.axiosConfigConstructor(
                                        "put",
                                        Urls.parseUrl +
                                          `/classes/agreement/${agreement.objectId}`,
                                        updateAgreementQuery,
                                        {
                                          "Content-Type": "application/json",
                                        },
                                        null
                                      );
                                    await axios(updateAgreementConfig);
                                  }
                                }
                              }
                              await DirectToSignPage(
                                agreement.merchantPointer.objectId
                              );
                            }}
                            isLoading={
                              loadingAgreement &&
                              data.merchantPointer.objectId ===
                                selectedAgreementId
                            }
                            text="View Details"
                          />
                        </td>
                        <td>
                          {data?.merchantPointer.salesConfirm === "approved" ? (
                            <span style={{ color: "green" }}>Approved</span>
                          ) : data?.merchantPointer.salesConfirm ===
                            "rejected" ? (
                            <span style={{ color: "red" }}>Rejected</span>
                          ) : data?.merchantPointer.signingStatus === "true" ? (
                            <span style={{ color: "blue" }}>Signed</span>
                          ) : data?.merchantPointer.signingStatus ===
                            "false" ? (
                            <span style={{ color: "purple" }}>
                              Submitted for Signatures
                            </span>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          <LoadingButton
                            style={{
                              boxShadow: "none",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.7109375rem",
                              borderRadius: "0.2rem",
                              color: "#fff",
                              backgroundColor: "#405189",
                              borderColor: "#405189",
                              width: "100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={async (e) => {
                              setLoadingLogs(true);
                              setSelectedAgreementId(
                                data.merchantPointer.objectId
                              );
                              const merchantPointer = data?.merchantPointer;
                              const brandPointer = data?.brandPointer;
                              const storePointer = data?.storePointer;
                              const offersPointer = data?.offersPointer;
                              const storesId = [];
                              storePointer.forEach((store: any) => {
                                storesId.push(store.objectId);
                              });
                              const offersId = [];
                              offersPointer.forEach((offer: any) => {
                                offersId.push(offer.objectId);
                              });
                              const isLogsExist: any =
                                await ServerUploadsUtils.getViewLogs(
                                  merchantPointer.objectId,
                                  brandPointer.objectId,
                                  storesId,
                                  offersId,
                                  data?.objectId
                                );
                              if (isLogsExist === "No Logs Found") {
                                ToastUtils.showInfoToast("No Logs Found");
                                setLoadingLogs(false);
                                navigate("/");
                                return;
                              } else if (isLogsExist === "Error Occurred") {
                                ToastUtils.showInfoToast("Error Occurred");
                                setLoadingLogs(false);
                                navigate("/");
                                return;
                              }
                              const {
                                merchantLogsList,
                                brandLogsList,
                                storeLogsList,
                                offersLogsList,
                                agreementLogsList,
                              } = isLogsExist;
                              console.log("merchantLogsList", merchantLogsList);
                              console.log("brandLogsList", brandLogsList);
                              console.log("storeLogsList", storeLogsList);
                              console.log("offersLogsList", offersLogsList);
                              console.log(
                                "agreementLogsList",
                                agreementLogsList
                              );
                              setAllLogs({
                                merchantLogsList,
                                brandLogsList,
                                storeLogsList,
                                offersLogsList,
                                agreementLogsList,
                              });
                              setLoadingLogs(false);
                              setShowPopup(true);
                            }}
                            isLoading={
                              loadingLogs &&
                              data.merchantPointer.objectId ===
                                selectedAgreementId
                            }
                            text="View Logs"
                          />
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          {data?.merchantPointer?.verifierName
                            ? data?.merchantPointer?.verifierName
                            : "NA"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              {currentPage >= 0 && tableValues?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={async () => {
                      const getMerchant = await getMerchants(
                        buttonType,
                        15,
                        (currentPage - 1) * 15
                      );
                      setCurrentPage(currentPage - 1);
                      setTableValues(getMerchant?.data?.results);
                    }}
                    style={{
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage > 0 ? "blue" : "#878a99",
                    }}
                    disabled={currentPage > 0 ? false : true}
                  >
                    Previous
                  </button>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      // color: "black",
                      // fontWeight: "bold",
                      backgroundColor: "#405189",
                      borderColor: "#405189",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                    }}
                  >
                    {currentPage + 1}
                  </div>
                  <button
                    className="page-item pagination-prev disabled"
                    onClick={async () => {
                      const getMerchant = await getMerchants(
                        buttonType,
                        15,
                        (currentPage + 1) * 15
                      );
                      setCurrentPage(currentPage + 1);
                      setTableValues(getMerchant?.data?.results);
                    }}
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      outline: 0,
                      background: "white",
                      border: "1px solid #e9ebec",
                      padding: "0.372rem 0.75rem",
                      fontSize: "13px",
                      borderRadius: "0.25rem",
                      color: currentPage >= count - 1 ? "#878a99" : "blue",
                    }}
                    disabled={currentPage >= count - 1 ? true : false}
                  >
                    Next
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default SalesRepresent;
