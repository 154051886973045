import * as Yup from "yup";

export const validationSchema = Yup.object({
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  // agreementType: Yup.string().required("Agreement Type is required"),
  // mouType: Yup.string().required("Mou Type is required"),
  // mouValidity: Yup.number().typeError("Mou Validity is a number"),
  // // logo: Yup.string().required("Logo is required"),
});
