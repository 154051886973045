import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Table } from "react-bootstrap";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import Urls from "../../utils/urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingButton from "../../components/LoadingButton";
import Popup from "../../components/PopTable";

const Brief = () => {
  const [tableValues, setTableValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const navigate = useNavigate();

  const getBrief = async (limit: number, skip: number) => {
    try {
      var data = {
        order: "-createdAt",
        where: {
          accountManager: "kjndkdnfk",
        },
      };
      const briefConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + "/classes/brief",
        null,
        {
          "Content-Type": "application/json",
        },
        { count: 1, limit: limit, skip: skip, order: "-createdAt" }
      );
      const getBrief = await axios(briefConfig);
      console.log("getBrief", getBrief);
      return getBrief;
    } catch (error) {
      return error.message;
    }
  };

  useEffect(() => {
    const getBriefData = async () => {
      const brief = await getBrief(15, 0);
      setTableValues(brief.data.results);
      if (brief.data.count % 15) {
        setCount(Math.trunc(brief.data.count / 15) + 1);
      } else {
        setCount(Math.trunc(brief.data.count / 15));
      }
      setCurrentPage(0);
      setLoading(false);
    };
    getBriefData();
  }, []);

  if (loading) return <LoadingSpinner />;

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <small style={{ color: "#878a99", fontSize: "13px" }}> Brief</small>
        </div>
      </div>
      <div
        style={{
          padding: "2rem",
          backgroundColor: "#fffeff",
          overflowX: "scroll",
          marginTop: "20px",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <button
            className="page-item pagination-prev disabled"
            // onClick={() =>
            //   console.log((currentPage - 1) * 15)}
            onClick={async () => {
              navigate("/brief-form");
            }}
            style={{
              color: "white",
              borderRadius: "5px",
              border: "1px solid #405089",
              background: "rgb(64,81,167)",
              outlineColor: "navajowhite",
              fontSize: "13px",
              width: "120px",
              height: "35px",
              marginBottom: "20px",
            }}
            // disabled={currentPage >= count - 1 ? true : false}
          >
            Add New Brief
          </button>
        </div>
        {showPopup && (
          <Popup data={selectedData} onClose={() => setShowPopup(false)} />
        )}

        <Table responsive bordered hover>
          {tableValues.length !== 0 ? (
            <thead>
              <tr style={{ fontWeight: "bold", fontSize: "13px" }}>
                <th>S.No</th>
                <th>Region</th>
                <th>Program Name</th>
                <th>Account Manager</th>
                <th>Count</th>
                <th>Completed</th>
                <th>Pending</th>
                <th>Date</th>
              </tr>
            </thead>
          ) : null}
          <tbody style={{ fontWeight: "400", fontSize: "13px" }}>
            {tableValues.map((data: any, index) => (
              <tr key={index}>
                <td> {index + 1 + currentPage * 15}</td>
                <td>{data.region}</td>
                <td>{data.programName}</td>
                <td>{data.accountManager}</td>
                <td>{data.count}</td>
                <td>0</td>
                <td>0</td>
                <td>{data.submission}</td>
                <td>
                  <LoadingButton
                    style={{
                      boxShadow: "none",
                      padding: "0.25rem 0.5rem",
                      fontSize: "0.7109375rem",
                      borderRadius: "0.2rem",
                      color: "#fff",
                      backgroundColor: "#405189",
                      borderColor: "#405189",
                      width: "100px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={async (e) => {
                      e.preventDefault();
                      setShowPopup(true);
                      setSelectedData(data);
                    }}
                    text="View Details"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div style={{ marginTop: "15px" }}>
        {currentPage >= 0 && tableValues?.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "10px",
              paddingBottom: "20px",
            }}
          >
            <button
              className="page-item pagination-prev disabled"
              onClick={async () => {
                const brief = await getBrief(15, (currentPage - 1) * 15);
                setCurrentPage(currentPage - 1);
                setTableValues(brief?.data?.results);
              }}
              style={{
                marginRight: "5px",
                outline: 0,
                background: "white",
                border: "1px solid #e9ebec",
                padding: "0.372rem 0.75rem",
                fontSize: "13px",
                borderRadius: "0.25rem",
                color: currentPage > 0 ? "#3f5289" : "#878a99",
              }}
              disabled={currentPage > 0 ? false : true}
            >
              Previous
            </button>
            <div
              style={{
                textAlign: "center",
                margin: "3px",
                // color: "black",
                // fontWeight: "bold",
                backgroundColor: "#405189",
                borderColor: "#405189",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.372rem 0.75rem",
                fontSize: "13px",
                borderRadius: "0.25rem",
              }}
            >
              {currentPage + 1}
            </div>
            <button
              className="page-item pagination-prev disabled"
              // onClick={() =>
              //   console.log((currentPage - 1) * 15)}
              onClick={async () => {
                const brief = await getBrief(15, (currentPage + 1) * 15);
                setCurrentPage(currentPage + 1);
                setTableValues(brief?.data?.results);
              }}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                outline: 0,
                background: "white",
                border: "1px solid #e9ebec",
                padding: "0.372rem 0.75rem",
                fontSize: "13px",
                borderRadius: "0.25rem",
                color: currentPage >= count - 1 ? "#878a99" : "#3f5289",
              }}
              disabled={currentPage >= count - 1 ? true : false}
            >
              Next
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Brief;
