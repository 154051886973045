import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { FaGreaterThan } from "react-icons/fa";
import FormikInput from "../../components/FormikInput";
import Text from "../../components/Text";
import axios from "axios";
import { useMerchantContext } from "../../context/merchant-context";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useAppContext } from "../../context/app-context";
import "./style.css";
import LoadingButton from "../../components/LoadingButton";
import { useUserContext } from "../../context/user-context";
import pdfTemplate from "../../assets/documents";
import html2pdf from "html2pdf.js";
import Urls from "../../utils/urls";

const trimCanvas = (canvas) => {
  const ctx = canvas.getContext("2d");
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixels = imageData.data;
  let minX = canvas.width;
  let minY = canvas.height;
  let maxX = 0;
  let maxY = 0;

  // Find the minimum and maximum non-empty pixel coordinates
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const i = (y * canvas.width + x) * 4;
      const alpha = pixels[i + 3];
      if (alpha > 0) {
        if (x < minX) minX = x;
        if (y < minY) minY = y;
        if (x > maxX) maxX = x;
        if (y > maxY) maxY = y;
      }
    }
  }

  // Calculate the new width and height based on the min/max coordinates
  const newWidth = maxX - minX + 1;
  const newHeight = maxY - minY + 1;

  // Create a new canvas and copy the trimmed image data onto it
  const trimmedCanvas = document.createElement("canvas");
  const trimmedCtx = trimmedCanvas.getContext("2d");
  trimmedCanvas.width = newWidth;
  trimmedCanvas.height = newHeight;
  trimmedCtx.drawImage(
    canvas,
    minX,
    minY,
    newWidth,
    newHeight,
    0,
    0,
    newWidth,
    newHeight
  );

  return trimmedCanvas;
};

const CanvasText = ({ text, font, onDataURL }) => {
  const canvasRef = useRef(null);
  const [, setCanvasWidth] = useState(0);
  const [, setCanvasHeight] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas element not found.");
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Could not get 2D context for canvas.");
      return;
    }
    const pixelRatio = window.devicePixelRatio || 1; // Get the pixel ratio of the device
    const canvasRect = canvas.getBoundingClientRect(); // Get the dimensions of the canvas element

    const width = canvasRect.width * pixelRatio; // Calculate the actual width of the canvas
    const height = canvasRect.height * pixelRatio; // Calculate the actual height of the canvas

    canvas.width = width; // Set the canvas width with the increased pixel density
    canvas.height = height; // Set the canvas height with the increased pixel density

    ctx.scale(pixelRatio, pixelRatio);

    if (font) {
      // Use individual font properties instead of shorthand
      ctx.font = `${12}px ${font}`;
    }

    const nameWidth = ctx.measureText(text).width;
    const dateTimeWidth = ctx.measureText(
      `Digitally Signed on ${new Date().toLocaleDateString()} and ${new Date().toLocaleTimeString()}`
    ).width;
    const maxWidth = Math.max(nameWidth, dateTimeWidth);

    setCanvasWidth(maxWidth + 20); // Add padding for better visibility
    setCanvasHeight(60); // Fixed height for the signature

    // Clear the canvas before rendering text
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Use requestAnimationFrame for rendering the text
    requestAnimationFrame(() => {
      ctx.fillText(text, 10, 20);
      ctx.fillText(
        `Digitally Signed on ${new Date().toLocaleDateString()} and ${new Date().toLocaleTimeString()}`,
        10,
        40
      );
      const trimmedCanvas = trimCanvas(canvas);
      if (onDataURL) {
        onDataURL(trimmedCanvas.toDataURL());
      }
    });
  }, [text, font, onDataURL]);

  return <canvas ref={canvasRef} width={400} height={100} />;
};

const MerchantSign = () => {
  const [Loading, setLoading] = useState(false);
  const merchantContext = useMerchantContext();
  const [signatureUrl, setSignatureUrl] = useState("");
  console.log("signatureUrl", signatureUrl);
  const { formData } = merchantContext.state;
  const context = useAppContext();
  const { clearOffers, clearStores, setIsEditing } = context;
  const {
    stores,
    offers,
    isEditing,
    selectedAgreement,
    signingState,
    formData: updateFormData,
  } = context.state;
  const [buttonClicked, setButtonClicked] = useState(null);
  const [buttonData, setButtonData] = useState({});

  const handleButtonClick = (button: any) => {
    setButtonClicked(button);
  };
  const { setFormData, setCustomPageIndex } = useMerchantContext();
  const { selectedOffers, selectedStores } = merchantContext.state;
  const userContext = useUserContext();

  const postRequest = async (values) => {
    console.log("My hs", values);
    // alert(JSON.stringify(values));
    // setLoading(true);
    const SigningStatus = true;
    try {
      console.log("signingState", signingState);
      console.log("Selected Stores", selectedStores);
      console.log("Selected Offers", selectedOffers);
      if (isEditing || signingState) {
        await ServerUploadsUtils.updateDataToDB(
          { ...formData, ...values },
          stores,
          offers,
          selectedAgreement?.objectId,
          formData?.sales,
          "true",
          SigningStatus
        );
      }
      // setLoading(false);
      // addPageIndex();
      setCustomPageIndex(13);
      setFormData({});
      clearStores();
      clearOffers();
      setIsEditing(false);
    } catch (e) {
      // setLoading(false);
    }
  };

  const createAndDownloadPdf = async (values) => {
    // const ipAddress = await getIPAddress();
    // console.log("ipAddress", ipAddress);
    if (formData?.country === "Saudi") {
      console.log(formData.country);
      const options = {
        margin: 10,
        filename: "converted.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      let data = {
        formData,
        offers,
        stores,
      };
      console.log("data");
      console.log(values.signature);
      const htmlString = pdfTemplate(data, values.signature);
      const element = document.createElement("div");
      element.innerHTML = htmlString;
      const pdf = await html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .output("blob");
      console.log("pdf-> ", pdf);
      const result = await ServerUploadsUtils.sendPdfToServer(pdf);
      const str1 = result.url.substring(0, 4);
      const str2 = result.url.substring(4);
      result.url = str1 + "s" + str2;
      localStorage.setItem("pdfUrl", result.url);
      values.upload_signed_agreement = result.url;
      return await postRequest(values);
    } else {
      let data = {
        data: {
          formData,
          offers,
          stores,
        },
        templateType:
          formData.country === "UAE" ? 3 : formData.mouType == "Offer" ? 1 : 2,
        textSignature: values.signature,
        fileToBeDeletedName: values.fileToBeDeletedName,
        ipAddress: "ip",
        signedAgreement: true,
      };

      console.log(data);
      try {
        var config = {
          method: "post",
          url: Urls.lambdaUrl,
          headers: {
            "Content-Type": "application/json",
            Origin: "*",
          },
          data: data,
        };
        const result = await axios(config);
        localStorage.setItem("pdfUrl", result.data.url);
        values.upload_signed_agreement = result.data.url;
        return await postRequest(values);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            New Submission
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <Formik
          initialValues={{}}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values) => {
            if (!signatureUrl) return;
            console.log("buttonData", buttonData);
            console.log("formData is", formData);
            setLoading(true);
            const newValues: any = { ...values };
            newValues.email = formData.authorisedSignatoryMail;
            newValues.contactNo = formData.authorisedSignatoryNo;
            newValues.templateType = formData.templateType;
            newValues.extraField = formData.extraField;
            newValues.signatoryName = formData.authorisedSignatoryName;
            newValues.fileToBeDeletedName = formData.fileToBeDeletedName;
            // setSignature(newValues);

            newValues.signature = signatureUrl;
            console.log("Before Reuest");
            await createAndDownloadPdf(newValues);
            // setLoading(false);
            // alert(JSON.stringify(content))
          }}
        >
          {({ isSubmitting, values, handleSubmit }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  Merchant Sign
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div className="response-template">
                    <FormikInput
                      label="Authorised Signatory Name"
                      type="text"
                      name="authorisedSignatoryName"
                      value={formData?.authorisedSignatoryName}
                      disabled
                    />
                    <FormikInput
                      label="Authorised Signatory Email"
                      type="email"
                      name="authorisedSignatoryName"
                      value={formData?.authorisedSignatoryMail}
                      disabled
                    />
                    <FormikInput
                      label="Authorised Signatory Mobile No."
                      type="number"
                      name="authorised_signatory_mobile_no."
                      value={formData?.authorisedSignatoryNo}
                      disabled
                    />
                  </div>
                  {/* <div
                    style={{
                      width: "100%",
                      border: "1px solid black",
                    }}
                  ></div> */}
                  <p style={{ fontSize: "13px" }}>For Sign Agreement</p>
                  <select
                    onChange={(e) => {
                      handleButtonClick(e.target.value);
                    }}
                  >
                    <option value="">Select Signature Type</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Verdana">Verdana</option>
                  </select>
                  {buttonClicked === "Times New Roman" && (
                    <>
                      <CanvasText
                        text={
                          formData.signatureDisplayName
                            ? formData.signatureDisplayName
                            : formData.authorisedSignatoryName
                        }
                        font="'Times New Roman', cursive"
                        onDataURL={(url) => setSignatureUrl(url)}
                      />
                    </>
                  )}
                  {buttonClicked === "Verdana" && (
                    <>
                      <CanvasText
                        text={
                          formData.signatureDisplayName
                            ? formData.signatureDisplayName
                            : formData.authorisedSignatoryName
                        }
                        font="'Verdana', cursive"
                        onDataURL={(url) => setSignatureUrl(url)}
                      />
                    </>
                  )}
                  {/* <div>
                {!uploadingFile && fileUploadSuccess ? "success" : ""}
              </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <LoadingButton
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid rgb(10,179,156)",
                        background: "rgb(10,179,156)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        // width: "1px",
                        height: "35px",
                        marginLeft: "10px",
                      }}
                      text={"Submit"}
                      type="submit"
                      onClick={handleSubmit}
                      // disabled={isSubmitting || uploadingFile ? true : false}
                      isLoading={Loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MerchantSign;
