import { Formik, Form } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import FormikSelect from "../../../components/FormikSelect";
import { FaGreaterThan } from "react-icons/fa";
import Text from "../../../components/Text";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/app-context";
import GoBackBtn from "../../../components/GoBackBtn";
import ServerUploadsUtils from "../../../utils/serverUploads";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";

const fieldNames = [
  "brandType",
  "brandName",
  "registeredAddress",
  "corporateAddress",
  "registeredAddressState",
  "registeredAddressCity",
  "spocName",
  "spocNo",
  "spocEmail",
  "registeredAddressPincode",
  "corporateAddressPincode",
  "corporateAddressCity",
  "corporateAddressState",
  "categoryOfMerchant",
  "registeredAddressCountry",
  "corporateAddressCountry",
  "authorisedSignatoryName",
  "authorisedSignatoryNo",
  "designation",
  "authorisedSignatoryMail",
  "logo",
];

export default function AddBrand() {
  const [salesManager, setSalesManager] = useState(false);
  const [uploadingFileForLogo, setUploadingFileForLogo] = useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [updateForLoading, setUpdateForLoading] = useState(false);
  const [, refreshComponent] = useState(false);
  const [initialValues, setInitialValues] = useState({
    brandType: "",
    brandName: "",
    registeredAddress: "",
    corporateAddress: "",
    registeredAddressState: "",
    registeredAddressCity: "",
    spocName: "",
    spocNo: "",
    spocEmail: "",
    registeredAddressPincode: "",
    corporateAddressPincode: "",
    corporateAddressCity: "",
    corporateAddressState: "",
    categoryOfMerchant: "",
    registeredAddressCountry: "",
    corporateAddressCountry: "",
    authorisedSignatoryName: "",
    authorisedSignatoryNo: "",
    designation: "",
    authorisedSignatoryMail: "",
    logo: "",
  });

  const merchantContext = useMerchantContext();
  const { setFormData, addPageIndex, subtractPageIndex, setCustomPageIndex } =
    merchantContext;
  const { formData } = merchantContext.state;

  const context = useAppContext();
  const { formData: updateFormData, isEditing } = context.state;
  const { updateFormData: setMasterFormData } = context;

  const navigate = useNavigate();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("Hii", JSON.parse(localStorage.getItem("userPermissions")));
    console.log("SalesHandler", SalesHandler);
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(updateFormData)) {
      const brand = JSON.parse(localStorage.getItem("brand"));
      if (brand) {
        setInitialValues({
          brandType: brand?.brandType,
          brandName: brand?.brandName,
          registeredAddress: brand?.registeredAddress,
          corporateAddress: brand?.corporateAddress,
          spocName: brand?.spocName,
          spocNo: brand?.spocNo,
          spocEmail: brand?.spocEmail,
          registeredAddressPincode: brand?.registeredAddressPincode,
          corporateAddressPincode: brand?.corporateAddressPincode,
          categoryOfMerchant: brand?.categoryOfMerchant,
          registeredAddressState: brand?.registeredAddressState,
          registeredAddressCity: brand?.registeredAddressCity,
          corporateAddressCity: brand?.corporateAddressCity,
          corporateAddressState: brand?.corporateAddressState,
          registeredAddressCountry: brand?.registeredAddressCountry,
          corporateAddressCountry: brand?.corporateAddressCountry,
          authorisedSignatoryName: brand?.authorisedSignatoryName,
          authorisedSignatoryNo: brand?.authorisedSignatoryNo,
          designation: brand?.designation,
          authorisedSignatoryMail: brand?.authorisedSignatoryMail,
          logo: brand?.logo,
        });
      } else {
        setInitialValues({
          brandType: "",
          brandName: "",
          registeredAddress: "",
          corporateAddress: "",
          spocName: "",
          spocNo: "",
          spocEmail: "",
          registeredAddressPincode: "",
          corporateAddressPincode: "",
          categoryOfMerchant: "",
          registeredAddressState: "",
          registeredAddressCity: "",
          corporateAddressCity: "",
          corporateAddressState: "",
          registeredAddressCountry: "",
          corporateAddressCountry: "",
          authorisedSignatoryName: "",
          authorisedSignatoryNo: "",
          designation: "",
          authorisedSignatoryMail: "",
          logo: "",
        });
      }
    } else {
      console.log("updateFormData", updateFormData);
      setInitialValues({
        spocEmail: updateFormData?.spocEmail,
        brandType: updateFormData?.brandType,
        brandName: updateFormData?.brandName,
        registeredAddress: updateFormData?.registeredAddress,
        corporateAddress: updateFormData?.corporateAddress,
        spocName: updateFormData?.spocName,
        spocNo: updateFormData.spocNo,
        registeredAddressPincode: updateFormData?.registeredAddressPincode,
        corporateAddressPincode: updateFormData?.corporateAddressPincode,
        categoryOfMerchant: updateFormData?.categoryOfMerchant,
        registeredAddressState: updateFormData?.registeredAddressState,
        registeredAddressCity: updateFormData?.registeredAddressCity,
        corporateAddressCity: updateFormData?.corporateAddressCity,
        corporateAddressState: updateFormData?.corporateAddressState,
        registeredAddressCountry: updateFormData?.registeredAddressCountry,
        corporateAddressCountry: updateFormData?.corporateAddressCountry,
        authorisedSignatoryName: updateFormData?.authorisedSignatoryName,
        authorisedSignatoryNo: updateFormData?.authorisedSignatoryNo,
        designation: updateFormData?.designation,
        authorisedSignatoryMail: updateFormData?.authorisedSignatoryMail,
        logo: updateFormData?.logo,
      });
    }
  }, [updateFormData]);

  const areEqual = (value1, value2) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateBrand = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = formData[fieldName];

      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const brandConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/brand/${formData.brandId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        }
      );
      await axios(brandConfig);
      await ServerUploadsUtils.addLogs("brand", formData?.brandId, fieldLogs);
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a href="/" style={{ color: "#878a99", fontSize: "13px" }}>
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isEditing ? "Edit Brand" : "Add Brand"}
          </Text>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(formData.country)}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values) => {
              setFormData({ ...formData, ...values });
              if (salesManager && formData.verificationStatus !== "approved") {
                await updateBrand(values);
              }
              salesManager ? setCustomPageIndex(5) : addPageIndex();
            }}
          >
            {({ isSubmitting, values }) => (
              <div
                style={{
                  background: "#ffffff",
                  marginBottom: "20px",
                }}
              >
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <FormikSelect
                      label="Brand Type"
                      name="brandType"
                      options={["Retail", "Dining", "Health & Wellness"]}
                    />
                    <FormikInput
                      label="Brand Name"
                      type="text"
                      name="brandName"
                      placeholder="Xerox"
                      disabled={salesManager}
                    />
                    <FormikSelect
                      label="Category Of Merchant"
                      name="categoryOfMerchant"
                      options={["Standalone", "Chain"]}
                    />
                    <FormikInput
                      label="Merchant Name"
                      type="text"
                      name="merchantName"
                      value={formData?.merchantName}
                      disabled
                    />
                    <FormikInput
                      label="Registered Address"
                      type="text"
                      name="registeredAddress"
                      placeholder="New Delhi"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="City"
                      type="text"
                      name="registeredAddressCity"
                      placeholder="New Delhi"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="State"
                      type="text"
                      name="registeredAddressState"
                      placeholder="New Delhi"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="Country"
                      type="text"
                      name="registeredAddressCountry"
                      placeholder="India"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="Pincode"
                      type="text"
                      name="registeredAddressPincode"
                      placeholder="209861"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="Corporate Address"
                      type="text"
                      name="corporateAddress"
                      value={values.corporateAddress}
                      placeholder="New Delhi"
                      labelBtn={
                        <button
                          type="button"
                          onClick={() => {
                            if (values.registeredAddress) {
                              values.corporateAddress =
                                values.registeredAddress;
                              values.corporateAddressPincode =
                                values.registeredAddressPincode;
                              values.corporateAddressCity =
                                values.registeredAddressCity;
                              values.corporateAddressState =
                                values.registeredAddressState;
                              values.corporateAddressCountry =
                                values.registeredAddressCountry;
                            } else {
                              values.corporateAddress = "";
                              values.corporateAddressPincode = "";
                              values.corporateAddressCity = "";
                              values.corporateAddressState = "";
                              values.corporateAddressCountry = "";
                            }
                            refreshComponent((prev) => !prev);
                          }}
                          style={{
                            outline: "none",
                            background: "transparent",
                            border: "none",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Same As Above
                        </button>
                      }
                    />
                    <FormikInput
                      label="City"
                      type="text"
                      name="corporateAddressCity"
                      placeholder="New Delhi"
                    />
                    <FormikInput
                      label="State"
                      type="text"
                      name="corporateAddressState"
                      placeholder="New Delhi"
                    />
                    <FormikInput
                      label="Country"
                      type="text"
                      name="corporateAddressCountry"
                      placeholder="India"
                    />
                    <FormikInput
                      label="Pincode"
                      type="text"
                      name="corporateAddressPincode"
                      placeholder="209861"
                    />
                    <FormikInput
                      label="Spoc Name"
                      type="text"
                      name="spocName"
                      placeholder="ABC"
                    />
                    <FormikInput
                      label="Spoc No."
                      type="text"
                      name="spocNo"
                      placeholder="987458457"
                    />
                    <FormikInput
                      label="SPOC Email"
                      type="text"
                      name="spocEmail"
                      placeholder="abc@gmail.com"
                    />
                    {salesManager && (
                      <>
                        {" "}
                        <FormikInput
                          label="Authorised Signatory Name"
                          type="text"
                          name="authorisedSignatoryName"
                          placeholder="128474938"
                          disabled={salesManager}
                        />
                        <FormikInput
                          label="Authorized Signatory Mail"
                          type="text"
                          name="authorisedSignatoryMail"
                          placeholder="abc@something.com"
                          disabled={salesManager}
                        />
                        <FormikInput
                          label="Authorised Signatory Number"
                          type="text"
                          name="authorisedSignatoryNo"
                          placeholder="128474938"
                          disabled={salesManager}
                        />
                        <FormikInput
                          label="Designation"
                          type="text"
                          name="designation"
                          placeholder="Sales"
                        />
                        <FormikInput
                          label="Logo"
                          type="file"
                          name="logo"
                          useDefaultInput={true}
                          filename={values.logo}
                          onChange={async (e) => {
                            setUploadingFileForLogo(true);
                            setUploadSuccess(false);
                            try {
                              values.logo =
                                await ServerUploadsUtils.uploadImageToServer(
                                  e.target.files[0],
                                  e.target.files[0].name
                                );
                              console.log(typeof e.target.files[0]);
                              const url = values.logo;
                              // console.log(url);
                              const str1 = url.substring(0, 4);
                              const str2 = url.substring(4);
                              values.logo = str1 + "s" + str2;
                              setUploadingFileForLogo(false);
                              setUploadSuccess(true);
                            } catch (e) {
                              setUploadingFileForLogo(false);
                            }
                          }}
                          disabled={uploadingFileForLogo}
                        />
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        gap: "10px",
                      }}
                    >
                      <GoBackBtn
                        onClick={() => {
                          setMasterFormData(formData);
                          subtractPageIndex();
                        }}
                      />
                      <button
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        Next
                      </button>
                      {salesManager && (
                        <LoadingButton
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #405089",
                            background: "rgb(64,81,167)",
                            outlineColor: "navajowhite",
                            fontSize: "13px",
                            // width: "1px",
                            height: "35px",
                          }}
                          text={
                            formData.verificationStatus !== "approved"
                              ? "Save and Exit"
                              : "Exit"
                          }
                          type="button"
                          onClick={async () => {
                            setUpdateForLoading(true);
                            if (formData.verificationStatus !== "approved") {
                              await updateBrand(values);
                              const { objectId } = JSON.parse(
                                localStorage.getItem("user")
                              );
                              await ServerUploadsUtils.unlockVerifier(
                                formData.agreementId,
                                objectId,
                                formData.merchantId
                              );
                            }
                            setUpdateForLoading(false);
                            navigate("/");
                          }}
                          isLoading={updateForLoading}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
