import * as Yup from "yup";
import RegexUtils from "../../../utils/regexUtils";

export const validationSchema = Yup.object({
  store_address: Yup.string().required("Store address is required"),
  store_name: Yup.string().required("Store name is required"),
  gst_no: Yup.string()
    .matches(RegexUtils.gstno, "Invalid Gst Number"),
  category: Yup.string().required("Please select categories"),
  amenities: Yup.array().required("Please select amenities"),
  subcategory: Yup.array().required("Please select subcategories"),
  pan_number: Yup.string().matches(RegexUtils.pan, "Invalid pan number"),
});
