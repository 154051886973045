import { CSSProperties } from "react";
import { Button, Spinner } from "react-bootstrap";

interface LoadingButtonPropTypes {
  isLoading?: boolean;
  onClick?: Function;
  text?: string;
  [x: string]: any;
}

export default function LoadingButton({
  isLoading,
  onClick,
  text = "Submit",
  ...props
}: LoadingButtonPropTypes) {
  return (
    <Button
      disabled={props.disabled}
      onClick={
        !isLoading
          ? (e) => {
              e.preventDefault();
              onClick(e);
            }
          : null
      }
      {...props}
    >
      {isLoading ? (
        <Spinner animation="border" role="status" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        text
      )}
    </Button>
  );
}
