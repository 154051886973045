import React, { useEffect } from "react";
import BrandBox from "../../components/BrandBox";
import NavBar from "../../components/NavBar";
import Text from "../../components/Text";
import { useLocation } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";

const BrnadList = () => {
  const location = useLocation();
  const checkAuth = useAuth("/login");

  useEffect(()=>{
    checkAuth()
  },[])


  return (
    <div style={{ background: "#f3f3f8" }}>
      <NavBar />
      <div
        style={{
          marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      <div
        className="brandLabel"
        style={{
          textAlign: "center",
          justifyContent: "center",
          marginTop: "12%",
          marginBottom: "8%",
        }}
      >
        <Text color="#494f57" fontWeight="bold" fontSize="25px">
          ABC Pvt. Ltd.
        </Text>
      </div>
      {location?.state?.brandList?.map((data: any, index: number) => (
        <BrandBox
          brandName={data.brandName}
          index={index}
          brandId={data.objectId}
        />
      ))}
    </div>
  );
};

export default BrnadList;
