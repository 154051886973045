import React from "react";
import { Form } from "react-bootstrap";

interface Props {
  type: string;
  placeholder: string;
  value: string;
  onChange: any;
}

const FormInput = ({
  type,
  placeholder,
  value,
  onChange,
}: React.PropsWithChildren<Props> & any) => {
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default FormInput;
