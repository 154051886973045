import React from "react";
import "./style.css";

const AgreementTemplate = ({ value, handleChange, imgSrc }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="box-response"
        style={{
          // height: "150px",
          border: "1px solid black",
          // width: "100px",
          objectFit: "contain",
        }}
      >
        <img
          src={imgSrc}
          alt="template"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "fill",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        {/* <input type="radio" name="templateType" value={value} onChange={handleChange}/> */}
      </div>
    </div>
  );
};

export default AgreementTemplate;
