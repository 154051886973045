import React, { useEffect, useMemo, useState } from "react";
import { useTable, useRowSelect } from "react-table";
import { Button, Table } from "react-bootstrap";
import { useAppContext } from "../../context/app-context";
import { TableCheckbox } from "../../components/TableCheckbox";
import { useMerchantContext } from "../../context/merchant-context";
import Text from "../../components/Text";
import { ImArrowLeft2 } from "react-icons/im";
import { FaGreaterThan } from "react-icons/fa";
import ShowStores from "./showStores";
import DateComp from "../ViewStores/date";
import StoreItemsCount from "./storeItemsCount";
import GoBackBtn from "../../components/GoBackBtn";
import EditOfferBtn from "./editOfferBtn";
import PopModel from "../../components/Model";
import ToastUtils from "../../utils/toast/toast-utils";

function ShowProgram({ row }) {
  console.log("MY ROW BRO", row);
  return (
    <div>
      {row?.original?.program?.map((program, index) => (
        <span
          style={{
            marginRight: "3px",
          }}
        >
          {program}
          {row?.original?.program?.length - 1 !== index && ","}
        </span>
      ))}
    </div>
  );
}

function ViewOffers() {
  const context = useAppContext();
  const { stores, offers, isMobile, isEditing } = context.state;
  if (!isEditing) localStorage.setItem("offers", JSON.stringify(offers));
  const merchantContext = useMerchantContext();
  const {
    setCustomPageIndex,
    addPageIndex,
    updateSelectedOffers,
    subtractPageIndex,
    updateSelectedOffersRows,
  } = merchantContext;
  const [showModal, setShowModal] = useState(false);
  const { setIsAddingOffer } = merchantContext;
  const { formData, selectedOffersRowIds } = merchantContext.state;
  const [selectedIds, setSelectedIds] = useState([]);
  const { setCustomOffers } = context;

  const tableColumn = [
    {
      Header: "Offer Name",
      accessor: "editOfferBtn",
      Cell: ({ row }) => <EditOfferBtn offerInfo={row} />,
    },
    {
      Header: "Program",
      accessor: "program",
      Cell: ({ row }) => <ShowProgram row={row} />,
    },
    {
      Header: "Creation Date",
      accessor: "date",
      Cell: ({ row }) => <DateComp storeInfo={row} />,
    },
    {
      Header: "Show Stores",
      accessor: "myStoresModal",
      Cell: ({ row }) => (
        <ShowStores selectedStore={row?.original?.linkStore} />
      ),
    },
  ];

  const { selectedOffers } = merchantContext.state;
  console.log("MERCHANT CONTEXT", merchantContext);

  useEffect(() => {
    const newOffers = [...offers];
    newOffers.forEach((offer) => {
      const selectedTableOffer2: any = { ...offer };
      selectedTableOffer2?.linkStore?.forEach((linkStoreValue, index) => {
        stores.forEach((store) => {
          if (store.storeId == linkStoreValue.storeId) {
            console.log("STORE ID", store.storeId);
            console.log("LINKTORE ID", linkStoreValue.storeId);
            selectedTableOffer2.linkStore[index] = { ...store };
          }
        });
      });
    });

    setCustomOffers(newOffers);
  }, []);

  // const { updateFormData: setMasterFormData } = context;

  const columns = useMemo(() => tableColumn, []);
  const data = useMemo(() => offers, [offers]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [showHandler, setShowHandler] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: selectedOffersRowIds,
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (isEmpty(selectedOffersRowIds)) {
      // alert("I was CALLED BRO!");
      toggleAllRowsSelected();
    }
  }, [offers]);

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    setSelectedIds({ ...selectedRowIds });
  }, [selectedRowIds]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("SalesHandler", SalesHandler);
    setShowHandler(SalesHandler);
  }, []);

  console.log("SELECTED OFFERS", selectedOffers);
  console.log("ALL OFFERS", offers);
  console.log("OFFERS SELECTED ROWS", selectedOffersRowIds);
  console.log("OFFERS SELECTED ROWS Original", selectedRowIds);
  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            Offers
          </Text>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="15px">
            {/* Newly Added Offers */}
          </Text>
        </div>
        <div style={{ fontSize: "13px" }}>
          <div
            style={{
              // backgroundColor: "green",
              padding: isMobile ? 0 : "0 25px",
              overflowX: "scroll",
              margin: "0 5px",
            }}
          >
            <Table
              bordered
              {...getTableProps()}
              style={
                {
                  // background: "red",
                  // margin: "0 10px",
                }
              }
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {/* <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot> */}
            </Table>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                gap: "9px",
                height: "40px",
              }}
            >
              <GoBackBtn
                onClick={() => {
                  // setMasterFormData(formData);
                  updateSelectedOffers(selectedRows);
                  updateSelectedOffersRows(selectedRowIds);
                  let offers;
                  if (!isEditing) {
                    offers = JSON.parse(localStorage.getItem("offers"));
                  }
                  if (isEditing || offers.length > 0) {
                    setCustomPageIndex(5);
                  } else {
                    subtractPageIndex();
                  }
                }}
              />
              <Button
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #405089",
                  background: "rgb(64,81,167)",
                  outlineColor: "navajowhite",
                  fontSize: "13px",
                  width: "70px",
                  height: "35px",
                }}
                onClick={() => {
                  // alert(JSON.stringify(selectedRows));
                  console.log("Selcted Rows", selectedRows);
                  updateSelectedOffers(selectedRows);
                  updateSelectedOffersRows(selectedRowIds);
                  setSelectedRows([]);
                  // addPageIndex();
                  if (selectedFlatRows.length == 0) {
                    ToastUtils.showErrorToast("Please select at least 1 offer");
                  } else {
                    addPageIndex();
                  }
                }}
              >
                Next
              </Button>
            </div>
            {!showHandler && (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  margin: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid #0ab39c",
                    background: "#0ab39c",
                    outlineColor: "navajowhite",
                    marginBottom: "50px",
                  }}
                  onClick={() => {
                    // setIsAddingOffer(true);
                    // updateSelectedOffers(selectedRows);
                    // updateSelectedOffersRows(selectedRowIds);
                    // setCustomPageIndex(6);
                    setShowModal(true);
                  }}
                >
                  Add New Offer
                </Button>
              </div>
            )}
          </div>
          {
            // SEE SELECTED VALUES FROM TABLE BY UNCOMMENTING BELOW DIV
          }
          {/* <div>
          {JSON.stringify(
            {
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            },
            null,
            2
          )}
        </div> */}
          <PopModel
            setOpenModal={() => setShowModal(false)}
            openModal={showModal}
            store={false}
            cb={() => {
              setIsAddingOffer(true);
              updateSelectedOffers(selectedRows);
              updateSelectedOffersRows(selectedRowIds);
              setCustomPageIndex(6);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewOffers;
