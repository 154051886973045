import {
  MerchantWrapper,
  useMerchantContext,
} from "../../context/merchant-context";
import AddAgreement from "./AddAgreement";
import ViewStores from "../ViewStores";
import AddBrand from "./AddBrand";
import AddBrandAdditionalValues from "./AddBrandAdditionalValues";
import AddMerchant from "./AddMerchant";
import AddOffers from "./AddOffers";
import AddStore from "./AddStore";
import AddStoreAdditionalValues from "./AddStoreAdditionalValues";
import ViewOffers from "../ViewOffers";
import SubmitForm from "../SubmitForm";
import { useEffect } from "react";
import { useUserContext } from "../../context/user-context";
import { useLocation, useNavigate } from "react-router-dom";
import ViewAgreement from "../ViewAgreement";
import PdfViewer from "../PdfViewer";
import MerchantSign from "../MerchantSign";
import AddAgreementAdditionalValues from "./AddAgreementAdditionalValues";
import { useAuth } from "../../hooks/useAuth";
import { useAppContext } from "../../context/app-context";
import ToastUtils from "../../utils/toast/toast-utils";
import AddStoreExtraValues from "./AddStoreExtraValues";

export default function Merchant() {
  return (
    <MerchantWrapper>
      <MerchantForm />
    </MerchantWrapper>
  );
}

function MerchantForm() {
  const merchantContext = useMerchantContext();

  const checkAuth = useAuth("/login");

  const userContext = useUserContext();
  const location = useLocation();
  const {
    state: { page, formData },
    setFormData,
    setCustomPageIndex,
  } = merchantContext;
  const navigate = useNavigate();
  const appContext = useAppContext();
  console.log("user Information", userContext);
  useEffect(() => {
    const isSigning = location?.state?.isSigning;
    const isViewing = location?.state?.isViewing;
    console.log("isSigning", isSigning, location.state);
    if (isSigning == true) {
      setCustomPageIndex(10);
    } else if (isViewing == true) {
      setCustomPageIndex(11);
    }
  }, []);

  useEffect(() => {
    if (
      userContext.state?.userPermission?.salesRepresentative == false &&
      userContext.state?.userPermission?.CanSignAgreement == false &&
      userContext.state?.userPermission?.SalesHandler == false
    ) {
      // alert("Not Authorised");
      ToastUtils.showErrorToast("Not Authorized");
      navigate("/");
      return;
    }
  }, [userContext]);

  console.log("MERCHANT CONTEXT", merchantContext);

  useEffect(() => {
    checkAuth();
    // alert("HELLO")
    if (
      appContext.state.isEditing &&
      Object.keys(userContext.state.userPermission).length === 0
    ) {
      // alert("Not Authorized");
      navigate("/");
      return;
    }
  }, []);

  useEffect(() => {
    checkAuth();
    // alert("HELLO")
    if (Object.keys(userContext.state.userPermission).length === 0) {
      if (window.performance.navigation.type !== 1) {
        // alert("Not Authorized");
        ToastUtils.showErrorToast("Not Authorized");
      }
      navigate("/");
      return;
    }
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const _renderComponent = () => {
    if (page === 0) {
      return <AddMerchant />;
    } else if (page === 1) {
      return <AddBrand />;
    } else if (page === 2) {
      return <AddBrandAdditionalValues />;
    } else if (page === 3) {
      return <AddStore />;
    } else if (page === 4) {
      return <AddStoreAdditionalValues />;
    } else if (page === 5) {
      return <ViewStores />;
    } else if (page === 6) {
      return <AddOffers />;
    } else if (page === 7) {
      return <ViewOffers />;
    } else if (page === 8) {
      return <AddAgreement />;
    } else if (page === 9) {
      return <AddAgreementAdditionalValues />;
    } else if (page === 10) {
      return <ViewAgreement />;
    } else if (page === 11) {
      return <PdfViewer />;
    } else if (page === 12) {
      return <MerchantSign />;
    } else if (page === 13) {
      return <SubmitForm />;
    } else if (page === 14) {
      return <AddStoreExtraValues />;
    }
  };

  return _renderComponent();
}
