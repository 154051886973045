import { useState } from "react";
import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import { FaGreaterThan } from "react-icons/fa";
import Text from "../../components/Text";
import FormikInput from "../../components/FormikInput";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver"; // Import the saveAs function from file-saver library
import LoadingButton from "../../components/LoadingButton";
import ToastUtils from "../../utils/toast/toast-utils";
import FormikSelect from "../../components/FormikSelect";
import Urls from "../../utils/urls";

export default function GetData() {
  const [initialValues, setInitialValues] = useState({
    startDate: "",
    endDate: "",
    dataType: "",
  });
  const [loading, setLoading] = useState(false);

  const fullDataDownload = (
    data: Array<Object>,
    startDate: string,
    endDate: string
  ) => {
    try {
      if (data && data?.length > 0) {
        const rows = [];
        // Add header row
        const header = [
          "Program",
          "S.No.",
          "Mou Type",
          "Sign Status",
          "Regular / Premium / Super Premium",
          "Status (Renewal/Fresh)",
          "Category of Merchant (Standalone/Chain)",
          "Category",
          "Subcategory",
          "Executive Name",
          "About Us",
          "Merchant Name",
          "Legal Name",
          "Address of the Merchant",
          "Locality",
          "City of the Merchant",
          "State",
          "Country",
          "Pin code",
          "Merchant Landline Number",
          "Merchant Mobile Number",
          "Timings",
          "Cuisines",
          "Rating Platform",
          "Rating",
          "Meal For Two/Reviews",
          "Latitude",
          "Longitude ",
          "Offer Details",
          "Offer Exclusions",
          "Start Date",
          "End Date",
          "Valid On Which Cards",
          "EDC Machine",
          "POS Machine",
          "POS Provider",
          "TID Number",
          "MID Number",
          "Signatory Name",
          "Signatory Designation",
          "Signatory Email ID",
          "Signatory Phone Number",
          "Account Name",
          "Bank Name",
          "Account Number",
          "IFSC Code",
          "Branch Name",
          "Pan Number",
          "GST Number",
          "Aadhar Card",
          "Menu",
          "Upload Sign Agreement",
          "Interior Images",
          "Upload Charge Slip",
          "Logo",
        ];
        rows.push(header);
        data?.map((agreement: any, index: any) => {
          console.log(agreement.merchantPointer.merchantName);
          const merchant = agreement.merchantPointer;
          const brand = agreement.brandPointer;
          const offers = agreement.offersPointer;
          const stores = agreement.storePointer;
          stores?.map((store: any) => {
            offers?.map((offer: any) => {
              if (offer.linkStore.length <= 0) {
                const row = [
                  "-",
                  index + 1,
                  merchant?.mouType ? merchant?.mouType : "-",
                  merchant?.signingStatus === "true"
                    ? "Signed"
                    : merchant?.signingStatus === "false"
                    ? "Submit for Signatures"
                    : "-",
                  "-",
                  merchant?.agreementType ? merchant?.agreementType : "",
                  brand?.categoryOfMerchant ? brand?.categoryOfMerchant : "",
                  store?.category ? store?.category : "",
                  store?.subcategory.length > 0
                    ? store?.subcategory.join()
                    : "",
                  merchant?.salesRep ? merchant?.salesRep : "",
                  "-",
                  brand?.brandName ? brand?.brandName : "",
                  merchant?.merchantName ? merchant?.merchantName : "",

                  store?.store_address
                    ? store?.store_address + ", " + store?.store_city
                    : "",
                  store?.locality ? store?.locality : "",

                  store?.store_city ? store?.store_city : "",
                  store?.store_state ? store?.store_state : "",
                  store?.store_country ? store?.store_country : "",
                  store?.store_pincode ? store?.store_pincode : "",
                  "-",
                  "-",
                  "-",
                  store?.cuisines.length > 0 ? store?.cuisines.join(", ") : "-",
                  store?.rating_platform ? store?.rating_platform : "",
                  store?.rating_no ? store?.rating_no : "",
                  store?.meal_for_two ? store?.meal_for_two : "-",
                  "-",
                  "-",
                  offer.offer_text ? offer.offer_text : "",
                  offer.offerExclusion ? offer.offerExclusion : "",
                  offer.offer_validity_startDate
                    ? offer.offer_validity_startDate
                    : "",
                  offer.offer_validity_endDate
                    ? offer.offer_validity_endDate
                    : "",
                  "All Cards",
                  "-",
                  "-",
                  "-",
                  store?.tidANDMID?.map((obj) => obj.tid).join(", ").length > 0
                    ? store?.tidANDMID.map((obj) => obj.tid).join(", ")
                    : "",
                  store?.tidANDMID.map((obj) => obj.mid).join(", ").length > 0
                    ? store?.tidANDMID.map((obj) => obj.mid).join(", ")
                    : "",
                  brand?.authorisedSignatoryName
                    ? brand?.authorisedSignatoryName
                    : "",
                  brand?.designation ? brand?.designation : "",
                  brand?.authorisedSignatoryMail
                    ? brand?.authorisedSignatoryMail
                    : "",
                  brand?.authorisedSignatoryNo
                    ? brand?.authorisedSignatoryNo
                    : "",
                  "-",
                  store?.bank_name ? store?.bank_name : "",
                  store?.bank_account_number ? store?.bank_account_number : "",
                  store?.ifsc_code ? store?.ifsc_code : "",
                  store?.branch_name ? store?.branch_name : "",
                  store?.panNumber ? store?.panNumber : "",
                  store?.gst_no ? store?.gst_no : "",
                  "-",
                  store?.menu ? store?.menu : "",
                  agreement?.upload_signed_agreement
                    ? agreement?.upload_signed_agreement
                    : "",
                  store?.interior_images ? store?.interior_images : "",
                  store?.upload_carges_slip?.map((obj) => obj.data).join(", ")
                    .length > 0
                    ? store?.upload_carges_slip
                        .map((obj) => obj.data)
                        .join(", ")
                    : "",
                  brand?.logo ? brand?.logo : "",
                ];

                if (index + 1 == 4 || index + 1 == 5) {
                  console.log(index + 1, row);
                }
                rows.push(row);
              } else {
                for (let i = 0; i < offer.linkStore.length; i++) {
                  if (offer.linkStore[i].id === store.objectId) {
                    if (offer?.program?.length > 0) {
                      offer?.program.map((prog) => {
                        console.log("Index", index + 1);
                        const row = [
                          prog,
                          index + 1,
                          merchant?.mouType ? merchant?.mouType : "-",
                          merchant?.signingStatus === "true"
                            ? "Signed"
                            : merchant?.signingStatus === "false"
                            ? "Submit for Signatures"
                            : "-",
                          "-",
                          merchant?.agreementType
                            ? merchant?.agreementType
                            : "",
                          brand?.categoryOfMerchant
                            ? brand?.categoryOfMerchant
                            : "",
                          store?.category ? store?.category : "",
                          store?.subcategory.length > 0
                            ? store?.subcategory.join()
                            : "",
                          merchant?.salesRep ? merchant?.salesRep : "",
                          "-",
                          brand?.brandName ? brand?.brandName : "",
                          merchant?.merchantName ? merchant?.merchantName : "",

                          store?.store_address
                            ? store?.store_address + ", " + store?.store_city
                            : "",
                          store?.locality ? store?.locality : "",
                          store?.store_city ? store?.store_city : "",
                          store?.store_state ? store?.store_state : "",
                          store?.store_country ? store?.store_country : "",
                          store?.store_pincode ? store?.store_pincode : "",
                          "-",
                          "-",
                          "-",
                          store?.cuisines.length > 0
                            ? store?.cuisines.join(", ")
                            : "-",
                          store?.rating_platform ? store?.rating_platform : "",
                          store?.rating_no ? store?.rating_no : "",
                          store?.meal_for_two ? store?.meal_for_two : "-",
                          "-",
                          "-",
                          offer.offer_text ? offer.offer_text : "",
                          offer.offerExclusion ? offer.offerExclusion : "",
                          offer.offer_validity_startDate
                            ? offer.offer_validity_startDate
                            : "",
                          offer.offer_validity_endDate
                            ? offer.offer_validity_endDate
                            : "",
                          "All Cards",
                          "-",
                          "-",
                          "-",
                          store?.tidANDMID?.map((obj) => obj.tid).join(", ")
                            .length > 0
                            ? store?.tidANDMID.map((obj) => obj.tid).join(", ")
                            : "",
                          store?.tidANDMID.map((obj) => obj.mid).join(", ")
                            .length > 0
                            ? store?.tidANDMID.map((obj) => obj.mid).join(", ")
                            : "",
                          brand?.authorisedSignatoryName
                            ? brand?.authorisedSignatoryName
                            : "",
                          brand?.designation ? brand?.designation : "",
                          brand?.authorisedSignatoryMail
                            ? brand?.authorisedSignatoryMail
                            : "",
                          brand?.authorisedSignatoryNo
                            ? brand?.authorisedSignatoryNo
                            : "",
                          "-",
                          store?.bank_name ? store?.bank_name : "",
                          store?.bank_account_number
                            ? store?.bank_account_number
                            : "",
                          store?.ifsc_code ? store?.ifsc_code : "",
                          store?.branch_name ? store?.branch_name : "",
                          store?.panNumber ? store?.panNumber : "",
                          store?.gst_no ? store?.gst_no : "",
                          "-",
                          store?.menu ? store?.menu : "",
                          agreement?.upload_signed_agreement
                            ? agreement?.upload_signed_agreement
                            : "",

                          store?.interior_images ? store?.interior_images : "",
                          store?.upload_carges_slip
                            ?.map((obj) => obj.data)
                            .join(", ").length > 0
                            ? store?.upload_carges_slip
                                .map((obj) => obj.data)
                                .join(", ")
                            : "",
                          brand?.logo ? brand?.logo : "",
                        ];

                        if (index + 1 == 4 || index + 1 == 5) {
                          console.log(index + 1, row);
                        }
                        rows.push(row);
                      });
                    } else {
                      const row = [
                        "-",
                        index + 1,
                        merchant?.mouType ? merchant?.mouType : "-",
                        merchant?.signingStatus === "true"
                          ? "Signed"
                          : merchant?.signingStatus === "false"
                          ? "Submit for Signatures"
                          : "-",
                        "-",
                        merchant?.agreementType ? merchant?.agreementType : "",
                        brand?.categoryOfMerchant
                          ? brand?.categoryOfMerchant
                          : "",
                        store?.category ? store?.category : "",
                        store?.subcategory.length > 0
                          ? store?.subcategory.join()
                          : "",
                        merchant?.salesRep ? merchant?.salesRep : "",
                        "-",
                        brand?.brandName ? brand?.brandName : "",
                        merchant?.merchantName ? merchant?.merchantName : "",

                        store?.store_address
                          ? store?.store_address + ", " + store?.store_city
                          : "",
                        store?.locality ? store?.locality : "",
                        store?.store_city ? store?.store_city : "",
                        store?.store_state ? store?.store_state : "",
                        store?.store_country ? store?.store_country : "",
                        store?.store_pincode ? store?.store_pincode : "",
                        "-",
                        "-",
                        "-",
                        store?.cuisines.length > 0
                          ? store?.cuisines.join(", ")
                          : "-",
                        store?.rating_platform ? store?.rating_platform : "",
                        store?.rating_no ? store?.rating_no : "",
                        store?.meal_for_two ? store?.meal_for_two : "-",
                        "-",
                        "-",
                        offer.offer_text ? offer.offer_text : "",
                        offer.offerExclusion ? offer.offerExclusion : "",
                        offer.offer_validity_startDate
                          ? offer.offer_validity_startDate
                          : "",
                        offer.offer_validity_endDate
                          ? offer.offer_validity_endDate
                          : "",
                        "All Cards",
                        "-",
                        "-",
                        "-",
                        store?.tidANDMID?.map((obj) => obj.tid).join(", ")
                          .length > 0
                          ? store?.tidANDMID.map((obj) => obj.tid).join(", ")
                          : "",
                        store?.tidANDMID.map((obj) => obj.mid).join(", ")
                          .length > 0
                          ? store?.tidANDMID.map((obj) => obj.mid).join(", ")
                          : "",
                        brand?.authorisedSignatoryName
                          ? brand?.authorisedSignatoryName
                          : "",
                        brand?.designation ? brand?.designation : "",
                        brand?.authorisedSignatoryMail
                          ? brand?.authorisedSignatoryMail
                          : "",
                        brand?.authorisedSignatoryNo
                          ? brand?.authorisedSignatoryNo
                          : "",
                        "-",
                        store?.bank_name ? store?.bank_name : "",
                        store?.bank_account_number
                          ? store?.bank_account_number
                          : "",
                        store?.ifsc_code ? store?.ifsc_code : "",
                        store?.branch_name ? store?.branch_name : "",
                        store?.panNumber ? store?.panNumber : "",
                        store?.gst_no ? store?.gst_no : "",
                        "-",
                        store?.menu ? store?.menu : "",
                        agreement?.upload_signed_agreement
                          ? agreement?.upload_signed_agreement
                          : "",
                        store?.interior_images ? store?.interior_images : "",
                        store?.upload_carges_slip
                          ?.map((obj) => obj.data)
                          .join(", ").length > 0
                          ? store?.upload_carges_slip
                              .map((obj) => obj.data)
                              .join(", ")
                          : "",
                        brand?.logo ? brand?.logo : "",
                      ];
                      rows.push(row);
                    }
                  }
                }
              }
            });
          });
        });
        console.log("rows", rows);
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        // Write the workbook to an Excel file
        const excelBuffer = XLSX.write(workbook, {
          type: "array",
          bookType: "xlsx",
        });
        const file = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = `${startDate}---${endDate}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const rawDataDownload = (
    data: Array<Object>,
    startDate: string,
    endDate: string
  ) => {
    try {
      if (data && data?.length > 0) {
        const rows = [];
        // Add header row
        const header = [
          "S.No.",
          "Legal Name",
          "Mou Type",
          "Brand Name",
          "Store Count",
          "Offers Count",
          "Program Count",
          "Upload Sign Agreement",
        ];
        rows.push(header);
        data?.map((agreement: any, index: any) => {
          console.log(agreement.merchantPointer.merchantName);
          const merchant = agreement.merchantPointer;
          const brand = agreement.brandPointer;
          const offers = agreement.offersPointer;
          const stores = agreement.storePointer;
          const row = [
            index + 1,
            merchant.merchantName,
            merchant?.mouType,
            brand?.brandName,
            stores?.length,
            offers?.length > 0
              ? offers[0]?.program?.length > 0 || offers[0]?.offer_text
                ? offers?.length
                : 0
              : 0,
            agreement?.upload_signed_agreement
              ? agreement?.upload_signed_agreement
              : "",
          ];
          rows.push(row);
        });
        console.log("rows", rows);
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        // Write the workbook to an Excel file
        const excelBuffer = XLSX.write(workbook, {
          type: "array",
          bookType: "xlsx",
        });
        const file = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = `${startDate.substring(
          0,
          10
        )}---${endDate.substring(0, 10)}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getData = async (
    dataType: string,
    startDate: string,
    endDate: string
  ) => {
    var agreement = {
      method: "get",
      url: `${Urls.parseUrl}/classes/agreement?where={"createdAt":{"$gte":{"__type":"Date","iso":"${startDate}"},"$lte":{"__type":"Date","iso":"${endDate}"}}}&include=merchantPointer&include=brandPointer&include=storePointer&include=offersPointer,offersPointer.stores&count=1`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
        "Content-Type": "application/json",
      },
      // params: query,
    };
    const data: any = await axios(agreement);
    let arr = [];
    for (let i = 0; i < data.data.count; i += 50) {
      var agreementConfig = {
        method: "get",
        url: `${Urls.parseUrl}/classes/agreement?where={"createdAt":{"$gte":{"__type":"Date","iso":"${startDate}"},"$lte":{"__type":"Date","iso":"${endDate}"}}}&include=merchantPointer&include=brandPointer&include=storePointer&include=offersPointer,offersPointer.stores&limit=50&skip=${i}&count=1`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
        // params: query,
      };
      const agreementResults: any = await axios(agreementConfig);
      console.log("Agreement Results", agreementResults.data.results);
      arr = arr.concat(agreementResults.data.results);
    }
    console.log("data", arr);
    if (dataType === "Full Data") {
      fullDataDownload(arr, startDate, endDate);
    } else {
      rawDataDownload(arr, startDate, endDate);
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
           
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values: any, { resetForm }) => {
            setLoading(true);
            const startDate = new Date(values.startDate);
            const endDate = new Date(values.endDate);
            const today = new Date();

            const differenceInTime = endDate.getTime() - startDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);

            if (differenceInDays > 7) {
              ToastUtils.showErrorToast(
                "The maximum allowed difference between start and end dates is 7 days"
              );
              setLoading(false);
              return;
            } else if (differenceInDays < 0) {
              ToastUtils.showErrorToast(
                "End date cannot be earlier than start date"
              );
              setLoading(false);
              return;
            } else if (startDate > today) {
              ToastUtils.showErrorToast(
                "you can not select a start date as future date"
              );
              setLoading(false);
              return;
            } else if (endDate > today) {
              ToastUtils.showErrorToast(
                "you can not select a end date as future date"
              );
              setLoading(false);
              return;
            }
            await getData(
              values.dataType,
              startDate.toISOString(),
              endDate.toISOString()
            );
            setLoading(false);
            resetForm();
          }}
        >
          {({ handleSubmit }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  Download Data
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormikSelect
                    label="Data Type"
                    name="dataType"
                    options={["Full Data", "Raw Data"]}
                  />
                  <FormikInput
                    label="Start Date"
                    type="Date"
                    name="startDate"
                  />
                  <FormikInput label="End Date" type="Date" name="endDate" />
                  {/* <FormikInput
                    label="Agreement Type"
                    type="text"
                    name="agreementType"
                    value="Fresh"
                    disabled
                  /> */}
                  {/* <div>
                    {!uploadingFile && fileUploadSuccess ? "success" : ""}
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <LoadingButton
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background: "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        minWidth: "70px",
                        height: "35px",
                      }}
                      text={"Downlaod Data"}
                      type="button"
                      onClick={handleSubmit}
                      // onClick={() => alert("hello")}
                      isLoading={loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
