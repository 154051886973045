import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  format,
  isAfter,
  parse,
  parseISO,
  subDays,
} from "date-fns";
export default class DateUtils {
  static extractDateOnly = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = format(dateString, "dd/MMM/yyyy");
    } catch (e) {
      console.log(e);
      date = "";
    }
    return date;
  };
  static getDateAndTime = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    console.log(dateString);
    const date = format(dateString, "dd-MMM-yyyy hh:mm aa");
    return date;
  };
  static parseDateString = (dateString: any) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let returnDate: any;
    try {
      returnDate = parse(dateString, "yyyy-MM-dd", new Date());
    } catch (e) {
      returnDate = "";
    }
    return returnDate;
  };
  static parseDateTimeString = (dateString: any) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let returnDate: any;
    try {
      returnDate = parse(dateString, "yyyy-MM-dd HH:mm", new Date());
    } catch (e) {
      returnDate = "";
    }
    return returnDate;
  };

  static formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  static subtractDaysFromNow = (days) => {
    let date = new Date();
    date = subDays(date, days);
    return date;
  };

  static formatDateyyyyMMdd = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = format(dateString, "yyyy-MM-dd");
    } catch (e) {
      console.log(e);
      date = "";
    }
    return date;
  };

  static addDaysToNow = (amount: number) => {
    let date = new Date();
    // date = addMinutes(date, amount);
    date = addDays(date, amount);
    return date;
  };

  static addDays(date, days) {
    console.log(date);
    console.log(days);

    var result = new Date(date);
    result.setDate(result.getDate() + days);
    console.log(result);
    return result;
  }
  static addHoursToDate(date, hours) {
    console.log(date);
    console.log(hours);
    var result = new Date(date);
    result = addHours(date, hours);

    console.log(result);
    return result;
  }
  static addMonthsToDate(date, months) {
    var result = new Date(date);
    result = addMonths(date, months);
    return result;
  }

  static addMinutesToDate(minutes) {
    var result = addMinutes(new Date(), minutes);
    return result;
  }

  static parseStringToDate(dateString: string) {
    let date;
    try {
      date = parseISO(dateString);
    } catch (e) {
      date = null;
    }
    return date;
  }

  static isDatePastNow = (date) => {
    return isAfter(new Date(), date);
  };
}
