import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import StoreItemsCount from "./storeItemsCount";

function ShowStores({ selectedStore, row }: any) {
  const [showModal, setShowModal] = useState(false);

  console.log("MY SELECGTED STORE", selectedStore);
  console.log("ROW", row);

  return (
    <>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Body>
          <div
            style={
              {
                //   backgroundColor: "red",
              }
            }
          >
            <h2>Stores</h2>
            <ul>
              {selectedStore?.map((store) => (
                <li>{store?.store_name}</li>
              ))}
            </ul>
          </div>
          <button
            style={{
              color: "white",
              borderRadius: "5px",
              border: "1px solid red",
              background: "red",
              outlineColor: "navajowhite",
              fontSize: "13px",
              height: "35px",
            }}
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
      <div>
        <button
          onClick={() => setShowModal(true)}
          style={{
            color: "white",
            borderRadius: "5px",
            border: "1px solid #405089",
            background: "rgb(64,81,167)",
            outlineColor: "navajowhite",
            fontSize: "13px",
            // height: "35px",
          }}
        >
          {`View ${selectedStore?.length} Stores`}
        </button>
      </div>
    </>
  );
}

export default ShowStores;
