import * as Yup from "yup";
import RegexUtils from "../../../utils/regexUtils";

export const validationSchema = Yup.object({
  contact_name: Yup.string().required("Contact name is required"),
  contact_no: Yup.string()
    .matches(RegexUtils.phoneNumber, "Invalid Phone Number")
    .required("Contact no is required"),
  shift_number: Yup.number()
    .typeError("Shift number should be a number")
    .required("Shift number is required"),
  shift_email: Yup.string()
    .email("Invalid email")
    .required("Shift email is required"),
  // upload_carges_slip: Yup.string().required("Please upload charges slip"),
});
