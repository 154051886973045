import React, { useEffect, useMemo, useState } from "react";
import { useTable, useRowSelect } from "react-table";
import { Button, Table } from "react-bootstrap";
import { useAppContext } from "../../context/app-context";
import { TableCheckbox } from "../../components/TableCheckbox";
import { useMerchantContext } from "../../context/merchant-context";
import { ImArrowLeft2 } from "react-icons/im";
import Text from "../../components/Text";
import { FaGreaterThan } from "react-icons/fa";
import DateComp from "./date";
import GoBackBtn from "../../components/GoBackBtn";
import EditStoreBtn from "./editStoreBtn";
import BrandName from "./brandName";
import PopModel from "../../components/Model";
import ToastUtils from "../../utils/toast/toast-utils";
import Locality from "./localityName";

function ViewStores() {
  const [openModal, setOpenModal] = useState<any>(true);
  console.log(openModal);
  const context = useAppContext();
  const { stores, isMobile, isEditing } = context.state;
  const [salesManager, setSalesManager] = useState(false);
  const { setOffers } = context;
  if (!isEditing) localStorage.setItem("stores", JSON.stringify(stores));
  const [showModal, setShowModal] = useState(false);
  const [showHandler, setShowHandler] = useState(false);
  const [tableColumn, setTableColumn] = useState([
    {
      Header: "Store Name",
      accessor: "store_name",
      Cell: ({ row }) => <EditStoreBtn storeInfo={row} />,
    },
    {
      Header: "Brand Name",
      accessor: "brandNameComponent",
      Cell: ({ row }) => <BrandName formData={formData} />,
    },
    {
      Header: "Creation Date",
      accessor: "date",
      Cell: ({ row }) => <DateComp storeInfo={row} />,
    },
  ]);

  const merchantContext = useMerchantContext();
  const {
    setCustomPageIndex,
    addPageIndex,
    updateSelectedStores,
    subtractPageIndex,
    updateSelectedRows,
  } = merchantContext;
  const { formData, selectedStoresRowIds, selectedTableOffer } =
    merchantContext.state;

  const { selectedStores } = merchantContext.state;
  console.log("MERCHANT CONTEXT", merchantContext);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("SalesHandler", SalesHandler, tableColumn, [
      {
        Header: "Store Name",
        accessor: "store_name",
        Cell: ({ row }) => <EditStoreBtn storeInfo={row} />,
      },
      {
        Header: "Locality",
        accessor: "localityComponent",
        Cell: ({ row }) => <Locality storeInfo={row} />,
      },
      {
        Header: "Brand Name",
        accessor: "brandNameComponent",
        Cell: ({ row }) => <BrandName formData={formData} />,
      },
      {
        Header: "Creation Date",
        accessor: "date",
        Cell: ({ row }) => <DateComp storeInfo={row} />,
      },
    ]);

    setShowHandler(SalesHandler);
    if (SalesHandler) {
      setTableColumn([
        {
          Header: "Store Name",
          accessor: "store_name",
          Cell: ({ row }) => <EditStoreBtn storeInfo={row} />,
        },
        {
          Header: "Locality",
          accessor: "localityComponent",
          Cell: ({ row }) => <Locality storeInfo={row} />,
        },
        {
          Header: "Brand Name",
          accessor: "brandNameComponent",
          Cell: ({ row }) => <BrandName formData={formData} />,
        },
        {
          Header: "Creation Date",
          accessor: "date",
          Cell: ({ row }) => <DateComp storeInfo={row} />,
        },
      ]);
    }
  }, []);

  // const tableColumn = [
  //   {
  //     Header: "Store Name",
  //     accessor: "store_name",
  //     Cell: ({ row }) => <EditStoreBtn storeInfo={row} />,
  //   },
  //   {
  //     Header: "Brand Name",
  //     accessor: "brandNameComponent",
  //     Cell: ({ row }) => <BrandName formData={formData} />,
  //   },
  //   {
  //     Header: "Creation Date",
  //     accessor: "date",
  //     Cell: ({ row }) => <DateComp storeInfo={row} />,
  //   },
  // ];

  const columns = tableColumn;

  // useMemo(() => tableColumn, []);
  const data = useMemo(() => stores, [stores]);
  const { updateFormData: setMasterFormData } = context;

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  console.log("STORES VIEW BRO", data);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: selectedStoresRowIds,
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  useEffect(() => {
    if (isEmpty(selectedStoresRowIds)) {
      toggleAllRowsSelected();
    }
  }, []);

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    setSelectedIds({ ...selectedRowIds });
  }, [selectedRowIds]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("Hii", JSON.parse(localStorage.getItem("userPermissions")));
    console.log("SalesHandler", SalesHandler);
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  console.log("SELECTED ROWS", selectedStores);
  console.log("SELECTED ROW IDS", selectedIds);
  return (
    <div style={{ background: "rgb(243,243,249)", flexGrow: 1 }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            Link Stores
          </Text>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="15px">
            {/* Newly Added Stores */}
          </Text>
        </div>
        <div style={{ fontSize: "13px" }}>
          <div
            style={{
              // backgroundColor: "green",
              padding: isMobile ? 0 : "0 25px",
            }}
          >
            <Table bordered {...getTableProps()}>
              <thead>
                {headerGroups?.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers?.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows?.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row?.cells?.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {/* <tfoot>
                {footerGroups.map((footerGroup) => (
                  <tr {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map((column) => (
                      <td {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot> */}
            </Table>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                gap: "9px",
                height: "40px",
              }}
            >
              <GoBackBtn
                onClick={() => {
                  setMasterFormData(formData);
                  updateSelectedStores(selectedRows);
                  updateSelectedRows(selectedRowIds);
                  let stores;
                  if (!isEditing) {
                    stores = JSON.parse(localStorage.getItem("stores"));
                  }
                  if (isEditing || stores?.length > 0) {
                    if (salesManager) {
                      setCustomPageIndex(1);
                    } else {
                      setCustomPageIndex(2);
                    }
                  } else {
                    subtractPageIndex();
                  }
                }}
              />
              <Button
                style={{
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid #405089",
                  background: "rgb(64,81,167)",
                  outlineColor: "navajowhite",
                  fontSize: "13px",
                  width: "70px",
                  height: "35px",
                }}
                onClick={() => {
                  // alert(JSON.stringify(selectedRows));
                  updateSelectedStores(selectedRows);
                  updateSelectedRows(selectedRowIds);
                  setSelectedRows([]);

                  if (selectedFlatRows.length == 0) {
                    ToastUtils.showErrorToast("Please select at least 1 store");
                  } else {
                    let offers;
                    if (!isEditing) {
                      offers = JSON.parse(localStorage.getItem("offers"));
                      if (offers?.length > 0) setOffers(offers);
                    }
                    if (isEditing || offers?.length > 0) {
                      setCustomPageIndex(7);
                    } else {
                      addPageIndex();
                    }
                  }
                }}
              >
                Next
              </Button>
            </div>
            {!showHandler && (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  margin: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid #0ab39c",
                    background: "#0ab39c",
                    outlineColor: "navajowhite",
                    marginBottom: "50px",
                  }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Add new Store
                </Button>
              </div>
            )}
          </div>
        </div>
        <PopModel
          setOpenModal={() => setShowModal(false)}
          openModal={showModal}
          store={true}
          cb={() => {
            merchantContext.setIsAddingStore(true);
            updateSelectedStores(selectedRows);
            updateSelectedRows(selectedRowIds);
            setCustomPageIndex(3);
          }}
        />
      </div>
    </div>
  );
}

export default ViewStores;
