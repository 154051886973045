import { Formik, Form, FieldArray } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import FormikSelect from "../../../components/FormikSelect";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import Text from "../../../components/Text";
import { useState, useEffect } from "react";
import { useAppContext } from "../../../context/app-context";
import { TidMidModel } from "../../../models/tid-mid-model";
import { FaGreaterThan } from "react-icons/fa";
import GoBackBtn from "../../../components/GoBackBtn";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import MultiSelectAll from "../AddOffers/MultiSelectAll";
import ServerUploadsUtils from "../../../utils/serverUploads";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";
import LoadingButton from "../../../components/LoadingButton";
import { useNavigate } from "react-router-dom";

const initialValuesObj = {
  contact_name: "",
  contact_no: "",
  shift_number: "",
  shift_email: "",
  upload_carges_slip: "",
  cuisines: "",
  registration_no: "",
  reservation_email: "",
  meal_for_two: "",
  mode_of_payment: "",
  delivery_option: "",
};

const fieldNames = [
  "contact_name",
  "contact_no",
  "shift_number",
  "shift_email",
  "upload_carges_slip",
  "cuisines",
  "registration_no",
  "reservation_email",
  "meal_for_two",
  "mode_of_payment",
  "delivery_option",
  "tidANDMID",
];

const cuisines = [
  "North Indian",
  "Chinese",
  "Italian",
  "South Indian",
  "Pan Asian",
  "European",
  "Japanese",
  "Lebanese",
  "Mexican",
  "Thai",
  "Bengali",
  "French",
  "Mughlai",
  "Bakery",
  "Rolls",
  "Biryani",
  "African",
  "American",
  "Asian",
  "Asian Fusion",
  "Australian",
  "Assamese",
  "Arabian",
  "BBQ",
  "Brasserie",
  "Café",
  "Cantonese",
  "Continental",
  "Contemporary",
  "Desserts",
  "Finger Food",
  "Fast Food",
  "Fusion",
  "Goan",
  "Hyderabadi",
  "Indian",
  "Indo-Chinese",
  "Indonesian",
  "International",
  "Japanese BBQ",
  "Kashmiri",
  "Korean",
  "Latin American",
  "Lucknowi",
  "Mediterranean",
  "Middle Eastern",
  "Multi Cuisine",
  "Modern Indian",
  "Nepalese",
  "Pizza",
  "Pasta",
  "Russian",
  "Singaporean",
  "Sushi",
  "Spanish",
  "Sri Lankan",
  "Swedish",
  "Salad",
  "Taiwanese",
  "Tibetian",
  "Turkish",
  "Vietnamese",
  "World Cuisine",
  "Western",
  "Oriental",
];
const BankName = [
  "American Express",
  "Axis Bank",
  "Bandhan Bank",
  "Bank of Baroda",
  "Bank of India",
  "Benow",
  "Bharat Swipe",
  "Bharatiya Mahila Bank",
  "Bijli Pay",
  "Canara Bank",
  "City Union Bank",
  "Commerical Bank",
  "Corporation Bank",
  "Explorex",
  "Ezetap",
  "Federal Bank",
  "Globalpayments",
  "Hatton National Bank",
  "HDFC Bank",
  "HSBC",
  "ICICI Bank",
  "IDBI Bank",
  "IDFC First Bank",
  "Indian Overseas Bank",
  "Indusind Bank",
  "Ingenico",
  "Karnataka Bank",
  "Kotak Mahindra Bank",
  "Mswipe",
  "ONGO Bank",
  "Pay Swiff",
  "Pay Swipe",
  "Paytivo",
  "Paytm",
  "Pine Labs",
  "Punjab National Bank",
  "Ratnakar Bank Limited",
  "South Indian Bank",
  "State Bank of India",
  "Union Bank of India",
  "Verifone",
  "Worldline",
  "Yes Bank",
  "AMEX",
];
const modeOfPayment = [
  "EDC/ POS machine with order delivery for customer to do card payment",
  "Online Payment Link",
  "Cash Payment",
  "QR Code – UPI/ Wallet",
];
export default function AddStoreAdditionalValues() {
  const merchantContext = useMerchantContext();
  const [cuisinesState, setCuisinesState] = useState<any>([]);
  const [paymentState, setPaymentState] = useState<any>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [tidMidTempArr, setTidMidTempArr] = useState<TidMidModel[]>([{}]);
  const [refresh, refreshComponent] = useState(false);
  const [chargesSlipArr, setChargesSlipArr] = useState<any>([]);
  const [initialValues, setInitialValues] = useState(initialValuesObj);
  const [updateForLoading, setUpdateForLoading] = useState(false);
  const [salesManager, setSalesManager] = useState(false);

  const {
    setFormData,
    addPageIndex,
    subtractPageIndex,
    setIsAddingStore,
    updateSelectedRows,
    updateSelectedTableStore,
    setCustomPageIndex,
  } = merchantContext;
  const {
    formData,
    isAddingStore,
    selectedStores,
    selectedStoresRowIds,
    selectedTableStore,
  } = merchantContext.state;
  const context = useAppContext();
  const { addToStore, setCustomStore } = context;
  const { stores, isEditing } = context.state;
  const { updateFormData: setMasterFormData } = context;

  const navigate = useNavigate();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  useEffect(() => {
    let updateFormData: any = {};
    if (stores.length > 0) {
      updateFormData = selectedTableStore?.original;
    } else {
      updateFormData = {};
    }
    console.log("Update", updateFormData);
    if (isEmpty(updateFormData) || isAddingStore) {
      setInitialValues(initialValuesObj);
    } else {
      setInitialValues({
        contact_name: updateFormData?.contact_name,
        contact_no: updateFormData?.contact_no,
        shift_number: updateFormData?.shift_number,
        shift_email: updateFormData?.shift_email,
        upload_carges_slip: updateFormData?.upload_carges_slip,
        cuisines: updateFormData?.cuisines,
        registration_no: updateFormData?.registration_no,
        reservation_email: updateFormData?.reservation_email,
        meal_for_two: updateFormData.meal_for_two,
        mode_of_payment: updateFormData?.mode_of_payment,
        delivery_option: updateFormData?.delivery_option,
      });
      setTidMidTempArr(updateFormData?.tidANDMID);
      setChargesSlipArr(updateFormData?.upload_carges_slip);
    }
  }, [stores]);

  useEffect(() => {
    if (Array.isArray(initialValues?.cuisines)) {
      console.log("Ini", initialValues?.cuisines);
      setCuisinesState(
        initialValues?.cuisines?.map((cuisine) => {
          return {
            label: cuisine,
            value: cuisine,
          };
        })
      );
    }
    if (Array.isArray(initialValues?.mode_of_payment)) {
      console.log("Pat", initialValues?.mode_of_payment);
      setPaymentState(
        initialValues?.mode_of_payment?.map((payment) => {
          return {
            label: payment,
            value: payment,
          };
        })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("Hii", JSON.parse(localStorage.getItem("userPermissions")));
    console.log("SalesHandler", SalesHandler);
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  function removeFromArrayByIndex(
    array: any[],
    elementToBeRemovedIndex: number
  ) {
    const tempArr = [...array];
    if (elementToBeRemovedIndex > -1) {
      // only splice array when item is found
      tempArr.splice(elementToBeRemovedIndex, 1); // 2nd parameter means remove one item only
    }
    console.log("remove from index", elementToBeRemovedIndex);
    console.log(tempArr);
    return tempArr;
  }

  const areEqual = (value1, value2) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateStore = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const storeData = selectedTableStore?.original;
    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = storeData[fieldName];

      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const brandConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/stores/${storeData.objectId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        }
      );
      await axios(brandConfig);
      await ServerUploadsUtils.addLogs(
        "stores",
        storeData.objectId,
        fieldLogs,
        storeData?.store_name
      );
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {" "}
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isAddingStore ? "Add Store" : "Edit Store"}
          </Text>
        </div>
        <div
          style={{
            paddingRight: "10%",
            paddingLeft: "10%",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values) => {
              // alert(" SUBMIT CLLAED ");
              values.cuisines = cuisinesState.map((cuisine) => cuisine?.value);
              values.mode_of_payment = paymentState.map(
                (cuisine) => cuisine?.value
              );
              var fullFormData: any = { ...values };
              fullFormData.upload_carges_slip = chargesSlipArr;
              console.log("tid and mid", tidMidTempArr);
              fullFormData.tidANDMID = tidMidTempArr;
              console.log("FULL FORM DATA", fullFormData);
              // alert(JSON.stringify(fullFormData));
              let myLength = stores.length;
              if (isAddingStore) {
                myLength = myLength + 1;
                // alert(JSON.stringify(stores));
                const newObj = { ...selectedTableStore };
                console.log("OLD OBJ", newObj);
                if (
                  newObj.hasOwnProperty("original") &&
                  newObj.original.hasOwnProperty("storeId")
                ) {
                  delete newObj["original"]["storeId"];
                }
                newObj.original = {
                  ...selectedTableStore?.original,
                  ...fullFormData,
                };
                // alert(JSON.stringify(newObj));
                console.log("New OBJ", newObj);
                updateSelectedTableStore(newObj);
                addToStore(newObj.original);
              } else {
                // alert("false");
                const insert = (arr, index, newItem) => [
                  ...arr.slice(0, index),

                  newItem,

                  ...arr.slice(index),
                ];
                const tempStore = [...stores];
                console.log("original arr", tempStore);
                let elementToRemoveIndex = selectedTableStore?.index;
                if (elementToRemoveIndex == null) {
                  elementToRemoveIndex = tempStore?.length - 1;
                }
                // tempOffers.pop();
                console.log("elementToRemoveIndex", elementToRemoveIndex);
                const removedElementArr = _.pullAt(
                  tempStore,
                  elementToRemoveIndex
                );
                const modifiedArray = insert(tempStore, elementToRemoveIndex, {
                  ...selectedTableStore?.original,
                  ...fullFormData,
                });
                console.log("TEMP OFFERS", modifiedArray);
                setCustomStore([...modifiedArray]);
                // setCustomStore([...tempStore, { ...formData, ...values }]);
              }
              if (salesManager && formData.verificationStatus !== "approved") {
                await updateStore(fullFormData);
              }
              if (isAddingStore && myLength > 0) {
                const index: number = myLength - 1;
                const newSelectedRows = {
                  ...selectedStoresRowIds,
                };
                newSelectedRows[index] = true;
                updateSelectedRows(newSelectedRows);
              }

              setIsAddingStore(false);
              addPageIndex();
            }}
          >
            {({ isSubmitting, values, errors }) => (
              <Form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    // background: "green",
                  }}
                >
                  <FormikInput
                    label="Contact Name"
                    type="text"
                    name="contact_name"
                    placeholder="Mukesh"
                    labelBtn={
                      <button
                        type="button"
                        onClick={() => {
                          if (formData.spocName) {
                            values.contact_name = formData.spocName;
                            values.contact_no = formData.spocNo;
                            values.shift_email = formData.spocEmail;
                          } else {
                            values.contact_name = "";
                            values.contact_no = "";
                            values.shift_email = "";
                          }
                          refreshComponent((prev) => !prev);
                        }}
                        style={{
                          outline: "none",
                          background: "transparent",
                          border: "none",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Same As Spoc
                      </button>
                    }
                  />
                  <FormikInput
                    label="Contact No."
                    type="text"
                    name="contact_no"
                    placeholder="919879734749"
                  />
                  <FormikInput
                    label="Outlet Email"
                    type="text"
                    name="shift_email"
                    placeholder="abc@gmail.com"
                  />
                  <FormikInput
                    label="Outlet Number"
                    type="text"
                    name="shift_number"
                    placeholder="12"
                  />
                  {
                    // TID MID LOGIC IMPLEMENTED HERE -------------
                  }
                  {tidMidTempArr?.map((value, index) => {
                    console.log("hdsdfh", value, index);
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "25px",
                          alignItems: "center",
                          paddingBottom: "15px",
                        }}
                        key={index + 1}
                      >
                        <div>
                          <div>
                            <FormikInput
                              label="TID"
                              type="text"
                              name="tId"
                              useDefaultInput={true}
                              value={value.tid}
                              onChange={(e) => {
                                var xx = [...tidMidTempArr];
                                var o = xx[index];

                                o = { ...o, tid: e.target.value };
                                xx[index] = o;
                                console.log(xx);
                                setTidMidTempArr([...xx]);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <FormikInput
                              label="MID"
                              type="text"
                              name="mId"
                              value={value.mid}
                              onChange={(e) => {
                                var xx = [...tidMidTempArr];
                                var o = xx[index];

                                o = { ...o, mid: e.target.value };
                                xx[index] = o;
                                console.log(xx);
                                setTidMidTempArr([...xx]);
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <FormikSelect
                              label="Bank Name"
                              name="bankMachineName"
                              options={BankName}
                              value={value.bankMachineName}
                              onChange={(e) => {
                                var xx = [...tidMidTempArr];
                                var o = xx[index];

                                o = { ...o, bankMachineName: e.target.value };
                                xx[index] = o;
                                console.log(xx);
                                setTidMidTempArr([...xx]);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <FormikInput
                              label="MDR"
                              type="text"
                              name="mdr"
                              value={value.mdr}
                              onChange={(e) => {
                                var xx = [...tidMidTempArr];
                                var o = xx[index];

                                o = { ...o, mdr: e.target.value };
                                xx[index] = o;
                                console.log(xx);
                                setTidMidTempArr([...xx]);
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <button
                            style={{
                              border: "none",
                              alignItems: "center",
                              background: "transparent",
                            }}
                            type="button"
                            onClick={(e) => {
                              if (index + 1 == tidMidTempArr.length) {
                                setTidMidTempArr([...tidMidTempArr, {}]);
                              } else {
                                setTidMidTempArr(
                                  removeFromArrayByIndex(tidMidTempArr, index)
                                );
                              }
                            }}
                          >
                            {index + 1 == tidMidTempArr.length ? (
                              <AiOutlinePlusSquare
                                style={{ fontSize: "24px", marginTop: "20px" }}
                              />
                            ) : (
                              <AiOutlineMinusSquare
                                style={{ fontSize: "24px", marginTop: "20px" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  {<div style={{ textAlign: "center" }}> Or</div>}
                  <div>
                    <div
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Upload Charges Slip
                    </div>
                    <div
                      style={{
                        fontSize: "13px",
                        margin: "5px 0",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {chargesSlipArr?.map((slip) => (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <a target="_blank" href={slip.data} rel="noreferrer">
                            File Upload Successfully
                          </a>
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const filteredChargesSlip =
                                chargesSlipArr?.filter(
                                  (selectedSlip) =>
                                    slip?.id !== selectedSlip?.id
                                );

                              setChargesSlipArr(filteredChargesSlip);

                              console.log(
                                "FILTERED DATA BRO",
                                filteredChargesSlip
                              );
                            }}
                          >
                            <AiOutlineMinusSquare
                              style={{ fontSize: "14px" }}
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                    <FormikInput
                      label=""
                      type="file"
                      name="upload_carges_slip"
                      filename={""}
                      value=""
                      onChange={async (e) => {
                        setUploadingFile(true);
                        setUploadSuccess(false);
                        try {
                          values.upload_carges_slip =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          const url = values.upload_carges_slip;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.upload_carges_slip = str1 + "s" + str2;
                          console.log(values.upload_carges_slip);
                          // console.log({
                          //   data: values.upload_carges_slip,
                          //   name: e.target.files[0].name,
                          //   id,
                          // });
                          const myArr = [...chargesSlipArr];
                          console.log(myArr);
                          myArr.push({
                            id: uuidv4(),
                            data: values.upload_carges_slip,
                          });
                          console.log(myArr);
                          // // encodedImage = reader.result;
                          // console.log("myArr", myArr);
                          setChargesSlipArr(myArr);
                          // const as = encodeImageFileAsURL(
                          //   values.upload_carges_slip,
                          //   e.target.files[0].name
                          // );
                          console.log("New File");
                          setUploadingFile(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFile(false);
                        }
                      }}
                      // onChange={(e) => {
                      //   encodeImageFileAsURL(e.target, e.target.files[0].name);
                      //   // console.log("IMAGE FILE", e.target.files[0]);
                      // }}
                      disabled={uploadingFile}
                    />
                  </div>
                  {formData?.brandType !== "Retail" && (
                    <div
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Cuisines
                    </div>
                  )}
                  {formData?.brandType !== "Retail" && (
                    <MultiSelectAll
                      options={cuisines.map((cuisine) => {
                        return {
                          label: cuisine,
                          value: cuisine,
                        };
                      })}
                      value={cuisinesState}
                      onChange={(e) => {
                        setCuisinesState(e);
                        delete errors["cuisines"];
                      }}
                    />
                  )}
                  <div>
                    <FormikInput
                      label="FSSA Reservation No"
                      type="string"
                      name="registration_no"
                      placeholder="97498749749"
                    />
                  </div>
                  <div>
                    <FormikInput
                      label="Reservation Email"
                      type="email"
                      name="reservation_email"
                      placeholder="abcd@gmail.com"
                    />
                  </div>
                  <div>
                    <FormikInput
                      label="Meal For Two"
                      type="text"
                      name="meal_for_two"
                      placeholder=""
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Mode Of Payment
                  </div>
                  <MultiSelectAll
                    options={modeOfPayment.map((payment) => {
                      return {
                        label: payment,
                        value: payment,
                      };
                    })}
                    value={paymentState}
                    onChange={(e) => {
                      setPaymentState(e);
                      delete errors["mode_of_payment"];
                    }}
                  />
                  <FormikSelect
                    label="Delivery Option"
                    name="delivery_option"
                    options={["Yes", "No"]}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      gap: "10px",
                    }}
                  >
                    <GoBackBtn
                      onClick={() => {
                        setMasterFormData(formData);
                        if (formData.mouType != "Offer") {
                          setCustomPageIndex(14);
                        } else {
                          subtractPageIndex();
                        }
                      }}
                    />
                    <button
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background: uploadingFile ? "grey" : "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        width: "70px",
                        height: "35px",
                      }}
                      disabled={uploadingFile}
                    >
                      Next
                    </button>
                    {salesManager && (
                      <LoadingButton
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          // width: "1px",
                          height: "35px",
                        }}
                        text={
                          formData.verificationStatus !== "approved"
                            ? "Save and Exit"
                            : "Exit"
                        }
                        type="button"
                        onClick={async () => {
                          setUpdateForLoading(true);
                          if (formData.verificationStatus !== "approved") {
                            await updateStore(values);
                            const { objectId } = JSON.parse(
                              localStorage.getItem("user")
                            );
                            await ServerUploadsUtils.unlockVerifier(
                              formData.agreementId,
                              objectId,
                              formData.merchantId
                            );
                          }
                          setUpdateForLoading(false);
                          navigate("/");
                        }}
                        isLoading={updateForLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
