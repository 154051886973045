import React from "react";

interface Props {
  color: string;
  fontWeight: string;
  fontSize: string;
}
const Text = ({
  children,
  color,
  fontWeight,
  fontSize,
}: React.PropsWithChildren<Props>) => {
  return <p style={{ color, fontWeight, fontSize }}>{children}</p>;
};

export default Text;
