import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaGreaterThan } from "react-icons/fa";
import Text from "../../../components/Text";
import FormikInput from "../../../components/FormikInput";
import GoBackBtn from "../../../components/GoBackBtn";
import AgreementTemplate from "./AgreementTemplate";
import axios from "axios";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useMerchantContext } from "../../../context/merchant-context";
import "./style.css";
import { validationSchema } from "./validationSchema";
import LoadingButton from "../../../components/LoadingButton";
import img from "../../../assets/images/templatePreviews/template1.png";
import { useAppContext } from "../../../context/app-context";
import pdfTemplate from "../../../assets/documents";
import html2pdf from "html2pdf.js";
import ServerUploadsUtils from "../../../utils/serverUploads";
import Urls from "../../../utils/urls";

const AddAgreementAdditionalValues = () => {
  const merchantContext = useMerchantContext();
  const context = useAppContext();
  const { stores, offers } = context.state;
  const [Loading, setLoading] = useState(false);
  const { addPageIndex, setFormData, subtractPageIndex } = merchantContext;
  const { formData } = merchantContext.state;
  console.log("UI", {
    formData,
    stores,
    offers,
  });
  const [initialValues, setInitialValues] = useState({
    email: formData.authorisedSignatoryMail,
    signatoryName: formData.authorisedSignatoryName,
    contactNo: formData.authorisedSignatoryNo,
    extraField: "",
    signatureDisplayName: formData?.signatureDisplayName
      ? formData?.signatureDisplayName
      : formData.authorisedSignatoryName,
  });

  const createAndDownloadPdf = async (values) => {
    console.log("formData is", values?.signatureDisplayName);
    try {
      console.log(formData?.country);
      if (formData?.country === "Saudi") {
        console.log(formData.country);
        const options = {
          margin: 10,
          filename: "converted.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        let data = {
          formData,
          offers,
          stores,
        };
        console.log("data");
        const htmlString = pdfTemplate(data);
        const element = document.createElement("div");
        element.innerHTML = htmlString;
        const pdf = await html2pdf()
          .set(options)
          .from(element)
          .toPdf()
          .output("blob");
        console.log("pdf-> ", pdf);
        // Create a download link for the PDF
        // const downloadLink = document.createElement("a");
        // downloadLink.href = URL.createObjectURL(pdf);
        // downloadLink.download = options.filename;
        // document.body.appendChild(downloadLink);

        // // Trigger the download
        // downloadLink.click();

        // // Clean up
        // document.body.removeChild(downloadLink);
        // URL.revokeObjectURL(downloadLink.href);

        // Send the PDF data to the server
        const result = await ServerUploadsUtils.sendPdfToServer(pdf);
        const str1 = result.url.substring(0, 4);
        const str2 = result.url.substring(4);
        result.url = str1 + "s" + str2;
        setFormData({
          ...formData,
          ...values,
          upload_signed_agreement: result.url,
          fileToBeDeletedName: result.name,
          signatureDisplayName: values?.signatureDisplayName,
        });
      } else {
        let data = {
          data: {
            formData,
            offers,
            stores,
          },
          templateType:
            formData.country === "UAE"
              ? 3
              : formData.mouType == "Offer"
              ? 1
              : 2,
        };
        let config = {
          method: "post",
          url: Urls.lambdaUrl,
          headers: {
            "Content-Type": "application/json",
            Origin: "*",
          },
          data: data,
        };
        const result = await axios(config);
        console.log("Result", result.data);
        setFormData({
          ...formData,
          ...values,
          upload_signed_agreement: result.data.url,
          fileToBeDeletedName: result.data.name,
          signatureDisplayName: values?.signatureDisplayName,
        });
      }
      addPageIndex();
      // console.log("formData is", formData);
    } catch (error) {
      setLoading(false);
    }

    // console.log("123", values);
    // const url = "http://localhost:9000";
    // axios
    //   .post(`${url}/create-pdf`, values)
    //   .then(() =>
    //     axios.get(`${url}/fetch-pdf/${values.email}`, { responseType: "blob" })
    //   )
    //   .then((res) => {
    //     console.log("FILE RES", res);
    //     const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    //     // saveAs(pdfBlob, `${values.email}.pdf`);
    //     addPageIndex();
    //   });
  };

  const navigate = useNavigate();

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>New Submission</a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            New Submission
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoading(true);
            await createAndDownloadPdf(values);
            setLoading(false);
          }}
        >
          {({ isSubmitting, values, handleChange, handleSubmit }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  Add Agreement
                </Text>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  Date:&nbsp;
                  {new Date().getDate() +
                    "/" +
                    new Date().getMonth() +
                    "/" +
                    new Date().getFullYear()}
                  &nbsp;&nbsp; Time : &nbsp;{new Date().getHours() + ":"}
                  &nbsp;{new Date().getMinutes() + ":"}&nbsp;
                  {new Date().getSeconds()}
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <AgreementTemplate
                      value={1}
                      handleChange={handleChange}
                      imgSrc={img}
                      // imgSrc = "../../../assets/images/templatePreviews/template1.png"
                    />
                  </div>
                  <FormikInput
                    label="Signatory Name"
                    type="text"
                    name="signatoryName"
                  />
                  <FormikInput
                    label="Signature Display Name"
                    type="text"
                    name="signatureDisplayName"
                  />
                  <FormikInput label="Email" type="email" name="email" />
                  <FormikInput
                    label="Contact No"
                    type="number"
                    name="contactNo"
                  />
                  <FormikInput
                    label="Extra Field"
                    type="text"
                    name="extraField"
                  />
                  {/* <div>
                  {!uploadingFile && fileUploadSuccess ? "success" : ""}
                </div> */}
                  <div
                    className="btn-response"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                      gap: "10px",
                    }}
                  >
                    <GoBackBtn
                      onClick={() => {
                        subtractPageIndex();
                      }}
                    />
                    <LoadingButton
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background: "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        minWidth: "70px",
                        height: "35px",
                      }}
                      text={"Preview & Send Agreement"}
                      type="button"
                      onClick={handleSubmit}
                      // disabled={isSubmitting || uploadingFile ? true : false}
                      isLoading={Loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddAgreementAdditionalValues;
