import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <p
        style={{
          background: "white",
          marginBottom: 0,
          paddingTop: "20px",
          color: "rgb(152,166,173)",
          textAlign: "center",
          padding: "15px",
          fontSize: "13px",
        }}
      >
        Copyright © 2022 Powered by Thriwe Glocal Pvt. Ltd.
      </p>
    </footer>
  );
};

export default Footer;
