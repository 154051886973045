import { useEffect, useState } from "react";

import { Modal, ModalBody } from "react-bootstrap";
import Urls from "../../utils/urls";
import axios from "axios";
import ToastUtils from "../../utils/toast/toast-utils";
import LoadingButton from "../LoadingButton";

const AuditDeleteConfirmaiton = ({
  auditId,
  onCloseHandler,
  setIsRefresh,
  isRefresh,
}) => {
  const [loading, setLoading] = useState(false);

  const deleteAudits = async () => {
    try {
      setLoading(true);
      let getConfigCount = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${Urls.parseUrl}/classes/audit/${auditId}`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/json",
        },
      };
      const getAudit = await axios(getConfigCount);
      setLoading(false);
      onCloseHandler();
      setIsRefresh(!isRefresh);
      return getAudit;
    } catch (error) {
      setLoading(false);
      ToastUtils.showErrorToast(error.message);
      return error.message;
    }
  };

  return (
    <Modal show={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">
              Are you sure you want to Delete this Audit?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseHandler}
          >
            Close
          </button>

          <LoadingButton
            className="btn w-sm btn-primary"
            style={{
              boxShadow: "none",
              padding: "0.25rem 0.5rem",
              fontSize: "0.7109375rem",
              width: "80px",
              borderRadius: "0.2rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            isLoading={loading}
            onClick={async () => {
              await deleteAudits();
            }}
            text={"Sure"}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AuditDeleteConfirmaiton;
