import React, {
  FC,
  ReactEventHandler,
  ClassAttributes,
  InputHTMLAttributes,
} from "react";
import { Field, ErrorMessage, FieldHookConfig } from "formik";
import MultiSelect from "multiselect-react-dropdown";

interface MultiselectPropsType {
  name: string;
  label: string;
  options: string[];
  onSelect: Function;
  onRemove: Function;
}

function Multiselect({
  name,
  label,
  options = [],
  onSelect,
  onRemove,
  ...props
}: MultiselectPropsType &
  InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement> &
  FieldHookConfig<string> &
  any) {
  return (
    <div
      style={{
        fontSize: "13px",
      }}
    >
      <label
        style={{
          color: "#212124",
          fontWeight: "bold",
          fontSize: "13px",
          padding: 0,
          margin: 0,
          paddingBottom: "8px",
        }}
      >
        {label}
      </label>
      <MultiSelect
        isObject={false}
        options={options}
        onSelect={(e, listItem) => {
          onSelect(e, listItem);
        }}
        onRemove={(e, listItem) => {
          onRemove(e, listItem);
        }}
        showCheckbox
        {...props}
      />
      <div
        style={{
          color: "red",
          fontSize: "13px",
        }}
      >
        <small>
          <ErrorMessage name={name} />
        </small>
      </div>
    </div>
  );
}

export default Multiselect;
