import * as Yup from "yup";

export const validationSchema = Yup.object({
  offer_text: Yup.string().required("Offer text is required"),
  // program: Yup.array().required("Program is required"),
  // linkStore: Yup.array().required("Link stores is required"),
  termOfUse: Yup.object().shape({
    id: Yup.number().required("Terms of Use is required"),
    name: Yup.string().required("Terms of Use is required"),
    value: Yup.string().required("Terms of Use is required"),
  }),
  redemption: Yup.object().shape({
    id: Yup.number().required("Redemption process is required"),
    name: Yup.string().required("Redemption process is required"),
    value: Yup.string().required("Redemption process is required"),
  }),
});
