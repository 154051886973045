import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Urls from "../../utils/urls";

interface Props {
  brandId: string;
  brandName: any;
  index: number;
}

const BrandBox = ({ brandName, index, brandId }: Props) => {
  const navigate = useNavigate();

  let [storeData, setStoreData] = useState<{}[]>([]);
  //   console.log(brandId);
  var data = {
    where: {
      "brand.brandId": {
        $eq: brandId,
      },
    },
  };
  const directToStore = async () => {
    var config = {
      method: "get",
      url: `${Urls.parseUrl}/classes/stores`,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
        "Content-Type": "application/json",
      },
      params: data,
    };
    const response = await axios(config);
    setStoreData(response.data.results);
    navigate("/store-list", {
      state: {
        storeData: response.data.results,
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#FDEDEC",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Table striped bordered hover>
        <thead></thead>
        <tbody>
          <tr key={index}>
            <td>Brand Name</td>
            <td>{brandName}</td>
          </tr>
          <tr>
            <td>Aggrement</td>
            <td>
              <button
                style={{
                  boxShadow: "none",
                  padding: "0.25rem 0.5rem",
                  fontSize: "0.7109375rem",
                  borderRadius: "0.2rem",
                  color: "#fff",
                  backgroundColor: "#405189",
                  borderColor: "#405189",
                  width: "150px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={directToStore}
              >
                Edit Agreement
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default BrandBox;
