import * as Yup from "yup";

export const validationSchema = Yup.object({
  // templateType: Yup.string().required("templateType is required"),
  signatoryName: Yup.string().required("Signatory Name is required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  contactNo: Yup.number()
    .typeError("Contact No should be a number")
    .required("Contact No is required"),
});
