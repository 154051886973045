import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import Text from "../../components/Text";
import LoadingButton from "../../components/LoadingButton";
import Urls from "../../utils/urls";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import axios from "axios";
import DateUtils from "../../utils/date-utils";
import LoadingSpinner from "../../components/LoadingSpinner";

const SignatoryForm = () => {
  const location = useLocation();
  const [merchantId, setMerchantId] = useState("");
  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);
  const [tableValues, setTableValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const agreement_id = searchParams.get("agreement_id");
        const mouType = searchParams.get("mouType");
        const agreementWhereClause = {
          where: {
            objectId: agreement_id,
          },
        };
        const agreementConfig = AxiosUtils.axiosConfigConstructor(
          "get",
          Urls.parseUrl + "/classes/agreement",
          null,
          {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          agreementWhereClause
        );
        const agreementResponse = await axios(agreementConfig);
        const agreement = agreementResponse.data.results[0];
        if (!agreement) {
          navigate("/");
        }
        const merchantName = agreement?.merchant?.name;
        const createdAt = DateUtils.formatDate(agreement?.createdAt);
        const brandName = agreement?.brand?.name;
        const brandId = agreement?.brand?.id;
        const noOfStores = agreement?.stores?.length;
        const noOfOffers = agreement?.offers?.length;
        console.log("Stores Length: ", noOfStores);
        console.log("Offers Length: ", agreement?.offers);
        const brandWhereCluase = {
          where: {
            objectId: brandId,
          },
        };
        const brandConfig = AxiosUtils.axiosConfigConstructor(
          "get",
          Urls.parseUrl + "/classes/brand",
          null,
          {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          brandWhereCluase
        );
        const brandResponse = await axios(brandConfig);
        const brand = brandResponse.data.results[0];
        if (!brand) {
          navigate("/");
        }
        const name = brand?.authorisedSignatoryName;
        const email = brand?.authorisedSignatoryMail;
        setMerchantId(agreement?.merchant?.id);
        setTableValues([
          {
            merchantName,
            brandName,
            noOfStores,
            name,
            email,
            noOfOffers,
            mouType,
            createdAt,
          },
        ]);
        setLoading(false);
      } catch (error) {
        // Handle any errors that occurred during the asynchronous operations
        console.error(error);
        setLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, [location]);

  console.log("tableValues", tableValues);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="signatory-form-container">
      <div className="submission-header">
        <p className="submission-title">SUBMISSION</p>
        <div className="breadcrumb">
          <a href="/" className="breadcrumb-link">
            Dashboard
          </a>
          <FaGreaterThan className="breadcrumb-icon" />
          <span className="breadcrumb-link">Submission</span>
        </div>
      </div>
      <div className="page-content" style={{ marginBottom: "50px" }}>
        <div className="form-wrapper">
          <div className="form-box">
            <Text color="#494f57" fontWeight="bold" fontSize="20px">
              Would you like to approve this merchant?
            </Text>
            <div className="table-container">
              {tableValues.length > 0 &&
                tableValues.map((data, index) => (
                  <table key={index} className="data-table">
                    <tbody>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Legal Name
                        </th>
                        <td style={{ textAlign: "left" }}>
                          {data.merchantName}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Brand Name
                        </th>
                        <td style={{ textAlign: "left" }}>{data.brandName}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          No of Stores
                        </th>
                        <td style={{ textAlign: "left" }}>{data.noOfStores}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Authorized Signatory Name
                        </th>
                        <td style={{ textAlign: "left" }}>{data.name}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Authorized Signatory Email
                        </th>
                        <td style={{ textAlign: "left" }}>{data.email}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          No of Offers
                        </th>
                        <td style={{ textAlign: "left" }}>{data.noOfOffers}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Mou Type
                        </th>
                        <td style={{ textAlign: "left" }}>{data.mouType}</td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "bold",
                          }}
                        >
                          Created At
                        </th>
                        <td style={{ textAlign: "left" }}>{data.createdAt}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
            </div>
            <div className="button-container">
              <LoadingButton
                className="approve-button"
                text="Approve"
                type="button"
                onClick={async () => {}}
                isLoading={approve}
              />
              <LoadingButton
                className="reject-button"
                text="Reject"
                type="button"
                onClick={async () => {}}
                isLoading={reject}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatoryForm;
