export default class RegexUtils {
  static passwordRegex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/m;

  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static mobileRegex = /^\d{8,10}$/i;
  static mobileRegExp = /^\d{8,10}$/i;

  static onlyAlphabetsAllCase = /^[A-Za-z]+$/;

  static alphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;

  //   static mobileRegExp =
  //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  static otpRegex = /^\d{6}$/i;
  static phoneNumber =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  static gstno = /^[a-zA-Z0-9]+$/;
  static pan = /^[A-Z]{5}\d{4}[A-Z]$/;
  static pincode = /^\d{6}$/;
  static endsWithThriweDomain = /@thriwe.com\s*$/;
}
