import { Formik, Form } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import FormikSelect from "../../../components/FormikSelect";
import { FaGreaterThan } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";
import Text from "../../../components/Text";
import { useAppContext } from "../../../context/app-context";
import { useEffect, useState } from "react";
import GoBackBtn from "../../../components/GoBackBtn";
import ServerUploadsUtils from "../../../utils/serverUploads";

var initialValuesObj = {
  authorisedSignatoryName: "",
  authorisedSignatoryNo: "",
  designation: "",
  authorisedSignatoryMail: "",
  logo: "",
};

export default function AddBrandAdditionalValues() {
  const merchantContext = useMerchantContext();
  const { setFormData, addPageIndex, subtractPageIndex, setCustomPageIndex } =
    merchantContext;
  const { formData } = merchantContext.state;
  const [initialValues, setInitialValues] = useState(initialValuesObj);
  const [uploadingFileForLogo, setUploadingFileForLogo] = useState(false);
  const [refresh, refreshComponent] = useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  const context = useAppContext();
  const { formData: updateFormData, isEditing } = context.state;
  const { updateFormData: setMasterFormData, setStores } = context;

  useEffect(() => {
    if (isEmpty(updateFormData)) {
      const brand = JSON.parse(localStorage.getItem("brand"));
      if (brand) {
        setInitialValues({
          authorisedSignatoryName: brand?.authorisedSignatoryName,
          authorisedSignatoryNo: brand?.authorisedSignatoryNo,
          designation: brand?.designation,
          authorisedSignatoryMail: brand?.authorisedSignatoryMail,
          logo: brand?.logo,
        });
      } else {
        setInitialValues(initialValuesObj);
      }
    } else {
      setInitialValues({
        authorisedSignatoryName: updateFormData?.authorisedSignatoryName,
        authorisedSignatoryNo:
          updateFormData?.authorisedSignatoryNo,
        designation: updateFormData?.designation,
        authorisedSignatoryMail: updateFormData?.authorisedSignatoryMail,
        logo: updateFormData?.logo,
      });
    }
  }, [updateFormData]);

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isEditing ? "Edit Brand" : "Add Brand"}
          </Text>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={(values) => {
              if (!isEditing) {
                const brandObj = { ...formData, ...values };
                localStorage.setItem("brand", JSON.stringify(brandObj));
              }
              setFormData({ ...formData, ...values });
              // alert(JSON.stringify(values));
              let stores;
              if (!isEditing) {
                stores = JSON.parse(localStorage.getItem("stores"));
                if (stores?.length > 0) setStores(stores);
              }
              if (isEditing || stores?.length > 0) {
                setCustomPageIndex(5);
              } else {
                addPageIndex();
              }
            }}
          >
            {({ isSubmitting, values }) => (
              <div style={{ background: "#ffffff", marginBottom: "20px" }}>
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <FormikInput
                      label="Authorised Signatory Name"
                      type="text"
                      name="authorisedSignatoryName"
                      placeholder="Rakesh"
                      labelBtn={
                        <button
                          type="button"
                          onClick={() => {
                            if (formData.spocName) {
                              values.authorisedSignatoryName =
                                formData.spocName;
                              values.authorisedSignatoryNo =
                                formData.spocNo;
                              values.authorisedSignatoryMail =
                                formData.spocEmail;
                            } else {
                              values.authorisedSignatoryName = "";
                              values.authorisedSignatoryNo = "";
                              values.authorisedSignatoryMail = "";
                            }
                            refreshComponent((prev) => !prev);
                          }}
                          style={{
                            outline: "none",
                            background: "transparent",
                            border: "none",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Same As Spoc
                        </button>
                      }
                    />
                    <FormikInput
                      label="Authorised Signatory Number"
                      type="text"
                      name="authorisedSignatoryNo"
                      placeholder="128474938"
                    />
                    <FormikInput
                      label="Authorized Signatory Mail"
                      type="text"
                      name="authorisedSignatoryMail"
                      placeholder="abc@something.com"
                    />
                    <FormikInput
                      label="Designation"
                      type="text"
                      name="designation"
                      placeholder="Sales"
                    />
                    <FormikInput
                      label="Logo"
                      type="file"
                      name="logo"
                      useDefaultInput={true}
                      filename={values.logo}
                      onChange={async (e) => {
                        setUploadingFileForLogo(true);
                        setUploadSuccess(false);
                        try {
                          values.logo =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          console.log(typeof e.target.files[0]);
                          const url = values.logo;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.logo = str1 + "s" + str2;
                          setUploadingFileForLogo(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFileForLogo(false);
                        }
                      }}
                      disabled={uploadingFileForLogo}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        gap: "9px",
                      }}
                    >
                      <GoBackBtn
                        onClick={() => {
                          setMasterFormData(formData);
                          subtractPageIndex();
                        }}
                      />
                      <button
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
