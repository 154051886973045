import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app-context";
import ServerUploadsUtils from "../../utils/serverUploads";
import { useMerchantContext } from "../../context/merchant-context";
import Box from "../Box";
import BrandBox from "../BrandBox";
import NavBar from "../NavBar";
import Text from "../Text";
import { useAuth } from "../../hooks/useAuth";

const Agreement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { agreementList } = location?.state?.;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const postsPerPage = 1;
  const indexOfLastPage = currentPage * postsPerPage;
  let indexOfFirstPage;
  if (indexOfLastPage != 0) indexOfFirstPage = indexOfLastPage - postsPerPage;
  const currentPosts = location?.state?.agreementList.slice(
    indexOfFirstPage,
    indexOfLastPage
  );

  const checkAuth = useAuth("/login");

  useEffect(() => {
    checkAuth();
  }, []);

  const PageCount = Math.ceil(
    location?.state?.agreementList.length / postsPerPage
  );
  const context = useAppContext();
  const { updateFormData, setOffers, setStores } = context;

  const fetchAgreementDetails = async (data: any, selectedAgreement: any) => {
    setLoading(true);
    try {
      let merchantResp = await ServerUploadsUtils.fetchObjectIds("merchant", [
        data?.merchant?.id,
      ]);
      let brandResp = await ServerUploadsUtils.fetchObjectIds("brand", [
        data?.brand?.id,
      ]);
      let storesResp = await ServerUploadsUtils.fetchStoresAndOffers(
        "stores",
        data?.stores
      );
      let offersResp = await ServerUploadsUtils.fetchStoresAndOffers(
        "offers",
        data?.offers
      );

      console.log("merchantResp", merchantResp);
      console.log("brandResp", brandResp);
      console.log("storesResp", storesResp);
      console.log("offersRep", offersResp);
      // alert(JSON.stringify(selectedAgreement));

      const formData = {
        ...merchantResp?.data?.results[0],
        ...brandResp?.data?.results[0],
      };
      console.log("ABCDRF", formData);
      const values = {
        salesRep: formData?.salesRep,
        agreementType: formData?.agreementType,
        registration_no: formData?.registrationNo,
        signingStatus: formData?.signingStatus,
        mouType: formData?.mouType,
        mouValidity: formData?.mouValidity,
        brandType: formData?.brandType,
        country: formData?.country,
        brandName: formData?.brandName,
        companyType: formData?.companyType,
        emirates: formData?.emirates,
        spocName: formData?.spocName,
        poBox: formData?.poBox,
        spocNo: formData?.spocNo,
        spocEmail: formData?.spocEmail,
        extraField: formData.extraField,
        sales: formData.sales,
        signatureDisplayName: formData?.signatureDisplayName,
        templateType: formData.templateType,
        authorisedSignatoryName: formData?.authorisedSignatoryName,
        authorisedSignatoryNo: formData?.authorisedSignatoryNo,
        merchantName: formData?.merchantName,
        corporateAddress: formData?.corporateAddress,
        authorisedSignatoryMail: formData?.authorisedSignatoryMail,
        authorisedSignatoryMail_confirm: formData?.authorisedSignatoryMail,
        registeredAddress: formData?.registeredAddress,
        categoryOfMerchant: formData?.categoryOfMerchant,
        registeredAddressPincode: formData?.registeredAddressPincode,
        corporateAddressCountry: formData?.corporateAddressCountry,
        registeredAddressCountry: formData?.registeredAddressCountry,
        corporateAddressPincode: formData.corporateAddressPincode,
        store_name: formData?.storeName,
        store_address: formData?.storeAddress,
        gst_no: formData?.gstNo,
        registeredAddressState: formData?.registeredAddressState,
        registeredAddressCity: formData?.registeredAddressCity,
        corporateAddressCity: formData?.corporateAddressCity,
        corporateAddressState: formData?.corporateAddressState,
        contact_name: formData?.contactName,
        contact_no: formData?.contactNo,
        shift_number: formData?.shiftNumber,
        store_email: formData?.storeEmail,
        upload_carges_slip: formData?.uploadCargesSlip,
        designation: formData?.designation,
        logo: formData?.logo,
        merchantId: merchantResp?.data?.results[0].objectId,
        brandId: brandResp?.data?.results[0].objectId,
        upload_signed_agreement: selectedAgreement?.upload_signed_agreement,
      };
      console.log("JHTK", values);
      updateFormData(values);
      setStores(storesResp);
      setOffers(offersResp);
      setLoading(false);
      navigate("/edit-merchant");
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div style={{ background: "#f3f3f8", flexGrow: 1 }}>
      {/* <NavBar /> */}
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
            }}
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> Submission</a>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          // marginTop: "12%",
          marginTop: "45px",
          marginBottom: "25px",
        }}
      >
        <Text color="#494f57" fontWeight="bold" fontSize="25px">
          {location?.state?.agreementList[0]?.merchant?.name}
        </Text>
      </div>
      <div
        style={{
          background: "#ffffff",
          marginLeft: "15px",
          marginRight: "15px",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        {currentPosts?.map((data: any, index: number) => (
          <Box
            key={index}
            Brand={data.brand}
            Offers={data.offers}
            Stores={data.stores}
            Files={data.uploadSignedAgreement}
            SalesRep={data.salesRep}
            createdAt={data.createdAt}
            updatedAt={data.updatedAt}
            index={index}
            onClick={() => {
              fetchAgreementDetails(data, location?.state?.agreementList[0]);
            }}
            agreementId={location?.state?.agreementList[0]}
            loading={loading}
          />
        ))}
        {
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "20px",
              }}
            >
              <button
                className="page-item pagination-prev disabled"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                style={{
                  marginRight: "5px",
                  outline: 0,
                  background: "white",
                  border: "1px solid #e9ebec",
                  padding: "0.372rem 0.75rem",
                  fontSize: "13px",
                  borderRadius: "0.25rem",
                  color: currentPage > 1 ? "#405189" : "#878a99",
                }}
                disabled={currentPage > 1 ? false : true}
              >
                Previous
              </button>
              <div
                style={{
                  textAlign: "center",
                  margin: "3px",
                  // color: "black",
                  // fontWeight: "bold",
                  backgroundColor: "#405189",
                  borderColor: "#405189",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.372rem 0.75rem",
                  fontSize: "13px",
                  borderRadius: "0.25rem",
                }}
              >
                {currentPage}
              </div>
              <button
                className="page-item pagination-prev disabled"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  outline: 0,
                  background: "white",
                  border: "1px solid #e9ebec",
                  padding: "0.372rem 0.75rem",
                  fontSize: "13px",
                  borderRadius: "0.25rem",
                  color:
                    currentPage > 0 && currentPage < PageCount
                      ? "#405189"
                      : "#878a99",
                }}
                disabled={
                  currentPage > 0 && currentPage < PageCount ? false : true
                }
              >
                Next
              </button>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Agreement;
