import { Formik, Form, FieldArray } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import FormikSelect from "../../../components/FormikSelect";
import Multiselect from "../../../components/Multiselect";
import { FaGreaterThan } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";
import Text from "../../../components/Text";
import { useAppContext } from "../../../context/app-context";
import { useEffect, useState } from "react";
import GoBackBtn from "../../../components/GoBackBtn";
import _ from "lodash";
import ServerUploadsUtils from "../../../utils/serverUploads";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";
import LoadingButton from "../../../components/LoadingButton";
import { useNavigate } from "react-router-dom";

const initialValuesObj = {
  bank_name: "",
  bank_account_number: "",
  merchantName_written: "",
  ifsc_code: "",
  upload_cancelled_cheque: "",
  commission_to_thriwe: "",
  remarks_store: "",
  payout: "",
  start_validity_voucher: "",
  end_validity_voucher: "",
  branch_name: "",
};

const fieldNames = [
  "bank_name",
  "bank_account_number",
  "merchantName_written",
  "ifsc_code",
  "upload_cancelled_cheque",
  "commission_to_thriwe",
  "remarks_store",
  "payout",
  "start_validity_voucher",
  "end_validity_voucher",
  "branch_name",
];

export default function AddStoreExtraValues() {
  const merchantContext = useMerchantContext();
  const { updateSelectedTableStore, setCustomPageIndex } = merchantContext;
  const { formData, isAddingStore, selectedTableStore } = merchantContext.state;
  const [initialValues, setInitialValues] = useState(initialValuesObj);
  const [salesManager, setSalesManager] = useState(false);
  const [uploadingFileForCanceledCheque, setUploadingFileForCanceledCheque] =
    useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [updateForLoading, setUpdateForLoading] = useState(false);

  const navigate = useNavigate();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  const context = useAppContext();
  const { formData: updateFormData, stores, isEditing } = context.state;
  const { updateFormData: setMasterFormData } = context;

  useEffect(() => {
    let updateFormData = selectedTableStore?.original;
    console.log("Stores ", stores[stores.length - 1]);
    console.log("IS ADD STORE", isAddingStore);
    if (isEmpty(updateFormData) || isAddingStore) {
      // alert("True");
      if (stores.length > 0) {
        initialValues.bank_name = stores[0].bank_name;
        initialValues.bank_account_number = stores[0].bank_account_number;
        initialValues.merchantName_written = stores[0].merchantName_written;
        initialValues.ifsc_code = stores[0].ifsc_code;
        initialValues.upload_cancelled_cheque =
          stores[0].upload_cancelled_cheque;
        initialValues.commission_to_thriwe = stores[0].commission_to_thriwe;
        initialValues.remarks_store = stores[0].remarks_store;
        initialValues.payout = stores[0].payout;
        initialValues.start_validity_voucher = stores[0].start_validity_voucher;
        initialValues.end_validity_voucher = stores[0].start_validity_voucher;
      }
      setInitialValues(initialValuesObj);
    } else {
      // alert("False");
      setInitialValues({
        bank_name: updateFormData?.bank_name,
        bank_account_number: updateFormData?.bank_account_number,
        merchantName_written: updateFormData?.merchantName_written,
        ifsc_code: updateFormData?.ifsc_code,
        upload_cancelled_cheque: updateFormData?.upload_cancelled_cheque,
        commission_to_thriwe: updateFormData?.commission_to_thriwe,
        remarks_store: updateFormData?.remarks_store,
        payout: updateFormData?.payout,
        start_validity_voucher: updateFormData?.start_validity_voucher,
        end_validity_voucher: updateFormData?.end_validity_voucher,
        branch_name: updateFormData?.branch_name,
      });
    }
  }, [stores]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  const areEqual = (value1, value2) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateStore = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const storeData = selectedTableStore?.original;
    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = storeData[fieldName];

      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const brandConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/stores/${storeData.objectId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        }
      );
      await axios(brandConfig);
      await ServerUploadsUtils.addLogs(
        "stores",
        storeData.objectId,
        fieldLogs,
        storeData?.store_name
      );
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>

        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {" "}
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isAddingStore ? "Add Store" : "Edit Store"}
          </Text>
        </div>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values) => {
              if (isAddingStore) {
                // myLength = myLength + 1;
                const newObj = { ...selectedTableStore };
                console.log("OLD OBJ", newObj);
                if (
                  newObj.hasOwnProperty("original") &&
                  newObj.original.hasOwnProperty("storeId")
                ) {
                  delete newObj["original"]["storeId"];
                }
                newObj.original = {
                  ...selectedTableStore?.original,
                  ...values,
                };
                console.log("New OBJ", newObj);
                updateSelectedTableStore(newObj);
              } else {
                const updatedStore = {
                  ...selectedTableStore,
                };

                updatedStore.original = {
                  ...selectedTableStore?.original,
                  ...values,
                };
                console.log("updateSelectedTableStore", updatedStore);
                updateSelectedTableStore({ ...updatedStore });
              }
              if (salesManager && formData.verificationStatus !== "approved") {
                await updateStore(values);
              }
              setCustomPageIndex(4);
            }}
          >
            {({
              isSubmitting,
              handleBlur,
              handleChange,
              values,
              setFieldValue,
            }) => (
              <div style={{ background: "#ffffff", marginBottom: "20px" }}>
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <FormikInput
                      label="Bank Name"
                      name={"bank_name"}
                      type="text"
                      placeholder="SBI"
                    />
                    <FormikInput
                      label="Branch Name"
                      name={"branch_name"}
                      type="text"
                      placeholder=""
                    />
                    <FormikInput
                      label="Bank A/c number"
                      type="text"
                      name="bank_account_number"
                      placeholder=""
                    />
                    <FormikInput
                      label="Merchant Name on Bank"
                      type="text"
                      name="merchantName_written"
                      placeholder="New Delhi"
                    />
                    <FormikInput
                      label="IFSC code"
                      type="text"
                      name="ifsc_code"
                      placeholder="IGJSY87785"
                    />
                    <FormikInput
                      label="Upload Cancelled Cheque"
                      type="file"
                      name="upload_cancelled_cheque"
                      useDefaultInput={true}
                      filename={values?.upload_cancelled_cheque}
                      onChange={async (e) => {
                        setUploadingFileForCanceledCheque(true);
                        setUploadSuccess(false);
                        try {
                          values.upload_cancelled_cheque =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          console.log(typeof e.target.files[0]);
                          const url = values.upload_cancelled_cheque;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.upload_cancelled_cheque = str1 + "s" + str2;
                          console.log(
                            "upload_cancelled_cheque",
                            values.upload_cancelled_cheque
                          );
                          setUploadingFileForCanceledCheque(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFileForCanceledCheque(false);
                        }
                      }}
                      disabled={uploadingFileForCanceledCheque}
                    />
                    <FormikInput
                      label="Commission to Thriwe"
                      type="text"
                      name="commission_to_thriwe"
                      placeholder="IGJSY87785"
                      disabled={salesManager}
                    />
                    <FormikInput
                      label="Remarks"
                      type="text"
                      name="remarks_store"
                      placeholder=""
                    />
                    <FormikSelect
                      label="Payout"
                      name="payout"
                      options={["Once a week", "Twice a week"]}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Voucher Validity Start Date
                    </div>
                    <input
                      type="date"
                      id="start_validity_voucher"
                      name="start_validity_voucher"
                      onChange={handleChange}
                      defaultValue={values.start_validity_voucher ?? ""}
                      onBlur={handleBlur}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Voucher Validity End Date
                    </div>
                    <input
                      type="date"
                      id="end_validity_voucher"
                      name="end_validity_voucher"
                      onChange={handleChange}
                      defaultValue={values.end_validity_voucher ?? ""}
                      onBlur={handleBlur}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        gap: "10px",
                      }}
                    >
                      <GoBackBtn
                        onClick={() => {
                          setMasterFormData(formData);
                          setCustomPageIndex(3);
                        }}
                      />
                      <button
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        Next
                      </button>
                      {salesManager && (
                        <LoadingButton
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #405089",
                            background: "rgb(64,81,167)",
                            outlineColor: "navajowhite",
                            fontSize: "13px",
                            // width: "1px",
                            height: "35px",
                          }}
                          text={
                            formData.verificationStatus !== "approved"
                              ? "Save and Exit"
                              : "Exit"
                          }
                          type="button"
                          onClick={async () => {
                            setUpdateForLoading(true);
                            if (formData.verificationStatus !== "approved") {
                              await updateStore(values);
                              const { objectId } = JSON.parse(
                                localStorage.getItem("user")
                              );
                              await ServerUploadsUtils.unlockVerifier(
                                formData.agreementId,
                                objectId,
                                formData.merchantId
                              );
                            }
                            setUpdateForLoading(false);
                            navigate("/");
                          }}
                          isLoading={updateForLoading}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
