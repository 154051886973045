import { FieldArray, Form, Formik } from "formik";
import { FaGreaterThan } from "react-icons/fa";
import { validationSchema } from "./validationSchema";
import { useEffect, useState } from "react";
import FormikSelect from "../../components/FormikSelect";
import FormikInput from "../../components/FormikInput";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import ServerUploadsUtils from "../../utils/serverUploads";
import Urls from "../../utils/urls";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import ToastUtils from "../../utils/toast/toast-utils";
import { useNavigate } from "react-router-dom";
import Multiselect from "../../components/Multiselect";

const cities = [
  "Mumbai",
  "Kolkata",
  "Bangalore",
  "Chennai",
  "Hyderabad",
  "Ahmadabad",
  "Surat",
  "Pune",
  "Jaipur",
  "Lucknow",
  "Kozhikode",
  "Malappuram",
  "Thrissur",
  "Kochi",
  "Kanpur",
  "Indore",
  "Nagpur",
  "Coimbatore",
  "Thiruvananthapuram",
  "Patna",
  "Bhopal",
  "Agra",
  "Vadodara",
  "Kannur",
  "Visakhapatnam",
  "Nashik",
  "Vijayawada",
  "Kollam",
  "Rajkot",
  "Ludhiana",
  "Madurai",
  "Meerut",
  "Raipur",
  "Varanasi",
  "Jamshedpur",
  "Srinagar",
  "Aurangabad",
  "Tiruppur",
  "Jodhpur",
  "Ranchi",
  "Jabalpur",
  "Asansol",
  "Kota",
  "Allahabad",
  "Gwalior",
  "Amritsar",
  "Dhanbad",
  "Bareilly",
  "Aligarh",
  "Mysore",
  "Moradabad",
  "Durg-Bhilainagar",
  "Bhubaneswar",
  "Tiruchirappalli",
  "Chandigarh",
  "Hubli-Dharwad",
  "Guwahati",
  "Salem",
  "Saharanpur",
  "Jalandhar",
  "Siliguri",
  "Solapur",
  "Warangal",
  "Dehradun",
  "Guntur",
  "Bhiwandi",
  "Puducherry",
  "Firozabad",
  "Cherthala",
  "Bikaner",
  "Nellore",
  "Gorakhpur",
  "Amravati",
  "Cuttack",
  "Muzaffarnagar",
  "Kottayam",
  "Belgaum",
  "Malegaon",
  "Kayamkulam",
  "Mangalore",
  "Bhavnagar",
  "Jammu",
  "Nanded Waghala",
  "Tirupati",
  "Gulbarga",
  "Durgapur",
  "Kurnool",
  "Jhansi",
  "Jamnagar",
  "Erode",
  "Bokaro Steel City",
  "Raurkela",
  "Kolhapur",
  "Ajmer",
  "Ujjain",
  "Patiala",
  "Bilaspur",
  "Mathura",
  "Agartala",
  "Imphal",
  "Sangli",
  "Udaipur",
  "Vellore",
  "Thoothukkudi",
  "Jalgaon",
  "Gaya",
  "Tirunelveli",
  "Panipat",
  "Rajahmundry",
  "Bellary",
  "Hosur",
  "Kakinada",
  "Davanagere",
  "Muzaffarpur",
  "Purnia",
  "Begusarai",
  "Latur",
  "Bhagalpur",
  "Rohtak",
  "Shillong",
  "Bhilwara",
  "Anantapur",
  "Yamunanagar",
  "Akola",
  "Kadapa",
  "Sagar",
  "English Bazar",
  "Alwar",
  "Hardwar",
  "Rampur",
  "Raniganj",
  "Korba",
  "Bijapur",
  "Barddhaman",
  "Baharampur",
  "Brahmapur",
  "Dhule",
  "Karnal",
  "Dhulian",
  "Ahmadnagar",
  "Karimnagar",
  "Gandhidham",
  "Junagadh",
  "Ottappalam",
  "Dindigul",
  "Bihar Sharif",
  "Anand",
  "Shimoga",
  "Aizawl",
];

const BriefForm = () => {
  const [welcomeKit, setWelcomeKit] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  // const [name, setName] = useState("");
  const inActiveListArray = [
    "Accessories",
    "Apparels",
    "Appliances",
    "Bags & Luggage",
    "Computer Accessories",
    "Cosmetics",
    "Electronics",
    "Others",
  ];
  const navigate = useNavigate();
  const { name } = JSON.parse(localStorage.getItem("user"));
  const [initialValues, setInitialValues] = useState<any>({
    region: "",
    programName: "",
    offerConstruct: "",
    offersTandC: "",
    category: "",
    customerRedemptionProcess: "",
    visibilityElements: "",
    merchantRedemptionProcess: "",
    preRequisiteOffers: "",
    updateSchedule: "",
    collaterals: "",
    merchantTraining: "",
    submission: "",
    sourcing: "",
    count: "",
    writeUp: "",
    welcomeKit: "",
    accountManager: name,
    IndicativeList: "",
    wishlist: "",
    mouToBeUsed: "",
    ratingPlatform: "",
    rating: "",
    costForTwo: "",
    targetCities: [],
    exclusion: "",
    others: "",
  });

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <small style={{ color: "#878a99", fontSize: "13px" }}> Brief</small>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
          background: "#ffffff",
        }}
      >
        <div>
          <div
            className="sub-text"
            style={{ fontSize: "30px", padding: "20px" }}
          >
            Brief
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            try {
              setLoading(true);
              setDisableButton(true);
              const data = {
                region: values.region,
                programName: values.programName,
                offerConstruct: values.offerConstruct,
                offersTandC: values.offersTandC,
                ratingPlatform: values.ratingPlatform,
                rating: values.rating,
                costForTwo: values.costForTwo,
                targetCities: values.targetCities,
                exclusion: values.exclusion,
                others: values.others,
                category: values.category,
                customerRedemptionProcess: values.customerRedemptionProcess,
                visibilityElements: values.visibilityElements,
                merchantRedemptionProcess: "",
                preRequisiteOffers: values.merchantRedemptionProcess,
                updateSchedule: values.updateSchedule,
                collaterals: values.collaterals,
                merchantTraining: values.merchantTraining,
                submission: values.submission,
                sourcing: values.sourcing,
                count: values.count,
                writeUp: values.writeUp,
                welcomeKit: welcomeKit,
                accountManager: values.accountManager,
                IndicativeList: values.IndicativeList,
                wishlist: values.wishlist,
                mouToBeUsed: values.mouToBeUsed,
              };
              console.log("Data", data);
              setLoading(false);
              setDisableButton(false);
              console.log("data", data);
              const config = AxiosUtils.axiosConfigConstructor(
                "post",
                Urls.parseUrl + "/classes/brief",
                data,
                {
                  "Content-Type": "application/json",
                }
              );
              await axios(config);
              const { username } = JSON.parse(localStorage.getItem("user"));
              console.log("username", username);
              const mailData = {
                briefMails: [
                  username,
                  "amit.madaan@thriwe.com",
                  "sahil.darne@thriwe.com",
                ],
                briefData: data,
              };
              var mailConfig = {
                method: "post",
                url: Urls.lambdaUrl,
                headers: {
                  "Content-Type": "application/json",
                  Origin: "*",
                },
                data: mailData,
              };
              await axios(mailConfig);
              setLoading(false);
              setDisableButton(false);
              resetForm();
              setWelcomeKit([]);
              ToastUtils.showSuccessToast("Form is Successfully submit");
              navigate("/brief");
            } catch (error) {
              setLoading(false);
              setDisableButton(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <div
              style={{
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormikSelect
                    label="Region"
                    name="region"
                    options={["India", "UAE", "Saudi"]}
                  />
                  <FormikInput
                    label="Program Name"
                    type="text"
                    name="programName"
                    placeholder=""
                  />
                  <FormikInput
                    label="Account Manager"
                    type="text"
                    name="accountManager"
                    placeholder=""
                  />
                  <FormikInput
                    label="Offer Construct"
                    type="text"
                    name="offerConstruct"
                    placeholder=""
                  />
                  <FormikInput
                    label="Offers T&C"
                    type="text"
                    name="offersTandC"
                    placeholder=""
                    as="textArea"
                  />
                  <div>
                    <p
                      style={{
                        color: "#212124",
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Criteria
                    </p>
                    <div style={{ margin: "10px" }}>
                      <FormikSelect
                        label="Rating Platform"
                        name="ratingPlatform"
                        options={[
                          "Zomato",
                          "Tripadvisor",
                          "Google",
                          "Just Dial",
                        ]}
                      />
                      {values.ratingPlatform && (
                        <FormikInput
                          label=""
                          type="text"
                          name="rating"
                          placeholder="Minimum required rating"
                        />
                      )}
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Cost for Two"
                        type="text"
                        name="costForTwo"
                        placeholder="Minimum required amount"
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FieldArray
                        name="targetCities"
                        render={(arrayHelpers) => (
                          <Multiselect
                            label="Target Cities"
                            name="targetCities"
                            options={cities}
                            selectedValues={values.targetCities}
                            onSelect={(e, listItem) => {
                              arrayHelpers.push(listItem);
                            }}
                            onRemove={(e, listItem) => {
                              const elementToRemoveIndex =
                                values.targetCities.indexOf(listItem);
                              arrayHelpers.remove(elementToRemoveIndex);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Exclusion"
                        type="file"
                        name="exclusion"
                        filename={values.exclusion}
                        value=""
                        onChange={async (e) => {
                          try {
                            setDisableButton(true);
                            let contentType = "";
                            const file = e.target.files[0];
                            const fileName = e.target.files[0].name.replace(
                              /[^a-zA-Z]/g,
                              ""
                            );
                            if (
                              file.type ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                              contentType =
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel file
                            } else {
                              setDisableButton(false);
                              return;
                            }
                            console.log("contentType", contentType);
                            let imageUrl =
                              await ServerUploadsUtils.uploadImageToServer(
                                e.target.files[0],
                                fileName,
                                contentType
                              );
                            const url = imageUrl;
                            console.log(url);
                            const str1 = url.substring(0, 4);
                            const str2 = url.substring(4);
                            values.exclusion = str1 + "s" + str2;
                            console.log("New File");
                            setDisableButton(false);
                          } catch (e) {
                            setDisableButton(false);
                          }
                        }}
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Others"
                        type="text"
                        name="others"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <FormikInput
                    label="Indicative List"
                    type="text"
                    name="IndicativeList"
                    placeholder=""
                  />
                  <FormikSelect
                    label="Categories"
                    name="category"
                    options={[
                      "Retail",
                      "Health & Wellness",
                      "Entertainment",
                      "Accomodation",
                      "Dining",
                    ]}
                  />
                  <FormikInput
                    label="Customer Redemption Process"
                    type="text"
                    name="customerRedemptionProcess"
                    placeholder=""
                  />
                  <FormikInput
                    label="Visibility Elements"
                    type="text"
                    name="visibilityElements"
                    placeholder=""
                  />
                  <FormikInput
                    label="Merchant Redemption Process"
                    type="text"
                    name="merchantRedemptionProcess"
                    placeholder=""
                  />
                  <FormikInput
                    label="Pre-requisite for the Offers"
                    type="text"
                    name="preRequisiteOffers"
                    placeholder=""
                  />
                  <FormikInput
                    label="Update Schedule"
                    type="text"
                    name="updateSchedule"
                    placeholder=""
                  />
                  <div>
                    <p
                      style={{
                        color: "#212124",
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Internal Team Deadline
                    </p>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Sourcing"
                        type="Date"
                        name="sourcing"
                        placeholder=""
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Submission"
                        type="Date"
                        name="submission"
                        placeholder=""
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Merchant Training"
                        type="Date"
                        name="training"
                        placeholder=""
                      />
                    </div>
                    <div style={{ margin: "10px" }}>
                      <FormikInput
                        label="Collaterals"
                        type="Date"
                        name="collaterals"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#212124",
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Welcome Kit
                    </div>
                    <div
                      style={{
                        fontSize: "13px",
                        margin: "5px 0",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {welcomeKit?.map((slip) => (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <a target="_blank" href={slip.data} rel="noreferrer">
                            File Upload Successfully
                          </a>
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const filteredChargesSlip = welcomeKit?.filter(
                                (selectedSlip) => slip?.id !== selectedSlip?.id
                              );

                              setWelcomeKit(filteredChargesSlip);

                              console.log(
                                "FILTERED DATA BRO",
                                filteredChargesSlip
                              );
                            }}
                          >
                            <AiOutlineMinusSquare
                              style={{ fontSize: "14px" }}
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <FormikInput
                    label=""
                    type="file"
                    name="welcomeKit"
                    filename={""}
                    value=""
                    onChange={async (e) => {
                      try {
                        setDisableButton(true);
                        const file = e.target.files[0];
                        const fileName = e.target.files[0].name.replace(
                          /[^a-zA-Z]/g,
                          ""
                        );
                        let contentType = "";
                        if (file.type.startsWith("image/")) {
                          contentType = "image/jpeg";
                        } else if (file.type === "application/pdf") {
                          contentType = "application/pdf";
                        } else if (
                          file.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel file
                        } else if (
                          file.type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ) {
                          contentType =
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // Docx file
                        }

                        console.log("contentType", contentType);

                        let imageUrl =
                          await ServerUploadsUtils.uploadImageToServer(
                            e.target.files[0],
                            fileName,
                            contentType
                          );
                        const url = imageUrl;
                        console.log(url);
                        const str1 = url.substring(0, 4);
                        const str2 = url.substring(4);
                        imageUrl = str1 + "s" + str2;
                        console.log(imageUrl);
                        const myArr = [...welcomeKit];
                        console.log(myArr);
                        myArr.push({
                          id: uuidv4(),
                          data: imageUrl,
                        });
                        console.log(myArr);
                        setWelcomeKit(myArr);
                        console.log("New File");
                        setDisableButton(false);
                      } catch (e) {
                        setDisableButton(false);
                      }
                    }}
                  />
                  <FormikInput
                    label="Wishlist"
                    type="file"
                    name="wishlist"
                    filename={values.wishlist}
                    value=""
                    onChange={async (e) => {
                      try {
                        setDisableButton(true);
                        let contentType = "";
                        const file = e.target.files[0];
                        const fileName = e.target.files[0].name.replace(
                          /[^a-zA-Z]/g,
                          ""
                        );
                        if (
                          file.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          contentType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Excel file
                        } else {
                          setDisableButton(false);
                          return;
                        }
                        console.log("contentType", contentType);
                        let imageUrl =
                          await ServerUploadsUtils.uploadImageToServer(
                            e.target.files[0],
                            fileName,
                            contentType
                          );
                        const url = imageUrl;
                        console.log(url);
                        const str1 = url.substring(0, 4);
                        const str2 = url.substring(4);
                        values.wishlist = str1 + "s" + str2;
                        console.log("New File");
                        setDisableButton(false);
                      } catch (e) {
                        setDisableButton(false);
                      }
                    }}
                  />
                  <FormikInput
                    label="Mou To be Used"
                    type="file"
                    name="mouToBeUsed"
                    filename={values.mouToBeUsed}
                    value=""
                    onChange={async (e) => {
                      try {
                        setDisableButton(true);
                        const file = e.target.files[0];
                        const fileName = e.target.files[0].name.replace(
                          /[^a-zA-Z]/g,
                          ""
                        );
                        let contentType = "";

                        if (file.type === "application/pdf") {
                          contentType = "application/pdf";
                        } else if (
                          file.type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ) {
                          contentType =
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // Docx file
                        } else {
                          setDisableButton(false);
                          return;
                        }

                        let imageUrl =
                          await ServerUploadsUtils.uploadImageToServer(
                            e.target.files[0],
                            fileName,
                            contentType
                          );
                        const url = imageUrl;
                        console.log(url);
                        const str1 = url.substring(0, 4);
                        const str2 = url.substring(4);
                        values.mouToBeUsed = str1 + "s" + str2;
                        console.log("New File");
                        setDisableButton(false);
                      } catch (e) {
                        setDisableButton(false);
                      }
                    }}
                  />
                  <FormikInput
                    label="Write Up"
                    type="text"
                    name="writeUp"
                    placeholder=""
                    as="textArea"
                  />
                  <FormikInput
                    label="Count"
                    type="text"
                    name="count"
                    placeholder=""
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background:
                          isSubmitting || disableButton ? "" : "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        width: "70px",
                        height: "35px",
                      }}
                      type="submit"
                      disabled={isSubmitting || disableButton}
                    >
                      {loading ? (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BriefForm;
