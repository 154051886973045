import "./style.css";
import { Modal, Button } from "react-bootstrap";
import { useMerchantContext } from "../../context/merchant-context";

interface Props {
  setOpenModal: any;
  openModal: any;
  store: any;
  cb?: Function;
}

const PopModel = ({ setOpenModal, openModal, store, cb = () => {} }: Props) => {
  const merchantContext = useMerchantContext();
  const { setCustomPageIndex } = merchantContext;
  return (
    <Modal show={openModal} centered>
      {/* <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header> */}

      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            padding: "10px",
            paddingBottom: "0px",
            marginTop: "20px",
            marginBottom: "0px",
          }}
        >
          Are you sure want to add a {store ? "store" : "offer"}
        </p>
      </Modal.Body>

      <Modal.Footer
        style={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="secondary"
          style={{
            background: "red",
            borderColor: "red",
          }}
          onClick={() => setOpenModal()}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            cb();
          }}
          style={{
            background: "rgb(64, 81, 167)",
            borderColor: "rgb(64, 81, 167)",
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopModel;
