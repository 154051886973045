import React from "react";
import "./style.css";
import { FaTimes } from "react-icons/fa";

const Popup = ({ data, onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <button className="popup-close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <h1>{data?.programName}</h1>
        <table>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Region</td>
            <td>{data?.region}</td>
          </tr>
          <tr>
            <td>Program Name</td>
            <td>{data?.programName}</td>
          </tr>
          <tr>
            <td>Offer Construct</td>
            <td>{data?.offerConstruct}</td>
          </tr>
          <tr>
            <td>Offer T&amp;C</td>
            <td>{data?.offersTandC}</td>
          </tr>
          <tr>
            <td>Category</td>
            <td>{data?.category}</td>
          </tr>
          <tr>
            <td>Customer Redemption Process</td>
            <td>{data?.customerRedemptionProcess}</td>
          </tr>
          <tr>
            <td>Visibility Elements</td>
            <td>{data?.visibilityElements}</td>
          </tr>
          <tr>
            <td>Indicative List</td>
            <td>{data?.IndicativeList}</td>
          </tr>
          <tr>
            <td>Wishlist</td>
            <td>
              <a href={data?.wishlist} target="_blank">
                View Wishlist
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {data?.ratingPlatform && data?.rating
                ? "Rating Platform & Rating"
                : data?.ratingPlatform && !data?.rating
                ? "Rating Platform"
                : !data?.ratingPlatform && data?.rating
                ? "Rating"
                : "Rating Platform & Rating"}
            </td>
            <td>
              {data?.ratingPlatform && data?.rating
                ? data?.ratingPlatform & data?.rating
                : data?.ratingPlatform && !data?.rating
                ? data?.ratingPlatform
                : !data?.ratingPlatform && data?.rating
                ? data?.rating
                : ""}
            </td>
          </tr>
          <tr>
            <td>Cost for Two</td>
            <td>{data?.costForTwo}</td>
          </tr>
          <tr>
            <td>Target Cities</td>
            <td>{data?.targetCities}</td>
          </tr>
          <tr>
            <td>Exclusion</td>
            <td>
              <a href={data?.exclusion} target="_blank">
                View Exclusion
              </a>
            </td>
          </tr>
          <tr>
            <td>Others</td>
            <td>{data?.others}</td>
          </tr>
          <tr>
            <td>Mou To be Used</td>
            <td>
              <a href={data?.mouToBeUsed} target="_blank">
                View Mou
              </a>
            </td>
          </tr>
          <tr>
            <td>Merchant Redemption Process</td>
            <td>{data?.merchantRedemptionProcess}</td>
          </tr>
          <tr>
            <td>Pre Requisite Offers</td>
            <td>{data?.preRequisiteOffers}</td>
          </tr>
          <tr>
            <td>Update Schedule</td>
            <td>{data?.updateSchedule}</td>
          </tr>
          <tr>
            <td>Collaterals</td>
            <td>{data?.collaterals}</td>
          </tr>
          <tr>
            <td>Training</td>
            <td>{data?.training}</td>
          </tr>
          <tr>
            <td>Submission</td>
            <td>{data?.submission}</td>
          </tr>
          <tr>
            <td>Sourcing</td>
            <td>{data?.sourcing}</td>
          </tr>
          <tr>
            <td>Count</td>
            <td>{data?.count}</td>
          </tr>
          <tr>
            <td>Write Up</td>
            <td>{data?.writeUp}</td>
          </tr>
          {data?.welcomeKit.length > 0 &&
            data?.welcomeKit.map((image) => (
              <tr>
                <td>Welcome Kit</td>
                <td>
                  <a href={image.data} target="_blank">
                    View Welcome Kit
                  </a>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};

export default Popup;
