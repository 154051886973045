import * as Yup from "yup";

export const validationSchema = Yup.object({
  salesRep: Yup.string().required("Sales rep is required"),
  merchantName: Yup.string().required("Merchant Name is required"),
  agreementType: Yup.string().required("Agreement Type is required"),
  mouType: Yup.string().required("Mou Type is required"),
  mouValidity: Yup.number().typeError("Mou Validity is a number"),
  // logo: Yup.string().required("Logo is required"),
});
