import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
// import './index.css';
import App from "./App";
import { AppWrapper } from "./context/app-context";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppWrapper>
    <ToastContainer limit={3} autoClose={4000} />
    <App />
  </AppWrapper>
);
