import React from "react";

const App = () => {
  const htmlString =
    "<h1>Hello, PDF!</h1><p>This is an example HTML string.</p>";

  const handleSaveAsPdf = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>PDF</title>
        </head>
        <body>
          ${htmlString}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />

      <button onClick={handleSaveAsPdf}>Save as PDF</button>
    </div>
  );
};

export default App;
