import React from "react";
import Dashboard from "./pages/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-bootstrap/dist/react-bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BrnadList from "./pages/BrandList";
import ParentFormUpdateOffer from "./components/Form/update-offer/parent-form";
import Agreement from "./components/Agreement";
import Merchant from "./pages/Merchant";
import WithNav from "./components/WithNav";
import WithoutNav from "./components/WithoutNav";
import Login from "./pages/Login";
import { UserWrapper } from "./context/user-context";
import { MerchantWrapper } from "./context/merchant-context";
import MerchantSignAgreement from "./pages/MerchantSignAgreement";
import "./index.css";
import GetData from "./pages/Data";
import SalesRepresent from "./pages/SalesConfirm";
import ReactPdf from "./pages/Data/pdfviewer";
import SignatoryForm from "./pages/Signatory";
import SummayDetails from "./pages/Summary";
import BriefForm from "./pages/BriefForm";
import Brief from "./pages/Brief";
import AuditForm from "./pages/AuditForm";
import AuditUpload from "./pages/AuditUpload";
import Audit from "./pages/Audit";
import AuditList from "./pages/AuditList";

function App() {
  return (
    <div
      style={{
        // background: "orange",
        // padding: "30px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <NavBar /> */}
      <div
        style={{
          //  padding:"20px",
          background: "rgb(243,243,249)",
          // flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          height: "100%",
          overflow: "scroll",
        }}
      >
        <UserWrapper>
          <Router>
            <Routes>
              <Route element={<WithoutNav />}>
                <Route path="/login" element={<Login />} />
              </Route>
              <Route element={<WithNav />}>
                <Route
                  path="/"
                  element={
                    <>
                      <MerchantWrapper>
                        <Dashboard />
                      </MerchantWrapper>
                    </>
                  }
                />
                <Route
                  path="/sign-agreement"
                  element={
                    <>
                      <MerchantWrapper>
                        <MerchantSignAgreement />
                      </MerchantWrapper>
                    </>
                  }
                />
                <Route path="/add-merchant" element={<Merchant />} />
                <Route path="/audit-form" element={<AuditForm />} />
                <Route path="/audit-upload" element={<AuditUpload />} />
                <Route path="/audit" element={<Audit />} />
                <Route path="/audit-list" element={<AuditList />} />
                <Route path="/view-pdf" element={<ReactPdf />} />
                <Route path="/edit-merchant" element={<Merchant />} />
                <Route path="/brand-list" element={<BrnadList />} />
                <Route path="/agreement-list" element={<Agreement />} />
                <Route path="/get-data" element={<GetData />} />
                <Route path="/sales" element={<SalesRepresent />} />
                <Route path="/signatory-form" element={<SignatoryForm />} />
                <Route path="/summary" element={<SummayDetails />} />
                <Route path="/brief-form" element={<BriefForm />} />
                <Route path="/brief" element={<Brief />} />
                <Route
                  path="/update-offer"
                  element={<ParentFormUpdateOffer />}
                />
              </Route>
            </Routes>
          </Router>
        </UserWrapper>
      </div>
    </div>
  );
}

export default App;
