import { createContext, useContext, useEffect, useState } from "react";
import { TidMidModel } from "../models/tid-mid-model";
import Constants from "../utils/constants";
import { useAppContext } from "./app-context";

interface MerchantWrapperProps {
  state: {
    page: number;
    formData: FormDataInterface;
    selectedStores: any[];
    selectedOffers: any[];
    isAddingStore: boolean;
    isAddingOffer: boolean;
    selectedStoresRowIds: any;
    selectedOffersRowIds: any;
    selectedTableOffer: any;
    selectedTableStore: any;
  };
  addPageIndex: Function;
  subtractPageIndex: Function;
  setFormData: Function;
  setCustomPageIndex: Function;
  updateSelectedStores: Function;
  updateSelectedOffers: Function;
  setIsAddingStore: Function;
  updateSelectedRows: Function;
  setIsAddingOffer: Function;
  updateSelectedOffersRows: Function;
  updateSelectedTableOffer: Function;
  updateSelectedTableStore: Function;
}
export interface FormDataInterface {
  salesRep: string;
  agreementType: string;
  brandId: string;
  agreementId: string;
  merchantName: string;
  registration_no: string;
  merchantId: string;
  brandType: string;
  brandName: string;
  email: string;
  registeredAddress: string;
  corporateAddress: string;
  spocName: string;
  spocNo: string;
  spocEmail: string;
  authorisedSignatoryName: string;
  authorisedSignatoryNo: string;
  designation: string;
  authorisedSignatoryMail: string;
  store_name: string;
  store_address: string;
  gst_no: string;
  category: string;
  contactNo: string;
  extraField: string;
  templateType: string;
  signatoryName: string;
  amenities: [];
  subcategory: [];
  contact_name: string;
  contact_no: string;
  interior_images: string;
  shift_number: string;
  store_email: string;
  tid: string;
  mid: string;
  tidANDMID: TidMidModel[];
  upload_carges_slip: string;
  logo: string;
  menu: string;
  zomato_rating: string;
  rating_no: string;
  interor_images: string;
  offer_text: string;
  linkStore: [];
  program: [];
  termOfUse: any;
  redemption: any;
  offerExclusion: any;
  upload_signed_agreement: string;
  fileToBeDeletedName: string;
  isSigning: string;
  cuisines: [];
  reservation_email: string;
  meal_for_two: string;
  mode_of_payment: [];
  delivery_option: string;
  offer_validity_startDate: string;
  offer_validity_endDate: string;
  rating_platform: string;
  remarks: string;
  registeredAddressPincode: string;
  corporateAddressPincode: string;
  store_city: string;
  store_country: string;
  store_pincode: string;
  panNumber: string;
  bank_name: string;
  bank_account_number: string;
  merchantName_written: string;
  ifsc_code: string;
  registeredAddressCity: string;
  registeredAddressState: string;
  corporateAddressCity: string;
  corporateAddressState: string;
  upload_cancelled_cheque: string;
  commission_to_thriwe: string;
  remarks_store: string;
  payout: string;
  start_validity_voucher: string;
  end_validity_voucher: string;
  branch_name: string;
  mouType: string;
  categoryOfMerchant: string;
  locality: string;
  mouValidity: string;
  signatureDisplayName: string;
  store_state: string;
  registeredAddressCountry: string;
  corporateAddressCountry: string;
  authorisedSignatoryMail_confirm: string;
  signingStatus: string;
  otp: string;
  sales: string;
  country: string;
  pan_certificate: string;
  gst_certificate: string;
  trade_license_number: string;
  trade_license_certificate: string;
  vat_number: string;
  vat_certificate: string;
  poBox: string;
  updatedAt: string;
  signedDate: string;
  companyType: string;
  emirates: string;
  verificationStatus: string;
  agreementRemarks: string;
  reason: [];
}
const MerchantContext = createContext<MerchantWrapperProps>(undefined);

export function MerchantWrapper({ children, ...props }: any) {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState<FormDataInterface>();
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedStoresRowIds, setSelectedStoresRowIds] = useState([]);
  const [selectedOffersRowIds, setSelectedOffersRowIds] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [isAddingStore, setIsAddingStore] = useState(true);
  const [isAddingOffer, setIsAddingOffer] = useState(true);

  const [selectedTableOffer, setSelectedTableOffer] = useState({});
  const [selectedTableStore, setSelectedTableStore] = useState({});

  const context = useAppContext();

  useEffect(() => {
    // alert("I Was Called");
    setFormData({ ...context.state.formData });
  }, [context.state.formData]);

  useEffect(() => {
    if (context.state.isEditing) {
      setIsAddingOffer(false);
      setIsAddingStore(false);
    } else {
      setIsAddingOffer(true);
      setIsAddingStore(true);
    }
  }, [context.state.isEditing]);

  const addPageIndex = () => {
    setPage((prev) => prev + 1);
  };
  const subtractPageIndex = () => {
    setPage((prev) => prev - 1);
  };

  const setCustomPageIndex = (pageIndex) => {
    setPage(pageIndex);
  };

  const updateSelectedStores = (store) => {
    setSelectedStores([...store]);
  };

  const updateSelectedOffers = (offer) => {
    setSelectedOffers([...offer]);
  };

  const updateSelectedRows = (rows) => {
    setSelectedStoresRowIds({ ...rows });
  };

  const updateSelectedOffersRows = (rows) => {
    setSelectedOffersRowIds({ ...rows });
  };

  const updateSelectedTableOffer = (offer) => {
    setSelectedTableOffer({ ...offer });
  };

  const updateSelectedTableStore = (store) => {
    setSelectedTableStore({ ...store });
  };

  return (
    <MerchantContext.Provider
      value={{
        state: {
          page: page,
          formData: formData,
          selectedStores: selectedStores,
          selectedOffers: selectedOffers,
          isAddingStore: isAddingStore,
          isAddingOffer: isAddingOffer,
          selectedStoresRowIds: selectedStoresRowIds,
          selectedOffersRowIds: selectedOffersRowIds,
          selectedTableOffer: selectedTableOffer,
          selectedTableStore: selectedTableStore,
        },
        addPageIndex: addPageIndex,
        subtractPageIndex: subtractPageIndex,
        setFormData: setFormData,
        setCustomPageIndex: setCustomPageIndex,
        updateSelectedStores: updateSelectedStores,
        updateSelectedOffers: updateSelectedOffers,
        setIsAddingStore: setIsAddingStore,
        setIsAddingOffer: setIsAddingOffer,
        updateSelectedRows: updateSelectedRows,
        updateSelectedOffersRows: updateSelectedOffersRows,
        updateSelectedTableOffer: updateSelectedTableOffer,
        updateSelectedTableStore: updateSelectedTableStore,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
}

export function useMerchantContext() {
  return useContext(MerchantContext);
}
