import React, {
  FC,
  ClassAttributes,
  InputHTMLAttributes,
  useState,
} from "react";
import { Field, ErrorMessage, FieldHookConfig } from "formik";
import "./formikInput.css";

interface FormikInputPropsType {
  type: string;
  name: string;
  label: string;
  useDefaultInput?: boolean;
  filename?: string;
  labelBtn?: any;
}

function FormikInput({
  type,
  name,
  label,
  useDefaultInput = false,
  filename = "",
  labelBtn,
  ...props
}: FormikInputPropsType &
  InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement> &
  FieldHookConfig<string>) {
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);

  const _renderComponent = () => {
    if (type == "file") {
      return (
        <div style={{}}>
          <div
            style={{
              // padding: "8px 0",
              // paddingTop: "3px",
              overflowX: "scroll",
              borderRadius: "5px",
            }}
          >
            <small style={{ fontSize: "10px", fontWeight: "400" }}>
              {filename ? (
                <div>
                  <small>
                    <a href={filename} target="_blank" rel="noreferrer">
                      File successfully uploaded
                    </a>
                  </small>
                </div>
              ) : (
                "No file chosen"
              )}
            </small>
          </div>
          <div
            style={{
              // backgroundColor: "green",
              marginTop: "4px",
            }}
          >
            <small>
              <input type="file" {...props} />
            </small>
          </div>
        </div>
      );
    }

    if (type !== "file") {
      if (useDefaultInput) {
        return <input className="form-control" type={type} {...props} />;
      } else {
        return (
          <Field
            className="form-control"
            type={type}
            name={name}
            {...props}
            style={{
              fontSize: "13px",
              ...props?.style,
            }}
          />
        );
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: labelBtn && "flex",
          alignItems: labelBtn && "center",
          paddingBottom: "8px",
          justifyContent: labelBtn && "space-between",
        }}
      >
        <p
          style={{
            color: "#212124",
            fontWeight: "bold",
            fontSize: "13px",
            padding: 0,
            margin: 0,
          }}
        >
          {label}
        </p>
        <span
          style={{
            fontSize: "13px",
          }}
        >
          {labelBtn}
        </span>
      </div>

      {_renderComponent()}
      <div
        style={{
          color: "red",
          fontSize: "13px",
        }}
      >
        <small>
          <ErrorMessage name={name} />
        </small>
      </div>
    </div>
  );
}

export default FormikInput;
