import React, { useEffect, useState } from "react";
import DateUtils from "../../utils/date-utils";
interface DateCompPropsType {
  storeInfo?: any;
}

function DateComp({ storeInfo }: DateCompPropsType) {
  const [dateComp, setDateComp] = useState<any>();

  console.log("STORE Info", storeInfo);
  useEffect(() => {
    const date = new Date();
    const formattedDate = DateUtils.formatDate(date);
    setDateComp(formattedDate);
  }, []);

  return (
    <div>
      {storeInfo?.original?.createdAt
        ? DateUtils.formatDate(storeInfo?.original?.createdAt)
        : dateComp}
    </div>
  );
}

export default DateComp;
