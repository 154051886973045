import AxiosUtils from "./AxiosUtils/axiosUtils";
import Urls from "./urls";
import axios from "axios";
import qs from "qs";

export default class ServerUploadsUtils {
  static createBatch = async (
    method: string,
    collection: string,
    objectsArr: any[]
  ) => {
    let arr = [];
    objectsArr.forEach((item, index) => {
      let str = `/parse/classes/${collection}/`;
      if (method == "put") {
        str += item.objectId;
      }
      let obj = {};
      obj["method"] = method.toUpperCase();
      obj["path"] = str;
      obj["body"] = {
        ...item,
      };
      arr.push(obj);
    });
    let responseArr = [];
    while (arr.length) {
      let splicedArr = arr.splice(0, 50);
      let response = await axios(`${Urls.parseUrl}/batch`, {
        method: method,
        data: {
          requests: splicedArr,
        },
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
        },
      });
      console.log(response.data);
      responseArr.push(...response.data);
    }
    return responseArr;
  };

  static uploadImageToServer = async (
    image: any,
    filename: string,
    contentType?: string
  ) => {
    let data = image;
    console.log("data image", data);
    const headers = {
      "Content-Type": contentType ? contentType : "image/jpeg",
    };
    const config = AxiosUtils.axiosConfigConstructor(
      "post",
      Urls.parseUrl + "/files/" + filename,
      data,
      headers
    );
    console.log("config", config);
    const response = await axios(config);
    console.log("response", response);
    return response.data.url;
  };

  static generatePassword() {
    var length = 8,
      charset = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ1356789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  static saveDataToDB = async (
    formData,
    stores = [],
    offers = [],
    userEmail,
    isSigning = "false",
    locallySigned = false
  ) => {
    console.log("Form Data ORIGINAL", formData);
    console.log("Stores ORIGINAL", stores);
    console.log("Offers ORIGINAL", offers);
    console.log("user email broooooo", userEmail);

    console.log("YESS BRO YESSS !!!");

    const signingDate = new Date();

    var merchantBody = qs.stringify({
      salesRep: formData.salesRep,
      agreementType: formData.agreementType,
      merchantName: formData.merchantName,
      logo: formData.logo,
      templateType: formData.templateType,
      extraField: formData.extraField,
      isSigning: isSigning,
      mouType: formData.mouType,
      mouValidity: formData.mouValidity,
      signingStatus: locallySigned ? true : false,
      signatureDisplayName: formData?.signatureDisplayName,
      sales: userEmail,
      country: formData?.country,
      companyType: formData?.companyType,
      emirates: formData?.emirates,
      poBox: formData?.poBox,
      salesConfirm: "",
      signedDate: locallySigned && signingDate.toISOString().substring(0, 10),
    });

    if (!locallySigned) {
      delete merchantBody["signedDate"];
    }
    console.log("Merchant Body", merchantBody);

    const merchantConfig = AxiosUtils.axiosConfigConstructor(
      "post",
      Urls.parseUrl + "/classes/merchant",
      merchantBody,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );

    console.log("Merchant Config", merchantConfig);
    var password;
    const merchantObjectId = await axios(merchantConfig);
    if (locallySigned != true) {
      var userData = {
        where: {
          username: formData.authorisedSignatoryMail.toLowerCase(),
        },
      };
      var userConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + "/users",
        null,
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        userData
      );
      const userInformation = await axios(userConfig);
      console.log(
        "userInformation",
        userInformation.data.results,
        userInformation.data.results.length
      );
      password = this.generatePassword();
      if (userInformation.data.results.length > 0) {
        let updateUserData = {
          merchant: {
            __op: "AddUnique",
            objects: [merchantObjectId.data.objectId],
          },
          password: password,
        };
        let updateUserConfig = AxiosUtils.axiosConfigConstructor(
          "put",
          Urls.parseUrl + `/users/${userInformation.data.results[0].objectId}`,
          updateUserData,
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(updateUserConfig);
      } else {
        let updateUserData = {
          username: formData.authorisedSignatoryMail.toLowerCase(),
          permission: {
            salesRepresentative: false,
            CanSignAgreement: true,
          },
          name: formData.authorisedSignatoryName,
          phoneNo: formData.authorisedSignatoryNo,
          merchant: [merchantObjectId.data.objectId],
          password: password,
        };
        let updateUserConfig = AxiosUtils.axiosConfigConstructor(
          "post",
          Urls.parseUrl + "/users",
          updateUserData,
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(updateUserConfig);
      }
    }
    console.log("Merchant Object Id", merchantObjectId);
    console.log("categoryOfMerchant", formData.categoryOfMerchant);
    var brandBody = {
      brandType: formData.brandType,
      brandName: formData.brandName,
      registeredAddress: formData.registeredAddress,
      categoryOfMerchant: formData.categoryOfMerchant,
      registeredAddressPincode: formData.registeredAddressPincode,
      registeredAddressCountry: formData.registeredAddressCountry,
      corporateAddressCountry: formData.corporateAddressCountry,
      corporateAddressPincode: formData.corporateAddressPincode,
      corporateAddress: formData.corporateAddress,
      spocName: formData.spocName,
      registeredAddressState: formData?.registeredAddressState,
      registeredAddressCity: formData?.registeredAddressCity,
      corporateAddressCity: formData?.corporateAddressCity,
      corporateAddressState: formData?.corporateAddressState,
      spocNo: formData.spocNo,
      spocEmail: formData.spocEmail,
      logo: formData.logo,
      authorisedSignatoryName: formData.authorisedSignatoryName,
      authorisedSignatoryNo: formData.authorisedSignatoryNo,
      designation: formData.designation,
      authorisedSignatoryMail: formData.authorisedSignatoryMail,
      merchant: {
        id: merchantObjectId.data.objectId,
        name: formData.merchantName,
      },
    };

    console.log("Brand Body", brandBody);

    const brandConfig = AxiosUtils.axiosConfigConstructor(
      "post",
      Urls.parseUrl + "/classes/brand",
      brandBody,
      {
        "Content-Type": "application/json",
      }
    );

    console.log("Brand Config", brandConfig);

    const brandObjectId = await axios(brandConfig);

    console.log("Brand Object Id", brandObjectId);

    // console.log(brandData);
    const updatedStoresWithBrandDetails = [];
    console.log("Stores:    ", stores);
    stores.forEach((store, index) => {
      console.log(
        "STore Best",
        store,
        store.cuisines,
        store.reservation_email,
        store.registration_no
      );
      const oldStore = {
        storeId: store.storeId,
        store_name: store.store_name,
        store_address: store.store_address,
        gst_no: store.gst_no,
        subcategory: store.subcategory,
        category: store.category,
        store_state: store.store_state,
        amenities: store.amenities,
        contact_name: store.contact_name,
        contact_no: store.contact_no,
        shift_number: store.shift_number,
        shift_email: store.shift_email,
        interior_images: store.interior_images,
        locality: store.locality,
        menu: store.menu,
        panNumber: store.panNumber,
        zomato_rating: store.zomato_rating,
        tidANDMID: store.tidANDMID,
        branch_name: store.branch_name,
        upload_carges_slip: store.upload_carges_slip,
        brandName: formData.brandName,
        cuisines: store.cuisines,
        reservation_email: store.reservation_email,
        registration_no: store.registration_no,
        rating_no: String(store.rating_no),
        rating_platform: store.rating_platform,
        meal_for_two: store.meal_for_two,
        mode_of_payment: store.mode_of_payment,
        delivery_option: store.delivery_option,
        bank_name: store.bank_name,
        bank_account_number: store.bank_account_number,
        merchantName_written: store.merchantName_written,
        ifsc_code: store.ifsc_code,
        upload_cancelled_cheque: store.upload_cancelled_cheque,
        commission_to_thriwe: store.commission_to_thriwe,
        remarks_store: store.remarks_store,
        payout: store.payout,
        start_validity_voucher: store.start_validity_voucher,
        end_validity_voucher: store.end_validity_voucher,
        store_city: store.store_city,
        store_country: store.store_country,
        store_pincode: store.store_pincode,
        pan_certificate: store.pan_certificate,
        gst_certificate: store.gst_certificate,
        trade_license_number: store.trade_license_number,
        trade_license_certificate: store.trade_license_certificate,
        vat_number: store.vat_number,
        vat_certificate: store.vat_certificate,
        batchOrder: index,
      };
      oldStore["brand"] = {
        id: brandObjectId.data.objectId,
        name: formData.brandName,
      };
      updatedStoresWithBrandDetails.push(oldStore);
    });

    console.log(
      "Updated Stores With Brand Details",
      updatedStoresWithBrandDetails
    );

    let storesId = [];
    const storesResponse = await this.createBatch(
      "post",
      "stores",
      updatedStoresWithBrandDetails
    );

    console.log("Stores Id", storesResponse);

    console.log("storeResponse", storesResponse);

    for (let i = 0; i < storesResponse.length; i++) {
      storesId.push(storesResponse[i].success.objectId);
      updatedStoresWithBrandDetails[i].objectId =
        storesResponse[i].success.objectId;
    }

    console.log("Stores Id :  ", storesId);

    let updatedOffersWithStoreDetails = [];

    console.log("MY OFFERS", offers);

    for (let i = 0; i < offers.length; i++) {
      let linkStore = offers[i].linkStore;
      console.log("linkStore", linkStore);
      let findIdByLinkStores = [];
      let storesId = [];
      for (let j = 0; j < linkStore.length; j++) {
        for (let k = 0; k < updatedStoresWithBrandDetails.length; k++) {
          if (
            linkStore[j].storeId === updatedStoresWithBrandDetails[k].storeId
          ) {
            findIdByLinkStores.push({
              storeId: updatedStoresWithBrandDetails[k].storeId,
              id: updatedStoresWithBrandDetails[k].objectId,
              store_name: updatedStoresWithBrandDetails[k].store_name,
            });
            storesId.push({
              __type: "Pointer",
              className: "stores",
              objectId: updatedStoresWithBrandDetails[k].objectId,
            });
          }
        }
      }
      updatedOffersWithStoreDetails.push({
        offer_text: offers[i].offer_text,
        program: offers[i].program,
        linkStore: findIdByLinkStores,
        termOfUse: offers[i].termOfUse,
        redemption: offers[i].redemption,
        stores: storesId,
        offer_validity_startDate: offers[i].offer_validity_startDate,
        offer_validity_endDate: offers[i].offer_validity_endDate,
        remarks: offers[i].remarks,
        offerExclusion: offers[i].offerExclusion,
        batchOrder: i,
      });
    }

    console.log(
      "updated offers with stores details",
      updatedOffersWithStoreDetails
    );

    let offersId = [];
    const offersResponse = await this.createBatch(
      "post",
      "offers",
      updatedOffersWithStoreDetails
    );

    console.log("Offers Id", offersId);

    for (let i = 0; i < offersResponse.length; i++) {
      offersId.push(offersResponse[i].success.objectId);
    }
    let storePointerObjectId = [];
    for (let i = 0; i < storesId.length; i++) {
      storePointerObjectId.push({
        __type: "Pointer",
        className: "stores",
        objectId: storesId[i],
      });
    }
    let offerPointerObjectId = [];
    for (let i = 0; i < offersId.length; i++) {
      offerPointerObjectId.push({
        __type: "Pointer",
        className: "offers",
        objectId: offersId[i],
      });
    }
    const agreementBody = {
      merchant: {
        id: merchantObjectId.data.objectId,
        name: formData.merchantName,
      },
      brand: {
        id: brandObjectId.data.objectId,
        name: formData.brandName,
      },
      stores: storesId,
      offers: offersId,
      upload_signed_agreement: formData.upload_signed_agreement,
      salesRep: formData.salesRep,
      merchantPointer: {
        __type: "Pointer",
        className: "merchant",
        objectId: merchantObjectId.data.objectId,
      },
      brandPointer: {
        __type: "Pointer",
        className: "brand",
        objectId: brandObjectId.data.objectId,
      },
      storePointer: storePointerObjectId,
      offersPointer: offerPointerObjectId,
      signingStatus: locallySigned ? true : false,
    };

    console.log("Agreement Body", agreementBody);

    var agreementConfig = AxiosUtils.axiosConfigConstructor(
      "post",
      Urls.parseUrl + "/classes/agreement",
      agreementBody,
      {
        "Content-Type": "application/json",
      },
      null
    );
    console.log("Agreement Config", agreementConfig);

    await axios(agreementConfig);
    var mailData;
    if (locallySigned) {
      mailData = {
        noremail: userEmail.toLowerCase(),
        authEmail: formData.authorisedSignatoryMail.toLowerCase(),
        spocEmail: formData.spocEmail.toLowerCase(),
        isSigning: isSigning,
        locallySigned: locallySigned,
        brandName: formData.brandName,
        merchantName: formData.merchantName,
        authName: formData.authorisedSignatoryName,
        agreement: formData?.upload_signed_agreement,
      };
    } else {
      mailData = {
        noremail: userEmail.toLowerCase(),
        authEmail: formData.authorisedSignatoryMail.toLowerCase(),
        spocEmail: formData.spocEmail.toLowerCase(),
        isSigning: isSigning,
        password: password,
        brandName: formData.brandName,
        merchantName: formData.merchantName,
        authName: formData.authorisedSignatoryName,
        agreement: formData?.upload_signed_agreement,
      };
    }
    var mailConfig = {
      method: "post",
      url: Urls.lambdaUrl,
      headers: {
        "Content-Type": "application/json",
        Origin: "*",
      },
      data: mailData,
    };

    await axios(mailConfig);
    localStorage.removeItem("merchant");
    localStorage.removeItem("brand");
    localStorage.removeItem("stores");
    localStorage.removeItem("offers");
    // for (let i = 1; i < formDataStore.length; i++) {
    //   if (formDataStore[i].flag) {
    //     const storeJson = {
    //       brand: {
    //         brandId: brandData.data.objectId,
    //         brandName: formData.brandName,
    //       },
    //       storeName: formDataStore[i].store_name,
    //       storeAddress: formDataStore[i].store_address,
    //       gstNo: formDataStore[i].gst_no,
    //       category: formDataStore[i].category,
    //       amenities: formDataStore[i].amenities,
    //       contactName: formDataStore[i].contact_name,
    //       contactNo: formDataStore[i].contact_no,
    //       shiftNumber: formDataStore[i].shift_number,
    //       storeEmail: formDataStore[i].store_email,
    //       tidAndMID: formData.tidANDMID,
    //       uploadCargesSlip: formDataStore[i].upload_carges_slip,
    //     };
    //     var storeConfig = {
    //       method: "post",
    //       url: "https://dev-fab-api-gateway.thriwe.com/parse/classes/stores",
    //       headers: {
    //         "X-Parse-Master-Key": "Hh4evLBEui54XoUj",
    //         "X-Parse-Application-Id": "PROD_APPLICATION_ID",
    //         "Content-Type": "application/json",
    //       },
    //       data: storeJson,
    //     };
    //     const storeData = await axios(storeConfig);
    //     console.log(storeData);
    //     fetchStoreData.push({
    //       id: storeData.data.objectId,
    //       name: formDataStore[i].store_name,
    //     });
    //   }
    // }
  };

  static fetchObjectIds = async (collection: string, objectIds: string[]) => {
    let params = {};
    let where = {};
    let objectId = {};
    objectId["$in"] = objectIds;
    console.log(objectId);
    where["objectId"] = objectId;
    params["where"] = where;
    const response = await axios.get("/classes/" + collection, {
      baseURL: Urls.parseUrl,
      params,
      headers: {
        "X-Parse-Master-Key": Urls.MASTERKEY,
        "X-Parse-Application-Id": Urls.APPLICATIONID,
      },
    });
    console.log("I was jdwkfenhiluf", response);
    return response;
  };

  static fetchStoresAndOffers = async (
    collection: string,
    objectIds: string[]
  ) => {
    const batchSize = 20;
    const responses = [];
    for (let i = 0; i < objectIds.length; i += batchSize) {
      const batch = objectIds.slice(i, i + batchSize);
      let params = {};
      let where = {};
      let objectId = {};
      objectId["$in"] = batch;
      console.log(objectId);
      where["objectId"] = objectId;
      params["where"] = where;
      params["order"] = "batchOrder";

      const response = await axios.get("/classes/" + collection, {
        baseURL: Urls.parseUrl,
        params,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
        },
      });

      console.log("I was jdwkfenhiluf", response);
      responses.push(...response.data.results);
    }
    console.log("responses", responses);
    return responses;
  };

  static updateDataToDB = async (
    formData,
    stores = [],
    offers = [],
    agreementId: string,
    userEmail,
    isSigning = "false",
    signingStatus = false,
    salesConfirmation = ""
  ) => {
    console.log("fooormData", formData);
    console.log("salesConfirmation", salesConfirmation);

    const signingDate = new Date();

    var merchantBody = qs.stringify({
      salesRep: formData.salesRep,
      agreementType: formData.agreementType,
      merchantName: formData.merchantName,
      logo: formData.logo,
      templateType: formData.templateType,
      extraField: formData.extraField,
      isSigning: isSigning,
      mouType: formData.mouType,
      mouValidity: formData.mouValidity,
      signingStatus: signingStatus,
      country: formData?.country,
      companyType: formData?.companyType,
      emirates: formData?.emirates,
      salesConfirm:
        salesConfirmation === "approve"
          ? "approved"
          : salesConfirmation === "reject"
          ? "rejected"
          : "",
      signedDate: signingStatus && signingDate.toISOString().substring(0, 10),
    });
    console.log("Merchant Body", merchantBody);
    if (!signingStatus) {
      delete merchantBody["signedDate"];
    }
    const merchantConfig = AxiosUtils.axiosConfigConstructor(
      "put",
      Urls.parseUrl + `/classes/merchant/${formData.merchantId}`,
      merchantBody,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );
    var password;

    if (
      formData.authorisedSignatoryMail !=
        formData.authorisedSignatoryMail_confirm &&
      formData.signingStatus == "false"
    ) {
      var userOldData = {
        where: {
          username: formData.authorisedSignatoryMail_confirm.toLowerCase(),
        },
      };
      var userOldConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/users`,
        null,
        null,
        userOldData
      );
      const userOldInformation = await axios(userOldConfig);
      let merchant = userOldInformation.data.results[0].merchant;
      const filteredMerchant = merchant.filter(
        (element) => element !== formData.merchantId
      );
      let updateUserOldData = {
        merchant: filteredMerchant,
      };
      let updateUserOldConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/users/${userOldInformation.data.results[0].objectId}`,
        updateUserOldData,
        {
          "Content-Type": "application/json",
        },
        null
      );
      await axios(updateUserOldConfig);
      var userData = {
        where: {
          username: formData.authorisedSignatoryMail.toLowerCase(),
        },
      };
      password = this.generatePassword();
      var userConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/users`,
        null,
        null,
        userData
      );
      const userInformation = await axios(userConfig);
      console.log(
        "userInformation",
        userInformation.data.results,
        userInformation.data.results.length
      );
      if (userInformation.data.results.length > 0) {
        let updateUserData = {
          merchant: {
            __op: "AddUnique",
            objects: [formData.merchantId],
          },
          password: password,
        };
        let updateUserConfig = AxiosUtils.axiosConfigConstructor(
          "put",
          Urls.parseUrl + `/users/${userInformation.data.results[0].objectId}`,
          updateUserData,
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(updateUserConfig);
      } else {
        let updateUserData = {
          username: formData.authorisedSignatoryMail.toLowerCase(),
          permission: {
            salesRepresentative: false,
            CanSignAgreement: true,
          },
          name: formData.authorisedSignatoryName,
          phoneNo: formData.authorisedSignatoryNo,
          merchant: [formData.merchantId],
          password: password,
        };
        let updateUserConfig = AxiosUtils.axiosConfigConstructor(
          "post",
          Urls.parseUrl + `/users`,
          updateUserData,
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(updateUserConfig);
      }
    }

    const updateMerchantObject = await axios(merchantConfig);
    console.log("categoryOfMerchant", formData.categoryOfMerchant);
    var brandBody = {
      brandType: formData.brandType,
      brandName: formData.brandName,
      registeredAddress: formData.registeredAddress,
      categoryOfMerchant: formData.categoryOfMerchant,
      registeredAddressCountry: formData.registeredAddressCountry,
      corporateAddressCountry: formData.corporateAddressCountry,
      registeredAddressPincode: formData.registeredAddressPincode,
      corporateAddressPincode: formData.corporateAddressPincode,
      corporateAddress: formData.corporateAddress,
      spocName: formData.spocName,
      registeredAddressState: formData?.registeredAddressState,
      registeredAddressCity: formData?.registeredAddressCity,
      corporateAddressCity: formData?.corporateAddressCity,
      corporateAddressState: formData?.corporateAddressState,
      spocNo: formData.spocNo,
      spocEmail: formData.spocEmail,
      authorisedSignatoryName: formData.authorisedSignatoryName,
      logo: formData.logo,
      authorisedSignatoryNo: formData.authorisedSignatoryNo,
      designation: formData.designation,
      authorisedSignatoryMail: formData.authorisedSignatoryMail,
      merchant: {
        id: formData.merchantId,
        name: formData.merchantName,
      },
    };

    const brandConfig = AxiosUtils.axiosConfigConstructor(
      "put",
      Urls.parseUrl + `/classes/brand/${formData.brandId}`,
      brandBody,
      {
        "Content-Type": "application/json",
      }
    );

    const updateBrand = await axios(brandConfig);
    let updatedStoresWithBrandDetails = [];
    console.log("Stores : ", stores);
    stores.forEach((store) => {
      const oldStore = {
        objectId: store?.objectId,
        storeId: store.storeId,
        store_name: store.store_name,
        store_address: store.store_address,
        gst_no: store.gst_no,
        category: store.category,
        subcategory: store.subcategory,
        amenities: store.amenities,
        contact_name: store.contact_name,
        contact_no: store.contact_no,
        shift_number: store.shift_number,
        menu: store.menu,
        panNumber: store.pan_number,
        zomato_rating: store.zomato_rating,
        interior_images: store.interior_images,
        locality: store.locality,
        shift_email: store.shift_email,
        tidANDMID: store.tidANDMID,
        branch_name: store.branch_name,
        upload_carges_slip: store.upload_carges_slip,
        brandName: formData.brandName,
        cuisines: store.cuisines,
        reservation_email: store.reservation_email,
        registration_no: store.registration_no,
        rating_no: String(store.rating_no),
        rating_platform: store.rating_platform,
        meal_for_two: store.meal_for_two,
        mode_of_payment: store.mode_of_payment,
        delivery_option: store.delivery_option,
        bank_name: store.bank_name,
        store_state: store.store_state,
        bank_account_number: store.bank_account_number,
        merchantName_written: store.merchantName_written,
        ifsc_code: store.ifsc_code,
        upload_cancelled_cheque: store.upload_cancelled_cheque,
        commission_to_thriwe: store.commission_to_thriwe,
        remarks_store: store.remarks_store,
        payout: store.payout,
        start_validity_voucher: store.start_validity_voucher,
        end_validity_voucher: store.end_validity_voucher,
        store_city: store.store_city,
        store_country: store.store_country,
        store_pincode: store.store_pincode,
        pan_certificate: store.pan_certificate,
        gst_certificate: store.gst_certificate,
        trade_license_number: store.trade_license_number,
        trade_license_certificate: store.trade_license_certificate,
        vat_number: store.vat_number,
        vat_certificate: store.vat_certificate,
      };
      console.log("old Stores", oldStore);
      if (!oldStore.objectId) {
        oldStore["brand"] = {
          id: formData.brandId,
          name: formData.brandName,
        };
      }
      updatedStoresWithBrandDetails.push(oldStore);
    });
    console.log(
      "updatedStoresWithBrandDetails : ",
      updatedStoresWithBrandDetails
    );
    let updateStores = [];
    let createStores = [];
    for (let i = 0; i < updatedStoresWithBrandDetails.length; i++) {
      if (updatedStoresWithBrandDetails[i].objectId) {
        updateStores.push(updatedStoresWithBrandDetails[i]);
      } else {
        createStores.push(updatedStoresWithBrandDetails[i]);
      }
    }
    console.log("Created STores", createStores);
    console.log("Update Stores", updateStores);
    console.log(stores);
    const createStoreResponse = await this.createBatch(
      "post",
      "stores",
      createStores
    );
    // const updateStoreResponse = await this.createBatch('put', 'stores', updateStores);
    for (let i = 0; i < updateStores.length; i++) {
      var config: any = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/stores/${updateStores[i].objectId}`,
        updateStores[i],
        {
          "Content-Type": "application/json",
        },
        null
      );
      const updateStoreResponse = await axios(config);
    }
    updatedStoresWithBrandDetails = [];
    let storesId = [];
    for (let i = 0; i < createStores.length; i++) {
      storesId.push(createStoreResponse[i].success.objectId);
      createStores[i].objectId = createStoreResponse[i].success.objectId;
      updatedStoresWithBrandDetails.push(createStores[i]);
    }
    for (let i = 0; i < updateStores.length; i++) {
      storesId.push(updateStores[i].objectId);
      updatedStoresWithBrandDetails.push(updateStores[i]);
    }
    let updatedOffersWithStoreDetails = [];
    console.log(
      "Updated Stores With Brand Details 1",
      updatedStoresWithBrandDetails
    );
    for (let i = 0; i < offers.length; i++) {
      let linkStore = offers[i].linkStore;
      console.log("linkStore", linkStore);
      let findIdByLinkStores = [];
      let storesId = [];
      for (let j = 0; j < linkStore.length; j++) {
        for (let k = 0; k < updatedStoresWithBrandDetails.length; k++) {
          if (
            linkStore[j].storeId === updatedStoresWithBrandDetails[k].storeId
          ) {
            findIdByLinkStores.push({
              storeId: updatedStoresWithBrandDetails[k].storeId,
              id: updatedStoresWithBrandDetails[k].objectId,
              store_name: updatedStoresWithBrandDetails[k].store_name,
            });
            storesId.push({
              __type: "Pointer",
              className: "",
              objectId: updatedStoresWithBrandDetails[k].objectId,
            });
          }
        }
      }
      updatedOffersWithStoreDetails.push({
        objectId: offers[i]?.objectId,
        offer_text: offers[i].offer_text,
        stores: storesId,
        program: offers[i].program,
        linkStore: findIdByLinkStores,
        termOfUse: offers[i].termOfUse,
        redemption: offers[i].redemption,
        offer_validity_startDate: offers[i].offer_validity_startDate,
        offer_validity_endDate: offers[i].offer_validity_endDate,
        remarks: offers[i].remarks,
        offerExclusion: offers[i].offerExclusion,
      });
    }
    let createOffers = [];
    let updateOffers = [];
    let offersId = [];
    for (let i = 0; i < updatedOffersWithStoreDetails.length; i++) {
      if (updatedOffersWithStoreDetails[i].objectId) {
        offersId.push(updatedOffersWithStoreDetails[i].objectId);
        updateOffers.push(updatedOffersWithStoreDetails[i]);
      } else {
        createOffers.push(updatedOffersWithStoreDetails[i]);
      }
    }

    const createOfferResponse = await this.createBatch(
      "post",
      "offers",
      createOffers
    );
    // const updateOfferResponse = await this.createBatch('put', 'offers', updateOffers);
    for (let i = 0; i < updateOffers.length; i++) {
      var offerConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/offers/${updateOffers[i].objectId}`,
        updateOffers[i],
        {
          "Content-Type": "application/json",
        },
        null
      );
      const updateStoreResponse = await axios(offerConfig);
    }
    for (let i = 0; i < createOfferResponse.length; i++) {
      offersId.push(createOfferResponse[i].success.objectId);
    }
    let storePointerObjectId = [];
    for (let i = 0; i < storesId.length; i++) {
      const obj = {
        __type: "Pointer",
        className: "stores",
        objectId: storesId[i],
      };
      console.log("obj", obj);
      storePointerObjectId.push(obj);
    }
    console.log(storePointerObjectId);
    let offerPointerObjectId = [];
    for (let i = 0; i < offersId.length; i++) {
      offerPointerObjectId.push({
        __type: "Pointer",
        className: "offers",
        objectId: offersId[i],
      });
    }
    console.log(offerPointerObjectId);
    const agreementBody = {
      merchant: {
        id: formData.merchantId,
        name: formData.merchantName,
      },
      brand: {
        id: formData.brandId,
        name: formData.brandName,
      },
      stores: storesId,
      offers: offersId,
      upload_signed_agreement: formData.upload_signed_agreement,
      salesRep: formData.salesRep,
      merchantPointer: {
        __type: "Pointer",
        className: "merchant",
        objectId: formData.merchantId,
      },
      brandPointer: {
        __type: "Pointer",
        className: "brand",
        objectId: formData.brandId,
      },
      storePointer: storePointerObjectId,
      offersPointer: offerPointerObjectId,
      signingStatus: signingStatus,
    };
    console.log(agreementBody);
    var agreementConfig = AxiosUtils.axiosConfigConstructor(
      "put",
      Urls.parseUrl + `/classes/agreement/${agreementId}`,
      agreementBody,
      {
        "Content-Type": "application/json",
      },
      null
    );
    const agreement = await axios(agreementConfig);
    console.log(
      "Mail Change",
      formData.authorisedSignatoryMail ===
        formData.authorisedSignatoryMail_confirm,
      formData.authorisedSignatoryMail,
      formData.authorisedSignatoryMail_confirm
    );
    console.log("salesConfirmation", salesConfirmation);
    var mailData: Object;
    if (
      formData.authorisedSignatoryMail ===
      formData.authorisedSignatoryMail_confirm
    ) {
      mailData = {
        noremail: userEmail.toLowerCase(),
        authEmail: formData.authorisedSignatoryMail.toLowerCase(),
        spocEmail: formData.spocEmail.toLowerCase(),
        isSigning: isSigning,
        brandName: formData.brandName,
        merchantName: formData.merchantName,
        authName: formData.authorisedSignatoryName,
        agreement: formData?.upload_signed_agreement,
      };
    } else {
      mailData = {
        noremail: userEmail.toLowerCase(),
        authEmail: formData.authorisedSignatoryMail.toLowerCase(),
        spocEmail: formData.spocEmail.toLowerCase(),
        isSigning: isSigning,
        password: password,
        mailChange: "Yes",
        agreement: formData?.upload_signed_agreement,
        brandName: formData.brandName,
        merchantName: formData.merchantName,
        authName: formData.authorisedSignatoryName,
      };
    }

    var mailConfig = {
      method: "post",
      url: Urls.lambdaUrl,
      headers: {
        "Content-Type": "application/json",
        Origin: "*",
      },
      data: mailData,
    };
    if (salesConfirmation !== "approve" && salesConfirmation !== "reject") {
      await axios(mailConfig);
    }
    localStorage.removeItem("merchant");
    localStorage.removeItem("brand");
    localStorage.removeItem("stores");
    localStorage.removeItem("offers");
  };

  static login = async ({ username, password }) => {
    const data = {
      username,
      password,
    };

    const config = AxiosUtils.axiosConfigConstructor(
      "post",
      Urls.parseUrl + "/login",
      data
    );

    const response = await axios(config);
    return response.data;
  };

  static sendPdfToServer = async (pdfBlob) => {
    try {
      const options = {
        margin: 10,
        filename: "converted.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      console.log("Options", options);
      let randomString = (Math.random() + 1).toString(36).substring(7);

      var config = {
        method: "post",
        url: `${Urls.parseUrl}/files/${randomString}`,
        headers: {
          "X-Parse-Master-Key": Urls.MASTERKEY,
          "X-Parse-Application-Id": Urls.APPLICATIONID,
          "Content-Type": "application/pdf",
        },
        data: pdfBlob,
      };
      const result = await axios(config);
      console.log("Url", result.data.url);
      return result.data;
    } catch (error) {
      console.error("Error sending PDF to server:", error);
    }
  };

  static unlockVerifier = async (
    agreementId: string,
    userId: string,
    merchantId: string
  ) => {
    try {
      const updateAgreementQuery = {
        verifiersPointer: {
          __op: "Delete",
        },
      };
      const updateAgreementConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/agreement/${agreementId}`,
        updateAgreementQuery,
        {
          "Content-Type": "application/json",
        },
        null
      );
      const getVerifierQuery = {
        where: {
          userId: userId,
        },
      };
      const getVerifier = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/verifier`,
        null,
        {
          "Content-Type": "application/json",
        },
        getVerifierQuery
      );
      await axios(updateAgreementConfig);
      const verifierData = await axios(getVerifier);
      const merchant: Array<any> = verifierData.data.results[0].merchant;
      const verifierId = verifierData.data.results[0].objectId;
      console.log("merchant", merchant);
      const newMerchantArray = merchant.filter(
        (merchant) => merchant !== merchantId
      );
      console.log("newMerchantArray", newMerchantArray);
      const updateVerifierQuery = {
        merchant: newMerchantArray,
      };
      const updateVerifier = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/verifier/${verifierId}`,
        updateVerifierQuery,
        {
          "Content-Type": "application/json",
        },
        null
      );
      await axios(updateVerifier);
      return "unlock verifier successfully";
    } catch (error) {
      return error.message;
    }
  };

  static addLogs = async (
    collectionName: string,
    collectionId: string,
    fieldLogs: any,
    storeName?: "",
    offersName?: "",
    remarks?: string,
    reason?: string
  ) => {
    console.log("Store Name", storeName);
    const collectionQuery = {
      where: {
        collectionId,
      },
    };
    const configCollection = AxiosUtils.axiosConfigConstructor(
      "get",
      Urls.parseUrl + `/classes/logs`,
      null,
      {
        "Content-Type": "application/json",
      },
      collectionQuery
    );
    const getLogsForThisCollection = await axios(configCollection);

    console.log("getLogsForThisCollection", getLogsForThisCollection);

    const currentTime = new Date(); // Get the current timestamp
    const { name } = JSON.parse(localStorage.getItem("user"));

    if (getLogsForThisCollection.data.results.length > 0) {
      if (remarks || reason) {
        const configUpdateCollection = AxiosUtils.axiosConfigConstructor(
          "put",
          Urls.parseUrl +
            `/classes/logs/${getLogsForThisCollection.data.results[0].objectId}`,
          { agreementRemarks: remarks, reason },
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(configUpdateCollection);
      } else {
        const logs: any = {};
        fieldLogs.forEach((fieldLog: any) => {
          const { fieldName, oldValue, newValue } = fieldLog;
          const existingLogs =
            getLogsForThisCollection.data.results[0][fieldName] || [];
          // Add the  new values with timestamps to the existing array
          if (existingLogs.length > 0)
            existingLogs.push({
              value: newValue,
              time: `${currentTime.getUTCDate()}/${
                currentTime.getUTCMonth() + 1
              }/${currentTime.getUTCFullYear()}`,
              name: name,
            });
          else
            existingLogs.push(
              { value: oldValue, time: currentTime },
              {
                value: newValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
                name: name,
              }
            );
          logs[fieldName] = existingLogs;
        });
        const configUpdateCollection = AxiosUtils.axiosConfigConstructor(
          "put",
          Urls.parseUrl +
            `/classes/logs/${getLogsForThisCollection.data.results[0].objectId}`,
          logs,
          {
            "Content-Type": "application/json",
          },
          null
        );
        await axios(configUpdateCollection);
      }
    } else {
      const logs: any = {};
      if (fieldLogs?.length > 0) {
        fieldLogs?.forEach((fieldLog: any) => {
          const { fieldName, oldValue, newValue } = fieldLog;
          // Create an array if it doesn't exist for the current fieldName
          if (!logs[fieldName]) {
            logs[fieldName] = [];
          }
          // Add the old and new values with timestamps to the array
          if (storeName) {
            logs[fieldName].push(
              {
                value: oldValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
              },
              {
                value: newValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
                name: name,
              }
            );
            logs["storeName"] = storeName;
          } else if (offersName) {
            logs[fieldName].push(
              {
                value: oldValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
              },
              {
                value: newValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
                name: name,
              }
            );
            logs["offerName"] = offersName;
          } else {
            logs[fieldName].push(
              {
                value: oldValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
              },
              {
                value: newValue,
                time: `${currentTime.getUTCDate()}/${
                  currentTime.getUTCMonth() + 1
                }/${currentTime.getUTCFullYear()}`,
                name: name,
              }
            );
          }
        });
      }
      logs.agreementRemarks = remarks;
      logs.reason = reason;
      logs.collectionName = collectionName;
      logs.collectionId = collectionId;
      const configCreateCollection = AxiosUtils.axiosConfigConstructor(
        "post",
        Urls.parseUrl + `/classes/logs`,
        logs,
        {
          "Content-Type": "application/json",
        },
        null
      );
      await axios(configCreateCollection);
    }
  };

  static getViewLogs = async (
    merchantId: string,
    brandId: string,
    storesId: Array<string>,
    offersId: Array<string>,
    agreementId: string
  ) => {
    console.log("merchantId", merchantId);
    console.log("brandId", brandId);
    console.log("storesId", storesId);
    console.log("offersId", offersId);
    try {
      // merchant logs
      const merchantLogQuery = {
        where: {
          collectionId: merchantId,
        },
      };
      const merchantLogConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        merchantLogQuery
      );

      // brand logs
      const brandLogQuery = {
        where: {
          collectionId: brandId,
        },
      };
      const brandLogConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        brandLogQuery
      );

      // store logs
      const storesIdsObject = [];
      storesId.forEach((id: string) => {
        storesIdsObject.push({ collectionId: id });
      });
      const storesLogQuery = {
        where: {
          $or: storesIdsObject,
        },
      };
      const storesLogsConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        storesLogQuery
      );

      // offers logs
      const offersIdsObject = [];
      offersId.forEach((id: string) => {
        offersIdsObject.push({ collectionId: id });
      });
      const offersLogQuery = {
        where: {
          $or: offersIdsObject,
        },
      };
      const offersLogsConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        offersLogQuery
      );

      // agreement logs
      const agreementLogQuery = {
        where: {
          collectionId: agreementId,
        },
      };
      const agreementLogConfig = AxiosUtils.axiosConfigConstructor(
        "get",
        Urls.parseUrl + `/classes/logs`,
        null,
        {
          "Content-Type": "application/json",
        },
        agreementLogQuery
      );

      const merchantLogsResult = await axios(merchantLogConfig);
      const merchantLogsList = merchantLogsResult.data.results;

      const brandLogsResult = await axios(brandLogConfig);
      const brandLogsList = brandLogsResult.data.results;

      const storeLogsResult = await axios(storesLogsConfig);
      const storeLogsList = storeLogsResult.data.results;

      const offerLogsResult = await axios(offersLogsConfig);
      const offersLogsList = offerLogsResult.data.results;

      const agreementLogsResult = await axios(agreementLogConfig);
      const agreementLogsList = agreementLogsResult.data.results;
      if (
        merchantLogsList.length === 0 &&
        brandLogsList.length === 0 &&
        storeLogsList.length === 0 &&
        offersLogsList.length === 0 &&
        agreementLogsList.length === 0
      ) {
        return "No Logs Found";
      }
      return {
        merchantLogsList,
        brandLogsList,
        storeLogsList,
        offersLogsList,
        agreementLogsList,
      };
    } catch (error) {
      // setLoadingLogs(false);
      return "Error Occurred";
    }
  };
}
