import { Formik, Form } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import { FaGreaterThan } from "react-icons/fa";
import Text from "../../../components/Text";
import { useAppContext } from "../../../context/app-context";
import { useEffect, useState } from "react";
import FormikSelect from "../../../components/FormikSelect";
import { useUserContext } from "../../../context/user-context";
import FormikSelectValidation from "../../../components/FormikSelectValidation";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";
import LoadingButton from "../../../components/LoadingButton";
import { useNavigate } from "react-router-dom";
import ServerUploadsUtils from "../../../utils/serverUploads";

import "./style.css";

const fieldNames = [
  "salesRep",
  "merchantName",
  "agreementType",
  "mouType",
  "mouValidity",
  "country",
  "companyType",
  "emirates",
  "poBox",
];

export default function AddMerchant() {
  const merchantContext = useMerchantContext();
  const { setFormData, addPageIndex } = merchantContext;
  const { formData } = merchantContext.state;
  const [uploadingFile] = useState(false);
  const [salesManager, setSalesManager] = useState(false);
  const [updateForLoading, setUpdateForLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [initialValues, setInitialValues] = useState({
    salesRep: "",
    merchantName: "",
    agreementType: "",
    mouType: "",
    mouValidity: "",
    country: "",
    companyType: "",
    emirates: "",
    poBox: "",
  });

  const context = useAppContext();
  const userContext = useUserContext();
  const { formData: updateFormData, offers, isEditing, stores } = context.state;

  const navigate = useNavigate();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(updateFormData)) {
      const merchant = JSON.parse(localStorage.getItem("merchant"));
      if (merchant) {
        setCountry(merchant?.country);
        setInitialValues({
          salesRep: userContext?.state?.user?.name,
          merchantName: merchant?.merchantName,
          agreementType: merchant?.agreementType,
          mouType: merchant?.mouType,
          mouValidity: merchant?.mouValidity,
          country: merchant?.country,
          companyType: merchant?.companyType,
          emirates: merchant?.emirates,
          poBox: merchant?.poBox,
        });
      } else {
        setInitialValues({
          salesRep: userContext?.state?.user?.name,
          merchantName: "",
          agreementType: "",
          mouType: "",
          mouValidity: "",
          country: "",
          companyType: "",
          emirates: "",
          poBox: "",
        });
      }
    } else {
      setCountry(updateFormData.country);
      setInitialValues({
        salesRep: updateFormData.salesRep,
        merchantName: updateFormData.merchantName,
        agreementType: updateFormData.agreementType,
        mouType: updateFormData.mouType,
        mouValidity: updateFormData.mouValidity,
        country: updateFormData.country,
        companyType: updateFormData.companyType,
        emirates: updateFormData.emirates,
        poBox: updateFormData.poBox,
      });
    }
  }, [updateFormData]);

  const areEqual = (value1: any, value2: any) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateMerchant = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = formData[fieldName];
      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const merchantConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/merchant/${formData.merchantId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        },
        null
      );
      await axios(merchantConfig);
      await ServerUploadsUtils.addLogs(
        "merchant",
        formData?.merchantId,
        fieldLogs
      );
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {" "}
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values: any) => {
            console.log("formData Yaar", formData);
            if (!isEditing)
              localStorage.setItem("merchant", JSON.stringify(values));

            if (salesManager && formData.verificationStatus !== "approved") {
              values.mouValidity = String(values?.mouValidity);
              await updateMerchant(values);
            }
            setFormData({ ...formData, ...values });
            // alert(JSON.stringify(values));
            addPageIndex();
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingTop: "35px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  {isEditing ? "Edit Merchant" : "Add Merchant"}
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormikSelectValidation
                    label="Country"
                    name="country"
                    options={["India", "UAE", "Saudi"]}
                    selectedValued={setCountry}
                  />
                  <FormikInput
                    label="Sales Rep"
                    type="text"
                    name="salesRep"
                    placeholder="Sales Rep"
                  />

                  <FormikSelect
                    label="Agreement Type"
                    name="agreementType"
                    options={["Fresh", "Renewal", "Updation"]}
                  />
                  <FormikInput
                    label="Legal name"
                    type="text"
                    name="merchantName"
                    placeholder="ABC Private Limited"
                    disabled={salesManager}
                  />
                  {(country === "UAE" || country === "Saudi") && (
                    <FormikInput
                      label="P.O Box"
                      type="text"
                      name="poBox"
                      placeholder=""
                    />
                  )}
                  <FormikSelect
                    label="Mou Type"
                    name="mouType"
                    options={
                      country === "UAE"
                        ? ["Voucher", "Offer", "Both"]
                        : ["Voucher", "Offer"]
                    }
                  />

                  {(country === "UAE" || country === "Saudi") && (
                    <>
                      <FormikInput
                        label="Company Type"
                        type="text"
                        name="companyType"
                        placeholder="ABC Private Limited"
                      />
                      <FormikInput
                        label="Emirates"
                        type="text"
                        name="emirates"
                        placeholder="ABC Private Limited"
                      />
                    </>
                  )}
                  <FormikInput
                    label="MOU Validity"
                    type="number"
                    name="mouValidity"
                    placeholder="12"
                  />

                  {values?.mouValidity && (
                    <p>MOU Validity: {values.mouValidity} months</p>
                  )}

                  {salesManager && (
                    <div className="center-button">
                      <a
                        className="download-link"
                        href={formData.upload_signed_agreement}
                        target="_blank"
                        rel="noopener noreferrer"
                        download // This attribute triggers the download behavior
                      >
                        Download MOU
                      </a>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                      gap: "10px",
                    }}
                  >
                    <button
                      style={{
                        color: "white",
                        borderRadius: "5px",
                        border: "1px solid #405089",
                        background: uploadingFile ? "grey" : "rgb(64,81,167)",
                        outlineColor: "navajowhite",
                        fontSize: "13px",
                        width: "70px",
                        height: "35px",
                      }}
                      disabled={uploadingFile}
                      onClick={() => {
                        console.log("Hii I am Here");
                      }}
                    >
                      Next
                    </button>
                    {salesManager && (
                      <LoadingButton
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          // width: "1px",
                          height: "35px",
                        }}
                        text={
                          formData.verificationStatus !== "approved"
                            ? "Save and Exit"
                            : "Exit"
                        }
                        type="button"
                        onClick={async () => {
                          setUpdateForLoading(true);
                          if (formData.verificationStatus !== "approved") {
                            await updateMerchant(values);
                            const { objectId } = JSON.parse(
                              localStorage.getItem("user")
                            );
                            await ServerUploadsUtils.unlockVerifier(
                              formData.agreementId,
                              objectId,
                              formData.merchantId
                            );
                          }
                          setUpdateForLoading(false);
                          navigate("/");
                        }}
                        isLoading={updateForLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
