import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { ImArrowLeft2 } from "react-icons/im";
import Text from "../../../components/Text";
import { string } from "yup";
import { validationSchema } from "./validationSchema";
import FormikInput from "../../../components/FormikInput";
import Multiselect from "../../../components/Multiselect";
import FormikSelect from "../../../components/FormikSelect";
import { useMerchantContext } from "../../../context/merchant-context";
import _, { update, values } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAppContext } from "../../../context/app-context";
import GoBackBtn from "../../../components/GoBackBtn";
import MultiSelectAll from "./MultiSelectAll";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";
import LoadingButton from "../../../components/LoadingButton";
import { useNavigate } from "react-router-dom";
import ServerUploadsUtils from "../../../utils/serverUploads";

const programArray = [
  "Hyperlocal - Axis Bank",
  "Hyperlocal - Bandhan Bank",
  "Hyperlocal - Citibank",
  "Hyperlocal - Deutsche Bank",
  "Hyperlocal - Federal Bank",
  "Hyperlocal - HDFC Bank",
  "Hyperlocal - HSBC",
  "Hyperlocal - IDFC First Bank",
  "Hyperlocal - Indusind Bank",
  "Hyperlocal - Kotak Mahindra Bank",
  "Hyperlocal - Rupay - NPCI",
  "Hyperlocal - SBI Cards",
  "Hyperlocal - Standard Chartered Bank",
  "Hyperlocal - Yes Bank",
  "Hyperlocal - Mastercard",
  "Hyperlocal - Visa",
  "Hyperlocal - American Express",
  "Thriwe Care",
  "Dining Delights - Axis Bank",
  "Dining - Bandhan Bank",
  "Dining - Citibank - Gourmet Privileges",
  "Dining - Deutsche Bank",
  "Dining Federal Bank - Feddelights",
  "Dining - HDFC Bank - The Good Food Trail",
  "Dining - HSBC - Simply Indulge",
  "Dining - IDFC First Bank",
  "Dining - Indusind Bank - Indusmoment Dining",
  "Dining - Kotak Mahindra Bank",
  "Dining - Rupay - NPCI",
  "Dining - SBI Cards",
  "Dining - Standard Chartered Bank - The Good Life",
  "Dining - Yes Bank",
  "Dining - Mastercard",
  "Dining - Visa",
  "Dining - American Express - Dining Selects/Resy",
  "EIB",
  "DIB",
  "ENBD",
  "Visa",
  "Saudi National Bank",
  "Al Rajhi Bank",
  "Saudi Franzi Bank",
  "Arab National Bank",
  "Riyadh Bank",
  "Mastercard",
  "Saudi Awwal Bank",
  "Thriwe Application",
  "Dining - Amex IPL",
  "Dining-Rupay IPL",
  "Dining-IDFC Dining",
  "HDFC Emi instalment",
];

const initialValuesObj: any = {
  offer_text: "",
  program: "",
  linkStore: "",
  termOfUse: { id: -1, name: "", value: "" },
  redemption: { id: -1, name: "", value: "" },
  offer_validity_startDate: "",
  offer_validity_endDate: "",
  remarks: "",
  offerExclusion: { id: -1, name: "", value: "" },
};

const fieldNames = [
  "offer_text",
  "program",
  "linkStore",
  "termOfUse",
  "redemption",
  "offer_validity_startDate",
  "offer_validity_endDate",
  "remarks",
  "offerExclusion",
];

const offerExclusion = [
  {
    id: 1,
    name: "No two offers can be clubbed",
    value: "No two offers can be clubbed",
  },
];

const termsOfUse = [
  {
    id: 1,
    name: "The cardholder has to present his valid Credit/Debit Card at the restaurant and state the intention to use the offer before asking for the bill.",
    value:
      "The cardholder has to present his valid Credit/Debit Card at the restaurant and state the intention to use the offer before asking for the bill.",
  },
  {
    id: 2,
    name: "To avail the Offer, the customer has to inform the restaurant/ merchant about his / her choice of payment through appropriate Credit / Debit Card before the bill is generated.",
    value:
      "To avail the Offer, the customer has to inform the restaurant/ merchant about his / her choice of payment through appropriate Credit / Debit Card before the bill is generated.",
  },
  {
    id: 3,
    name: "The offer cannot be clubbed with any other offer.",
    value: "The offer cannot be clubbed with any other offer.",
  },
  {
    id: 4,
    name: "The benefits as mentioned herein above cannot be used or redeemed in combination with any other discounts or promotions or vouchers or special events and other in-house specials, and is also not valid on the Blackout Dates (24th Dec, 25th Dec, 31st Dec, 14th Feb, Durga Puja Holidays & Public Holidays), unless otherwise stated by the respective partner.",
    value:
      "The benefits as mentioned herein above cannot be used or redeemed in combination with any other discounts or promotions or vouchers or special events and other in-house specials, and is also not valid on the Blackout Dates (24th Dec, 25th Dec, 31st Dec, 14th Feb, Durga Puja Holidays & Public Holidays), unless otherwise stated by the respective partner.",
  },
  {
    id: 5,
    name: "The benefits as mentioned herein above cannot be used or redeemed in combination with any other discounts or promotions or vouchers or special events and other in-house specials, and is also not valid on the Blackout Dates (24th Dec, 25th Dec, 31st Dec, 14th Feb, Durga Puja Holidays & Public Holidays), unless otherwise stated by the respective partner.",
    value:
      "The benefits as mentioned herein above cannot be used or redeemed in combination with any other discounts or promotions or vouchers or special events and other in-house specials, and is also not valid on the Blackout Dates (24th Dec, 25th Dec, 31st Dec, 14th Feb, Durga Puja Holidays & Public Holidays), unless otherwise stated by the respective partner.",
  },
  {
    id: 6,
    name: "The benefits mentioned above are subject to change/withdrawal without prior notice.",
    value:
      "The benefits mentioned above are subject to change/withdrawal without prior notice.",
  },
  {
    id: 7,
    name: "Offers shall be valid only against the appropriate Credit/Debit Card type.",
    value:
      "Offers shall be valid only against the appropriate Credit/Debit Card type.						",
  },
  {
    id: 7,
    name: "The offer shall be available on a la carte only.",
    value: "The offer shall be available on a la carte only.",
  },
  {
    id: 8,
    name: "The waiter/ cashier needs to be informed of the offer before the bill is generated.",
    value:
      "The waiter/ cashier needs to be informed of the offer before the bill is generated.",
  },
  {
    id: 9,
    name: "No two offers can be clubbed together.",
    value: "No two offers can be clubbed together.",
  },
  {
    id: 10,
    name: "Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.	Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.",
    value:
      "Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.Discount will be given to the card holder only when the customer asks/informs the server for it at the time of billing and presents his valid card. Restaurants/ merchant are not obliged to give the discount to every card holder who visits the outlet unless they explicitly ask for the discount.",
  },
  {
    id: 11,
    name: "Offer not valid on group bookings (10 pax and above).",
    value: "Offer not valid on group bookings (10 pax and above).",
  },
  {
    id: 12,
    name: "The discount is valid on only the net amount (excluding taxes or any other charges).",
    value:
      "The discount is valid on only the net amount (excluding taxes or any other charges).",
  },
  {
    id: 13,
    name: "Any applicable taxes have to be paid by the card holder.",
    value: "Any applicable taxes have to be paid by the card holder.",
  },
  {
    id: 14,
    name: "The offers are ongoing till the specified validity date mentioned under each listed outlet.",
    value:
      "The offers are ongoing till the specified validity date mentioned under each listed outlet.",
  },
  {
    id: 15,
    name: "Individual Restaurant/ Outlet Terms & Conditions apply.",
    value: "Individual Restaurant/ Outlet Terms & Conditions apply.",
  },
  {
    id: 16,
    name: "The minimum billing amount will be effective exclusive of taxes.",
    value: "The minimum billing amount will be effective exclusive of taxes.",
  },
];
const redemptionProcess = [
  {
    id: 1,
    name: "Visit the partnered outlet and ask for card discount (Partnered list can be viewed on bank website).",
    value:
      "Visit the partnered outlet and ask for card discount (Partnered list can be viewed on bank website).",
  },
  {
    id: 2,
    name: "Post bill preparation share the eligible card for payment.",
    value: "Post bill preparation share the eligible card for payment.",
  },
];

const programArrayDubai = ["Emirates NBD", "Emirati and AUH", "Entertainer"];

export default function AddOffers() {
  const [, refreshComponent] = useState(false);
  const [state, setSelectedState] = useState([]);
  const [programState, setProgramState] = useState([]);
  const [salesManager, setSalesManager] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesObj);
  const [check, setCheck] = useState(false);
  const [updateForLoading, setUpdateForLoading] = useState(false);

  const merchantContext = useMerchantContext();
  const { selectedStores } = merchantContext.state;
  const {
    updateSelectedStores,
    subtractPageIndex,
    updateSelectedTableOffer,
    setCustomPageIndex,
  } = merchantContext;
  const context = useAppContext();
  const { stores } = context.state;

  const navigate = useNavigate();

  useEffect(() => {
    const tempArr = [...selectedStores];
    for (let i = 0; i < tempArr.length; i++) {
      if (!tempArr[i]?.storeId) {
        tempArr[i].storeId = uuidv4();
      }
    }
    console.log("TempArr :   ", tempArr);
    updateSelectedStores(tempArr);
    // refreshComponent(!refresh);
    updateSelectedOffer();
  }, []);

  const updateSelectedOffer = () => {
    const selectedTableOffer2: any = { ...selectedTableOffer };
    selectedTableOffer2?.original?.linkStore?.forEach(
      (linkStoreValue, index) => {
        stores.forEach((store) => {
          if (store.storeId == linkStoreValue.storeId) {
            console.log("STORE ID", store.storeId);
            console.log("LINKTORE ID", linkStoreValue.storeId);
            selectedTableOffer2.original.linkStore[index] = { ...store };
          }
        });
      }
    );
    updateSelectedTableOffer(selectedTableOffer2);
    console.log("SELECTED TABLE OFFER 2", selectedTableOffer2);
    // updateSelectedOffer();
  };

  const { addToOffer, setCustomOffers } = context;
  const { offers, isEditing } = context.state;
  const { addPageIndex, updateSelectedOffersRows } = merchantContext;
  const { formData, selectedOffersRowIds } = merchantContext.state;
  const { updateFormData: setMasterFormData } = context;
  const { formData: updateFormData } = context.state;
  const {
    state: { isAddingOffer, selectedTableOffer },
    setIsAddingOffer,
  } = useMerchantContext();

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  useEffect(() => {
    let updateFormData: any = {};
    if (offers.length > 0) {
      updateFormData = selectedTableOffer?.original;
    } else {
      updateFormData = {};
    }

    console.log("updateFormData", updateFormData);

    if (isEmpty(updateFormData) || isAddingOffer) {
      setInitialValues(initialValuesObj);
    } else {
      setInitialValues({
        offer_text: updateFormData?.offer_text,
        program: updateFormData?.program,
        linkStore: updateFormData?.linkStore,
        termOfUse: updateFormData?.termOfUse,
        redemption: updateFormData?.redemption,
        offer_validity_startDate: updateFormData?.offer_validity_startDate,
        offer_validity_endDate: updateFormData?.offer_validity_endDate,
        remarks: updateFormData?.remarks,
        offerExclusion: updateFormData?.offerExclusion,
      });
    }
  }, [offers, selectedTableOffer]);

  useEffect(() => {
    if (Array.isArray(initialValues?.linkStore)) {
      setSelectedState(
        initialValues?.linkStore?.map((store) => {
          return {
            label: store?.store_name,
            value: store,
          };
        })
      );
    }

    if (Array.isArray(initialValues?.program)) {
      setProgramState(
        initialValues?.program?.map((program) => {
          return {
            label: program,
            value: program,
          };
        })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  const areEqual = (value1, value2) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateOffer = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const offerData = selectedTableOffer?.original;
    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = offerData[fieldName];
      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const brandConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/offers/${offerData.objectId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        }
      );
      await axios(brandConfig);
      await ServerUploadsUtils.addLogs(
        "offers",
        offerData.objectId,
        fieldLogs,
        "",
        offerData?.offer_text
      );
    }
  };

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isAddingOffer ? "Add Offer" : "Edit Offer"}
          </Text>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              formData?.mouType === "Offer" ? validationSchema : ""
            }
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log(values);
              values.linkStore = state.map((store) => store?.value);
              values.program = programState.map((program) => program?.value);
              if (
                (values.linkStore.length < 1 && formData.mouType == "Offer") ||
                (values.program.length < 1 && formData.mouType == "Offer")
              ) {
                setCheck(true);
                return;
              }
              console.log("LINK STORE VALUES", values.linkStore);
              console.log("Program STORE VALUES", values.program);

              let myLength = offers.length;

              if (isAddingOffer) {
                myLength = myLength + 1;
                const newObj = { ...selectedTableOffer };
                newObj.original = {
                  ...selectedTableOffer?.original,
                  ...values,
                };
                // alert(JSON.stringify(newObj));
                updateSelectedTableOffer(newObj);
                addToOffer(newObj.original);
              } else {
                const tempOffers = [...offers];
                console.log(
                  "MY TEMP OFFERS LENGTH BEFORE: ",
                  tempOffers.length
                );

                // alert(JSON.stringify(tempOffers));
                const insert = (arr, index, newItem) => [
                  ...arr.slice(0, index),

                  newItem,

                  ...arr.slice(index),
                ];
                let elementToRemoveIndex = selectedTableOffer?.index;

                // alert(elementToRemoveIndex);

                if (elementToRemoveIndex == null) {
                  elementToRemoveIndex = tempOffers?.length - 1;
                }
                // tempOffers.pop();
                const removedElementArr = _.pullAt(
                  tempOffers,
                  elementToRemoveIndex
                );
                // alert(JSON.stringify(tempOffers));
                // alert(JSON.stringify(removedElementArr));

                const modifiedArray = insert(tempOffers, elementToRemoveIndex, {
                  ...selectedTableOffer?.original,
                  ...values,
                });

                // alert(JSON.stringify(modifiedArray));

                console.log("TEMP OFFERS", modifiedArray);
                setCustomOffers([...modifiedArray]);
                const newObj = { ...selectedTableOffer };
                newObj.original = {
                  ...selectedTableOffer?.original,
                  ...values,
                };

                // alert(JSON.stringify(newObj));

                updateSelectedTableOffer(newObj);

                // alert(JSON.stringify(selectedTableOffer));
                // alert("END OF ELSE");
                console.log("MY TEMP OFFERS LENGTH After: ", tempOffers.length);
              }

              if (isAddingOffer && myLength > 0) {
                const index: number = myLength - 1;
                const newSelectedRows = {
                  ...selectedOffersRowIds,
                };
                newSelectedRows[index] = true;
                updateSelectedOffersRows(newSelectedRows);
              }

              if (salesManager && formData.verificationStatus !== "approved") {
                await updateOffer(values);
              }

              addPageIndex();
              setIsAddingOffer(false);
            }}
          >
            {({ isSubmitting, handleChange, handleBlur, values, errors }) => (
              <div style={{ background: "#ffffff", marginBottom: "20px" }}>
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <FormikInput
                      label="Offer Text"
                      type="text"
                      name="offer_text"
                      placeholder="10% of Total"
                      disabled={salesManager}
                    />
                    <div
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Program
                    </div>

                    <MultiSelectAll
                      options={
                        formData?.country !== "UAE" &&
                        formData?.country !== "Saudi"
                          ? programArray.map((program) => {
                              return {
                                label: program,
                                value: program,
                              };
                            })
                          : programArrayDubai.map((program) => {
                              return {
                                label: program,
                                value: program,
                              };
                            })
                      }
                      value={programState}
                      onChange={(e) => {
                        setProgramState(e);
                        delete errors["program"];
                      }}
                    />
                    {check == true && programState.length < 1 && (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        Program is Required
                      </span>
                    )}
                    <FormikInput
                      label="Remarks"
                      type="text"
                      name="remarks"
                      placeholder="10% of Total"
                    />
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Link Stores
                    </div>
                    <MultiSelectAll
                      options={selectedStores.map((store) => {
                        return {
                          label: store?.store_name,
                          value: store,
                        };
                      })}
                      value={state}
                      onChange={(e) => {
                        setSelectedState(e);
                        delete errors["linkStore"];
                      }}
                    />
                    {check == true && state.length < 1 && (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        Link Store is Required
                      </span>
                    )}
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Offer Validity Start Date
                    </div>
                    <input
                      type="date"
                      id="offer_validity_startDate"
                      name="offer_validity_startDate"
                      onChange={handleChange}
                      defaultValue={values.offer_validity_startDate ?? ""}
                      onBlur={handleBlur}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Offer Validity End Date
                    </div>
                    <input
                      type="date"
                      id="offer_validity_endDate"
                      name="offer_validity_endDate"
                      onChange={handleChange}
                      defaultValue={values.offer_validity_endDate ?? ""}
                      onBlur={handleBlur}
                    />
                    <FormikSelect
                      label="Offer Exclusion"
                      name="offerExclusion.name"
                      options={offerExclusion.map((e) => e.name)}
                      onChange={(e) => {
                        let selected = offerExclusion.filter(
                          (ee) => ee.name === e.target.value
                        );
                        console.log("selected tnc ", selected);
                        values.offerExclusion = selected.at(0);
                        console.log("juk", values.offerExclusion);
                        refreshComponent((prev) => !prev);
                      }}
                    />
                    {values?.offerExclusion?.id !== -1 && (
                      <Field
                        label=""
                        as="textarea"
                        name="offerExclusion.value"
                        placeholder=""
                        style={{
                          fontSize: "13px",
                        }}
                      />
                    )}
                    <FormikSelect
                      label="Terms of Use"
                      name="termOfUse.name"
                      options={termsOfUse.map((e) => e.name)}
                      onChange={(e) => {
                        let selected = termsOfUse.filter(
                          (ee) => ee.name === e.target.value
                        );
                        console.log("selected tnc ", selected);
                        values.termOfUse = selected.at(0);
                        console.log("juk", values.termOfUse);
                        refreshComponent((prev) => !prev);
                      }}
                    />
                    {values?.termOfUse?.id !== -1 && (
                      <Field
                        label=""
                        as="textarea"
                        name="termOfUse.value"
                        placeholder=""
                        style={{
                          fontSize: "13px",
                        }}
                      />
                    )}
                    <FormikSelect
                      label="Redemption Process"
                      name="redemption.name"
                      options={redemptionProcess.map((e) => e.name)}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let selected = redemptionProcess.filter(
                          (ee) => ee.name === e.target.value
                        );
                        console.log("selected redemption ", selected);
                        values.redemption = selected.at(0);
                        refreshComponent((prev) => !prev);
                      }}
                    />
                    {values?.redemption?.id !== -1 && (
                      <Field
                        label=""
                        as="textarea"
                        name="redemption.value"
                        placeholder=""
                        style={{
                          fontSize: "13px",
                        }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        gap: "10px",
                      }}
                    >
                      <GoBackBtn
                        onClick={() => {
                          setMasterFormData(formData);
                          if (offers.length > 0) {
                            setCustomPageIndex(7);
                          } else {
                            subtractPageIndex();
                          }
                        }}
                      />
                      <button
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        Next
                      </button>
                      {salesManager && (
                        <LoadingButton
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #405089",
                            background: "rgb(64,81,167)",
                            outlineColor: "navajowhite",
                            fontSize: "13px",
                            // width: "1px",
                            height: "35px",
                          }}
                          text={
                            formData.verificationStatus !== "approved"
                              ? "Save and Exit"
                              : "Exit"
                          }
                          type="button"
                          onClick={async () => {
                            setUpdateForLoading(true);
                            if (formData.verificationStatus !== "approved") {
                              await updateOffer(values);
                              const { objectId } = JSON.parse(
                                localStorage.getItem("user")
                              );
                              await ServerUploadsUtils.unlockVerifier(
                                formData.agreementId,
                                objectId,
                                formData.merchantId
                              );
                            }
                            setUpdateForLoading(false);
                            navigate("/");
                          }}
                          isLoading={updateForLoading}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
