import { Formik, Form, FieldArray } from "formik";
import FormikInput from "../../../components/FormikInput";
import { validationSchema } from "./validationSchema";
import { useMerchantContext } from "../../../context/merchant-context";
import FormikSelect from "../../../components/FormikSelect";
import Multiselect from "../../../components/Multiselect";
import { FaGreaterThan } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";
import Text from "../../../components/Text";
import { useAppContext } from "../../../context/app-context";
import { useEffect, useState } from "react";
import GoBackBtn from "../../../components/GoBackBtn";
import _ from "lodash";
import ServerUploadsUtils from "../../../utils/serverUploads";
import LoadingButton from "../../../components/LoadingButton";
import AxiosUtils from "../../../utils/AxiosUtils/axiosUtils";
import Urls from "../../../utils/urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const initialValuesObj = {
  store_address: "",
  store_name: "",
  gst_no: "",
  category: "",
  amenities: "",
  subcategory: "",
  menu: "",
  zomato_rating: "",
  interior_images: "",
  rating_no: "",
  rating_platform: "",
  locality: "",
  store_state: "",
  store_city: "",
  store_country: "",
  panNumber: "",
  store_pincode: "",
  pan_certificate: "",
  gst_certificate: "",
  trade_license_number: "",
  trade_license_certificate: "",
  vat_number: "",
  vat_certificate: "",
};

const fieldNames = [
  "store_address",
  "store_name",
  "gst_no",
  "category",
  "amenities",
  "subcategory",
  "menu",
  "zomato_rating",
  "interior_images",
  "rating_no",
  "rating_platform",
  "locality",
  "store_state",
  "store_city",
  "store_country",
  "panNumber",
  "store_pincode",
  "pan_certificate",
  "gst_certificate",
  "trade_license_number",
  "trade_license_certificate",
  "vat_number",
  "vat_certificate",
];

const amenitiesArray = {
  Retail: [
    "Air Conditioning",
    "Card Payment Accepted",
    "Free Wifi",
    "Qr Payment Accepted",
  ],
  "Health & Wellness": [
    "Air Conditioning",
    "Card Payment Accepted",
    "Free Wifi",
    "Qr Payment Accepted",
  ],
  Entertainment: [
    "Air Conditioning",
    "Card Payment Accepted",
    "Qr Payment Accepted",
    "Valet Parking Available",
  ],
  Accomodation: [
    "Air Conditioning",
    "Card Payment Accepted",
    "QR Payment Accepted",
    "Valet Parking Available",
  ],
  Dining: [
    "4/5 Star",
    "Above 18 Only",
    "Afternoon Tea",
    "All Day Breakfast",
    "Beer",
    "Board Games",
    "Breakfast",
    "Brunch",
    "Buffet",
    "Byob",
    "Celebrity Frequented",
    "Craft Beer",
    "Dance Floor",
    "Delivery Only",
    "Desserts And Bakes",
    "Disabled Friendly",
    "Dog Friendly",
    "Drinking Age Applicable",
    "Free Parking",
    "Free Wifi",
    "Full Bar Available",
    "Gaming Area",
    "Gastro Pub",
    "Gluten Free Options",
    "Home Delivery",
    "Indoor Seating",
    "Karaoke",
    "Keto Options",
    "Kid Friendly",
    "Lgbtqia Friendly",
    "Live Entertainment",
    "Live Music",
    "Live Sports Screening",
    "Luxury Dining",
    "Nightlife",
    "No Alcohol Available",
    "Non Halal",
    "Outdoor Seating",
    "Paid Parking",
    "Pet Friendly",
    "Pool Table",
    "Poolside",
    "Pork Free",
    "Pre-Ordering Required",
    "Private Dining Area Available",
    "Private Parking",
    "Resto Bar",
    "Restricted Entry",
    "River View",
    "Rooftop",
    "Seaside",
    "Seating Not Available",
    "Seaview",
    "Self Service",
    "Serves Alcohol",
    "Serves Cocktails",
    "Serves Halal",
    "Serves Jain Food",
    "Serves Non Veg",
    "Serves Organic Food",
    "Shisha",
    "Smoking Area",
    "Speakeasy",
    "Sports Bar",
    "Standing Tables",
    "Subscription Available",
    "Sugar Free Options",
    "Table Reservation Not Required",
    "Table Reservation Required",
    "Takeaway Available",
    "Takeaway Only",
    "Takeout Available",
    "Unlimited Pizza",
    "Valet Parking Available",
    "Variable Menu",
    "Vegan Options",
    "Vegetarian Friendly",
    "Vegetarian Only",
    "Waterfront",
    "Wheelchair Accessible",
    "Wifi",
    "Wine",
    "Wine And Beer",
    "Wine Tasting",
  ],
};

const subcategoriesArray = {
  Retail: [
    "Accessories",
    "Apparels",
    "Appliances",
    "Bags & Luggage",
    "Computer Accessories",
    "Cosmetics",
    "Electronics",
    "Footwear",
    "Gift Store",
    "Handicraft",
    "Handloom",
    "Home Appliances",
    "Jewellery",
    "Leather Good Store",
    "Luggage Store",
    "Mobile & Accessories",
    "Opticals",
    "Perfume",
    "Kids Toy Store",
    "Sports Accessories",
    "Daily Needs",
    "General Store",
    "Super Market",
    "Stationery",
    "Home Décor",
    "Home Goods Store",
    "Mattress Store",
    "Home Furnishing",
    "Watches",
    "Craft Store",
    "Furniture",
    "Bicycle Store",
    "Car Accessories",
    "Crockery",
    "Handlooms",
    "Kids & Toys",
    "Manufacturer",
    "Mobile & Accessories",
    "Mobile Accessories",
    "Pet Store",
    "Stationery",
    "Toys Store",
    "Watch & Accessories",
    "Entertainment",
  ],
  "Health & Wellness": [
    "Dental Clinic",
    "Fitness Centre Gym",
    "Diagnostic Centre",
    "Pharmacy",
    "Nutrition",
    "Salon",
    "Spa",
    "Hospitals",
    "Aerobics Center",
    "Zumba Classes",
    "Meditation Center",
    "Salon & Spa",
    "Beauty Parlour",
    "Fitness & Sports",
    "Clinic",
    "Ayurvedic Centre",
    "Nutrition",
    "Opticals",
    "Gym",
  ],
  Entertainment: [
    "Gaming Center",
    "Game Zone",
    "Kids Playground",
    "Escape Room",
    "Amusement Park",
    "Water Park",
    "Zoo",
    "Museum",
    "Theater",
  ],
  Accomodation: ["5 Star", "4 Star", "Premium Chain", "Regional", "Popular"],
  Dining: [
    "Casual Dining",
    "Quick Bites",
    "Fine Dining",
    "Brewery",
    "QSR",
    "Lounge",
    "Bar",
    "Pub",
    "Cafe",
    "Fast Food",
    "Café",
    "Sports Bar",
  ],
  "Home Furnishing": [
    "Home Goods Store",
    "Mattress Store",
    "Furniture",
    "Home Décor",
    "Home Appliances",
  ],
  Grocery: ["Super Market"],
};

export default function AddStore() {
  const [initialValues, setInitialValues] = useState(initialValuesObj);
  const [uploadingFileForMenu, setUploadingFileForMenu] = useState(false);
  const [uploadingFileForPan, setUploadingFileForPan] = useState(false);
  const [uploadingFileForGst, setUploadingFileForGst] = useState(false);
  const [uploadingFileForTrade, setUploadingFileForTrade] = useState(false);
  const [uploadingFileForZomato, setUploadingFileForZomato] = useState(false);
  const [uploadingFileForVat, setUploadingFileForVat] = useState(false);
  const [, setUploadSuccess] = useState(false);
  const [refresh, refreshComponent] = useState(false);
  const [uploadingFileForImages, setUploadingFileForImages] = useState(false);
  const [category, setCategory] = useState("");
  const [updateForLoading, setUpdateForLoading] = useState(false);
  const [salesManager, setSalesManager] = useState(false);

  const navigate = useNavigate();

  const merchantContext = useMerchantContext();
  const { formData, isAddingStore, selectedTableStore } = merchantContext.state;
  const {
    setFormData,
    addPageIndex,
    subtractPageIndex,
    updateSelectedTableStore,
    setCustomPageIndex,
  } = merchantContext;
  const context = useAppContext();
  const { formData: updateFormData, stores, isEditing } = context.state;
  const {
    updateFormData: setMasterFormData,
    addToStore,
    setCustomStore,
  } = context;

  console.log("selectedTableStore?.original", selectedTableStore?.original);

  useEffect(() => {
    let updateFormData: any = selectedTableStore?.original;
    console.log("Stores ", stores[stores?.length - 1]);
    if (isEmpty(updateFormData) || isAddingStore) {
      // alert("True");
      setInitialValues(initialValuesObj);
    } else {
      // alert("False");
      setInitialValues({
        store_address: updateFormData?.store_address,
        store_name: updateFormData?.store_name,
        gst_no: updateFormData?.gst_no,
        category: updateFormData?.category,
        amenities: updateFormData?.amenities,
        subcategory: updateFormData?.subcategory,
        menu: updateFormData?.menu,
        zomato_rating: updateFormData?.zomato_rating,
        interior_images: updateFormData?.interior_images,
        rating_no: updateFormData?.rating_no,
        rating_platform: updateFormData?.rating_platform,
        locality: updateFormData?.locality,
        store_state: updateFormData?.store_state,
        store_city: updateFormData?.store_city,
        store_country: updateFormData?.store_country,
        panNumber: updateFormData?.panNumber,
        store_pincode: updateFormData?.store_pincode,
        pan_certificate: updateFormData?.pan_certificate,
        gst_certificate: updateFormData?.gst_certificate,
        trade_license_number: updateFormData?.trade_license_number,
        trade_license_certificate: updateFormData?.trade_license_certificate,
        vat_number: updateFormData?.vat_number,
        vat_certificate: updateFormData?.vat_certificate,
      });
    }
  }, [stores]);

  useEffect(() => {
    const { SalesHandler } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    console.log("Hii", JSON.parse(localStorage.getItem("userPermissions")));
    console.log("SalesHandler", SalesHandler);
    if (SalesHandler) {
      setSalesManager(true);
    }
  }, []);

  const areEqual = (value1, value2) => {
    // Compare arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // Compare objects
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }

    // For other types, use strict equality (===)
    return value1 === value2;
  };

  const updateStore = async (values: any) => {
    let isNotEqual = false;
    // Initialize an array to store unequal fields

    const fieldLogs = []; // Initialize an array to store field change logs

    const storeData = selectedTableStore?.original;
    const unequalFieldsObject = {};
    for (const fieldName of fieldNames) {
      const value1 = values[fieldName];
      const value2 = storeData[fieldName];

      if (!areEqual(value1, value2)) {
        isNotEqual = true;
        unequalFieldsObject[fieldName] = values[fieldName];
        fieldLogs.push({
          fieldName,
          oldValue: value2,
          newValue: value1,
        });
      }
    }
    if (isNotEqual && Object.keys(unequalFieldsObject).length > 0) {
      const brandConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/stores/${storeData.objectId}`,
        unequalFieldsObject,
        {
          "Content-Type": "application/json",
        }
      );
      await axios(brandConfig);
      await ServerUploadsUtils.addLogs(
        "stores",
        storeData.objectId,
        fieldLogs,
        storeData?.store_name
      );
    }
  };

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          // marginTop: "70px",
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}>
            {" "}
            {isEditing ? "Submission" : "New Submission"}
          </a>
        </div>
      </div>
      <div>
        <div className="sub-text">
          <h3 className="mb-0 flex-grow-1" style={{ marginTop: "40px" }}>
            {isEditing ? "Edit Agreement" : "New Submission"}
          </h3>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fffeff",
          marginRight: "5%",
          marginLeft: "5%",
          padding: "30px 0",
          marginBottom: "30px",
          paddingTop: "35px",
        }}
      >
        <div className="form-box" style={{ textAlign: "center" }}>
          <Text color="#494f57" fontWeight="bold" fontSize="20px">
            {isAddingStore ? "Add Store" : "Edit Store"}
          </Text>
        </div>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={async (values) => {
              if (isAddingStore) {
                // myLength = myLength + 1;
                const newObj = { ...selectedTableStore };
                console.log("OLD OBJ", newObj);
                if (
                  newObj.hasOwnProperty("original") &&
                  newObj.original.hasOwnProperty("storeId")
                ) {
                  delete newObj["original"]["storeId"];
                }
                newObj.original = {
                  ...selectedTableStore?.original,
                  ...values,
                };
                // alert(JSON.stringify(newObj));
                console.log("New OBJ", newObj);
                updateSelectedTableStore(newObj);
                // addToStore({ ...values });
              } else {
                // const insert = (arr, index, newItem) => [
                //   ...arr.slice(0, index),

                //   newItem,

                //   ...arr.slice(index),
                // ];
                // const tempStore = [...stores];
                // const elementToRemoveIndex = selectedTableStore?.index;
                // // tempOffers.pop();
                // const removedElementArr = _.pullAt(
                //   tempStore,
                //   elementToRemoveIndex
                // );
                // const modifiedArray = insert(tempStore, elementToRemoveIndex, {
                //   ...selectedTableStore?.original,
                //   ...values,
                // });
                // setCustomStore([...modifiedArray]);
                const updatedStore = {
                  ...selectedTableStore,
                };

                updatedStore.original = {
                  ...selectedTableStore?.original,
                  ...values,
                };
                console.log("updatedStore", updatedStore);
                updateSelectedTableStore({ ...updatedStore });
              }
              if (salesManager && formData.verificationStatus !== "approved") {
                await updateStore(values);
              }
              if (formData?.mouType != "Offer") {
                setCustomPageIndex(14);
              } else {
                addPageIndex();
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <div style={{ background: "#ffffff", marginBottom: "20px" }}>
                <Form>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <FormikInput
                      label="Brand Name"
                      type="text"
                      name="brandName"
                      value={formData?.brandName}
                      disabled
                    />
                    <FormikInput
                      label="Store Name"
                      type="text"
                      name="store_name"
                      placeholder="Rakesh General Store"
                    />
                    <FormikInput
                      label="Locality"
                      type="text"
                      name="locality"
                      placeholder=""
                    />
                    <FormikInput
                      label="Store Address"
                      type="text"
                      name="store_address"
                      placeholder="New Delhi"
                      labelBtn={
                        <button
                          type="button"
                          onClick={() => {
                            if (formData.corporateAddress) {
                              values.store_address = formData.corporateAddress;
                              values.store_pincode =
                                formData.corporateAddressPincode;
                              values.store_city = formData.corporateAddressCity;
                              values.store_country =
                                formData.corporateAddressCountry;
                              values.store_state =
                                formData.corporateAddressState;
                            } else {
                              values.store_address = "";
                              values.store_pincode = "";
                              values.store_city = "";
                              values.store_state = "";
                            }
                            refreshComponent((prev) => !prev);
                          }}
                          style={{
                            outline: "none",
                            background: "transparent",
                            border: "none",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Same As Corporate Address
                        </button>
                      }
                    />
                    <FormikInput
                      label="City"
                      type="text"
                      name="store_city"
                      placeholder=""
                    />
                    <FormikInput
                      label="State"
                      type="text"
                      name="store_state"
                      placeholder="New Delhi"
                    />
                    <FormikInput
                      label="Country"
                      type="text"
                      name="store_country"
                      placeholder=""
                    />
                    <FormikInput
                      label="PinCode"
                      type="text"
                      name="store_pincode"
                      placeholder=""
                    />
                    {formData?.country !== "UAE" &&
                      formData?.country !== "Saudi" && (
                        <>
                          <FormikInput
                            label="Pan Number"
                            type="text"
                            name="panNumber"
                            placeholder="IGJSY87785"
                          />
                          <FormikInput
                            label="PAN Card"
                            type="file"
                            name="pan_certificate"
                            useDefaultInput={true}
                            filename={values.pan_certificate}
                            onChange={async (e) => {
                              setUploadingFileForPan(true);
                              setUploadSuccess(false);
                              try {
                                values.pan_certificate =
                                  await ServerUploadsUtils.uploadImageToServer(
                                    e.target.files[0],
                                    e.target.files[0].name
                                  );
                                console.log(typeof e.target.files[0]);
                                const url = values.pan_certificate;
                                // console.log(url);
                                const str1 = url.substring(0, 4);
                                const str2 = url.substring(4);
                                values.pan_certificate = str1 + "s" + str2;
                                setUploadingFileForPan(false);
                                setUploadSuccess(true);
                              } catch (e) {
                                setUploadingFileForPan(false);
                              }
                            }}
                            disabled={uploadingFileForPan}
                          />
                          <FormikInput
                            label="GST No"
                            type="text"
                            name="gst_no"
                            placeholder="12hgjdjksd"
                          />
                          <FormikInput
                            label="GST Certificate"
                            type="file"
                            name="gst_certificate"
                            useDefaultInput={true}
                            filename={values.gst_certificate}
                            onChange={async (e) => {
                              setUploadingFileForGst(true);
                              setUploadSuccess(false);
                              try {
                                values.gst_certificate =
                                  await ServerUploadsUtils.uploadImageToServer(
                                    e.target.files[0],
                                    e.target.files[0].name
                                  );
                                console.log(typeof e.target.files[0]);
                                const url = values.gst_certificate;
                                // console.log(url);
                                const str1 = url.substring(0, 4);
                                const str2 = url.substring(4);
                                values.gst_certificate = str1 + "s" + str2;
                                console.log(
                                  "Gst Cetificate",
                                  values.gst_certificate
                                );
                                setUploadingFileForGst(false);
                                setUploadSuccess(true);
                              } catch (e) {
                                setUploadingFileForGst(false);
                              }
                            }}
                            disabled={uploadingFileForGst}
                          />
                        </>
                      )}
                    {formData?.country === "UAE" ||
                      (formData?.country === "Saudi" && (
                        <>
                          <FormikInput
                            label="Trade License Number"
                            type="text"
                            name="trade_license_number"
                            placeholder=""
                          />
                          <FormikInput
                            label="Trade License Certificate"
                            type="file"
                            name="trade_license_certificate"
                            useDefaultInput={true}
                            filename={values.trade_license_certificate}
                            onChange={async (e) => {
                              setUploadingFileForTrade(true);
                              setUploadSuccess(false);
                              try {
                                values.trade_license_certificate =
                                  await ServerUploadsUtils.uploadImageToServer(
                                    e.target.files[0],
                                    e.target.files[0].name
                                  );
                                console.log(typeof e.target.files[0]);
                                const url = values.trade_license_certificate;
                                // console.log(url);
                                const str1 = url.substring(0, 4);
                                const str2 = url.substring(4);
                                values.trade_license_certificate =
                                  str1 + "s" + str2;
                                setUploadingFileForTrade(false);
                                setUploadSuccess(true);
                              } catch (e) {
                                setUploadingFileForTrade(false);
                              }
                            }}
                            disabled={uploadingFileForTrade}
                          />
                          <FormikInput
                            label="VAT Number"
                            type="text"
                            name="vat_number"
                            placeholder=""
                          />
                          <FormikInput
                            label="VAT Certificate"
                            type="file"
                            name="vat_certificate"
                            useDefaultInput={true}
                            filename={values.vat_certificate}
                            onChange={async (e) => {
                              setUploadingFileForVat(true);
                              setUploadSuccess(false);
                              try {
                                values.vat_certificate =
                                  await ServerUploadsUtils.uploadImageToServer(
                                    e.target.files[0],
                                    e.target.files[0].name
                                  );
                                console.log(typeof e.target.files[0]);
                                const url = values.vat_certificate;
                                // console.log(url);
                                const str1 = url.substring(0, 4);
                                const str2 = url.substring(4);
                                values.vat_certificate = str1 + "s" + str2;
                                setUploadingFileForVat(false);
                                setUploadSuccess(true);
                              } catch (e) {
                                setUploadingFileForVat(false);
                              }
                            }}
                            disabled={uploadingFileForVat}
                          />
                        </>
                      ))}
                    <FormikSelect
                      label="Category"
                      name="category"
                      options={[
                        "Retail",
                        "Health & Wellness",
                        "Entertainment",
                        "Accomodation",
                        "Dining",
                        "Grocery",
                        "Home Furnishing",
                      ]}
                      onBlur={(event: any) => {
                        event.preventDefault();
                        console.log(event.target.value);
                        setFieldValue("subcategory", "");
                        initialValuesObj.category = event.target.value;
                        setFieldValue("amenities", "");
                        setCategory(event.target.value);
                      }}
                    />
                    <FieldArray
                      name="subcategory"
                      render={(arrayHelpers) => (
                        <Multiselect
                          label="Sub Categories"
                          name="subcategory"
                          options={subcategoriesArray[category]}
                          selectedValues={values.subcategory}
                          onSelect={(e, listItem) => {
                            arrayHelpers.push(listItem);
                          }}
                          onRemove={(e, listItem) => {
                            const elementToRemoveIndex =
                              values.subcategory.indexOf(listItem);
                            arrayHelpers.remove(elementToRemoveIndex);
                          }}
                        />
                      )}
                    />
                    <FieldArray
                      name="amenities"
                      render={(arrayHelpers) => (
                        <Multiselect
                          label="Amenities"
                          name="amenities"
                          options={amenitiesArray[category]}
                          selectedValues={values.amenities}
                          onSelect={(e, listItem) => {
                            arrayHelpers.push(listItem);
                          }}
                          onRemove={(e, listItem) => {
                            const elementToRemoveIndex =
                              values.category.indexOf(listItem);
                            arrayHelpers.remove(elementToRemoveIndex);
                          }}
                        />
                      )}
                    />
                    <FormikSelect
                      label="Rating Platform"
                      name="rating_platform"
                      options={["Zomato", "Tripadvisor", "Google", "Just Dial"]}
                    />

                    <FormikInput
                      label="Rating"
                      type="number"
                      pattern="^\d*(\.\d{0,2})?$"
                      name="rating_no"
                      placeholder="4.5"
                    />

                    <FormikInput
                      label="Rating"
                      type="file"
                      name="zomato_rating"
                      useDefaultInput={true}
                      filename={values.zomato_rating}
                      onChange={async (e) => {
                        setUploadingFileForZomato(true);
                        setUploadSuccess(false);
                        try {
                          values.zomato_rating =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          console.log(typeof e.target.files[0]);
                          const url = values.zomato_rating;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.zomato_rating = str1 + "s" + str2;
                          setUploadingFileForZomato(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFileForZomato(false);
                        }
                      }}
                      disabled={uploadingFileForZomato}
                    />

                    <FormikInput
                      label="Menu"
                      type="file"
                      name="menu"
                      useDefaultInput={true}
                      filename={values.menu}
                      onChange={async (e) => {
                        setUploadingFileForMenu(true);
                        setUploadSuccess(false);
                        try {
                          values.menu =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          console.log(typeof e.target.files[0]);
                          const url = values.menu;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.menu = str1 + "s" + str2;
                          setUploadingFileForMenu(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFileForMenu(false);
                        }
                      }}
                      disabled={uploadingFileForMenu}
                    />

                    <FormikInput
                      label="Interior Images"
                      type="file"
                      name="interior_images"
                      useDefaultInput={true}
                      filename={values.interior_images}
                      onChange={async (e) => {
                        setUploadingFileForImages(true);
                        setUploadSuccess(false);
                        try {
                          values.interior_images =
                            await ServerUploadsUtils.uploadImageToServer(
                              e.target.files[0],
                              e.target.files[0].name
                            );
                          console.log(typeof e.target.files[0]);
                          const url = values.interior_images;
                          // console.log(url);
                          const str1 = url.substring(0, 4);
                          const str2 = url.substring(4);
                          values.interior_images = str1 + "s" + str2;
                          setUploadingFileForImages(false);
                          setUploadSuccess(true);
                        } catch (e) {
                          setUploadingFileForImages(false);
                        }
                      }}
                      disabled={uploadingFileForImages}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        gap: "10px",
                      }}
                    >
                      <GoBackBtn
                        onClick={() => {
                          setMasterFormData(formData);
                          if (stores.length > 0) {
                            setCustomPageIndex(5);
                          } else {
                            subtractPageIndex();
                          }
                        }}
                      />
                      <button
                        style={{
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #405089",
                          background: "rgb(64,81,167)",
                          outlineColor: "navajowhite",
                          fontSize: "13px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        Next
                      </button>
                      {salesManager && (
                        <LoadingButton
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #405089",
                            background: "rgb(64,81,167)",
                            outlineColor: "navajowhite",
                            fontSize: "13px",
                            // width: "1px",
                            height: "35px",
                          }}
                          text={
                            formData.verificationStatus !== "approved"
                              ? "Save and Exit"
                              : "Exit"
                          }
                          type="button"
                          onClick={async () => {
                            setUpdateForLoading(true);
                            if (formData.verificationStatus !== "approved") {
                              await updateStore(values);
                              const { objectId } = JSON.parse(
                                localStorage.getItem("user")
                              );
                              await ServerUploadsUtils.unlockVerifier(
                                formData.agreementId,
                                objectId,
                                formData.merchantId
                              );
                            }
                            setUpdateForLoading(false);
                            navigate("/");
                          }}
                          isLoading={updateForLoading}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
