import * as Yup from "yup";
import RegexUtils from "../../../utils/regexUtils";

export const validationSchema = Yup.object({
  authorisedSignatoryName: Yup.string().required(
    "Authorized Signatory Name is required"
  ),
  authorisedSignatoryNo: Yup.number()
    .typeError("Authorised signatory number should be a number")
    .required("Authorized Signatory Number is required"),
  designation: Yup.string().required("Designation is required"),
  authorisedSignatoryMail: Yup.string()
    .email("Invalid email")
    .required("Authorized Signatory Mail is required"),
});
