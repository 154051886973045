import { FaGreaterThan } from "react-icons/fa";
import Text from "../../components/Text";
import { Field, Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, Table } from "react-bootstrap";
import { AiOutlineMinusSquare } from "react-icons/ai";
import ServerUploadsUtils from "../../utils/serverUploads";
import { v4 as uuidv4 } from "uuid";
import LoadingButton from "../../components/LoadingButton";
import ToastUtils from "../../utils/toast/toast-utils";
import AxiosUtils from "../../utils/AxiosUtils/axiosUtils";
import Urls from "../../utils/urls";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import * as XLSX from "xlsx";
import { validationSchema } from "./validationSchema";

const AuditForm = () => {
  const [initialValues, setInitialValues] = useState({
    date: "",
    outletName: "",
    address: "",
    personalMet: "",
    auditEmail: "",
    designation: "",
    contactNo: "",
    secondPersonInChargeName: "",
    secondPersonInChargeNo: "",
    externalStorePhotos: "",
    internalStorePhotos: "",
    latitude: "",
    longitude: "",
    signatoryContactNo: "",
  });
  const [resValues, setResValues] = useState<any>();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedValuesForColl, setCheckedValuesForColl] = useState([]);
  const [checkedValuesForHon, setCheckedValuesForHon] = useState([]);
  const [externalPhotos, setExternalPhotos] = useState<any>([]);
  const [internalPhotos, setInternalPhotos] = useState<any>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileUploadSuccess, setUploadSuccess] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isVisibleForAudit, setTableVisibleForAudit] = useState(true);
  const [isVisibleForCollateral, setTableVisibleForCollateral] = useState(true);
  const [loadingButtonForSubmit, setLoadingButtonForSubmit] = useState(false);
  const [loadingButtonForHold, setLoadingButtonForHold] = useState(false);
  const [auditType, setAuditType] = useState("");
  const [counts, setCounts] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [checkAuditType, setCheckAuditType] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [buttonType, setButtonType] = useState(null);
  const [isEditAudit, setIsEditAudit] = useState(false);
  const [isLoadingButtonForEdit, setIsLoadingButtonForEdit] = useState(false);

  console.log("dropDownValues", dropdownValues);

  console.log("counts", counts);
  console.log("externalPhotos", externalPhotos);
  console.log("internalPhotos", internalPhotos);
  console.log("resValues1", resValues);
  console.log("CheckedValues", checkedValues);

  const handleCountChange = (e) => {
    const { name, value } = e.target;
    console.log("Name And Value", name, value);
    setCounts({ ...counts, [name]: value });
  };

  const navigate = useNavigate();

  const toggleTableVisibilityForAudit = () => {
    setTableVisibleForAudit(!isVisibleForAudit);
  };

  console.log("auditType", auditType);

  const toggleTableVisibilityForCollateral = () => {
    setTableVisibleForCollateral(!isVisibleForCollateral);
  };

  const getCurrentLocation = async () => {
    const position: any = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    return { latitude, longitude };
    // Do something with latitude and longitude
  };

  const location = useLocation();

  useEffect(() => {
    const { auditData } = location.state;
    console.log("auditData", auditData);
    const updatedInitialValues = { ...initialValues };
    console.log("updatedInitialValues", updatedInitialValues);
    if (auditData) {
      updatedInitialValues.outletName = auditData?.store_name || "";
      updatedInitialValues.latitude = auditData?.latitude || "";
      updatedInitialValues.longitude = auditData?.longitude || "";
      updatedInitialValues.address = auditData?.store_address || "";
      updatedInitialValues.designation = auditData?.designation || "";
      updatedInitialValues.date = auditData?.date || "";
      updatedInitialValues.personalMet = auditData?.personalMet || "";
      updatedInitialValues.auditEmail = auditData?.auditEmail || "";
      updatedInitialValues.contactNo = auditData?.contactNo || "";
      updatedInitialValues.secondPersonInChargeName =
        auditData?.secondPersonInChargeName || "";
      updatedInitialValues.secondPersonInChargeNo =
        auditData?.secondPersonInChargeNo || "";
      updatedInitialValues.externalStorePhotos =
        auditData?.externalStorePhotos || "";
      updatedInitialValues.internalStorePhotos =
        auditData?.internalStorePhotos || "";
      updatedInitialValues.signatoryContactNo =
        auditData.signatoryContactNo || "";
    }
    setAuditType(auditData.type);
    setInitialValues(updatedInitialValues);
    setResValues({
      program: auditData.program || [],
      offer_text: auditData.offer_text,
      offerExclusion: auditData.offerExclusion,
      offer_validity_endDate: auditData.offer_validity_endDate,
      signatoryName: auditData.authorisedSignatoryName,
      designation: auditData.designation,
      items: auditData.items,
      id: auditData.objectId,
      status: auditData.status,
      brandName: auditData?.brandName,
      outletId: auditData?.outletId,
      editCount: auditData?.editCount,
    });
    setCount(Math.ceil(auditData.program.length / 5)); // Calculate the number of pages.
    if (auditData?.program?.length > 0) {
      setCheckedValues(
        auditData?.program?.map(({ id, remark }) => ({
          id,
          value: remark,
        }))
      );
    }
    if (auditData?.program?.length > 0) {
      setCheckedValuesForColl(
        auditData?.program
          ?.filter((item) => item.coll === "Yes") // Filter out items with coll !== 'Yes'
          .map(({ id }) => id)
      );
    }
    if (auditData?.program?.length > 0) {
      console.log(
        "Hon Exist",
        auditData?.program?.map(({ id, hon }) => ({
          id,
          value: hon,
        }))
      );
      setDropdownValues(
        auditData?.program?.map(({ id, hon }) => ({
          id,
          value: hon,
        }))
      );
    }
    setExternalPhotos(auditData.externalStorePhotos);
    setInternalPhotos(auditData.internalStorePhotos);
  }, [location]);

  const getCheckedValue = (id) => {
    const checkedValue = checkedValues.filter((obj) => obj.id === id);
    return checkedValue.length > 0 ? checkedValue[0].value : "";
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingPage(true);
      if (latitude === null || longitude === null) {
        // Location is not available
        const enableLocation = window.confirm(
          "Location is not available. Do you want to enable location services?"
        );
        console.log("Enable Location", enableLocation);
        if (enableLocation) {
          try {
            const { latitude, longitude } = await getCurrentLocation();
            setLatitude(latitude);
            setLongitude(longitude);
          } catch (error) {
            // Handle the error if getting the user's location fails
            console.error("Error getting location:", error);
            setLoadingPage(false);
          }
        } else {
          ToastUtils.showInfoToast(
            "Can't load audit page without fetching location"
          );
          navigate("/audit");
        }
        setLoadingPage(false);
      }
      setLoadingPage(false);
    };
    const { audit, checkAudit } = JSON.parse(
      localStorage.getItem("userPermissions")
    );
    if (audit) fetchData(); // Call the async function here
    else setCheckAuditType(checkAudit);
  }, []); // Empty dependency array to run the effect only once

  const addStampToImage = async (file, latitude, longitude) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(latitude, longitude, "In Function");

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();

        image.onload = async () => {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0);

          // Adding latitude and longitude to the image
          const locationFontSize = 14; // Set the desired font size for the location
          const locationFontStyle = "bold"; // Set the font style to bold
          const locationColor = "white"; // Set the font color to white
          ctx.font = `${locationFontStyle} ${locationFontSize}px Arial`; // Update the font style and size
          ctx.fillStyle = locationColor; // Update the font color

          ctx.fillText(`Latitude: ${latitude}`, 10, canvas.height - 90);
          ctx.fillText(`Longitude: ${longitude}`, 10, canvas.height - 60);

          // Create a timestamp outside the image.onload callback
          const currentDate = new Date();
          const date = currentDate.toLocaleDateString();
          const time = currentDate.toLocaleTimeString();
          const timestamp = `${date} ${time}`;

          // Set the timestamp font style and color
          const timestampFontSize = 14; // Set the desired font size for the timestamp
          const timestampFontStyle = "bold"; // Set the font style to bold
          const timestampColor = "white"; // Set the font color to white
          ctx.font = `${timestampFontStyle} ${timestampFontSize}px Arial`; // Update the font style and size
          ctx.fillStyle = timestampColor; // Update the font color

          // Calculate the position for the timestamp in the right bottom corner
          const textWidth = ctx.measureText(timestamp).width;
          const x = canvas.width - textWidth - 10;
          const y = canvas.height - 30;

          ctx.fillText(timestamp, x, y);

          const stampedBlob = await new Promise((resolve) => {
            canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.9);
          });

          if (!(stampedBlob instanceof Blob)) {
            return reject(new Error("Failed to generate stamped image."));
          }

          const stampedFile = new File([stampedBlob], "stamped_image.jpg", {
            type: "image/jpeg",
          });

          resolve(stampedFile);
        };

        // Create a temporary URL for the file to load it into the image
        const temporaryURL = URL.createObjectURL(file);

        // Ensure the image has loaded before setting the source
        image.src = temporaryURL;
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const handleSelectChange = (e, id) => {
    const selectedValue = e.target.value;
    const isIdPresent = dropdownValues.some((obj) => {
      console.log(obj?.id, id);
      return obj?.id === id;
    });
    console.log("isIdPresentForSelect", isIdPresent);
    if (isIdPresent) {
      // Value exists in the array, so remove it
      const updatedValues = dropdownValues.filter((obj) => obj.id !== id);
      console.log("updatedValues", updatedValues);
      updatedValues.push({
        id: id,
        value: selectedValue,
      });
      setDropdownValues(updatedValues);
    } else {
      // Value doesn't exist in the array, so add it
      setDropdownValues([
        ...dropdownValues,
        {
          id: id,
          value: selectedValue,
        },
      ]);
    }
  };

  const updateAudit = async (value: any) => {
    const keys = Object.keys(counts);
    const tempCounts = keys.map((key) => {
      return {
        [`${key.split("@")[0]}`]: counts[key],
      };
    });
    const updatedProgramForCheckedValues = resValues?.program?.map(
      (programItem) => {
        const matchingCheckedValue = checkedValues.find(
          (checkedItem) => checkedItem.id === programItem.id
        );
        // If a matching checkedValue is found, add the 'value' property to the program item
        if (matchingCheckedValue) {
          return { ...programItem, remark: matchingCheckedValue.value };
        }
        // If no matching checkedValue is found, return the original program item
        return programItem;
      }
    );

    const updatedProgramForHonaurable = updatedProgramForCheckedValues?.map(
      (programItem) => {
        const matchingCheckedValue = dropdownValues.find(
          (checkedItem) => checkedItem.id === programItem.id
        );
        // If a matching checkedValue is found, add the 'value' property to the program item
        if (matchingCheckedValue) {
          return { ...programItem, hon: matchingCheckedValue.value };
        }
        // If no matching checkedValue is found, return the original program item
        return programItem;
      }
    );

    const program = updatedProgramForHonaurable?.map((programItem) => {
      const collValue = checkedValuesForColl.includes(programItem.id)
        ? "Yes"
        : "No";
      return { ...programItem, coll: collValue };
    });

    const { username } = JSON.parse(localStorage.getItem("user"));
    const currentDate = new Date();

    var data = {
      date: value.date,
      outletName: value.outletName,
      address: value.address,
      personalMet: value.personalMet,
      auditEmail: value.auditEmail,
      designation: value.designation,
      contactNo: value.contactNo,
      secondPersonInChargeName: value.secondPersonInChargeName,
      secondPersonInChargeNo: value.secondPersonInChargeNo,
      externalStorePhotos: externalPhotos,
      internalStorePhotos: internalPhotos,
      program: program,
      status: buttonType,
      latitude: latitude,
      longitude: longitude,
      items: tempCounts,
      createdBy: username,
      submissionDate: currentDate.toISOString(),
      editCount: isEditAudit ? 1 : 0,
    };
    console.log("Program is Here", program);
    try {
      const updateAuditConfig = AxiosUtils.axiosConfigConstructor(
        "put",
        Urls.parseUrl + `/classes/audit/${resValues.id}`,
        data,
        {
          "Content-Type": "application/json",
        },
        null
      );
      await axios(updateAuditConfig);
      ToastUtils.showSuccessToast("Update Audit Successfully");
      navigate("/audit");
    } catch (error) {
      ToastUtils.showErrorToast(error.message);
    }
  };

  const fullDataDownload = (data: any) => {
    try {
      const rows = [];
      // Add header row including program headings
      const header = [
        "Date",
        "Brand Name",
        "Outlet Name",
        "Address",
        "Personal Met",
        "Email",
        "Designation",
        "Contact No",
        "Second Person Incharge Name",
        "Second Person Incharge Contact No",
        "Add External Store Photos",
        "Add Internal Store Photos",
        "Signatory Contact No",
        "Latitude",
        "Longitude",
        "Program", // Add program headings
        "Offer Details",
        "Offer Exclusion",
        "End Date",
        "Coll. Visi. (Y/N)",
        "Hon. (Y/N)	",
        "Signatory Name",
        "Designation",
        "Remarks",
        "Items",
      ];
      rows.push(header);
      const externalPhotos =
        data?.externalStorePhotos?.length > 0
          ? data?.externalStorePhotos?.map((item) => item.data)
          : "";
      const internalPhotos =
        data?.internalStorePhotos?.length > 0
          ? data?.internalStorePhotos?.map((item) => item.data)
          : "";

      console.log("resValues", resValues);

      if (resValues?.program?.length > 0 && auditType != "Collateral") {
        resValues?.program?.map((program: any, index: number) => {
          console.log("resValues?.items", resValues?.items);
          console.log("Program", program);
          if (index == 0) {
            const row = [
              data?.date,
              resValues?.brandName,
              data?.outletName,
              data?.address,
              data?.personalMet,
              data?.auditEmail,
              data?.designation,
              data?.contactNo,
              data?.secondPersonInChargeName,
              data?.secondPersonInChargeNo,
              externalPhotos?.[index],
              internalPhotos?.[index],
              data?.signatoryContactNo,
              data?.latitude ? data?.latitude : latitude,
              data?.longitude ? data?.longitude : longitude,
              program.name,
              resValues?.offer_text,
              resValues?.offerExclusion,
              resValues?.offer_validity_endDate,
              program?.coll === "Yes" ? "Yes" : "No",
              program?.hon ? program?.hon : "",
              resValues?.signatoryName,
              resValues?.designation,
              program?.remark ? program?.remark : "",
              resValues?.items?.length > 0
                ? `${Object.keys(resValues?.items?.[index])[0]}: ${
                    Object.values(resValues?.items?.[index])[0]
                  }`
                : "",
            ];
            rows.push(row);
          } else {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              externalPhotos?.[index],
              internalPhotos?.[index],
              "",
              "",
              "",
              program.name,
              resValues?.offer_text,
              resValues?.offerExclusion,
              resValues?.offer_validity_endDate,
              program?.coll === "Yes" ? "Yes" : "No",
              program?.hon ? program?.hon : "",
              resValues?.signatoryName,
              resValues?.designation,
              program?.remark ? program?.remark : "",
              resValues?.items?.length > 0
                ? `${Object.keys(resValues?.items?.[index])[0]}: ${
                    Object.values(resValues?.items?.[index])[0]
                  }`
                : "",
              ,
            ];
            rows.push(row);
          }
        });
      } else {
        resValues.items.map((item, index) => {
          if (index == 0) {
            const row = [
              data?.date,
              resValues?.brandName,
              data?.outletName,
              data?.address,
              data?.personalMet,
              data?.auditEmail,
              data?.designation,
              data?.contactNo,
              data?.secondPersonInChargeName,
              data?.secondPersonInChargeNo,
              externalPhotos?.[index],
              internalPhotos?.[index],
              data?.signatoryContactNo,
              data?.latitude ? data?.latitude : latitude,
              data?.longitude ? data?.longitude : longitude,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              item ? `${Object.keys(item)[0]}: ${Object.values(item)[0]}` : "",
            ];
            rows.push(row);
          } else {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              externalPhotos?.[index],
              internalPhotos?.[index],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              item ? `${Object.keys(item)[0]}: ${Object.values(item)[0]}` : "",
            ];
            rows.push(row);
          }
        });
      }

      if (
        auditType === "Both" &&
        resValues?.program?.length < resValues?.items?.length
      ) {
        for (
          let i = resValues?.program?.length;
          i < resValues?.items?.length;
          i++
        ) {
          const row = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            externalPhotos?.[i],
            internalPhotos?.[i],
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            resValues?.items?.length > 0
              ? `${Object.keys(resValues?.items?.[i])[0]}: ${
                  Object.values(resValues?.items?.[i])[0]
                }`
              : "",
          ];
          rows.push(row);
        }
      }

      if (
        resValues?.program?.length > 0 &&
        auditType != "Collateral" &&
        auditType != "Both"
      ) {
        if (
          resValues?.program?.length < externalPhotos.length ||
          resValues?.program?.length < internalPhotos.length
        ) {
          for (
            let i = resValues?.program?.length;
            i < externalPhotos.length;
            i++
          ) {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              externalPhotos?.[i],
              internalPhotos?.[i],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ];
            rows.push(row);
          }
        }
        if (
          resValues?.program?.length < externalPhotos?.length &&
          resValues?.program?.length < internalPhotos?.length &&
          externalPhotos.length < internalPhotos.length
        ) {
          for (let i = externalPhotos?.length; i < internalPhotos.length; i++) {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              internalPhotos?.[i],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ];
            rows.push(row);
          }
        }
      } else if (auditType != "Both") {
        if (
          resValues?.items?.length < externalPhotos.length ||
          resValues?.items?.length < internalPhotos.length
        ) {
          for (
            let i = resValues?.items?.length;
            i < externalPhotos.length;
            i++
          ) {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              externalPhotos?.[i],
              internalPhotos?.[i],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ];
            rows.push(row);
          }
        }
        if (
          resValues?.items?.length < externalPhotos?.length &&
          resValues?.items?.length < internalPhotos?.length &&
          externalPhotos.length < internalPhotos.length
        ) {
          for (let i = externalPhotos?.length; i < internalPhotos.length; i++) {
            const row = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              internalPhotos?.[i],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ];
            rows.push(row);
          }
        }
      }

      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");

      // Write the workbook to an Excel file
      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      const file = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = `auditData.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error);
    }
  };

  if (loadingPage) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ background: "rgb(243,243,249)" }}>
      <div
        style={{
          background: "#fffeff",
          padding: "5px",
          paddingLeft: "15px",
          borderTop: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
            fontSize: "15px",
          }}
        >
          SUBMISSION
        </p>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "center",
          }}
        >
          <a
            style={{
              color: "black",
              marginTop: "0px !important",
              fontSize: "13px",
              textDecoration: "none",
            }}
            href="/"
          >
            Dashboard
          </a>
          <span>
            <FaGreaterThan style={{ fontSize: "8px", color: "#878a99" }} />
          </span>
          <a style={{ color: "#878a99", fontSize: "13px" }}> New Submission</a>
        </div>
      </div>
      {(auditType === "Audit" || auditType === "Both") && (
        <div
          style={{
            marginRight: "5%",
            marginLeft: "5%",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={toggleTableVisibilityForAudit}>
                {isVisibleForAudit ? "Hide Program" : "Show Program"}
              </Accordion.Header>
              <Accordion.Body>
                {Object.keys(resValues).length > 0 && (
                  <div
                    style={{
                      backgroundColor: "white",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        background: "white",
                      }}
                    >
                      <div style={{ overflowX: "auto" }}>
                        <Table responsive bordered style={{ minWidth: "100%" }}>
                          <thead>
                            <tr
                              style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                              }}
                            >
                              <th>S.No</th>
                              <th style={{ minWidth: "100px" }}>Program</th>
                              <th style={{ minWidth: "100px" }}>
                                Offer Details
                              </th>
                              <th style={{ minWidth: "120px" }}>
                                Offer Exclusion
                              </th>
                              <th style={{ minWidth: "90px" }}>End Date</th>
                              <th style={{ minWidth: "120px" }}>
                                Coll. Visi. (Y/N)
                              </th>
                              <th style={{ minWidth: "100px" }}>Hon. (Y/N)</th>
                              <th style={{ minWidth: "130px" }}>
                                Signatory Name
                              </th>
                              <th style={{ minWidth: "100px" }}>Designation</th>
                              <th
                                style={{
                                  minWidth: "100px",
                                  position: "sticky",
                                  left: 0,
                                  background: "white",
                                }}
                              >
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{ fontWeight: "400", fontSize: "13px" }}
                          >
                            {resValues?.program?.map(
                              (data: any, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}.</td>
                                  <td>{data.name}</td>
                                  <td>{resValues?.offer_text?.[index]}</td>
                                  <td>{resValues?.offerExclusion}</td>
                                  <td style={{ minWidth: "100px" }}>
                                    {resValues?.offer_validity_endDate?.[index]}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="coll"
                                      value="coll"
                                      checked={checkedValuesForColl.includes(
                                        data.id
                                      )}
                                      style={{
                                        width: "20px", // Adjust the width as needed
                                        height: "20px", // Adjust the height as needed
                                      }}
                                      onChange={(e) => {
                                        if (
                                          checkedValuesForColl.includes(data.id)
                                        ) {
                                          // Value exists in the array, so remove it
                                          const updatedValues =
                                            checkedValuesForColl.filter(
                                              (id) => id !== data.id
                                            );
                                          setCheckedValuesForColl(
                                            updatedValues
                                          );
                                        } else {
                                          // Value doesn't exist in the array, so add it
                                          setCheckedValuesForColl([
                                            ...checkedValuesForColl,
                                            data.id,
                                          ]);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        handleSelectChange(e, data.id)
                                      }
                                      value={
                                        (dropdownValues.filter(
                                          (obj) => obj.id === data.id
                                        ).length > 0 &&
                                          dropdownValues.filter(
                                            (obj) => obj.id === data.id
                                          ))[0]?.value || ""
                                      }
                                    >
                                      <option value="">Please select</option>
                                      <option value="Honoring">Honoring</option>
                                      <option value="Non Honoring">
                                        Non Honoring
                                      </option>
                                      <option value="Permanently Closed">
                                        Permanently Closed
                                      </option>
                                      <option value="Temporarily Closed">
                                        Temporarily Closed
                                      </option>
                                      <option value="Address Not found">
                                        Address Not found
                                      </option>
                                    </select>
                                  </td>
                                  <td>{resValues?.signatoryName}</td>
                                  <td>{resValues?.designation}</td>
                                  <td>
                                    <input
                                      type="text"
                                      id="checkMarks"
                                      name="checkMarks"
                                      onChange={(e: any) => {
                                        console.log("Data id", data);
                                        const isIdPresent = checkedValues.some(
                                          (obj) => {
                                            console.log(obj?.id, data?.id);
                                            return obj?.id === data?.id;
                                          }
                                        );
                                        console.log("isIdPresent", isIdPresent);
                                        if (isIdPresent) {
                                          // Value exists in the array, so remove it
                                          const updatedValues =
                                            checkedValues.filter(
                                              (obj) => obj.id !== data.id
                                            );
                                          console.log(
                                            "updatedValues",
                                            updatedValues
                                          );
                                          updatedValues.push({
                                            id: data?.id,
                                            value: e.target.value,
                                          });
                                          setCheckedValues(updatedValues);
                                        } else {
                                          // Value doesn't exist in the array, so add it
                                          setCheckedValues([
                                            ...checkedValues,
                                            {
                                              id: data?.id,
                                              value: e.target.value,
                                            },
                                          ]);
                                        }
                                      }}
                                      value={getCheckedValue(data.id)}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      {currentPage >= 0 && resValues?.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "10px",
                            paddingBottom: "20px",
                          }}
                        >
                          <button
                            className="page-item pagination-prev disabled"
                            onClick={async () => {
                              setCurrentPage(currentPage - 1);
                            }}
                            style={{
                              marginRight: "5px",
                              outline: 0,
                              background: "white",
                              border: "1px solid #e9ebec",
                              padding: "0.372rem 0.75rem",
                              fontSize: "13px",
                              borderRadius: "0.25rem",
                              color: currentPage > 0 ? "blue" : "#878a99",
                            }}
                            disabled={currentPage > 0 ? false : true}
                          >
                            Previous
                          </button>
                          <div
                            style={{
                              textAlign: "center",
                              margin: "3px",
                              backgroundColor: "#405189",
                              borderColor: "#405189",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0.372rem 0.75rem",
                              fontSize: "13px",
                              borderRadius: "0.25rem",
                            }}
                          >
                            {currentPage + 1}
                          </div>
                          <button
                            className="page-item pagination-prev disabled"
                            onClick={async () => {
                              setCurrentPage(currentPage + 1);
                            }}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              outline: 0,
                              background: "white",
                              border: "1px solid #e9ebec",
                              padding: "0.372rem 0.75rem",
                              fontSize: "13px",
                              borderRadius: "0.25rem",
                              color:
                                currentPage >= count - 1 ? "#878a99" : "blue",
                            }}
                            disabled={currentPage >= count - 1 ? true : false}
                          >
                            Next
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}

      {(auditType === "Collateral" || auditType === "Both") && (
        <div
          style={{
            marginRight: "5%",
            marginLeft: "5%",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={toggleTableVisibilityForCollateral}>
                {isVisibleForCollateral ? "Hide Collateral" : "Show Collateral"}
              </Accordion.Header>
              <Accordion.Body>
                <div
                  style={{
                    backgroundColor: "white",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      background: "white",
                    }}
                  >
                    <div style={{ overflowX: "auto" }}>
                      <Table responsive bordered style={{ minWidth: "100%" }}>
                        <thead>
                          <tr>
                            <th>Items</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resValues?.items?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {typeof item === "object"
                                  ? Object.keys(item)[0]
                                  : item}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={`${
                                    typeof item === "object"
                                      ? item[Object.keys(item)[0]]
                                      : `${item}@${index}`
                                  }`}
                                  value={
                                    typeof item === "object"
                                      ? item[Object.keys(item)[0]]
                                      : counts[`item@${index}`]
                                  }
                                  style={{ border: "1px solid white" }}
                                  onChange={(e) => handleCountChange(e)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    {currentPage >= 0 && resValues?.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingRight: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <button
                          className="page-item pagination-prev disabled"
                          onClick={async () => {
                            setCurrentPage(currentPage - 1);
                          }}
                          style={{
                            marginRight: "5px",
                            outline: 0,
                            background: "white",
                            border: "1px solid #e9ebec",
                            padding: "0.372rem 0.75rem",
                            fontSize: "13px",
                            borderRadius: "0.25rem",
                            color: currentPage > 0 ? "blue" : "#878a99",
                          }}
                          disabled={currentPage > 0 ? false : true}
                        >
                          Previous
                        </button>
                        <div
                          style={{
                            textAlign: "center",
                            margin: "3px",
                            backgroundColor: "#405189",
                            borderColor: "#405189",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0.372rem 0.75rem",
                            fontSize: "13px",
                            borderRadius: "0.25rem",
                          }}
                        >
                          {currentPage + 1}
                        </div>
                        <button
                          className="page-item pagination-prev disabled"
                          onClick={async () => {
                            setCurrentPage(currentPage + 1);
                          }}
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            outline: 0,
                            background: "white",
                            border: "1px solid #e9ebec",
                            padding: "0.372rem 0.75rem",
                            fontSize: "13px",
                            borderRadius: "0.25rem",
                            color:
                              currentPage >= count - 1 ? "#878a99" : "blue",
                          }}
                          disabled={currentPage >= count - 1 ? true : false}
                        >
                          Next
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}

      <div
        style={{
          marginRight: "5%",
          marginLeft: "5%",
          marginTop: "20px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (values: any, { setSubmitting }) => {
            setIsLoadingButtonForEdit(true);
            !isEditAudit && buttonType === "Hold"
              ? setLoadingButtonForHold(true)
              : setLoadingButtonForSubmit(true);
            // if (!externalPhotos || !internalPhotos) {
            //   ToastUtils.showInfoToast(
            //     "You have to upload atleast two photos in each options"
            //   );
            //   setLoadingButtonForSubmit(false);
            //   return;
            // }
            console.log("Audit hai", auditType);
            console.log(auditType === "Audit");
            console.log(
              "Length",
              externalPhotos?.length < 1,
              externalPhotos?.length
            );
            if (auditType === "Audit" || auditType === "Both") {
              let checkAuditStatus;
              dropdownValues?.forEach((data) => {
                if (!data?.value) {
                  checkAuditStatus = false;
                }
              });
              if (checkAuditStatus === false) {
                ToastUtils.showInfoToast(
                  "Please select audit status for Program"
                );
                !isEditAudit && buttonType === "Hold"
                  ? setLoadingButtonForHold(true)
                  : setLoadingButtonForSubmit(true);
                setIsLoadingButtonForEdit(false);
                return;
              }
            }
            if (
              auditType === "Audit" &&
              (externalPhotos?.length < 1 || !externalPhotos)
            ) {
              ToastUtils.showInfoToast(
                "You have to upload atleast one photos in External Store Photos"
              );
              !isEditAudit && buttonType === "Hold"
                ? setLoadingButtonForHold(true)
                : setLoadingButtonForSubmit(true);
              setIsLoadingButtonForEdit(false);

              return;
            }
            if (
              auditType === "Both" &&
              (externalPhotos?.length < 1 || !externalPhotos) &&
              (internalPhotos?.length < 1 || !internalPhotos)
            ) {
              ToastUtils.showInfoToast(
                "You have to upload atleast one photos in External Store Photos and Internal Store Photos"
              );
              !isEditAudit && buttonType === "Hold"
                ? setLoadingButtonForHold(true)
                : setLoadingButtonForSubmit(true);
              return;
            }
            if (
              auditType === "Collateral" &&
              (internalPhotos?.length < 1 || !internalPhotos)
            ) {
              ToastUtils.showInfoToast(
                "You have to upload atleast one photos in Internal Store Photos"
              );
              !isEditAudit && buttonType === "Hold"
                ? setLoadingButtonForHold(true)
                : setLoadingButtonForSubmit(true);
              setIsLoadingButtonForEdit(false);
              return;
            }
            if (auditType === "Audit" || auditType === "Both") {
              dropdownValues?.map((data) => {
                if (!data?.value) {
                  ToastUtils.showInfoToast(
                    "Please select audit status for program"
                  );
                  !isEditAudit && buttonType === "Hold"
                    ? setLoadingButtonForHold(true)
                    : setLoadingButtonForSubmit(true);
                  return;
                }
              });
            }
            if (latitude !== null && longitude !== null) {
              // Location is available, proceed with form submission
              // You can access latitude and longitude here: latitude, longitude
              // Submit the form data including latitude and longitude
              values.latitude = latitude;
              values.longitude = longitude;
              await updateAudit(values);
              !isEditAudit && buttonType === "Hold"
                ? setLoadingButtonForHold(true)
                : setLoadingButtonForSubmit(true);
            } else {
              // Location is not available
              const enableLocation = window.confirm(
                "Location is not available. Do you want to enable location services?"
              );
              console.log("Enable Location", enableLocation);
              if (enableLocation) {
                setSubmitting(true); // Prevent the form from submitting while waiting for location data.
                const { latitude, longitude } = await getCurrentLocation(); // Attempt to get the user's location again.
                values.latitude = latitude;
                values.longitude = longitude;
                await updateAudit(values);
                fullDataDownload(values);
              } else {
                ToastUtils.showInfoToast(
                  "We can't submit your form without fetching your location"
                );
                !isEditAudit && buttonType === "Hold"
                  ? setLoadingButtonForHold(true)
                  : setLoadingButtonForSubmit(true);
                setIsLoadingButtonForEdit(false);
                return;
              }
            }
            !isEditAudit && buttonType === "Hold"
              ? setLoadingButtonForHold(true)
              : setLoadingButtonForSubmit(true);
            setIsLoadingButtonForEdit(false);
          }}
        >
          {({ values, handleSubmit }) => (
            <div
              style={{
                background: "#ffffff",
                marginBottom: "20px",
                paddingBottom: "35px",
              }}
            >
              <div className="form-box" style={{ textAlign: "center" }}>
                <Text color="#494f57" fontWeight="bold" fontSize="20px">
                  {resValues?.brandName ? resValues?.brandName : "Audit"}
                </Text>
              </div>
              <Form>
                <div
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <FormikInput
                    label="Date"
                    type="date"
                    name="date"
                    placeholder=""
                  />
                  <FormikInput
                    label="Outlet Name"
                    type="text"
                    name="outletName"
                    placeholder=""
                    disabled={true}
                  />
                  <FormikInput
                    label="Address"
                    type="text"
                    name="address"
                    placeholder=""
                    disabled={true}
                  />
                  <FormikInput
                    label="Person Met"
                    type="text"
                    name="personalMet"
                    placeholder=""
                  />
                  <FormikInput
                    label="Email"
                    type="email"
                    name="auditEmail"
                    placeholder=""
                  />
                  <FormikInput
                    label="Designation"
                    type="text"
                    name="designation"
                    placeholder=""
                  />
                  <FormikInput
                    label="Contact No"
                    type="text"
                    name="contactNo"
                    placeholder=""
                  />
                  <FormikInput
                    label="Second Person Incharge Name"
                    type="text"
                    name="secondPersonInChargeName"
                    placeholder=""
                  />
                  <FormikInput
                    label="Signatory Contact No"
                    type="text"
                    name="signatoryContactNo"
                    placeholder=""
                    disabled={true}
                  />
                  <FormikInput
                    label="Second Person Incharge Contact No"
                    type="text"
                    name="secondPersonInChargeNo"
                    placeholder=""
                  />
                  {checkAuditType && (
                    <div>
                      <FormikInput
                        label="Latitude"
                        type="text"
                        name="latitude"
                        placeholder=""
                        disabled={true}
                      />
                      <FormikInput
                        label="Longitude"
                        type="text"
                        name="longitude"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                  )}
                  <div>
                    {(auditType === "Audit" || auditType === "Both") && (
                      <>
                        {" "}
                        <div
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          Add External Store Photos
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            margin: "5px 0",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          {externalPhotos?.map((slip) => (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <a
                                target="_blank"
                                href={slip.data}
                                rel="noreferrer"
                              >
                                File Upload Successfully
                              </a>
                              {!checkAuditType && (
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const filteredChargesSlip =
                                      externalPhotos?.filter(
                                        (selectedSlip) =>
                                          slip?.id !== selectedSlip?.id
                                      );

                                    setExternalPhotos(filteredChargesSlip);

                                    console.log(
                                      "FILTERED DATA BRO",
                                      filteredChargesSlip
                                    );
                                  }}
                                >
                                  <AiOutlineMinusSquare
                                    style={{ fontSize: "14px" }}
                                  />
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                        <FormikInput
                          label=""
                          type="file"
                          name="externalStorePhotos"
                          filename={""}
                          accept="image/*"
                          capture={"environment"}
                          value=""
                          onChange={async (e) => {
                            setUploadingFile(true);
                            setUploadSuccess(false);
                            try {
                              let latitudeLocation, longitudeLocation;
                              console.log("check null", latitude, longitude);
                              if (latitude === null || longitude === null) {
                                // Location is not available
                                const enableLocation = window.confirm(
                                  "Location is not available. Do you want to enable location services?"
                                );
                                console.log("Enable Location", enableLocation);
                                if (enableLocation) {
                                  const { latitude, longitude } =
                                    await getCurrentLocation(); // Attempt to get the user's location again.
                                  latitudeLocation = latitude;
                                  longitudeLocation = longitude;
                                  setLatitude(latitude);
                                  setLongitude(longitude);
                                } else {
                                  ToastUtils.showInfoToast(
                                    "You can't add photos without fetching your location"
                                  );
                                  setUploadingFile(false);
                                  return;
                                }
                              } else {
                                latitudeLocation = latitude;
                                longitudeLocation = longitude;
                              }

                              const modifiedImage = await addStampToImage(
                                e.target.files[0],
                                String(latitudeLocation),
                                String(longitudeLocation)
                              );
                              console.log("modifiedImage", modifiedImage);
                              console.log("name", e?.target?.files[0]?.name);
                              if (
                                latitudeLocation === undefined ||
                                longitudeLocation === undefined
                              ) {
                                ToastUtils.showInfoToast(
                                  "Location is required for timestamp"
                                );
                                setUploadingFile(false);
                                return;
                              }

                              try {
                                values.externalStorePhotos =
                                  await ServerUploadsUtils.uploadImageToServer(
                                    modifiedImage,
                                    `${resValues?.outletId}_external_${
                                      internalPhotos?.length
                                        ? internalPhotos?.length
                                        : 1
                                    }`
                                  );
                              } catch (error) {
                                console.error("Error uploading image:", error);
                                // Handle the error here
                              }
                              const url = values.externalStorePhotos;
                              // console.log(url);
                              const str1 = url.substring(0, 4);
                              const str2 = url.substring(4);
                              values.externalStorePhotos = str1 + "s" + str2;
                              console.log(
                                "externalStorePhotos",
                                values.externalStorePhotos
                              );
                              let myArr: Array<any>;
                              if (!externalPhotos) {
                                myArr = [];
                              } else {
                                myArr = [...externalPhotos];
                              }
                              console.log("myArr", myArr, {
                                id: uuidv4(),
                                data: values.externalStorePhotos,
                              });
                              myArr.push({
                                id: uuidv4(),
                                data: values.externalStorePhotos,
                              });
                              console.log("myArr", myArr);
                              setExternalPhotos(myArr);
                              console.log("New File");
                              setUploadingFile(false);
                              setUploadSuccess(true);
                            } catch (e) {
                              setUploadingFile(false);
                            }
                          }}
                          disabled={
                            uploadingFile || checkAuditType ? true : false
                          }
                        />
                      </>
                    )}

                    <>
                      <div
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                        }}
                      >
                        Add Internal Store Photos
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          margin: "5px 0",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {internalPhotos?.map((slip) => (
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <a
                              target="_blank"
                              href={slip.data}
                              rel="noreferrer"
                            >
                              File Upload Successfully
                            </a>
                            {!checkAuditType && (
                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const filteredChargesSlip =
                                    internalPhotos?.filter(
                                      (selectedSlip) =>
                                        slip?.id !== selectedSlip?.id
                                    );

                                  setInternalPhotos(filteredChargesSlip);

                                  console.log(
                                    "FILTERED DATA BRO",
                                    filteredChargesSlip
                                  );
                                }}
                              >
                                <AiOutlineMinusSquare
                                  style={{ fontSize: "14px" }}
                                />
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                      <FormikInput
                        label=""
                        type="file"
                        name="internalStorePhotos"
                        filename={""}
                        value=""
                        accept="image/*"
                        capture={"environment"}
                        onChange={async (e) => {
                          setUploadingFile(true);
                          setUploadSuccess(false);
                          try {
                            let latitudeLocation, longitudeLocation;
                            if (latitude === null || longitude === null) {
                              // Location is not available
                              const enableLocation = window.confirm(
                                "Location is not available. Do you want to enable location services?"
                              );
                              console.log("Enable Location", enableLocation);
                              if (enableLocation) {
                                const { latitude, longitude } =
                                  await getCurrentLocation(); // Attempt to get the user's location again.
                                console.log("latitude", longitude);
                                latitudeLocation = latitude;
                                longitudeLocation = longitude;
                                setLatitude(latitude);
                                setLongitude(longitude);
                              } else {
                                ToastUtils.showInfoToast(
                                  "You can't add photos without fetching your location"
                                );
                                setUploadingFile(false);
                                return;
                              }
                            } else {
                              latitudeLocation = latitude;
                              longitudeLocation = longitude;
                            }
                            console.log(
                              latitudeLocation,
                              longitudeLocation,
                              "before image"
                            );
                            console.log(e.target.files.length);
                            const modifiedImage = await addStampToImage(
                              e.target.files[0],
                              String(latitudeLocation),
                              String(longitudeLocation)
                            );
                            console.log("modifiedImage", modifiedImage);
                            if (
                              latitudeLocation === undefined ||
                              longitudeLocation === undefined
                            ) {
                              ToastUtils.showInfoToast(
                                "Location is required for timestamp"
                              );
                              setUploadingFile(false);
                              return;
                            }
                            values.internalStorePhotos =
                              await ServerUploadsUtils.uploadImageToServer(
                                modifiedImage,
                                `${resValues?.outletId}_internal_${
                                  internalPhotos?.length
                                    ? internalPhotos?.length
                                    : 1
                                }`
                              );
                            const url = values.internalStorePhotos;
                            console.log(url);
                            const str1 = url.substring(0, 4);
                            const str2 = url.substring(4);
                            values.internalStorePhotos = str1 + "s" + str2;
                            console.log(
                              "values.internalStorePhotos",
                              values.internalStorePhotos
                            );
                            let myArr: Array<any>;
                            if (!internalPhotos) {
                              myArr = [];
                            } else {
                              myArr = [...internalPhotos];
                            }
                            console.log(myArr);
                            myArr.push({
                              id: uuidv4(),
                              data: values.internalStorePhotos,
                            });
                            console.log(myArr);
                            setInternalPhotos(myArr);
                            console.log("New File");
                            setUploadingFile(false);
                            setUploadSuccess(true);
                          } catch (e) {
                            setUploadingFile(false);
                          }
                        }}
                        disabled={
                          uploadingFile || checkAuditType ? true : false
                        }
                      />
                    </>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {!resValues?.status ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            gap: "9px",
                          }}
                        >
                          <LoadingButton
                            style={{
                              color: "white",
                              borderRadius: "5px",
                              border: "1px solid #405089",
                              background: uploadingFile
                                ? "rgb(64,81,167)"
                                : "rgb(64,81,167)",
                              outlineColor: "navajowhite",
                              fontSize: "13px",
                              minWidth: "70px",
                              height: "35px",
                            }}
                            text={"Hold"}
                            type="button"
                            onClick={() => {
                              setButtonType("Hold");

                              handleSubmit();
                            }}
                            isLoading={loadingButtonForHold}
                            isDisabled={uploadingFile}
                          />
                          <LoadingButton
                            style={{
                              color: "white",
                              borderRadius: "5px",
                              border: "1px solid #405089",
                              background: uploadingFile
                                ? "rgb(64,81,167)"
                                : "rgb(64,81,167)",
                              outlineColor: "navajowhite",
                              fontSize: "13px",
                              minWidth: "70px",
                              height: "35px",
                            }}
                            text={"Submit"}
                            type="button"
                            onClick={() => {
                              setButtonType("Close");
                              handleSubmit();
                            }}
                            isLoading={loadingButtonForSubmit}
                            isDisabled={uploadingFile}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            gap: "9px",
                          }}
                        >
                          <LoadingButton
                            style={{
                              color: "white",
                              borderRadius: "5px",
                              border: "1px solid #405089",
                              background: uploadingFile
                                ? "rgb(64,81,167)"
                                : "rgb(64,81,167)",
                              outlineColor: "navajowhite",
                              fontSize: "13px",
                              minWidth: "70px",
                              height: "35px",
                            }}
                            text={"Download Audit"}
                            type="button"
                            onClick={() => {
                              console.log("Values", values.externalStorePhotos);
                              setButtonType("Close");
                              fullDataDownload(values);
                            }}
                            isLoading={!isEditAudit && loadingButtonForSubmit}
                            isDisabled={uploadingFile}
                          />
                          {!resValues?.editCount && (
                            <LoadingButton
                              style={{
                                color: "white",
                                borderRadius: "5px",
                                border: "1px solid #405089",
                                background: uploadingFile
                                  ? "rgb(64,81,167)"
                                  : "rgb(64,81,167)",
                                outlineColor: "navajowhite",
                                fontSize: "13px",
                                minWidth: "70px",
                                height: "35px",
                              }}
                              text={"Submit"}
                              type="button"
                              onClick={() => {
                                setButtonType("Close");
                                setIsEditAudit(true);
                                handleSubmit();
                              }}
                              isLoading={isLoadingButtonForEdit}
                              isDisabled={uploadingFile}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AuditForm;
